<template>
    <div id="language-selector">
        <select name="language-select" v-model="locale" @change="languageChange">
            <option v-for="lang in languages" :value="lang.languageCode" :key="lang.languageCode">
                {{ lang.languageCode }}
            </option>
        </select>
    </div>
</template>
<script src="./LanguageSelector.ts"></script>
<style lang="scss">
@import '@/variables';

$language-padding-y: 0.75rem;

#language-selector {
    position: relative;
    border-radius: $border-radius;
    font-size: 1rem;

    select {
        position: relative;
        display: block;
        padding: $language-padding-y 3.5rem $language-padding-y 1rem;
        border: none;
        outline: none;
        font-weight: 700;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    &:before {
        content: '\25BC';
        position: absolute;
        padding: $language-padding-y 1rem;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        font-size: $font-size-sm;
    }
}
</style>
