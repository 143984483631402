import { ContextLocation, ContextOption } from './Context';
import Question from './Question';

export default class Survey {
    public surveyId: number;

    public context?: string;
    public contextLocation?: ContextLocation;
    public contextOption?: ContextOption;

    public instrumentAlias: string = '';
    public instrumentName: string = '';
    public participantFirstName: string = '';

    public questions: Question[] = [];
    public groupQuestionsBySection: boolean = false;
    public surveyCompleted: boolean = false;
    public demographicsEnabled: boolean = false;
    public surveyPriority: number = null;
    public firstOpenDate?: string = '';

    public sectionTitleKey: string = 'generic.SECTION_{sectionId}_TITLE';
    public sectionSubTitleKey: string = 'generic.SECTION_{sectionId}_DESCRIPTION';

    public constructor(obj?: Partial<Survey>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
