import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import components from './generic/components/index';
import moment from 'moment';
import tooltip from './generic/directives/tooltip';
import VCalendar from 'v-calendar';
import { useAccordion } from 'vue3-rich-accordion';
import './registerServiceWorker';
import 'v-calendar/dist/style.css';
import 'vue3-rich-accordion/accordion-library-styles.css';
import sanitizeHtml from 'sanitize-html';
import { AppInsightsPlugin, AppInsightsPluginOptions } from 'vue3-application-insights';
import EnvService from '@/generic/services/EnvService';
import { BPopover } from 'bootstrap-vue-next';

const aiOptions: AppInsightsPluginOptions = {
    appName: 'bi-survey', // Prefix for route events
    connectionString: `${EnvService.getEnvironment().appInsightsConnectionString}`,
    router: router,
    trackAppErrors: true,
};

const app = createApp(App);
app.use(router)
    .use(store)
    .use(i18n)
    .use(components)
    .use(useAccordion)
    .use(VCalendar)
    .use(AppInsightsPlugin, aiOptions)
    .directive('tooltip', tooltip)
    .component('b-popover', BPopover)
    .mount('#survey');

app.config.globalProperties.$filters = {
    date(value) {
        return moment(value).format(`MMM Do 'YY`);
    },
    decorateList(value) {
        const newLine = '<br />';
        value = value.replace(/\n/g, newLine);
        const parts = value.split(newLine).filter(function (e) {
            return !!e;
        });

        return "<li class='item'>" + parts.join("</li><li class='item'>") + '</li>';
    },
};

app.directive('sanitize-html', {
    created(el, binding) {
        const { value } = binding;
        const options = {
            allowedTags: [
                // Headers
                'h1',
                'h2',
                'h3',
                'h4',
                'h5',
                'h6',
                // Styles
                'b',
                'i',
                'em',
                'strong',
                's',
                'sup',
                'sub',
                // Paragraph / Line-breaks
                'p',
                'br',
                'hr',
                // Anchor
                'a',
                // Image
                'img',
                // List
                'ol',
                'ul',
                'li',
                // Blockquote
                'blockquote',
                // Table
                'table',
                'tbody',
                'caption',
                'tr',
                'th',
                'td',
                // Misc
                'div',
                'span',
            ],
            allowedAttributes: {
                h1: ['id'],
                h2: ['id'],
                h3: ['id'],
                h4: ['id'],
                h5: ['id'],
                h6: ['id'],
                p: ['style'],
                a: ['href', 'target'],
                img: ['src', 'width', 'height', 'style'],
                div: ['id', 'class', 'style'],
            },
            allowedClasses: {
                '*': ['*'],
            },
        };

        el.innerHTML = sanitizeHtml(value, options);
    },
});
