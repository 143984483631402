<template>
    <div class="v-distribution-wrapper">
        <div
            v-for="(item, index) in chartData"
            :style="getStyleForDistributionOption(item)"
            :key="`v-distribution-${item.key + index}`"
            :id="`v-distribution-${item.key + index}`"
            :class="`v-distribution-item ${item.value == 0 ? 'line' : ''}`"
        >
            {{ item.label }}
            <b-popover id="distribution-popover" :target="`v-distribution-${item.key + index}`">
                <component v-if="item.demographicData" :is="popoverComponent" :chartData="item.demographicData"></component>
                <div v-else>{{ item.label }}</div>
            </b-popover>
        </div>
    </div>
</template>
<script src="./PercentageDistributionChart.ts"></script>
<style lang="scss">
#distribution-popover {
    max-width: 650px;
}
.v-distribution-wrapper {
    margin: auto;
    width: 80%;
    display: flex;
    height: 400px;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-items: flex-end;
    border-bottom: 1px solid black;
    .v-distribution-item {
        text-align: center;
    }

    .line::before {
        content: '';
        position: absolute;
        border-left: 1px solid black;
        height: 1rem;
        width: 0.75rem;
        margin-left: 10px;
        margin-top: 25px;
    }
}
</style>
