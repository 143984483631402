<template>
    <div v-if="options.length" style="width: 160px; margin: 0 auto;">
        <survey-select :value="compareTo" @change="onChange($event)">
            <option v-if="compareTo == 'default'" value="default">All respondents</option>
            <option v-else value="">All respondents</option>
            <option v-for="option in options" :key="option.label" :value="option.label">{{ option.question }}</option>
        </survey-select>
    </div>
    <div ref="containerRef" class="chart-container" :id="name"></div>
</template>
<script src="./TeamRadar.ts"></script>
<style lang="scss">
@import '@/variables';

.chart-container {
    padding: 2rem 0.5rem;
    margin: 0 -2rem 2rem -2rem;

    @media screen and (min-width: 768px) {
        padding: 2rem 1rem 0;
        margin: 0 0 1rem 0;
        border: none;
    }
}
</style>
