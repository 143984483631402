import { uniqueId } from '@/generic/helpers/UniqueIdHelper';
import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';
import RadioOption from '@/survey/models/RadioOption';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    methods: {
        optionSelected(givenOption: RadioOption) {
            this.question.answerOptions.forEach((element) => {
                element.checked = false;
            });

            this.question.answerOptions.find((option) => option.id === givenOption.id).checked = true;

            this.$emit('selected', givenOption);
        },
    },
    setup(props) {
        const id = uniqueId('radio');
        const { t } = useI18n();
        const radioOptions = computed(() => {
            props.question.answerOptions.forEach((option: RadioOption) => {
                option.label = t('surveyAnswers.' + option.alias);
            });

            if (props.question.horizontal) {
                const options = props.question.answerOptions;
                // IEVP requirement => Id 1 always last (positive right, negative left)
                return options.sort((a, b) => b.id - a.id);
            }
            return props.question.answerOptions;
        });

        const selectedOption = computed(() => {
            return props.question.answerOptions.find((option) => option.checked);
        });

        return { id, radioOptions, selectedOption };
    },
});
