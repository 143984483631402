import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        score: { type: Number },
    },
    setup(props) {
        const heat = (data: number) => {
            let color = '';
            const colors = {
                0: 'red',
                61: 'orange',
                76: 'light-green',
                86: 'green',
            };
            if (!data) {
                return 'secondary';
            }

            Object.keys(colors).forEach((key: string) => {
                if (data >= +key) {
                    color = colors[key];
                }
            });
            return color;
        };
        const color = heat(+props.score);
        return { color };
    },
});
