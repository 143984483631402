import { defineComponent } from 'vue';
import ReportVue from './Report.vue';
import PageVue from './Page.vue';
import ContentWrapperVue from '@/generic/components/ContentWrapper.vue';
import BarChartWrapperVue from '@/report/components/charts/BarChartWrapper.vue';
import BarChartVue from '@/report/components/charts/BarChart.vue';
import CategoryVue from '@/report/fearless/partials/category.vue';
import PrintToolbarVue from './partials/PrintToolbar.vue';
import IDateReport from '@/report/models/IDateReport';
import ISection from '@/report/models/ISection';
import MainLayoutView from '@/generic/views/MainLayoutView';

export const scores = (data: IDateReport, category: string) => {
    if (data && data.sections) {
        return data.sections.find((sec: ISection) => {
            return sec.category === category;
        });
    }
    return {};
};

const colors = ['light-green', 'green', 'blue', 'dark-blue', 'light-blue', 'green-blue', 'orange'];
export const barColor = (index: number) => {
    return colors[index % colors.length];
};

export default defineComponent({
    extends: MainLayoutView,
    components: {
        Report: ReportVue,
        Page: PageVue,
        ContentWrapper: ContentWrapperVue,
        BarChartWrapper: BarChartWrapperVue,
        BarChart: BarChartVue,
        Category: CategoryVue,
        PrintToolbar: PrintToolbarVue,
    },
    computed: {
        scores() {
            return scores;
        },
    },
});
