import router from '@/router';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'home',

    setup() {
        const { query } = useRoute();

        const surveyId = query.surveyId;
        const reportId = query.reportId;
        const projectId = query.projectId;
        const language = query.language;
        const showKeys = query.showKeys;
        const token = query.token;
        const view = query.view;

        if (surveyId && !reportId) {
            router.push({ name: 'login', params: { surveyId }, query: { token, language, showKeys } });
        } else if (!surveyId && reportId && !view) {
            router.push({ name: 'personal-report-login', params: { surveyId: reportId }, query: { token, language, showKeys } });
        } else if (!surveyId && reportId && view) {
            router.push({ name: 'team-report-login', params: { projectId }, query: { token, language, showKeys, view } });
        } else {
            router.push({ name: 'not-found' });
        }
    },
});
