import PillarThreshold from '../PillarThreshold';
import ReportListItem from './ReportListItem';

export default class ReportListResponse {
    public name: string = '';
    public projectId: number = 0;
    public reports: Array<ReportListItem> = [];

    public languages: ReportListLanguage[];
    public knowledgeModelAlias: string;
    public languageCode: string;
    public knowledgeModelName: string;
    public primaryColor: string;
    public secondaryColor: string;
    public pillarThresholds?: PillarThreshold[];
}

export class ReportListLanguage {
    public name: string;
    public languageCode: string;
}
