import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    setup() {
        const { query } = useRoute();
        const router = useRouter();

        router.push({
            name: 'report-list',
            query: {
                token: query.token,
                language: query.prefLang,
            },
        });
    },
});
