<template>
    <div v-for="(option, index) in question.answerOptions" :key="'option_' + index" class="survey-radio">
        <input
            type="radio"
            class="survey-radio-input"
            :id="`answer_${id}_${index}`"
            :name="`answer_${id}_${index}`"
            :value="option.alias"
            :checked="option.checked"
            required
            @input="optionSelected(option)"
        />
        <label class="survey-radio-label" :for="`answer_${id}_${index}`">
            <div class="survey-radio-control" :class="{ checked: option.checked }"></div>
            <span>{{ option.alias }}</span>
        </label>
    </div>
</template>
<script src="./DemographicRadio.ts"></script>
<style lang="scss">
@import '@/variables';
@import '@/scss/mixins';

.survey-radio {
    position: relative;
    margin-bottom: 1rem;

    &-input {
        position: absolute;
        left: 0;
        opacity: 0;
    }

    &-label {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        gap: 1rem;
    }

    &-control {
        position: relative;
        display: inline-block;
        border: 1px transparent solid;
        border-radius: $border-radius;
        width: 1.5rem;
        height: 1.5rem;
        box-sizing: content-box;
        flex-basis: 1.5rem;
        flex-grow: 0;
        flex-shrink: 0;

        &::before {
            display: block;
            position: absolute;
            top: 0.25rem;
            left: 0.25rem;
            width: 1rem;
            height: 1rem;
            content: '';
            border-radius: $border-radius-sm;
            transition: background 0.15s ease-in-out;
        }
    }

    @include horizontal {
        display: inline-flex;
        flex: 1 1 0;
        justify-content: center;
        margin-bottom: 0rem;

        .survey-radio-label {
            flex-direction: column-reverse;
            text-align: center;
            font-weight: bold;
        }

        &:not(.first) {
            .survey-radio-label {
                span {
                    display: none;
                }
            }
        }
    }
}
</style>
