<template>
    <survey-loader v-if="!loaded"
                   cover
                   spinner-size="lg" />
    <survey-alert v-else-if="hasError"
                  variant="error">
        Oh no! Something went wrong when trying to load your report
    </survey-alert>
    <template v-else>
        <component :is="view"
                   :key="loadedLanguage" />
        <print-toolbar :key="loadedLanguage" />
    </template>
</template>
<script src="./PersonalReport.ts"></script>