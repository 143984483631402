import { ref, defineComponent, onMounted, computed, ComputedRef } from 'vue';
import TeamCategoryVue from '@/report/fearless/partials/TeamCategory.vue';
import { NovoCategoryType } from '../models/NovoNordisk/NovoCategoryType';
import { useI18n } from 'vue-i18n';
import { setup } from './BaseTeamReport';
import { useStore } from 'vuex';
import BaseReportComponent, { scores, barColor } from '@/report/components/BaseReportComponent';
import ITeamReportResponse from '../models/ITeamReportResponse';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        TeamCategory: TeamCategoryVue,
    },
    setup() {
        const { t } = useI18n();
        const store = useStore();
        const report: ComputedRef<ITeamReportResponse> = computed(() => store.getters['report/report']);
        const context = ref('context');
        const { loadReport } = setup();

        const type = 'reportsWeb';
        const categories = [
            NovoCategoryType.Psychological,
            NovoCategoryType.Motivation,
            NovoCategoryType.Accountability,
            NovoCategoryType.Supportive,
            NovoCategoryType.Complexity,
        ];
        const reportLoaded = ref(false);
        const invitedParticipants = computed(() => report.value.invitedParticipants);
        const completedParticipants = computed(() => report.value.completedParticipants);

        const questionScores = computed(() => {
            return (category: NovoCategoryType) => {
                const section = report.value.dateReport.sections.find((s) => s.category === category);
                if (!section.questions) {
                    return [];
                }

                return section.questions.map((question) => {
                    return {
                        ...question,
                        question: t(`surveyQuestions.${question.question}`),
                    };
                });
            };
        });

        onMounted(async () => {
            await loadReport('fearless', 'novo_nordisk', 'novo_nordisk', 'Fearless Organization Scan');
            reportLoaded.value = true;
            const lang = store.getters.language;
            lang.section = 'survey';
            lang.reportAlias = null;
            await store.dispatch('setLanguage', lang);
        });

        return {
            report,
            context,
            NovoCategoryType,
            type,
            categories,
            reportLoaded,
            questionScores,
            invitedParticipants,
            completedParticipants,
            barColor,
            scores,
        };
    },
});
