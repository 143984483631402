import ISection from '@/report/models/ISection';
import ITeamReportResponse from '@/report/models/ITeamReportResponse';
import { LosCategoryType } from '@/report/models/LosCategoryType';
import { computed, ComputedRef, defineComponent } from '@vue/runtime-core';
import { useStore } from 'vuex';
import category from '@/report/fearless/partials/LosCategory.vue';
import score from '@/report/fearless/partials/LosScore.vue';
import BaseReportComponent from '@/report/components/BaseReportComponent';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        category,
        score,
    },
    props: {
        type: { type: String, default: 'reportsWeb' },
    },
    setup() {
        const store = useStore();
        const report: ComputedRef<ITeamReportResponse> = computed(() => store.getters['report/report']);
        const scores = computed(() => {
            return (category): ISection => {
                return report.value.dateReport.sections.find((sec: ISection) => {
                    return sec.category === category;
                });
            };
        });
        const average = computed(() => {
            return (categories) => {
                let count = 0;
                let teamScore = 0;

                categories.forEach((cat) => {
                    teamScore += scores.value(cat).teamMedian;
                    count++;
                });

                return teamScore / count;
            };
        });
        const score = computed(() => {
            return (category) => {
                const s = scores.value(category);
                return s.teamMedian;
            };
        });
        const participant = computed(() => {
            return 'Team';
        });
        const envCategories = [LosCategoryType.Safety, LosCategoryType.Appreciation, LosCategoryType.Openness, LosCategoryType.Reflection];
        const processCategories = [
            LosCategoryType.Experimentation,
            LosCategoryType.Infocollection,
            LosCategoryType.Analysis,
            LosCategoryType.Education,
            LosCategoryType.Infotransfer,
        ];
        const invitedParticipants = computed(() => report.value.invitedParticipants);
        const completedParticipants = computed(() => report.value.completedParticipants);

        return {
            scores,
            score,
            average,
            participant,
            envCategories,
            processCategories,
            LosCategoryType,
            invitedParticipants,
            completedParticipants,
        };
    },
});
