import QuestionHelper from '@/generic/helpers/QuestionHelper';
import MainLayoutView from '@/generic/views/MainLayoutView';
import Language from '@/store/models/Language';
import to from 'await-to-js';
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SurveyPrivacyVue from '../components/SurveyPrivacy.vue';
import SurveyQuestionsVue from '../components/SurveyQuestions.vue';
import Answer from '../models/Answer';
import { FinishSurveyStatus } from '../models/FinishSurveyStatus';
import Question from '../models/Question';
import Survey from '../models/Survey';
import SurveyListResponse from '../models/SurveyListResponse';
import { SurveyService } from '../surveyService';
import { setup, loadDemographicSurvey } from './BaseSurvey';
import { appInsightsHub } from '@/store/appInsights';
import BoundContextVue from '../components/BoundContext.vue';
import { ContextOption } from '../models/Context';

export default defineComponent({
    extends: MainLayoutView,
    components: {
        SurveyQuestions: SurveyQuestionsVue,
        SurveyPrivacy: SurveyPrivacyVue,
        BoundContext: BoundContextVue,
    },
    props: {
        surveyList: { type: SurveyListResponse },
    },
    setup(props) {
        const store = useStore();
        const { redirectToFinishPage } = setup();
        const loading = ref(true);
        const loaded = ref(false);
        const error = ref(false);
        const surveys = ref([] as Survey[]);
        const questions = ref([] as Question[]);
        const questionIndex = ref(0);
        const finishingSurvey = ref(false);
        const surveyCompleteError = ref('');
        const privacyAccepted = ref(false);
        const hasDemographicSection = ref(false);
        const totalSurveyDuration = ref(0);
        const disableBackNavigation = ref(false);
        const surveyList: Ref<SurveyListResponse> = ref(new SurveyListResponse());

        // Computed
        const activeSurvey = computed(() => {
            if (questions.value.length === 0) {
                return new Survey();
            }

            if (surveys.value.some((x) => x.contextOption === ContextOption.SurveyBound && !x.context)) {
                return surveys.value.find((x) => x.contextOption === ContextOption.SurveyBound && !x.context);
            }
            const question = questions.value[questionIndex.value];
            return surveys.value.find((s: Survey) => s.surveyId === question.surveyId);
        });

        const needsBoundContext = computed<boolean>(() => {
            return (
                activeSurvey.value &&
                activeSurvey.value.surveyId &&
                activeSurvey.value.contextOption === ContextOption.SurveyBound &&
                !activeSurvey.value.context
            );
        });

        const knowledgeModelName = computed(() => {
            return props.surveyList.knowledgeModelName;
        });

        const welcomeText = computed(() => {
            const language = new Language(store.getters.language);
            const text = props.surveyList && props.surveyList.welcomeTexts.find((x) => x.languageCode === language.languageCode);

            return text ? text.text : null;
        });

        // Methods
        watch(
            () => activeSurvey.value,
            async () => {
                if (!loaded.value) {
                    return;
                }

                const language = new Language(store.getters.language);
                language.instrumentAlias = activeSurvey.value.instrumentAlias;
                language.section = 'survey';

                await store.dispatch('setLanguage', language);
            },
        );

        const loadCountryOptions = async () => {
            const [err, list] = await to(new SurveyService().getCountries());
            return list.map((x, i) => new Answer({ ...x, id: i + 1, alias: x.name }));
        };

        const loadSurveys = async () => {
            const service = new SurveyService();
            surveys.value = [];

            for (let i = 0; i < props.surveyList.surveys.length; i++) {
                const surveyId = props.surveyList.surveys[i].surveyId;
                if (!props.surveyList.surveys[i].surveyCompleted) {
                    totalSurveyDuration.value += props.surveyList.surveys[i].surveyDuration;
                }

                if (props.surveyList.surveys[i].contextOption !== ContextOption.SurveyBound) {
                    const [surveyErr, surveyResponse] = props.surveyList.surveys[i].firstOpenDate
                        ? await to(service.getSurvey(surveyId))
                        : await to(service.startSurvey(surveyId));
                    await handleSurveyResponse(surveyErr, surveyResponse, surveyId, i);
                } else {
                    if (props.surveyList.surveys[i].context) {
                        const [surveyErr, surveyResponse] = await to(service.getSurvey(surveyId));
                        await handleSurveyResponse(surveyErr, surveyResponse, surveyId, i);
                    } else {
                        const item = props.surveyList.surveys[i];
                        const survey = new Survey({
                            surveyId: item.surveyId,
                            context: item.context,
                            contextOption: item.contextOption,
                            instrumentAlias: item.instrumentAlias,
                            firstOpenDate: item.firstOpenDate,
                            instrumentName: item.instrumentName,
                        });
                        surveys.value.push(survey);

                        if (i === 0) {
                            await store.dispatch(
                                'setLanguage',
                                new Language({
                                    languageCode: props.surveyList.languageCode,
                                    knowledgeModelAlias: props.surveyList.knowledgeModelAlias,
                                    knowledgeModelName: props.surveyList.knowledgeModelName,
                                    instrumentAlias: item.instrumentAlias,
                                    section: 'survey',
                                }),
                            );
                        }
                    }
                }
            }
            await handleDemographicSurvey();

            appInsightsHub.value.instance?.trackEvent({ name: 'load_surveys' }, { surveyIds: props.surveyList?.surveys?.map((x) => x.surveyId) });
        };

        const combineSurveyStart = async (item: Survey) => {
            const service = new SurveyService();
            surveyList.value.surveys.sort((a, b) => a.surveyPriority - b.surveyPriority);
            surveys.value = [];

            for (let i = 0; i < surveyList.value.surveys.length; i++) {
                const surveyId = surveyList.value.surveys[i].surveyId;
                if (!surveyList.value.surveys[i].surveyCompleted) {
                    totalSurveyDuration.value += surveyList.value.surveys[i].surveyDuration;
                }

                const [surveyErr, surveyResponse] =
                    surveyList.value.surveys[i].firstOpenDate && !item.context
                        ? await to(service.getSurvey(surveyId))
                        : await to(service.startSurvey(surveyId, item.context));
                await handleSurveyResponse(surveyErr, surveyResponse, surveyId, i);
            }
            await handleDemographicSurvey();
            combineSurveyQuestions();
            appInsightsHub.value.instance?.trackEvent({ name: 'load_surveys' }, { surveyIds: surveyList.value?.surveys?.map((x) => x.surveyId) });
        };

        const handleSurveyResponse = async (surveyErr: Error, surveyResponse: Survey, surveyId: number, i: number) => {
            if (!surveyErr) {
                const survey = new Survey(surveyResponse);
                survey.surveyId = surveyId;
                surveys.value.push(survey);

                if (i === 0) {
                    await store.dispatch(
                        'setLanguage',
                        new Language({
                            languageCode: surveyList.value.languageCode,
                            knowledgeModelAlias: surveyList.value.knowledgeModelAlias,
                            knowledgeModelName: surveyList.value.knowledgeModelName,
                            instrumentAlias: surveyResponse.instrumentAlias,
                            section: 'survey',
                        }),
                    );
                }
            } else {
                error.value = true;
            }
        };

        const handleDemographicSurvey = async () => {
            if (surveyList.value.demographicSurvey) {
                const demographicSurvey = await loadDemographicSurvey(surveyList.value);
                demographicSurvey.groupQuestionsBySection = surveys.value[0].groupQuestionsBySection;
                const countryQuestion = demographicSurvey.questions.find((x) => x.label.toLowerCase() === 'default_country');
                if (countryQuestion) {
                    countryQuestion.answerOptions = await loadCountryOptions();
                }
                surveys.value.push(demographicSurvey);

                totalSurveyDuration.value += surveyList.value.demographicSurvey.surveyDuration;
            }
        };

        const combineSurveyQuestions = () => {
            questions.value = [];
            surveys.value.forEach((survey: Survey) => {
                const combined = survey.questions.map((q: Question) => {
                    return new Question({
                        ...q,
                        priority: survey.surveyPriority,
                        surveyId: survey.surveyId,
                    });
                });
                questions.value.push(...combined);
            });
            questionIndex.value = QuestionHelper.findNextQuestionIndex(questions.value);
        };

        const onQuestionUpdate = (newIndex: number) => {
            questionIndex.value = newIndex;
        };

        const onFinish = async () => {
            finishingSurvey.value = true;
            let failed = false;

            for (let i = 0; i < surveys.value.length; i++) {
                if (surveys.value[i].demographicsEnabled || surveys.value[i].surveyCompleted) {
                    continue;
                }

                const surveyId = surveys.value[i].surveyId;
                const status: FinishSurveyStatus = await store.dispatch('finishSurvey', { surveyId });
                if (status === FinishSurveyStatus.NotVerified || status === FinishSurveyStatus.NotCompleted) {
                    failed = true;
                }
            }

            finishingSurvey.value = false;
            surveyCompleteError.value = failed ? 'Survey could not be completed. Please refresh the browser and try again.' : null;
            if (!failed) {
                redirectToFinishPage();
            }
        };

        onMounted(async () => {
            surveyList.value = props.surveyList;
            await loadSurveys();
            combineSurveyQuestions();

            loading.value = false;
            loaded.value = true;
        });

        return {
            loading,
            privacyAccepted,
            surveys,
            questions,
            activeSurvey,
            finishingSurvey,
            surveyCompleteError,
            onQuestionUpdate,
            onFinish,
            combineSurveyStart,
            hasDemographicSection,
            totalSurveyDuration,
            disableBackNavigation,
            welcomeText,
            needsBoundContext,
            knowledgeModelName,
        };
    },
});
