import { defineComponent } from "vue";

export default defineComponent({
    emits: ['change', 'update:modelValue'],
    props: {
        modelValue: { type: [String, Number] },
        type: { type: String },
        id: { type: String },
        name: { type: String },
        required: { type: Boolean },
        min: { type: Number, default: null },
        max: { type: Number, default: null },
    },
    methods: {
        onChange(e: InputEvent) {
            const value = (e.target as HTMLInputElement).value;
            this.$emit('update:modelValue', value);
            this.$emit('change', value);
        }
    }
});
