import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { SurveyRedirectLink, SurveyService } from '../surveyService';
import { useStore } from 'vuex';
import MainLayoutView from '@/generic/views/MainLayoutView';

export default defineComponent({
    extends: MainLayoutView,
    setup() {
        const route = useRoute();
        const router = useRouter();
        const { query } = route;
        const surveyKey = query.survey_key.toString();
        const projectId = query.project_id.toString();
        const loading = ref(true);
        const error = ref(null);
        const store = useStore();

        const loadSurvey = async () => {
            const survey = await new SurveyService().getAnonymousSurvey(parseInt(projectId), surveyKey);
            if (!survey) {
                error.value = 'Survey can not be created.';
                return;
            }

            survey.projectId = parseInt(projectId);
            store.commit('SET_ANONYMOUS_SURVEY', survey);
            redirectToSurvey(survey.surveyLink);
        };

        const checkInitialHit = async () => {
            const surveyString = localStorage.getItem(`anonymousSurvey:${projectId}`);
            const survey: SurveyRedirectLink = surveyString ? JSON.parse(surveyString) : null;
            if (survey && survey.surveyLink && survey.projectId === parseInt(projectId)) {
                return redirectToSurvey(survey.surveyLink);
            } else {
                await loadSurvey();
            }
        };

        const redirectToSurvey = async (surveyLink: string) => {
            document.location = surveyLink;
        };

        onMounted(async () => {
            await checkInitialHit();

            loading.value = false;
        });

        return {
            loading,
            error,
        };
    },
});
