export default class BarChartScore {
    public teamMedian: number = 0;
    public positiveAverage: number = 0;
    public negativeAverage: number = 0;
    public personalScore?: number;
    public tooltipScore?: number;
    public participants?: any[] = [];
    public standardDeviation?: string;
    public teamQuartile?: string;

    constructor(obj?: Partial<BarChartScore>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
