<template>
    <div id="fearless-cover">
        <div id="fearless-cover-logo-small">
            <img src="/images/design/fearless/psi_logo_small.svg" />
        </div>

        <div id="fearless-cover-logo">
            <img src="/images/design/fearless/logo.svg" />
        </div>

        <div id="fearless-cover-text">
            <survey-title>Psychological <b>safety</b> index</survey-title>
            <survey-subtitle>{{ name }}</survey-subtitle>
            <survey-subtitle>{{ date }}</survey-subtitle>
            <survey-subtitle style="font-size: medium;">{{ participantsCompleted }}</survey-subtitle>
        </div>
    </div>
</template>
<script src="./CoverPage.ts"></script>
<style lang="scss">
@import '../../../variables';

#fearless-cover {
    text-align: center;
    margin-bottom: 10rem;
    padding: 0 4rem;

    &-logo-small {
        margin: 2rem 0 10rem 0;

        img {
            width: 4rem;
            height: 4rem;
        }
    }

    &-logo {
        margin: 4rem auto 10rem;

        height: 269px;
        width: 468px;

        /* img {
                height: 225px;
                width: 468px;
            } */
    }

    &-text {
        border-top: 2px $border-color solid;
        padding-top: 4rem;

        .survey-title {
            margin-bottom: 0;
        }
    }
}
</style>
