import AuthHelper, { TokenType } from '@/generic/helpers/AuthHelper';
import { defineComponent, ref, resolveComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import ITeamReportResponse from '@/report/models/ITeamReportResponse';
import TeamReportWrapperVue from '../components/ReportWrapper.vue';
import { getPreferredLanguage } from '@/i18n';
import to from 'await-to-js';
import MainLayoutView from '@/generic/views/MainLayoutView';
import Language from '@/store/models/Language';
import { SurveyService } from '@/survey/surveyService';
import { ReportService } from '@/generic/services/reportService';
import { DemographicDataTableRow } from '../models/IDemographicDataTable';

export const setup = () => {
    const store = useStore();
    const route = useRoute();
    const { params, query } = route;
    const projectId = params.projectId;
    const preferredLanguage = getPreferredLanguage(route);
    const loaded = ref(false);
    const error = ref(false);
    const token = ref('');

    const loadReport = async (
        knowledgeModelAlias: string,
        instrumentAlias: string,
        reportAlias: string,
        knowledgeModelName: string,
    ): Promise<ITeamReportResponse> => {
        let lang = preferredLanguage;
        const [err, availableLanguages] = await to(new SurveyService().getLanguages(params.projectId.toString(), reportAlias));
        if (err) {
            console.log('Failed to load languages');
        }

        if (!availableLanguages.find((x) => x.languageCode === preferredLanguage)) {
            lang = 'en';
        }

        const prefLang = new Language({
            knowledgeModelAlias: knowledgeModelAlias,
            languageCode: lang,
            section: 'reports',
            reportAlias: reportAlias,
        });
        await store.dispatch('setLanguage', prefLang);

        prefLang.instrumentAlias = instrumentAlias;
        await store.dispatch('setLanguage', prefLang);
        store.commit(
            'SET_LANGUAGES',
            availableLanguages.map(
                (x) =>
                    new Language({
                        knowledgeModelAlias: knowledgeModelAlias,
                        instrumentAlias: instrumentAlias,
                        languageCode: x.languageCode,
                        section: 'reports',
                        reportAlias: reportAlias,
                    }),
            ),
        );

        token.value = query.token.toString();
        AuthHelper.setToken(TokenType.ReportTeamToken, token.value);

        const [errReport, responseReport]: [Error, ITeamReportResponse] = await to(
            store.dispatch('report/fetchTeamReport', { projectId, reportAlias }),
        );
        error.value = !!errReport;

        const teamToken = AuthHelper.token(TokenType.ReportTeamToken);
        const [errProject, responseProject] = await to(new ReportService().getTeamReports(prefLang.languageCode, teamToken, +projectId));
        error.value = !!errProject;
        loaded.value = true;

        //set knowledge model name on language obj
        if (responseReport && responseReport.knowledgeModelName) {
            prefLang.knowledgeModelName = responseReport.knowledgeModelName;
            await store.dispatch('setLanguage', prefLang);
        }

        if (responseProject) {
            responseReport.primaryColor = responseProject.primaryColor;
            responseReport.secondaryColor = responseProject.secondaryColor;
            store.commit('SET_PILLAR_THRESHOLDS', responseProject.pillarThresholds);
        }

        if (responseReport.table) {
            const [errDemographics, responseDemographics] = await to(new ReportService().getDemographicQuestionAnswers(+projectId, teamToken));
            error.value = !!errDemographics;
            responseReport.table.entries = responseReport.table.entries.sort((a: DemographicDataTableRow, b: DemographicDataTableRow) => {
                const aTitle = a.title.split(':');
                const bTitle = b.title.split(':');
                if (aTitle.length > 1 && bTitle.length > 1) {
                    const sortOrder = responseDemographics.filter((x) => x.label == aTitle[0]);
                    return (
                        responseDemographics.indexOf(sortOrder.find((x) => x.answer == aTitle[1])) -
                        responseDemographics.indexOf(sortOrder.find((x) => x.answer == bTitle[1]))
                    );
                }
                return 0;
            });
        }

        return responseReport as ITeamReportResponse;
    };

    return { loaded, error, token, loadReport };
};

export default defineComponent({
    extends: MainLayoutView,
    components: {
        TeamReportWrapper: TeamReportWrapperVue,
    },
});
