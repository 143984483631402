export default class PillarThreshold {
    public pillarAlias: string;
    public type: PillarThresholdType;
    public value: number;
    public order: number;

    constructor(pillarThreshold?: Partial<PillarThreshold>) {
        Object.assign(this, pillarThreshold);
    }
}

export enum PillarThresholdType {
    quartile = 'quartile',
    benchmark = 'benchmark',
    barChartRatio = 'barChartRatio',
}
