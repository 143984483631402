import BaseReportComponent from '@/report/components/BaseReportComponent';
import { computed, defineComponent, onMounted, watch } from 'vue';
import Sunburst from 'sunburst-chart';
import { Constructs } from '../models/Constructs';
import SunburstChartData from '../../models/Charts/SunburstChartData';
import PitWheelVue from './PitWheel.vue';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        PitWheel: PitWheelVue,
    },
    props: {
        reportData: { type: Object },
        filteredParticipants: { type: Array, default: () => [] },
        personalStepSize: { type: Number, default: 1 },
        teamStepSize: { type: Number, default: 0.33333 },
        maxDepth: { type: Number, default: 6.0 },
        showStrokes: { type: Boolean, default: false },
    },
    setup(props) {
        const childData = (depth: number, stepSize: number, maxDepth: number, data?: SunburstChartData): SunburstChartData => {
            if (depth <= 0) {
                return {};
            }
            return {
                name: isWithinThreshold(depth, stepSize) ? (props.reportData.participants ? `${data?.score.toFixed(1)}` : '') : '',
                color: props.reportData.participants
                    ? isWithinThreshold(depth, stepSize)
                        ? 'rgba(0,0,0,0.0)'
                        : data?.color
                    : depth - stepSize <= 0
                    ? 'rgba(0,0,0,0.0)'
                    : data?.color,
                strokeColor: props.showStrokes ? 'white' : 'rgba(0,0,0,0.0)',
                size: isWithinThreshold(maxDepth, stepSize) ? 1 : 0,
                children: isWithinThreshold(maxDepth, stepSize)
                    ? []
                    : isWithinThreshold(depth, stepSize)
                    ? [fillChartData(maxDepth - stepSize, stepSize)]
                    : [childData(depth - stepSize, stepSize, maxDepth - stepSize, data)],
            };
        };

        const isWithinThreshold = (value: number, threshold: number): boolean => value - threshold <= threshold;
        const fillChartData = (maxDepth: number, stepSize: number): any => {
            if (maxDepth <= 0) {
                return {};
            }
            return {
                name: '',
                color: 'rgba(0,0,0,0.0)',
                strokeColor: props.showStrokes ? 'white' : 'rgba(0,0,0,0.0)',
                size: maxDepth - stepSize <= stepSize ? 1 : 0,
                children: maxDepth - stepSize <= stepSize ? [] : [fillChartData(maxDepth - stepSize, stepSize)],
            };
        };

        const constructChartData = (construct: Constructs, entry: [string, number], stepSize: number): any => {
            const constructName = construct.toString();
            const constructColorEnum = Object.entries(Constructs).find((x) => x[0] == `${constructName}Color`);
            const constructColor = constructColorEnum && constructColorEnum.length == 2 ? constructColorEnum[1] : 'black';
            const entryValue = entry[1];
            const entryScore = entry[1];

            if (entryValue <= 0) {
                return {
                    name: entryScore.toFixed(1) === '0.0' ? '' : entryScore.toFixed(1),
                    color: 'white',
                    size: 1,
                };
            }
            return {
                name: '',
                color: constructColor,
                strokeColor: props.showStrokes ? 'white' : constructColor,
                size: 0,
                children: [
                    childData(entryValue, stepSize, props.maxDepth, {
                        name: constructName,
                        score: entryScore,
                        color: constructColor,
                        strokeColor: props.showStrokes ? 'white' : constructColor,
                    }),
                ],
            };
        };

        const personalChartData = (): any[] => {
            const relevantConstructs = Object.entries(props.reportData).filter((entry) => Object.keys(Constructs).includes(entry[0]));
            return constructData(relevantConstructs, props.personalStepSize);
        };

        const teamChartData = (): any[] => {
            const participants: any[] = [];
            const relevantConstructNames = [];
            const filteredParticipantList = computed(() =>
                props.reportData.participants.filter((x) => props.filteredParticipants.includes(x.participantId)),
            );
            filteredParticipantList.value.forEach((participant) => {
                if (relevantConstructNames.length < 1) {
                    relevantConstructNames.push(
                        ...Object.entries(participant)
                            .filter((entry) => Object.keys(Constructs).includes(entry[0]))
                            .map((construct) => construct[0]),
                    );
                }
                participants.push(...Object.entries(participant).filter((entry) => Object.keys(Constructs).includes(entry[0])));
            });

            const relevantConstructs: [string, any][] = [];
            relevantConstructNames.forEach((construct) => {
                relevantConstructs.push([
                    construct,
                    participants.filter((value) => (value[0] == construct ? value[1] : 0)).reduce((amount, currVal) => (amount += currVal[1]), 0) /
                        filteredParticipantList.value.length,
                ]);
            });
            return constructData(relevantConstructs, props.teamStepSize);
        };

        const constructData = (relevantConstructs: [string, any][], stepSize: number) => {
            const data: any[] = [];

            relevantConstructs.forEach((entry) => {
                const [constructKey] = entry;
                // keyof takes an object type and returns a type that accepts any of the object's keys. Typeof makes sure to return the object type of Constructs.
                const construct = Constructs[constructKey as keyof typeof Constructs];
                data.push(constructChartData(construct, entry, stepSize));
            });
            return data;
        };

        const reloadChartData = () => {
            const htmlElement = document.getElementById('wheel-chart');
            htmlElement.innerHTML = '';
            const rootData = {
                name: 'root',
                size: 0,
                children: props.reportData.participants ? teamChartData() : personalChartData(),
            };
            const myChart = Sunburst();
            myChart
                .data(rootData)
                .size('size')
                .color('color')
                .strokeColor('strokeColor')
                .excludeRoot(true)
                .labelOrientation('angular')
                .showTooltip(() => false)
                .onClick(() => null)
                .transitionDuration(0)
                .width(600)
                .height(600)
                .radiusScaleExponent(1)(htmlElement);
        };

        watch(props.filteredParticipants, () => {
            reloadChartData();
        });

        onMounted(async () => {
            reloadChartData();
        });
    },
});
