export enum ContextOption {
    NoContext = 'noContext',
    SurveyBound = 'surveyBound',
}

export enum ContextLocation {
    None = '',
    AtQuestion = 'atQuestion',
    BetweenQuestions = 'betweenQuestions',
    InQuestion = 'inQuestion',
}
