export default class DemographicRepresentationChartData {
    public demographic: string;
    public totalParticipants: number;
    public representation: DemographicRepresentation[];

    constructor(obj?: Partial<DemographicRepresentationChartData>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class DemographicRepresentation {
    public demographicValue: string;
    public baseline: number;
    public value: number;
    public nValue: number;

    constructor(obj?: Partial<DemographicRepresentation>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
