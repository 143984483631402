<template>
    <div class="survey-question"
         :class="[orientation, { first: question.first }]">
        <div class="survey-question-title">
            <h3>{{ questionText }}

                <template v-if="nextButtonHovered">
                    <span style="font-size:1rem;"
                          v-if="answerComponent === 'percentage-slider' && isAnswered">
                        <survey-icon icon="check-mark"
                                     style="font-size:1rem; margin-top:-3px;" />
                    </span>
                    <span style="font-size:1rem;"
                          v-if="answerComponent === 'percentage-slider' && !isAnswered">
                        <survey-icon icon="warning"
                                     color="red"
                                     style="font-size:1rem; margin-top:-3px;" />
                    </span>
                </template>
            </h3>
        </div>
        <div class="survey-question-answer">
            <component :is="answerComponent"
                       v-bind="{
                    question,
                    questionText,
                }"
                       @selected="answerSelected" />
        </div>
    </div>
</template>
<script src="./SurveyQuestion.ts"></script>
<style lang="scss">
    @import '@/scss/mixins';

    .survey-question {
        h3 {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.5;
        }

        &:not(.horizontal) {
            h3 {
                margin: 0 1.5rem 1.5rem 0;
            }
        }

        @include horizontal {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 2rem;
            align-items: center;
            margin-bottom: 0.75rem;

            .survey-question-title {
                display: flex;
                align-items: flex-end;
                align-self: flex-end;

                h3 {
                    margin: 0;
                    font-size: 1rem;
                    font-weight: 200;
                }
            }

            &.first {
                .survey-question-title {
                    margin-top: 4rem;
                }
            }
        }
    }
</style>