import { defineComponent, computed } from 'vue';
import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';
import RadioOption from '@/survey/models/RadioOption';

export default defineComponent({
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    setup(props, context) {
        const onSelect = (option) => {
            const answer = {
                value: option,
            };
            context.emit('selected', answer);
        };

        const dropdownOptions = computed(() => {
            return props.question.answerOptions.map((option: RadioOption) => {
                return {
                    ...option,
                    label: option.alias,
                };
            });
        });

        return {
            onSelect,
            dropdownOptions,
        };
    },
});
