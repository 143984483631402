import ReportPersonal from './views/PersonalReport.vue';
import NovoTeamReportVue from './views/NovoTeamReport.vue';
import MainLayoutVue from '@/generic/layouts/MainLayout.vue';
import ReportListVue from './views/ReportList.vue';
import RedirectVue from './views/Redirect.vue';
import TeamPsiOrganizationVue from './views/TeamPsiOrganization.vue';
import TeamReportVue from './views/TeamReport.vue';

export default [
    {
        path: '/report',
        component: MainLayoutVue,
        children: [
            {
                path: '',
                name: 'report-list',
                component: ReportListVue,
            },
            {
                path: 'personal/view/:surveyId/:reportAlias',
                name: 'personal-report-view',
                component: ReportPersonal,
            },
            // https://scan.fearlessorganization.com/report/personal/236?token=QXlqeStlKFVp4Qh65cUfbQ%3d%3d&prefLang=en
            // https://tst-scan.fearlessorganization.com/report?token=1d8%2bGqmUbRQiSzfUJ8t0wuCSNu4slsmyI28O%2biPTNAA%3d&language=en
            // combined: report/personal/236?token=1d8%2bGqmUbRQiSzfUJ8t0wuCSNu4slsmyI28O%2biPTNAA%3d&prefLang=en
            {
                path: 'personal/:surveyId',
                component: RedirectVue,
            },
        ],
    },
    {
        path: '/:knowledgeModelAlias/:instrumentAlias/report',
        component: MainLayoutVue,
        children: [
            {
                path: 'psi_organization/:projectId',
                component: TeamPsiOrganizationVue,
            },
            {
                path: 'novo_nordisk/:projectId',
                component: NovoTeamReportVue,
            },
            {
                path: ':reportAlias/:projectId',
                component: TeamReportVue,
            },
            {
                path: 'organization/:orgId',
                redirect: { name: 'org-dashboard' },
            },
        ],
    },
];
