import { register } from 'register-service-worker';

register(`${process.env.BASE_URL}service-worker.js`, {
    registered() {
        console.log('Survey app: registered');
        document.dispatchEvent(new CustomEvent('pwa:registered'));
    },
    updatefound() {
        document.dispatchEvent(new CustomEvent('pwa:updatefound'));
    },
    updated() {
        document.dispatchEvent(new CustomEvent('pwa:updated'));
    },
});
