<template>
    <span class="survey-badge" :class="[variant, { 'upper': uppercase, 'hover': hover }]">
        <slot />
    </span>
</template>
<script src="./Badge.ts"></script>
<style lang="scss">
.survey-badge {
    display: inline-block;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;

    &.upper {
        text-transform: uppercase;
    }

    &.hover {
        cursor: pointer;
        user-select: none;
    }
}
</style>
