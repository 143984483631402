<template>
    <div class="personal-psi-wrapper">
        <table>
            <tr>
                <td class="personal-psi-column">
                    <div class="personal-psi">
                        <span class="willingness-help psi-chart-categories" v-tooltip:grey="'Willingness to help'"></span>
                        <span class="room-diversity psi-chart-categories" v-tooltip:grey="'Room for diveristy'"></span>
                        <span class="attitude-risk psi-chart-categories" v-tooltip:grey="'Attitude to risk and failure'"></span>
                        <span class="open-conversation psi-chart-categories" v-tooltip:grey="'Open conversation'"></span>
                        <canvas
                            id="psi-chart"
                            class="chart chart-radar"
                            width="150"
                            height="150"
                            chart-data="data"
                            chart-options="options"
                            chart-labels="labels"
                        ></canvas>
                        <span class="psi-score">{{ psi }}</span>
                    </div>
                </td>

                <td>
                    <div v-if="!hideIteration" class="period" id="period-selection">
                        <div class="iteration-picker-wrapper">
                            <iteration-picker primary :type="type" />
                            <span class="iteration-vs">vs.</span>
                            <iteration-picker :type="type" />
                        </div>
                    </div>
                    <div v-else class="completedOn">
                        {{ completedOn + ' ' + $filters.date(completionDate) }}
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>
<script src="./PersonalChart.ts"></script>
<style lang="scss">
$psiDimension: 150px;

.personal-psi-wrapper {
    .personal-psi-column {
        width: 8rem;
        .personal-psi {
            width: $psiDimension;
            height: $psiDimension;
            position: relative;

            canvas,
            img {
                position: relative;
                z-index: 1;
            }

            .psi-score {
                font-weight: bold;
                color: #5f6166;
                font-size: 18px;
                letter-spacing: 0.66px;
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 3;
                line-height: 32px;
                margin-top: -16px;
                margin-left: -19px;
                width: 40px;
                text-align: center;
            }

            .psi-chart-categories {
                position: absolute;
                width: 48px;
                height: 48px;
                content: '';
                z-index: 2;
            }

            .open-conversation {
                transform: translateY(48px);
            }

            .willingness-help {
                left: 48px;
            }

            .attitude-risk {
                left: 48px;
                transform: translateY(96px);
            }

            .room-diversity {
                left: 96px;
                transform: translateY(48px);
            }
        }
    }

    .completedOn{
        padding-left: 1rem;
    }

    .period {
        padding-left: 2rem;
        .iteration-picker-wrapper {
            display: flex;
            align-items: flex-start;

            .iteration-vs {
                font-size: 0.875rem;
                margin-right: 1.5rem;
            }
        }
    }
}
</style>
