<template>
    <select class="survey-select" @input="onInput">
        <slot />
    </select>
</template>
<script src="./Select.ts"></script>
<style lang="scss">
@import '@/scss/mixins';

.survey-select {
    display: inline-block;
    font-size: 1rem;
    padding : 0.5rem;
    width: auto;
    height: auto;
    font-weight: 400;
    outline: none;
    max-width: 160px;
    font-family: inherit;

    &:focus {
        box-shadow: none;
    }

}

@include print {
    .survey-select {
        appearance: none;
        border-radius: 0;
    }
}
</style>
