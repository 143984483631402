import { defineComponent, onMounted, ref } from 'vue';
import PsiTeamVue from '../fearless/reports/PsiTeam.vue';
import BaseTeamReport, { setup } from './BaseTeamReport';
import { useStore } from 'vuex';

export default defineComponent({
    extends: BaseTeamReport,
    components: {
        PsiTeam: PsiTeamVue,
    },
    setup() {
        const { loadReport, loaded, error } = setup();
        const store = useStore();
        const reportType = ref('reportsWeb');

        onMounted(async () => {
            await loadReport('fearless', 'psi_org', 'psi_organization', 'Fearless Organization Scan');

            const lang = store.getters.language;
            lang.section = 'survey';
            lang.reportAlias = null;
            await store.dispatch('setLanguage', lang);
        });

        return {
            loaded,
            error,
            reportType,
        };
    },
});
