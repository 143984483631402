import MainLayoutView from "@/generic/views/MainLayoutView";
import { defineComponent, onMounted, ref } from "vue";
import SurveyViewCombinedVue from "./SurveyViewCombined.vue";
import SurveyViewVue from "./SurveyView.vue";
import { setup } from "./BaseSurvey";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import SurveyListResponse from "../models/SurveyListResponse";
import AuthHelper, { TokenType } from "@/generic/helpers/AuthHelper";
import { getPreferredLanguageAndClear } from "@/i18n";
import { SurveyService } from "../surveyService";
import to from "await-to-js";
import Language from "@/store/models/Language";

export default defineComponent({
    extends: MainLayoutView,
    components: {
        SurveyViewCombined: SurveyViewCombinedVue,
        SurveyView: SurveyViewVue,
    },
    setup() {
        const { redirectToFinishPage, redirectToClosedPage } = setup();
        const route = useRoute();
        const router = useRouter();
        const { query } = route;
        const token = query.token.toString();
        const surveyList = ref<SurveyListResponse>(new SurveyListResponse());
        const loading = ref(true);
        const error = ref(null);
        const store = useStore();
        const disableBackNavigation = ref(false);
        const isCombinedSurvey = ref(false);

        const loadSurveys = async () => {
            AuthHelper.setToken(TokenType.SurveyToken, token);
            const prefLanguageCode = getPreferredLanguageAndClear(router, route);
            loading.value = true;
            const [err, list] = await to(new SurveyService().getSurveys(prefLanguageCode));
            error.value = err ? 'Failed to load surveys' : null;

            if (!err) {
                list.surveys.sort((a, b) => a.surveyPriority - b.surveyPriority);
                surveyList.value = list;

                if (list && list.surveyConfiguration) {
                    disableBackNavigation.value = list.surveyConfiguration.disableBackNavigation;
                    isCombinedSurvey.value = list.surveyConfiguration.isCombinedSurvey;
                }

                store.commit('SET_LANGUAGES', list.languages);
                await store.dispatch(
                    'setLanguage',
                    new Language({
                        languageCode: list.languageCode,
                        knowledgeModelAlias: list.knowledgeModelAlias,
                        knowledgeModelName: list.knowledgeModelName,
                        section: 'survey',
                    }),
                );

                store.commit('SET_THANK_YOU_MESSAGES', surveyList.value.thankYouTexts);
                if (isSurveyListComplete(list)) {
                    return redirectToFinishPage();
                }

                if (surveyList.value.closed) {
                    return redirectToClosedPage();
                }
            }
            loading.value = false;
        };

        const isSurveyListComplete = (surveyList: SurveyListResponse): boolean => {
            const notCompleted = surveyList.surveys.filter((s) => !s.surveyCompleted);
            if (surveyList.demographicSurvey) {
                return notCompleted.length === 0 && surveyList.demographicSurvey.surveyCompleted;
            }
            return notCompleted.length === 0;
        };

        onMounted(async () => {
            await loadSurveys();
        });

        return {
            loading,
            surveyList,
            isCombinedSurvey,
        };
    },
});
