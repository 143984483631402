import { PropType } from 'vue';
// import Answer from './Answer';
import Question from './Question';

export enum QuestionType {
    Score = 'score',
    Choice = 'choice',
    ArrangeOrder = 'arrangeOrder',
    Percentage = 'percentage',
    AllThatApply = 'allThatApply',
    OpenQuestion = 'openQuestion',
}

export const QuestionTypeProps = {
    question: { type: Object as PropType<Question> },
    // options: { type: Array as PropType<Answer[]> },
    // answer: { type: Object },
    instrumentKey: { type: String },
    // horizontal: { type: Boolean },
    // first: { type: Boolean },
    questionText: { type: String },
};

export const QuestionTypeEmits = ['selected'];
