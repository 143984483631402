import { defineComponent } from 'vue';
import ITableSection from '@/report/models/ITableSection';
import ScoreBadge from '@/report/fearless/partials/ScoreBadge.vue';

export default defineComponent({
    components: { ScoreBadge },
    props: {
        context: { type: String },
        type: { type: String },
        section: { type: Object },
        score: { type: Object },
        main: { type: String },
        compare: { type: String },
    },
    setup(props) {
        const getScoreOfCategory = (categories: ITableSection[], category: string) => {
            if (categories && categories.length) {
                return categories != null ? categories.find((x) => x.category == category).median : null;
            }
            return null;
        };

        const generalScore = getScoreOfCategory(props.section.categories, 'GENERAL');
        const tooltipRows = [
            ['PSI', generalScore],
            ['WTH', getScoreOfCategory(props.section.categories, 'HELP')],
            ['I&D', getScoreOfCategory(props.section.categories, 'DIVERSITY')],
            ['ATRF', getScoreOfCategory(props.section.categories, 'ATTITUDE')],
            ['OC', getScoreOfCategory(props.section.categories, 'CONVERSATION')],
            ['Distribution', props.score.distribution],
        ];

        const tooltipHtml = `
            <div class="mb-3">
                <h6 class="text-dark">${props.compare}</h6>
                <span class="text-accent">${props.main}</span>
            </div>

            <table class="table table-sm table-borderless mb-0">
                <tr>${tooltipRows.map((row) => `<td>${row.join('</td><td>')}</td>`).join('</tr><tr>')}</tr>
            </table>
        `;

        return { getScoreOfCategory, generalScore, tooltipHtml };
    },
});
