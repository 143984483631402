<template>
    <div class="consider">
        <span class="consider-label" v-t="'reportsWeb.GENERAL_TO_CONSIDER'"></span>
        <div class="consider-content">
            <content-wrapper full>
                <survey-translate tag="p" base="reportsWeb" :name="name" raw />
            </content-wrapper>
        </div>
    </div>
</template>
<script src="./Consider.ts"></script>
<style lang="scss">
@import '@/variables';

.consider {
    position: relative;
    border: 1px $border-color solid;
    margin: 2rem 0;

    &-label {
        position: absolute;
        top: -0.5rem;
        left: 1rem;
        height: 1rem;
        line-height: 1rem;
        padding: 0 0.5rem;
        background: $white;
        font-size: 1.125rem;
        font-weight: bold;
        color: $black;
    }

    &-content {
        padding: 1.5rem 1rem 0;
        color: $dark-gray;
    }
}
</style>
