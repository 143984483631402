<template>
    <component :is="tag" class="survey-subtitle" :class="[size, color]">
        <slot />
    </component>
</template>
<script src="./Subtitle.ts"></script>
<style lang="scss">
.survey-subtitle {
    font-weight: 700;
    font-size: 1.25rem;
    margin: 0 0 0.25rem 0;
}
</style>
