import Language from '@/store/models/Language';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
    setup() {
        const { locale } = useI18n({
            useScope: 'global',
        });
        const store = useStore();
        const route = useRoute();
        const loading = computed(() => store.getters.loadingLanguage);
        const languages = computed(() => {
            if (route.query.showKeys) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                locale.value = 'keys';
                return [new Language({ languageCode: 'keys' })];
            }
            return store.getters.languages;
        });

        const languageChange = async (event: { target: HTMLSelectElement }) => {
            const language: Language = store.getters.language;

            await store.dispatch(
                'setLanguage',
                new Language({
                    languageCode: event.target.value,
                    knowledgeModelAlias: language.knowledgeModelAlias,
                    knowledgeModelName: language.knowledgeModelName,
                    section: language.section,
                    reportAlias: language.reportAlias,
                }),
            );

            await store.dispatch(
                'setLanguage',
                new Language({
                    languageCode: event.target.value,
                    knowledgeModelAlias: language.knowledgeModelAlias,
                    knowledgeModelName: language.knowledgeModelName,
                    instrumentAlias: language.instrumentAlias,
                    section: language.section,
                    reportAlias: language.reportAlias,
                }),
            );
        };

        return {
            languages,
            languageChange,
            locale,
            loading,
        };
    },
});
