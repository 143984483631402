import ContentWrapperVue from '@/generic/components/ContentWrapper.vue';
import { computed, defineComponent } from 'vue';
import PageVue from '@/report/components/Page.vue';

export default defineComponent({
    components: {
        Page: PageVue,
        ContentWrapper: ContentWrapperVue,
    },
    props: {
        categoryKey: { type: String },
        context: { type: String },
        scores: { type: Object },
        type: { type: String },
    },
    setup() {
        const color = computed(() => {
            return (data: number) => {
                let color = '';
                const colors = {
                    0: 'red',
                    61: 'orange',
                    76: 'light-green',
                    86: 'green',
                };
                
                Object.keys(colors).forEach((key: string) => {
                    if (data >= +key) {
                        color = colors[key];
                    }
                });
                return color;
            };
        });

        const distribution = (data: string) => {
            const colors = {
                'LOW': 'green',
                'MEDIUM': 'orange',
                'MID': 'orange',
                'HIGH': 'red',
            };

            return colors[data.toUpperCase()];
        };

        return {
            color,
            distribution,
        };
    },
});
