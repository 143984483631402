import BaseReportComponent from '@/report/components/BaseReportComponent';
import ReportWrapperVue from '@/report/components/ReportWrapper.vue';
import { ComputedRef, computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import RjcTeamReportData from '../models/RjcTeamReportData';
import BarChartScore from '@/report/models/Charts/BarChartScore';
import TeamCompareQuestionsVue from '@/report/components/partials/TeamCompareQuestions.vue';
import QuestionOption from '@/report/models/QuestionOption';
import TeamRadarVue from '@/report/components/charts/TeamRadar.vue';
import RadarChartData from '@/report/models/Charts/RadarChartData';
import RadarChartScore from '@/report/models/Charts/RadarChartScore';
import RjcSunburstChartVue from '../Components/RjcSunburstChart.vue';
import { useI18n } from 'vue-i18n';
import { BCollapse } from 'bootstrap-vue-next';
import { RjcRadarChartSection } from '../models/RjcRadarChartSections';
import { PillarThresholdType } from '@/report/models/PillarThreshold';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        ReportWrapper: ReportWrapperVue,
        TeamCompareQuestion: TeamCompareQuestionsVue,
        teamRadar: TeamRadarVue,
        TeamSunburstChart: RjcSunburstChartVue,
        bCollapse: BCollapse,
    },
    props: {
        type: String,
    },
    setup() {
        const store = useStore();
        const { t } = useI18n();
        const reportData: ComputedRef<RjcTeamReportData> = computed(() => store.getters['report/report']);
        const totalChartData: ComputedRef<BarChartScore> = computed(
            () =>
                new BarChartScore({
                    teamMedian: reportData.value.totalSection.median,
                    positiveAverage: reportData.value.totalSection.positiveAverageDeviation,
                    negativeAverage: reportData.value.totalSection.negativeAverageDeviation,
                    tooltipScore: reportData.value.totalSection.median,
                }),
        );
        const compareTo = ref('default');
        const comparing = ref(false);
        const radarChartKey = ref(0);
        const chartLabelStyle: { boxHeight?: number; boxWidth?: number } = {
            boxHeight: 1,
        };
        const chartOrder: RjcRadarChartSection[] = [
            RjcRadarChartSection.Process,
            RjcRadarChartSection.Outcomes,
            RjcRadarChartSection.Lenience,
            RjcRadarChartSection.Context,
        ];
        const subTitleArgs: ComputedRef<{ projectName: string; completionDate: string }> = computed(() => {
            return {
                projectName: reportData.value.projectName,
                completionDate: reportData.value.closeDate.substring(0, reportData.value.closeDate.indexOf('T')),
            };
        });
        const maxDepth: ComputedRef<number> = computed(() => {
            return reportData.value.emotionSections.reduce((prev, curr) => {
                const current = Math.max(...Object.values(curr.emotionValuePairs));
                return prev > current ? prev : current;
            }, 0);
        });
        const stepSize: ComputedRef<number> = computed(() => {
            return Math.ceil(maxDepth.value / 10);
        });
        const rjcBenchmarkData = (alias: string) => store.getters.pillarThreshold(PillarThresholdType.benchmark, alias);

        const getDimensionChartData = (name: string): BarChartScore => {
            const section = reportData.value[name + 'Section'];
            return new BarChartScore({
                teamMedian: section.median,
                positiveAverage: section.positiveAverageDeviation,
                negativeAverage: section.negativeAverageDeviation,
                tooltipScore: section.median,
            });
        };

        const compareQuestions = async (question: string) => {
            comparing.value = true;
            if (!question) {
                compareTo.value = 'default';
            } else {
                compareTo.value = question;
            }
            comparing.value = false;
            radarChartKey.value += 1;
        };

        const questionOptions: ComputedRef<QuestionOption[]> = computed(() => {
            if (!reportData.value.table || !reportData.value.table.entries) {
                return [];
            }

            const allEntries = reportData.value.table.entries.map((entry: any) => {
                return {
                    label: entry.title.split(':')[0],
                    question: entry.title.split(':')[0],
                };
            });

            const uniqueEntries = [];
            allEntries.forEach((entry) => {
                if (!uniqueEntries.find((x) => x.label === entry.label)) {
                    uniqueEntries.push(entry);
                }
            });
            return uniqueEntries;
        });

        const compareScores = (category: string) => {
            const scores = [];

            reportData.value.table.entries.forEach((entry) => {
                const splittedLabel = entry.title.split(':');
                if (splittedLabel.length && splittedLabel[0] === compareTo.value && entry.sections) {
                    entry.sections.forEach((section) => {
                        if (section.sections) {
                            const data = section.sections.find((pillar) => pillar.category == category);
                            let label = '';
                            if (entry.title) {
                                label = splittedLabel.length > 1 ? splittedLabel[1] : 'None';
                            }
                            if (data) {
                                scores.push({
                                    category: data.category,
                                    pillar: data.category,
                                    compareAnswer: label,
                                    positiveAverage: data.positiveAverage,
                                    negativeAverage: data.negativeAverage,
                                    teamMedian: data.median,
                                    groupSize: entry.groupSize,
                                });
                            }
                        }
                    });
                }
            });
            return scores;
        };

        const radarChartScores: ComputedRef<RadarChartData[]> = computed(() => {
            const chartData: RadarChartData[] = [];
            const chartScores: RadarChartScore[] = [];
            chartScores.push(new RadarChartScore({ label: 'Process', score: reportData.value.processSection.median }));
            chartScores.push(new RadarChartScore({ label: 'Outcomes', score: reportData.value.outcomesSection.median }));
            chartScores.push(new RadarChartScore({ label: 'Lenience', score: reportData.value.lenienceSection.median }));
            chartScores.push(new RadarChartScore({ label: 'Organizational\ncontext', score: reportData.value.contextSection.median }));
            chartData.push(
                new RadarChartData({ label: 'example score', scores: chartScores, color: '#1A897E', borderWidth: 3, pointRadius: 0, fill: false }),
            );
            return chartData;
        });

        const compareScoresRadarChart = (): RadarChartData[] => {
            const radarChartScores: RadarChartData[] = [];
            reportData.value.table.entries.forEach((entry) => {
                const splittedLabel = entry.title.split(':');
                if (splittedLabel.length && splittedLabel[0] === compareTo.value && entry.sections) {
                    entry.sections.forEach((section) => {
                        const scores: RadarChartScore[] = section.sections
                            .map((section) => {
                                return new RadarChartScore({ label: section.category, score: section.median });
                            })
                            .sort((a, b) => (chartOrder.indexOf(a.label) > chartOrder.indexOf(b.label) ? 1 : -1));

                        radarChartScores.push(
                            new RadarChartData({
                                label: 'example score',
                                scores: scores,
                                color: reportData.value.primaryColor,
                                entry: entry.title,
                                title: splittedLabel[1],
                            }),
                        );
                    });
                }
            });
            return radarChartScores;
        };

        const dimensionsScores: ComputedRef<{ process: number; outcomes: number; lenience: number; context: number }> = computed(() => {
            return {
                process: parseFloat(reportData.value.processSection.median.toFixed(2)),
                outcomes: parseFloat(reportData.value.outcomesSection.median.toFixed(2)),
                lenience: parseFloat(reportData.value.lenienceSection.median.toFixed(2)),
                context: parseFloat(reportData.value.contextSection.median.toFixed(2)),
            };
        });

        const dimensionDeviationLabels: ComputedRef<{
            total: string[];
            process: string[];
            outcomes: string[];
            lenience: string[];
            context: string[];
        }> = computed(() => {
            return {
                total: [t(`reportsWeb.TOTAL_DEVIATION_ITEM_1`), t(`reportsWeb.TOTAL_DEVIATION_ITEM_2`)],
                process: [t(`reportsWeb.PROCESS_DEVIATION_ITEM_1`), t(`reportsWeb.PROCESS_DEVIATION_ITEM_2`)],
                outcomes: [t(`reportsWeb.OUTCOMES_DEVIATION_ITEM_1`), t(`reportsWeb.OUTCOMES_DEVIATION_ITEM_2`)],
                lenience: [t(`reportsWeb.LENIENCE_DEVIATION_ITEM_1`), t(`reportsWeb.LENIENCE_DEVIATION_ITEM_2`)],
                context: [t(`reportsWeb.CONTEXT_DEVIATION_ITEM_1`), t(`reportsWeb.CONTEXT_DEVIATION_ITEM_2`)],
            };
        });

        const emotionOneData: ComputedRef<Record<number, number>> = computed(() => reportData.value.emotionSections[0]?.emotionValuePairs);
        const emotionTwoData: ComputedRef<Record<number, number>> = computed(() => reportData.value.emotionSections[1]?.emotionValuePairs);
        const emotionThreeData: ComputedRef<Record<number, number>> = computed(() => reportData.value.emotionSections[2]?.emotionValuePairs);

        return {
            reportData,
            totalChartData,
            compareTo,
            comparing,
            questionOptions,
            radarChartKey,
            compareQuestions,
            compareScores,
            radarChartScores,
            compareScoresRadarChart,
            getDimensionChartData,
            chartLabelStyle,
            chartOrder,
            dimensionDeviationLabels,
            dimensionsScores,
            subTitleArgs,
            emotionOneData,
            emotionTwoData,
            emotionThreeData,
            rjcBenchmarkData,
            maxDepth,
            stepSize,
        };
    },
});
