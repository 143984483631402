import Alert from './Alert.vue';
import Badge from './Badge.vue';
import Button from './Button.vue';
import Checkbox from './Checkbox.vue';
import Input from './Input.vue';
import Lead from './Lead.vue';
import Loader from './LoaderContainer.vue';
import Progress from './Progress.vue';
// import Radio from './Radio.vue';
import Select from './Select.vue';
import Skeleton from './SkeletonLoader.vue';
import Spinner from './Spinner.vue';
import Subtitle from './Subtitle.vue';
import Title from './Title.vue';
import Translate from './TranslateWrapper.vue';

import Icon from './icons/Icon.vue';

const components = {
    Alert,
    Badge,
    Button,
    Checkbox,
    Input,
    Lead,
    Loader,
    Progress,
    // Radio,
    Select,
    Skeleton,
    Spinner,
    Subtitle,
    Title,
    Translate,
    Icon,
};

export default {
    install(app) {
        Object.keys(components).forEach((key: string) => {
            app.component(`Survey${key}`, components[key]);
        });
    },
};
