<template>
    <page break-before>
        <survey-subtitle v-t="{ path: `${type}.${categoryKey}`, args: { context } }" />
        <content-wrapper tag="p" v-t="{ path: `${type}.${categoryKey}_DESCRIPTION`, args: { context } }" />

        <div class="table-shared heatmap-table-wrapper">
            <survey-loader v-if="loading" cover />
            <table v-else :type="type">
                <tr>
                    <td class="column-vertical"></td>
                    <td v-for="title in titles" :key="title" class="column-horizontal">
                        <span class="label-horizontal" v-tooltip.large="title">{{ title }}</span>
                    </td>
                </tr>
                <tbody>
                    <tr v-for="(score, x) in rows" :key="`table_row_${score.mainTitle}`">
                        <td class="column-vertical" v-tooltip.large="score.mainTitle">
                            <span class="label-vertical">{{ score.mainTitle }}</span>
                        </td>
                        <template v-for="(section, y) in score.sections" :key="`table_data_${score.mainTitle}_${section.CompareTitle}`">
                            <td v-if="x + y < scores.length - 1">
                                <score-tooltip
                                    :context="context"
                                    :type="type"
                                    :section="section"
                                    :score="score"
                                    :main="score.mainTitle"
                                    :compare="section.compareTitle"
                                />
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </div>
    </page>
</template>
<script src="./TeamHeatmap.ts"></script>
<style lang="scss">
@import '@/scss/components/table';

.heatmap-table-wrapper {
    @extend .survey-table-shared;

    table {
        table-layout: fixed;

        tr {
            th, td {
                vertical-align: middle;
                height: 2.5rem;
                padding: .25rem;
                position: relative;
                text-align: center;

                .label-horizontal, .label-vertical {
                    display: inline-block;
                    font-size: .875rem;
                    text-align: left;
                    margin: 0;
                }

                &.column-horizontal {
                    height: max-content;
                    vertical-align: bottom;

                    .label-horizontal {
                        transform: rotate(180deg);
                        writing-mode: vertical-lr;
                        max-height: 8rem;
                        white-space: normal;
                        line-height: 1.2;
                        font-weight: bold;
                    }
                }

                &.column-vertical {
                    overflow: hidden;
                    width: 8rem;
                    vertical-align: middle;

                    .label-vertical {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 100%;
                    }
                }

            }
        }
    }
}
</style>
