<template>
    <div class="survey-question-radio"
         :class="{ horizontal: question.horizontal }">

        <div v-for="(option, index) in radioOptions"
             class="survey-radio"
             :class="{ horizontal: question.horizontal, first: question.first }">
            <input type="radio"
                   class="survey-radio-input"
                   :id="`answer_${id}_${index}`"
                   :name="`answer_${id}_${index}`"
                   :value="option.optionValue"
                   :checked="option.checked"
                   :required="required"
                   @input="optionSelected(option)" />
            <label class="survey-radio-label"
                   :for="`answer_${id}_${index}`">
                <div class="survey-radio-control"
                     :class="{ checked: option.checked }"></div>
                <span>{{ option.label }}</span>
            </label>
        </div>
    </div>
</template>
<script src="./QuestionRadio.ts"></script>
<style lang="scss">
    @import '@/scss/mixins';

    .survey-question-radio {
        @include horizontal {
            display: flex;
            justify-content: space-around;
        }
    }
</style>