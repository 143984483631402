import { uniqueId } from '@/generic/helpers/UniqueIdHelper';
import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';
import RadioOption from '@/survey/models/RadioOption';
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    setup(props, context) {
        const otherOption = ref({ checked: false, value: '' });
        const id = uniqueId('demographicRadio');
        const updateTimeoutTime = 500;
        let updateTimeout = null;

        onMounted(async () => {
            if (props.question.answer.value && !props.question.answerOptions.find((x) => x.alias === props.question.answer.value || x.checked)) {
                otherOption.value.checked = true;
                otherOption.value.value = props.question.answer.value;
            } else {
                const option = props.question.answerOptions.find((x) => x.alias === props.question.answer.value);
                if (option) {
                    option.checked = true;
                }
            }
        });

        const onInput = () => {
            const answer = props.question.answer;
            answer.value = otherOption.value.value;

            clearTimeout(updateTimeout);
            updateTimeout = setTimeout(answerChanged, updateTimeoutTime);
        };

        const answerChanged = () => {
            const value = otherOption.value.value ? otherOption.value.value : 'other';
            context.emit('selected', { value, label: props.question.label });
        };

        return {
            id,
            otherOption,
            onInput,
            answerChanged,
        };
    },
    methods: {
        optionSelected(option: RadioOption) {
            this.question.answerOptions.forEach((element) => {
                element.checked = false;
            });

            if (option.alias === 'other') {
                this.otherOption.checked = true;
                option.value = this.otherOption.value ?? 'other';
            } else {
                option.checked = true;
                this.otherOption.checked = false;
                this.otherOption.value = '';
            }

            const answer = {
                value: option.alias,
            };

            this.$emit('selected', answer);
        },
    },
});
