import IIteration from '@/report/models/IIteration';
import IReportData from '@/report/models/IReportData';
import IReportResponse from '@/report/models/IReportResponse';
import ITeamReportData from '@/report/models/ITeamReportData';
import ITeamReportResponse from '@/report/models/ITeamReportResponse';
import { ReportService } from '@/generic/services/reportService';
import AuthHelper, { TokenType } from '@/generic/helpers/AuthHelper';

export type State = {
    _report: IReportResponse | ITeamReportResponse;
    _loaded: boolean;
    _primary: IReportData | ITeamReportData;
    _secondary: IReportData | ITeamReportData;
    _showPrimary: boolean;
    _isTeam: boolean;
    _alias: string;
    _projectId: number;
};

const baseState: State = {
    _report: {} as IReportResponse | ITeamReportResponse,
    _primary: {} as IReportData | ITeamReportData,
    _secondary: null as IReportData | ITeamReportData,
    _loaded: false,
    _showPrimary: true,
    _isTeam: false,
    _alias: '',
    _projectId: 0,
};

export default {
    namespaced: true,
    state(): State {
        return baseState;
    },
    mutations: {
        CLEAR(state: State): void {
            Object.assign(state, baseState);
        },
        SET_REPORT(state: State, payload: { report: any; isTeam: boolean; alias: string }): void {
            state._report = payload.report;
            state._isTeam = payload.isTeam;
            state._alias = payload.alias;
        },
        SET_PRIMARY(state: State, data: IReportData): void {
            state._primary = data;
        },
        SET_SECONDARY(state: State, data: IReportData): void {
            state._secondary = data;
        },
        SET_LOADED(state: State, loaded: boolean): void {
            state._loaded = loaded;
        },
        SET_SHOW_PRIMARY(state: State, show: boolean): void {
            state._showPrimary = show;
        },
    },
    getters: {
        user(state: State): string {
            if (!state._isTeam) {
                return (state._report as IReportResponse).participantName;
            }
        },
        context(state: State): string {
            return state._report.context;
        },
        projectId(state: State): number {
            return state._projectId;
        },
        report(state: State): IReportResponse | ITeamReportResponse {
            return state._report;
        },
        loaded(state: State): boolean {
            return state._loaded;
        },
        primary(state: State): IReportData | ITeamReportData {
            return state._primary;
        },
        secondary(state: State): IReportData | ITeamReportData {
            return state._secondary;
        },
        showPrimary(state: State): boolean {
            if (state._secondary === null) {
                return true;
            }

            return state._showPrimary;
        },
        isTeam(state: State): boolean {
            return state._isTeam;
        },
        alias(state: State): string {
            return state._alias;
        },
        iterations(state: State): IIteration[] {
            const options = [];
            let report: IReportResponse | ITeamReportResponse = null;
            if (!state._report || !state._report) {
                return [];
            }
            if (!state._isTeam) {
                report = state._report as IReportResponse;
                if (report && report.iterations) {
                    report.iterations.forEach((iteration: IIteration) => {
                        options.push({
                            name: iteration.dateCompleted,
                            value: iteration,
                        });
                    });
                }
            } else {
                report = state._report as ITeamReportResponse;
                report.iterations.forEach((iteration: ITeamReportResponse) => {
                    const data = iteration;
                    if (data && data.dateReport) {
                        options.push({ name: data.dateReport.date, value: data.dateReport });
                    }
                });
            }

            return options;
        },
    },
    actions: {
        async fetchReport({ commit }, { surveyId, reportAlias }): Promise<void> {
            commit('SET_LOADED', false);
            const reportService = new ReportService();
            const data: IReportResponse = await reportService.getReportDetail(surveyId, reportAlias);
            commit('SET_REPORT', { report: data, isTeam: false, alias: reportAlias });
            commit('SET_PRIMARY', data);

            if (data.iterations && data.iterations.length > 0) {
                commit('SET_SECONDARY', data.iterations[0]);
            } else {
                commit('SET_SECONDARY', null);
            }

            commit('SET_LOADED', true);
        },
        async fetchTeamReport({ commit }, { projectId, reportAlias }): Promise<ITeamReportResponse> {
            commit('SET_LOADED', false);
            const reportService = new ReportService();
            const token = AuthHelper.token(TokenType.ReportTeamToken);
            const data: ITeamReportResponse = await reportService.getTeamReport(projectId, token, reportAlias);

            if (!data) {
                commit('SET_LOADED', true);
            }

            commit('SET_REPORT', { report: data, isTeam: true, alias: reportAlias });
            commit('SET_PRIMARY', data.dateReport);
            if (data.iterations && data.iterations.length > 0) {
                commit('SET_SECONDARY', data.iterations[0]);
            } else {
                commit('SET_SECONDARY', null);
            }
            commit('SET_LOADED', true);
            return data;
        },
    },
};
