import ContentWrapperVue from "@/generic/components/ContentWrapper.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        ContentWrapper: ContentWrapperVue,
    },
    props: { 
        name: { type: String, required: true },
    },
});
