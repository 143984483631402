import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ievp-score" }
const _hoisted_2 = { class: "ievp-score-graph-container" }
const _hoisted_3 = { class: "ievp-score-graph-label" }
const _hoisted_4 = { class: "ievp-score-graph-container-label" }
const _hoisted_5 = { class: "ievp-score-graph-container-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mappedScores, (score, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["ievp-score-graph", { personal: score.personal, organization: !score.personal }]),
          key: `score_${index}`
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(score.label), 1),
          _withDirectives(_createElementVNode("div", {
            style: _normalizeStyle(`left: ${score.left}%`),
            class: "ievp-score-graph-marker"
          }, null, 4), [
            [_directive_tooltip, score.tooltip]
          ])
        ], 2))
      }), 128)),
      _withDirectives(_createElementVNode("div", _hoisted_4, null, 512), [
        [_directive_t, 'reportsWeb.LEAST_IMPORTANT']
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [
        [_directive_t, 'reportsWeb.MOST_IMPORTANT']
      ])
    ])
  ]))
}