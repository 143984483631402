<template>
    <div>
        <survey-subtitle v-if="!hideTitle" v-t="{ path: `${type}.${categoryKey}_ROLE_TITLE`, args: { context } }" />
        <content-wrapper v-if="!hideIntro" tag="p" v-t="{ path: `${type}.${categoryKey}_INTRODUCTION`, args: { context } }" />

        <content-wrapper inverse>
            {{
                $t(`${type}.${categoryKey}_SCORE_DESC`, {
                    context,
                    score: primaryScores.teamMedian,
                    teamMedian: primaryScores.teamMedian,
                })
            }}
        </content-wrapper>
        <bar-chart-wrapper
            class="fearless-graph"
            :type="type"
            :show-quartiles="showQuartiles"
            :hide-iteration-picker="hideIterationPicker"
            :categoryKey="categoryKey"
            primary
        >
            <bar-chart :scores="secondaryScores" />
            <bar-chart :scores="primaryScores" primary />
        </bar-chart-wrapper>

        <div v-if="!hideExplanation" class="team-scores" :class="{ 'exposed': exposed }">
            <table v-if="exposed" class="exposed-table">
                <tr>
                    <td class="exposed-table-column">
                        <score-table :scores="primaryScores" :type="type" />
                    </td>
                    <td class="exposed-table-column">
                        <content-wrapper>
                            <p>{{ teamQuartileTranslation }}</p>
                            <p>{{ teamDeviationTranslation }}</p>
                        </content-wrapper>
                    </td>
                </tr>
            </table>
            <content-wrapper v-else>
                <p>{{ teamQuartileTranslation }}</p>
                <p>{{ teamDeviationTranslation }}</p>
            </content-wrapper>
        </div>
    </div>
</template>
<script src="./TeamCategory.ts"></script>
<style lang="scss">
.team-scores {
    .exposed-table {
        width: 100%;
        &-column {
            width: 50%;
            vertical-align: top;
            padding-right: 1rem;
        }
    }
}
</style>
