import { IevpPillar } from './IevpPillar';
import { IevpReportDataSection, IevpReportSectionCategory } from './IevpReportData';

export default class IevpScore {
    private sections: IevpReportDataSection[];

    constructor(sections: IevpReportDataSection[]) {
        this.sections = sections;
    }

    public categoryScores(category: IevpReportSectionCategory) {
        const section = this.sectionScores(category);
        return [
            section.standMaterialOfferings,
            section.standGrowthDevelopment,
            section.standConnectionCommunication,
            section.standMeaningPurpose,
        ];
    }

    public pillarScores(category: IevpReportSectionCategory) {
        const scores = this.sectionScores(category);
        return {
            [IevpPillar.MaterialOfferings]: scores.standMaterialOfferings,
            [IevpPillar.ConnectionCommunity]: scores.standConnectionCommunication,
            [IevpPillar.GrowthDevelopment]: scores.standGrowthDevelopment,
            [IevpPillar.MeaningPurpose]: scores.standMeaningPurpose,
        };
    }

    private sectionScores(category: IevpReportSectionCategory): IevpReportDataSection {
        return this.sections.find((s) => s.category === category);
    };
}
