<template>
    <button type="button" class="survey-button" :class="[variant]" :disabled="disabled">
        <slot />
    </button>
</template>
<script src="./Button.ts"></script>
<style lang="scss">
@import '@/variables';

.survey-button {
    font-size: $font-size-lg;
    line-height: 1.5;
    padding: 0.5rem 2rem;
    border: 0;
    outline: none;
    border-radius: $button-border-radius;

    &:not([disabled]) {
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
    
        &:active {
            transform: scale(0.95);
        }
    }
}
</style>
