import { defineComponent, onMounted, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import ITeamReportResponse from '@/report/models/ITeamReportResponse';
import { ReportService } from '@/generic/services/reportService';
import Project from '@/report/models/Project';
import OrganizationScore from '@/report/models/Organization/OrganizationScore';
import ProjectInOrganizationScore from '@/report/models/Organization/ProjectInOrganizationScore';
import { getPreferredLanguage } from '@/i18n';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import PageVue from '@/report/components/Page.vue';
import { scores, barColor } from '@/report/components/BaseReportComponent';
import ContentWrapperVue from '@/generic/components/ContentWrapper.vue';
import BarChartWrapperVue from '@/report/components/charts/BarChartWrapper.vue';
import BarChartVue from '@/report/components/charts/BarChart.vue';
import MathHelper from '@/generic/helpers/MathHelper';
import { CategoryType } from '@/report/models/CategoryType';
import Scores from '@/report/models/Scores';
import DashboardData, { DashboardDataProject, GroupScoreSet } from '@/report/models/DashboardData';
import { AccordionItem, AccordionList } from 'vue3-rich-accordion';
import Language from '@/store/models/Language';
import TeamHeatmap from '../../report/fearless/partials/TeamHeatmap.vue';
import he from 'he';

export default defineComponent({
    components: {
        Page: PageVue,
        ContentWrapper: ContentWrapperVue,
        BarChartWrapper: BarChartWrapperVue,
        BarChart: BarChartVue,
        TeamHeatmap,
        AccordionList: AccordionList,
        AccordionItem: AccordionItem,
    },
    setup() {
        const { t, locale } = useI18n();
        const store = useStore();
        const route = useRoute();
        const { params, query } = route;
        const loaded = ref(false);
        const reports: Ref<ITeamReportResponse[]> = ref([]);
        const dashboardData: Ref<DashboardData> = ref(null);
        const orgScores: Ref<OrganizationScore[]> = ref([]);
        const dateRangeProjects = ref({ start: null, end: new Date() });
        const filteredProjects = ref([]);
        const filteredMetaDataGroups = ref([]);
        const state = ref<Record<string, boolean>>({});
        const initialProjects = ref([]);
        const type = 'reportsWeb';
        const showReload = ref(false);
        const reloaded = ref(true);
        const viewOptions = [
            { name: 'Projects', key: 'project' },
            { name: 'Metadata', key: 'meta' },
        ];
        const view = ref(viewOptions[0].key);

        const fetchOrgReports = async (orgId: number, token: string, projectIds: number[]) => {
            orgScores.value = [];

            const reportService = new ReportService();
            dashboardData.value = await reportService.getDashboardInfo(orgId, token, projectIds);

            if (!projectIds || projectIds.length === 0) {
                initialProjects.value = dashboardData.value.projects;

                for (let i = 0; i < dashboardData.value.projects.length; i++) {
                    const project: DashboardDataProject = dashboardData.value.projects[i];
                    if (project.questionScores) {
                        project.questionScores.data.forEach((score: GroupScoreSet) => {
                            let orgQuestion = orgScores.value.find((orgScore) => orgScore.groupName === score.groupName);
                            if (!orgQuestion) {
                                orgQuestion = new OrganizationScore({ groupName: score.groupName });
                                orgScores.value.push(orgQuestion);
                            }

                            orgQuestion.projects.push(
                                new ProjectInOrganizationScore({
                                    positiveAverage: score.positiveAverage,
                                    negativeAverage: score.negativeAverage,
                                    teamMedian: score.median,
                                    projectId: project.projectId,
                                    projectName: project.projectName,
                                    context: project.context,
                                }),
                            );
                        });
                    }
                }
            }
        };

        const getMedianSelectedMetaDataGroups = (key: string = 'GENERAL') => {
            const medians: number[] = [];
            dashboardData.value.metaDataScores.data.forEach((metaDataGroup: GroupScoreSet) => {
                if (metaDataGroup.sections && filteredMetaDataGroups.value.includes(metaDataGroup.groupName)) {
                    const section = metaDataGroup.sections.find((x) => x.category === key);
                    if (section) {
                        medians.push(section.median);
                    }
                }
            });
            return MathHelper.median(medians);
        };

        const getOrgMedian = (key: string = 'GENERAL') => {
            const medians: number[] = [];
            dashboardData.value.projects.forEach((project: DashboardDataProject) => {
                if (project.sections) {
                    const section = project.sections.find((x) => x.category === key);
                    if (section) {
                        medians.push(section.teamMedian);
                    }
                }
            });
            return MathHelper.median(medians);
        };

        const scoresMedian = (scores: Scores[]) => {
            const medians = [];
            scores.forEach((score: Scores) => {
                medians.push(score.teamMedian);
            });
            return MathHelper.median(medians);
        };

        const questionTitle = (key: string) => {
            return t(`surveyQuestions.${key}`);
        };

        const setView = (newView: string) => {
            view.value = newView;
        };

        const mapToScoreObject = (scores: GroupScoreSet, key?: string) => {
            if (key) {
                const scoreSet = scores.sections.find((x) => x.category === key.toUpperCase());

                if (!scoreSet) {
                    debugger;
                }

                return {
                    teamMedian: scoreSet.median,
                    negativeAverage: scoreSet.negativeAverage,
                    positiveAverage: scoreSet.positiveAverage,
                };
            }

            return {
                teamMedian: scores.median,
                negativeAverage: scores.negativeAverage,
                positiveAverage: scores.positiveAverage,
            };
        };

        const reloadData = async () => {
            reloaded.value = false;
            await fetchOrgReports(+params.orgId, query.token.toString(), [...filteredProjects.value]);

            showReload.value = false;
            reloaded.value = true;
        };

        const toggleProjects = () => {
            if (filteredProjects.value.length > 0) {
                filteredProjects.value = [];
            } else {
                filteredProjects.value = [];
                dashboardData.value.projects.forEach((project: DashboardDataProject) => {
                    filteredProjects.value.push(project.projectId);
                });
            }
        };

        onMounted(async () => {
            await fetchOrgReports(+params.orgId, query.token.toString(), []);

            dashboardData.value.projects.forEach((project: DashboardDataProject) => {
                filteredProjects.value.push(project.projectId);
            });

            if (dashboardData.value.metaDataScores && dashboardData.value.metaDataScores.data && dashboardData.value.metaDataScores.data.length > 0) {
                dashboardData.value.metaDataScores.data.forEach((group) => {
                    filteredMetaDataGroups.value.push(group.groupName);
                });
            }

            const language: Language = new Language({
                knowledgeModelAlias: 'fearless',
                knowledgeModelName: 'Fearless Organization Scan',
                languageCode: getPreferredLanguage(route),
                section: 'reports',
                reportAlias: 'organization_dashboard',
            });

            await store.dispatch('setLanguage', language);
            language.instrumentAlias = 'psi';

            await store.dispatch('setLanguage', language);

            store.commit('SET_LANGUAGES', [language]);

            const lang = store.getters.language;
            lang.section = 'survey';
            lang.reportAlias = null;
            await store.dispatch('setLanguage', lang);

            loaded.value = true;
        });

        return {
            type,
            loaded,
            reports,
            dashboardData,
            orgScores,
            filteredProjects,
            filteredMetaDataGroups,
            dateRangeProjects,
            locale,
            scores,
            barColor,
            getOrgMedian,
            getMedianSelectedMetaDataGroups,
            scoresMedian,
            questionTitle,
            mapToScoreObject,
            CategoryType,
            value: true,
            setView,
            view,
            viewOptions,
            state,
            showReload,
            reloadData,
            reloaded,
            initialProjects,
            toggleProjects,
        };
    },
    computed: {
        projects() {
            return this.initialProjects.filter((project: Project) => {
                const closeDate = new Date(project.closeDate);
                if (!this.dateRangeProjects.start || !this.dateRangeProjects.end) {
                    return true;
                }

                return closeDate.getTime() >= this.dateRangeProjects.start.getTime() && closeDate.getTime() <= this.dateRangeProjects.end.getTime();
            });
        },
        projectSelected() {
            return (projectId: number) => {
                const project = this.projects.find((p: Project) => p.projectId === projectId);
                return project && this.filteredProjects.includes(projectId);
            };
        },
        metaDataGroupSelected() {
            return (groupName: string) => {
                const metaDataGroup = this.dashboardData.metaDataScores.data.find((x) => x.groupName === groupName);
                return metaDataGroup && this.filteredMetaDataGroups.includes(groupName);
            };
        },
        context() {
            return he.decode(this.dashboardData.organizationName);
        },
        categoryKeys() {
            return Object.keys(CategoryType).filter((key) => {
                return CategoryType[key] !== CategoryType.General;
            });
        },
    },
    methods: {
        formatProjectDate(dateString: string) {
            const date = new Date(dateString);
            if (!dateString || !date.getTime()) {
                return '';
            }

            return (
                date.toLocaleDateString(this.locale, { weekday: 'short' }) +
                ' ' +
                date.toLocaleDateString(this.locale, { day: 'numeric', month: 'numeric', year: 'numeric' })
            );
        },
        toggleProject(projectId: number) {
            this.showReload = true;
            const i = this.filteredProjects.indexOf(projectId);
            if (i < 0) {
                this.filteredProjects.push(projectId);
            } else {
                this.filteredProjects.splice(i, 1);
            }
        },
        toggleMetaDataGroups(groupName: string) {
            this.showReload = true;
            const i = this.filteredMetaDataGroups.indexOf(groupName);
            if (i < 0) {
                this.filteredMetaDataGroups.push(groupName);
            } else {
                this.filteredMetaDataGroups.splice(i, 1);
            }
        },
    },
});
