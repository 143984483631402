import { uniqueId } from '@/generic/helpers/UniqueIdHelper';
import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';
import RadioOption from '@/survey/models/RadioOption';
import { defineComponent } from 'vue';

export default defineComponent({
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    setup() {
        const id = uniqueId('demographicRadio');

        return {
            id,
        };
    },
    methods: {
        optionSelected(option: RadioOption) {
            this.question.answerOptions.forEach((element) => {
                element.checked = false;
            });

            option.checked = true;

            const answer = {
                value: option.alias,
            };
            this.$emit('selected', answer);
        },
    },
});
