import { computed, defineComponent, onMounted, onUnmounted, PropType, ref, watch } from 'vue';
import Question from '../models/Question';
import SpinnerVue from '@/generic/components/Spinner.vue';
import Survey from '../models/Survey';
import { useStore } from 'vuex';
import SurveyContextVue from './SurveyContext.vue';
import SurveyQuestionVue from './SurveyQuestion.vue';
import { setupGroupedSurvey, setupSurvey } from './BaseSurveyQuestions';
import ContentWrapperVue from '@/generic/components/ContentWrapper.vue';
import Language from '@/store/models/Language';

export default defineComponent({
    components: {
        SurveyQuestion: SurveyQuestionVue,
        ContentWrapper: ContentWrapperVue,
        context: SurveyContextVue,
        Spinner: SpinnerVue,
    },
    props: {
        questions: { type: Array as PropType<Question[]> },
        survey: { type: Object as PropType<Survey> },
        surveys: { type: Array as PropType<Survey[]> },
        disablePrevious: { type: Boolean },
        grouped: { type: Boolean },
        hasDemographicSection: { type: Boolean },
    },
    computed: {},
    setup(props, { emit }) {
        const store = useStore();
        const offline = ref(false);
        const error = computed(() => store.getters.queueError);
        const reloaded = computed(() => localStorage.getItem(`reloadSurveyAfterError:${props.survey.surveyId}`) === 'true');
        const survey = setupSurvey(props.questions);
        const groupedSurvey = setupGroupedSurvey(props.questions);

        // Methods
        const finish = () => {
            emit('finish');
        };

        const onOffline = () => {
            offline.value = true;
        };

        const onOnline = () => {
            offline.value = false;
            store.dispatch('processQueue');
        };

        watch(
            () => survey.questionIndex.value,
            () => {
                emit('question-index-updated', survey.questionIndex.value);
            },
        );

        // Start
        onMounted(() => {
            if (props.grouped) {
                groupedSurvey.resumeSurvey();
            } else {
                survey.findNextQuestionIndex();
            }

            window.addEventListener('online', onOnline);
            window.addEventListener('offline', onOffline);
        });

        onUnmounted(() => {
            window.removeEventListener('online', onOnline);
            window.removeEventListener('offline', onOffline);
        });

        const currentSurvey = computed(() => {
            if (props.grouped && props.surveys && props.surveys.length) {
                const question = groupedSurvey.sectionQuestions.value[0].question;
                return props.surveys.find((s: Survey) => s.surveyId === question.surveyId);
            }

            return props.survey;
        });

        // Methods
        watch(
            () => currentSurvey.value,
            async () => {
                const language = new Language(store.getters.language);
                language.instrumentAlias = currentSurvey.value.instrumentAlias;
                language.section = 'survey';

                await store.dispatch('setLanguage', language);
            },
        );

        const reloadSurvey = async () => {
            localStorage.setItem(`reloadSurveyAfterError:${props.survey.surveyId}`, 'true');
            document.location.reload();
        };

        return {
            currentSurvey,
            offline,
            finish,
            onOffline,
            onOnline,
            error,
            reloadSurvey,
            ...survey,
            ...groupedSurvey,
            reloaded,
        };
    },
});
