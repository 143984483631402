<template>
    <survey-loader v-if="loading" />
    <div v-else id="survey-finish">
        <survey-title v-t="'generic.RESULT_TITLE'" class="text-center" />
        <survey-progress :progress="100" />

        <div class="text-center">
            <survey-lead v-t="{ path: 'generic.RESULT_TEXT' }" />
            <survey-title>
                <survey-icon icon="high-five" size="lg" color="accent" />
            </survey-title>

            <survey-skeleton v-if="loading" :amount="4" :max-width="40" class="mb-3" />
            <div class="survey-note" v-if="thankYouMessage">
                <div class="survey-note-wrapper">
                    <div class="survey-note-content" v-sanitize-html="thankYouMessage"></div>
                </div>
            </div>

            <p>
                <span v-t="'generic.RESULT_FOOTER'" class="mr-1" />
                <a :href="link" target="_blank" v-t="'generic.RESULT_FOOTER_LINK'" />
            </p>
        </div>
    </div>
</template>
<script src="./SurveyFinish.ts"></script>
<style lang="scss">
#survey-finish {
    #hi5-logo {
        margin: 1rem 3rem;
        max-height: 200px;
    }

    .survey-note {
        margin: 5rem 0;
        background: transparent !important;

        &-wrapper {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            position: relative;
            width: 70%;
            margin: 3rem auto;

            >.survey-note-content {
                min-height: 200px;
                background: #fff !important;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                padding: 2rem;
                position: relative;
                z-index: 2;

                p {
                    margin: 0;
                }
            }

            &:before {
                content: '';
                height: 98%;
                position: absolute;
                width: 100%;
                background: #fafafa;
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
                left: -5px;
                top: 4px;
                transform: rotate(-2.5deg);
                z-index: 1;
            }

            &:after {
                content: '';
                height: 98%;
                position: absolute;
                width: 100%;
                background: #f6f6f6;
                box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
                right: -3px;
                top: 1px;
                transform: rotate(1.4deg);
                z-index: 1;
            }
        }
    }
}
</style>
