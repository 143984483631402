<template>
    <page break-before>
        <div class="compare-toolbar">
            <div>
                <label>Vertical axis</label>
                <survey-select :value="compare.a"
                               @select="updateQuestion('a', $event)">
                    <option value="">Select a option</option>
                    <option v-for="option in options"
                            :key="option.label"
                            :value="option.label"
                            :disabled="option.label === compare.b">
                        {{ option.question }}
                    </option>
                </survey-select>
            </div>

            <div>
                <label>Horizontal axis</label>
                <survey-select :value="compare.b"
                               @select="updateQuestion('b', $event)">
                    <option value="">Select a option</option>
                    <option v-for="option in options"
                            :key="option.label"
                            :value="option.label"
                            :disabled="option.label === compare.a">
                        {{ option.question }}
                    </option>
                </survey-select>
            </div>
        </div>

        <survey-loader v-if="loading"
                       center />
        <template v-else>
            <template v-for="category in Object.keys(scoresPerCategory)"
                      :key="`compare_${category}`">
                <h6 v-t="{ path: `${type}.${category}_ROLE_TITLE`, args: { context } }"></h6>
                <div class="survey-table-shared compare-table-wrapper border">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th style="text-align: left"
                                    v-for="title in categoryScores(category).titles"
                                    :key="`compare_${category}_${title}`">{{ title }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="main in Object.keys(categoryScores(category).scores)"
                                :key="`compare_${category}_${main}`">
                                <td>{{ main }}</td>
                                <td v-for="score in categoryScores(category).scores[main]"
                                    :key="`compare_${category}_${main}_${score.compare}`">
                                    <score-badge v-if="score.median"
                                                 :score="score.median"></score-badge>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </template>
    </page>
</template>
<script src="./TeamCompare.ts"></script>
<style lang="scss"
       scoped>
    h6 {
        font-size: 1rem;
        font-weight: bold;
        margin: 1rem 0 0.25rem 0;
    }

    .compare-toolbar {
        display: flex;
        justify-content: space-between;

        label {
            margin-right: .25rem;
        }

        .compare-table-wrapper {
            margin-bottom: 1rem;
        }
    }
</style>