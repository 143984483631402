<template>
    <transition name="fade" appear>
        <div class="survey-alert" :class="[variant]">
            <survey-icon v-if="icon" :icon="icon" :color="variant" />
            <slot />
        </div>
    </transition>
</template>
<script src="./Alert.ts"></script>
<style lang="scss">
@import '@/variables';

.survey-alert {
    padding: 1rem;
    line-height: 1.2;

    .survey-icon {
        margin-right: 0.5rem;
    }

    // Transition
    &.fade-enter-active,
    &.fade-leave-active {
        transition: opacity .2s ease;
    }

    &.fade-enter-from,
    &.fade-leave-to {
        opacity: 0;
    }

}
</style>
