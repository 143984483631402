import { Directive } from 'vue';

let deleteTimeout = null;
const deleteTimeoutTime = 250;

const createTooltip = (id, classes: string[] = []) => {
    const div = document.createElement('div');
    div.setAttribute('id', id);
    div.setAttribute('class', `v-tooltip ${classes.join(' ')}`);
    div.addEventListener('mouseover', cancelDelete);
    div.addEventListener('mouseleave', deleteTooltip);
    document.querySelector('html').append(div);
};

const getTooltip = (id): HTMLElement => {
    return document.querySelector(`#${id}`);
};

const deleteTooltip = () => {
    clearTimeout(deleteTimeout);
    deleteTimeout = setTimeout(() => {
        document.querySelectorAll('.v-tooltip').forEach((el) => {
            el.parentNode.removeChild(el);
        });
    }, deleteTimeoutTime);
};

const cancelDelete = () => {
    clearTimeout(deleteTimeout);
};

const showTooltip = (text, classes: string[], hoveredElement) => {
    const uniqueId = 'v-vue3-tooltip';
    if (!getTooltip(uniqueId)) {
        createTooltip(uniqueId, classes);
    } else {
        cancelDelete();
    }
    const tooltip: HTMLElement = getTooltip(uniqueId);
    const tooltipRect = tooltip.getBoundingClientRect();
    tooltip.innerHTML = text;

    const bodyRect = document.body.getBoundingClientRect();
    const rect = hoveredElement.getBoundingClientRect();
    const marginBottom = 16;
    const x = rect.x + ((rect.right - rect.x) / 2) - (tooltip.offsetWidth / 2);
    let left = x < 0 ? 0 : x;

    if (x + tooltipRect.width > bodyRect.width) {
        left = bodyRect.width - tooltipRect.width;
    }

    const pos = {
        top: rect.y - tooltip.offsetHeight - marginBottom + 'px',
        left: left + 'px',
    };
    tooltip.setAttribute('style', `top: ${pos.top}; left: ${pos.left}`);
};

const addListeners = (el: HTMLElement, binding) => {
    el.onmouseenter = () => {
        const classes = [binding.arg ? binding.arg : ''];
        classes.push(...Object.keys(binding.modifiers));
        showTooltip(binding.value, classes, el);
    };
    el.addEventListener('mouseleave', deleteTooltip);
};

const directive: Directive = {
    mounted(el: HTMLElement, binding) {
        addListeners(el, binding);
    },
    updated(el: HTMLElement, binding) {
        if (binding.oldValue !== binding.value) {
            addListeners(el, binding);
        }
    },
};

export default directive;
