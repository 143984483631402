import { computed, ComputedRef, defineComponent, nextTick, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { CategoryType } from '@/report/models/CategoryType';
import IReportData from '@/report/models/IReportData';
import ICategory from '@/report/models/ICategory';
import $ from 'jquery';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import BaseReportComponent from '../../components/BaseReportComponent';
import BaselineBarVue from '../components/BaselineBar.vue';
import ConsiderVue from '../components/Consider.vue';
import ThreeColumnsVue from '../components/ThreeColumns.vue';
import BaselineGraphicsVue from '../components/BaselineGraphics.vue';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        BaselineBar: BaselineBarVue,
        Consider: ConsiderVue,
        ThreeColumns: ThreeColumnsVue,
        BaselineGraphics: BaselineGraphicsVue,
    },
    setup() {
        const store = useStore();
        const user = computed(() => store.getters['report/user']);
        const context = computed(() => store.getters['report/context']);
        const { t } = useI18n();
        const loaded = computed(() => store.getters['report/loaded']);
        const primary: ComputedRef<IReportData> = computed(() => store.getters['report/primary']);
        const secondary: ComputedRef<IReportData> = computed(() => store.getters['report/secondary']);
        const reportData = computed(() => store.getters['report/report']);
        const categoryKeys = Object.keys(CategoryType).filter((key: string) => key !== 'General');

        const general: ComputedRef<ICategory> = computed(() => {
            if (primary.value && primary.value.categories) {
                return primary.value.categories.find((cat: ICategory) => {
                    return cat.category === CategoryType.General;
                });
            }
            return {} as ICategory;
        });

        const conclusion = computed(() => (general.value.negativeMedianDistributionRange ? 'NEGATIVE' : 'POSITIVE'));
        const scores = computed(() => {
            return (data: IReportData, category: string) => {
                if (data && data.categories) {
                    return data.categories.find((cat: ICategory) => {
                        return cat.category === category;
                    });
                }
                return {};
            };
        });

        const actionColors = ['brown', 'yellow', 'army-green', 'beige'];
        const osColours = ['grey', 'pink', 'purple'];
        const communicationColours = ['green', 'blue', 'red'];

        const drawGraphs = () => {
            const labelRotation = -45;
            const labelColor = '#000';
            const borderWidth = 0;
            const gridLinesColor = '#ddd';
            const publicBarColor = '#515151';
            const privateBarColor = '#949494';

            $('.zones-canvas').each(function() {
                const $el = $(this);
                const data = [];
                $el.parent()
                    .children('.zone-item')
                    .each(function() {
                        const $item = $(this);
                        data.push({
                            label: $item.attr('data-item-key'),
                            data: [parseInt($item.attr('data-public-item-value')), parseInt($item.attr('data-private-item-value'))],
                            datalabels: {
                                rotation: labelRotation,
                                anchor: 'start',
                                align: 115,
                                offset: 0,
                                font: {
                                    lineHeight: 1,
                                },
                                color: labelColor,
                                formatter: function(value, context) {
                                    return context.dataset.label + ': ' + value;
                                },
                            },
                        });
                    });

                new Chart(this.getContext('2d'), {
                    type: 'bar',
                    data: {
                        labels: ['Public', 'Private'],
                        // datasets: data,
                    },
                    options: {
                        layout: {
                            padding: {
                                bottom: 75,
                            },
                        },
                        elements: {
                            rectangle: {
                                borderWidth,
                                backgroundColor: [publicBarColor, privateBarColor],
                            },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: { display: false },
                        title: { display: false },
                        scales: {
                            yAxes: [
                                {
                                    display: true,
                                    gridLines: {
                                        display: false,
                                    },
                                    offset: 0,
                                    ticks: { min: 0, max: 100 },
                                },
                            ],
                            xAxes: [
                                {
                                    display: true,
                                    gridLines: {
                                        display: true,
                                        color: gridLinesColor,
                                        drawOnChartArea: false,
                                    },
                                    offset: 10,
                                    position: 'top',
                                },
                            ],
                        },
                    },
                    plugins: [ChartDataLabels],
                });
            });

            // $('.other-zones-canvas').each(function() {
            //     const $el = $(this);
            //     const data = [];
            //     $el.parent()
            //         .find('.zone-item')
            //         .each(function() {
            //             const $item = $(this);
            //             data.push({
            //                 label: $item.attr('data-item-key'),
            //                 data: [parseInt($item.attr('data-item-value'))],
            //                 backgroundColor: 'rgba(85, 85, 85, 0.6)',
            //                 datalabels: {
            //                     rotation: labelRotation,
            //                     anchor: 'start',
            //                     align: 115,
            //                     offset: 0,
            //                     color: labelColor,
            //                     formatter: function(value, context) {
            //                         return context.dataset.label + ': ' + value;
            //                     },
            //                 },
            //             });
            //         });

            //     new Chart(this.getContext('2d'), {
            //         type: 'bar',
            //         data: {
            //             labels: ['Public'],
            //             datasets: data,
            //         },
            //         options: {
            //             layout: {
            //                 padding: {
            //                     bottom: 75,
            //                 },
            //             },
            //             elements: {
            //                 rectangle: {
            //                     borderWidth,
            //                 },
            //             },
            //             tooltips: { enabled: false },
            //             responsive: true,
            //             maintainAspectRatio: false,
            //             legend: { display: false },
            //             title: { display: false },
            //             scales: {
            //                 yAxes: [
            //                     {
            //                         display: true,
            //                         ticks: {
            //                             min: 0,
            //                             max: 70,
            //                         },
            //                         gridLines: { display: false },
            //                     },
            //                 ],
            //                 xAxes: [
            //                     {
            //                         display: false,
            //                         gridLines: { color: gridLinesColor },
            //                     },
            //                 ],
            //             },
            //         },
            //         plugins: [ChartDataLabels],
            //     });
            // });
        };

        onMounted(() => {
            nextTick(drawGraphs);
        });

        return {
            loaded,
            user,
            t,
            context,
            categoryKeys,
            CategoryType,
            primary,
            secondary,
            store,
            reportData,
            general,
            conclusion,
            scores,
            actionColors,
            osColours,
            communicationColours,
        };
    },
});
