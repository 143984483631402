import { computed, ComputedRef, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PersonalChartVue from '@/report/fearless/charts/PersonalChart.vue';
import { CategoryType } from '@/report/models/CategoryType';
import IReportData from '@/report/models/IReportData';
import ICategory from '@/report/models/ICategory';
import BaseReportComponent from '../../components/BaseReportComponent';
import CoverPageVue from '@/report/fearless/components/CoverPage.vue';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        PersonalChart: PersonalChartVue,
        CoverPage: CoverPageVue,
    },
    setup() {
        const store = useStore();
        const { t } = useI18n();
        const user = computed(() => store.getters['report/user']);
        const context = computed(() => store.getters['report/context']);
        const loaded = computed(() => store.getters['report/loaded']);
        const primary: ComputedRef<IReportData> = computed(() => store.getters['report/primary']);
        const secondary: ComputedRef<IReportData> = computed(() => store.getters['report/secondary']);
        const reportData = computed(() => store.getters['report/report']);
        const categoryKeys = Object.keys(CategoryType).filter((key: string) => key !== 'General');

        const general: ComputedRef<ICategory> = computed(() => {
            if (primary.value && primary.value.categories) {
                return primary.value.categories.find((cat: ICategory) => {
                    return cat.category === CategoryType.General;
                });
            }
            return {} as ICategory;
        });

        const conclusion = computed(() => (general.value.negativeMedianDistributionRange ? 'NEGATIVE' : 'POSITIVE'));
        const scores = computed(() => {
            return (data: IReportData, category: string) => {
                if (data && data.categories) {
                    return data.categories.find((cat: ICategory) => {
                        return cat.category === category;
                    });
                }
                return {};
            };
        });

        return {
            loaded,
            user,
            t,
            context,
            categoryKeys,
            CategoryType,
            primary,
            secondary,
            store,
            reportData,
            general,
            conclusion,
            scores,
        };
    },
});
