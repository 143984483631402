export enum Constructs {
    HopeColor = '#AD494A',
    FearColor = '#843C39',
    PrideColor = '#E7CB94',
    ShameColor = '#E7BA52',
    SatisfactionColor = '#BD9F39',
    AngerColor = '#8C6D31',
    AdmirationColor = '#CEDB9C',
    ContemptColor = '#B4CF6B',
    DesireColor = '#8CA252',
    DisgustColor = '#637939',
    JoyColor = '#9C9EDE',
    SadnessColor = '#6B6ECF',
    FascinationColor = '#5255A3',
    BoredomColor = '#393B79',

    Construct_1 = "Hope",
    Construct_2 = "Fear",
    Construct_3 = "Pride",
    Construct_4 = "Shame",
    Construct_5 = "Satisfaction",
    Construct_6 = "Anger",
    Construct_7 = "Admiration",
    Construct_8 = "Contempt",
    Construct_9 = "Desire",
    Construct_10 = "Disgust",
    Construct_11 = "Joy",
    Construct_12 = "Sadness",
    Construct_13 = "Fascination",
    Construct_14 = "Boredom",
    Construct_15 = "Neutral",


}