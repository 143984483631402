import { defineComponent } from 'vue';
import PrintToolbarVue from '../components/partials/PrintToolbar.vue';

export default defineComponent({
    components: {
        PrintToolbar: PrintToolbarVue,
    },
    props: {
        loaded: Boolean,
        error: Boolean,
    },
});
