<template>
    <div id="chart-wrapper">
        <div id="chart">
            <pit-wheel />
            <div id="wheel-chart"></div>
        </div>
    </div>
</template>
<script src="./PitWheelWrapper.ts"></script>
<style lang="scss">
#chart {
    position: relative;
    max-height: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 800px;
        height: 1000px;
    }

    #wheel-chart {
        position: absolute;
    }
}
</style>
