import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    props: {
        inverse: Boolean,
        center: Boolean,
        justifyCenter: Boolean,
        small: Boolean,
        full: Boolean,
    },
});
