import ICategory from '@/report/models/ICategory';
import { computed, defineComponent, onMounted, watch } from 'vue';
import Chart from 'chart.js';
import IterationPickerVue from '@/report/components/partials/IterationPicker.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: {
        IterationPicker: IterationPickerVue,
    },
    props: {
        categories: { type: Array, default: () => [] },
        type: { type: String, default: 'report' },
        hideIteration: { type: Boolean },
    },
    setup(props) {
        const store = useStore();
        const { t } = useI18n();
        const completedOn = computed(() => t(`generic.YOUR_COMPLETION_DATE`));
        const drawChart = () => {
            const values = [];
            (props.categories as ICategory[]).forEach((cat: ICategory) => {
                if (cat.category !== 'GENERAL') {
                    values.push(cat.personalScore);
                }
            });

            const ctx = (document.querySelector('#psi-chart') as any).getContext('2d');
            new Chart(ctx, {
                type: 'radar',
                data: {
                    labels: values,
                    labelfontColor: 'red',
                    datasets: [
                        {
                            data: values,
                            borderColor: '#EC671A',
                            borderDash: [2, 3],
                            borderWidth: 2,
                            backgroundColor: 'rgba(255,255,255,0.9)',
                            pointBackgroundColor: 'rgb(178, 200, 24)',
                            pointRadius: 0,
                        },
                    ],
                },
                options: {
                    animation: false,
                    legend: { display: false },
                    title: { display: false },
                    scale: {
                        display: true,
                        ticks: {
                            display: false,
                            beginAtZero: true,
                            stepSize: 100,
                            max: 100,
                            min: 0,
                            maxTicksLimit: 1,
                            showLabelBackdrop: false,
                        },
                        angleLines: {
                            color: '#5F6166',
                            lineWidth: 1,
                        },
                        gridLines: {
                            color: '#5F6166',
                            lineWidth: 2,
                        },
                        pointLabels: {
                            fontSize: 16,
                        },
                    },
                    plugins: {
                        datalabels: false,
                    }
                },
            });
        };

        const completionDate = computed(() => store.getters['report/report'].dateCompleted);
        const psi = computed(() => {
            const general = (props.categories as ICategory[]).find((cat: ICategory) => {
                return cat.category === 'GENERAL';
            });
            return general ? general.personalScore : 0;
        });

        onMounted(() => {
            drawChart();
            watch(() => props.categories, drawChart);
        });

        return {
            psi,
            completionDate,
            completedOn,
        };
    },
});
