<template>
    <div v-if="showContext"
         class="context-overlay">
        <span v-if="showContextText"
              @click="toggleShrink"
              class="question-context">
            <small v-sanitize-html="contextHtml"></small>
        </span>
        <transition name="context">
            <div v-if="showOverlay"
                 @click="shrink"
                 class="context-overlay-fixed">
                <span class="question-context"
                      v-sanitize-html="contextHtml"></span>
            </div>
        </transition>
    </div>
</template>
<script src="./SurveyContext.ts"></script>
<style lang="scss">
    .context-overlay {
        cursor: pointer;
        user-select: none;
    }

    .context-overlay-fixed {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: #fff;
        animation-fill-mode: forwards;
        cursor: pointer;

        &.context-enter-active {
            animation: contextAnimation 0s ease-in-out;
        }

        &.context-leave-active {
            animation: contextAnimation 0.5s ease-in-out reverse;
        }
    }

    @keyframes contextAnimation {
        0% {
            transform-origin: top;
            transform: translateY(2rem) scale(0.95);
            opacity: 0;
            background: rgba(255, 255, 255, 0);
        }

        50% {
            transform: translateY(1rem) scale(1.1);
            background: rgba(255, 255, 255, 0);
        }

        75% {
            transform: translateY(0) scale(1);
            background: rgba(255, 255, 255, 5);
        }

        100% {
            transform: translateY(0) scale(1);
            opacity: 1;
            background: rgba(255, 255, 255, 1);
        }
    }
</style>