import { ContextLocation, ContextOption } from '@/survey/models/Context';
import { computed, defineComponent, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'context',
    props: {
        context: { type: String, default: '' },
        option: { type: String, default: ContextOption.NoContext },
        location: { type: String, default: ContextLocation.None },
    },
    data() {
        return {
            shrinkTimeout: null,
            isShrinked: true,
        };
    },
    setup(props) {
        const { t } = useI18n();
        const contextHtml = computed(() => {
            return t('generic.QUESTION_ANSWER_IN_CONTEXT', { context: props.context });
        });
        const ContextEnum = ContextLocation;

        return {
            contextHtml,
            ContextEnum,
        };
    },
    computed: {
        contextLocations() {
            return this.location.split(',').map((location: string ) => location.trim());
        },
        showContext() {
            return this.location !== ContextLocation.None && this.option !== ContextOption.NoContext && this.context;
        },
        showOverlay() {
            return !this.isShrinked && this.contextLocations.includes(ContextLocation.BetweenQuestions);
        },
        showContextText() {
            return this.contextLocations.includes(ContextLocation.AtQuestion);
        },
    },
    methods: {
        shrink() {
            if (this.isShrinked) {
                return;
            }

            this.isShrinked = true;
        },
        show(timeout: number) {
            clearTimeout(this.shrinkTimeout);
            this.isShrinked = false;
            this.shrinkTimeout = setTimeout(() => {
                this.isShrinked = true;
            }, timeout || 2000);
        },
        toggleShrink() {
            this.isShrinked = !this.isShrinked;
        },
    },
});
