import DistributionChartVue from "@/report/components/charts/DistributionChart.vue";
import TeamCompareQuestionsVue from "@/report/components/partials/TeamCompareQuestions.vue";
import DistributionChartData from "@/report/models/Charts/DistributionChartData";
import { PropType, defineComponent, ref } from "vue";

export default defineComponent({
    components: {
        TeamCompareQuestion: TeamCompareQuestionsVue,
        DistributionChart: DistributionChartVue,
    },
    methods: {
        compare(value) {
            this.$emit('compare', value);
        },
    },
    props: {
        report: { type: Object },
        distributionData: { type: Object as PropType<DistributionChartData> },
        categoryKey: { type: String },
        context: { type: String },
        scores: { type: Object, default: () => new Object() },
        type: { type: String },
        options: { type: Array },
        optionDefault: { type: String, default: 'Select an option' },
        compareTo: { type: String },
        compareScores: { type: Object },
        loading: { type: Boolean },
        quarters: { type: Array, default: () => ['Minimal', 'Maximum'] },
    },
    setup() {
        const state = ref<Record<string, boolean>>({});
        return { state };
    },
});
