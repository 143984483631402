<template>
    <div class="survey-percentage-slider">
        <div class="survey-percentage-slider-labels">
            <div class="label"
                 v-tooltip:large-fixed="t('generic.' + labels.left + '_INFO')">
                <survey-icon icon="info"
                             size="lg" />
                <span v-t="'surveyAnswers.' + labels.left"></span>
            </div>
            <div class="label"
                 v-tooltip:large-fixed="t('generic.' + labels.right + '_INFO')">
                <span v-t="'surveyAnswers.' + labels.right"></span>
                <survey-icon icon="info"
                             size="lg" />
            </div>
        </div>
        <input type="range"
               class="survey-percentage-slider-input"
               @change="onChange"
               :value="sliderValue"
               :class="{ 'answered' : answerGiven }" />
    </div>
</template>
<script src="./PercentageSlider.ts"></script>
<style lang="scss">
    @import '@/variables';

    $range-shadow: none;
    $range-radius: 50%;
    $range-accent: $accent-color;
    $range-color: $gray;
    $range-thumb-color: $dark-gray;

    .survey-percentage-slider {
        &-labels {
            display: flex;
            justify-content: space-between;

            .label {
                display: flex;
                gap: 0.5rem;
                user-select: none;
                padding: 0.25rem;
                border-radius: 0.25rem;

                &:hover {
                    background: $light-color;
                }
            }
        }

        input[type=range] {
            height: 26px;
            -webkit-appearance: none;
            margin: 10px 0;
            width: 100%;

            &.answered {

                &::-webkit-slider-thumb {
                    background: $range-accent;
                }

                &::-moz-range-thumb {
                    background: $range-accent;
                }

                &::-ms-thumb {
                    background: $range-accent;
                }
            }
        }

        input[type=range]:focus {
            outline: none;
        }

        input[type=range]::-webkit-slider-runnable-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            animate: 0.2s;
            box-shadow: $range-shadow;
            background: $range-color;
            border-radius: 3px;
            border: none;
        }

        input[type=range]::-webkit-slider-thumb {
            box-shadow: $range-shadow;
            border: none;
            height: 20px;
            width: 20px;
            border-radius: $range-radius;
            background: $range-thumb-color;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -5px;
        }

        input[type=range]:focus::-webkit-slider-runnable-track {
            background: $range-color;
        }

        input[type=range]::-moz-range-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            animate: 0.2s;
            box-shadow: $range-shadow;
            background: $range-color;
            border-radius: 3px;
            border: none;
        }

        input[type=range]::-moz-range-thumb {
            box-shadow: $range-shadow;
            border: none;
            height: 20px;
            width: 20px;
            border-radius: $range-radius;
            background: $range-thumb-color;
            cursor: pointer;
        }

        input[type=range]::-ms-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            animate: 0.2s;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }

        input[type=range]::-ms-fill-lower {
            background: $range-color;
            border: none;
            border-radius: 6px;
            box-shadow: $range-shadow;
        }

        input[type=range]::-ms-fill-upper {
            background: $range-color;
            border: none;
            border-radius: 6px;
            box-shadow: $range-shadow;
        }

        input[type=range]::-ms-thumb {
            margin-top: 1px;
            box-shadow: $range-shadow;
            border: none;
            height: 20px;
            width: 20px;
            border-radius: $range-radius;
            background: $range-thumb-color;
            cursor: pointer;
        }

        input[type=range]:focus::-ms-fill-lower {
            background: $range-color;
        }

        input[type=range]:focus::-ms-fill-upper {
            background: $range-color;
        }
    }
</style>