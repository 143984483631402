<template>
    <div class="baseline-bar">
        <div class="baseline-bar-graph">
            <div class="legend" v-t="'reportsWeb.GENERAL_CALCULATED'"></div>
            <div class="bar">
                <div
                    v-for="(report, name, index) in calculated"
                    :key="'act_' + index"
                    class="part"
                    :class="colors[index]"
                    :style="{ width: report + '%' }"
                >
                    <div class="part-text">
                        <div class="propensity">{{ name }}</div>
                        <div class="percentage">{{ report }}%</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="baseline-bar-graph">
            <div class="legend" v-t="'reportsWeb.GENERAL_SELF_REPORTED'"></div>
            <div class="bar">
                <div
                    v-for="(report, name, index) in reported"
                    :key="'act_self_' + index"
                    class="part"
                    :class="colors[index]"
                    :style="{ width: report + '%' }"
                >
                    <div class="part-text">
                        <div class="propensity">{{ name }}</div>
                        <div class="percentage">{{ report }}%</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="./BaselineBar.ts"></script>
<style lang="scss">
.baseline-bar {
    display: table;
    width: 100%;

    .baseline-bar-graph {
        display: table-row;

        .legend {
            display: table-cell;
            width: 15%;
            text-align: right;
            vertical-align: middle;
            font-weight: bold;
            padding: 0 1rem 1rem 0;
        }

        .bar {
            display: table-cell;
            width: 85%;
            vertical-align: middle;
            padding-bottom: 1rem;

            .part {
                display: inline-block;
                position: relative;
                overflow: hidden;
                height: 80px;

                .part-text {
                    position: absolute;
                    top: 16px;
                    width: 100%;
                    text-align: center;
                    .propensity { font-weight: bold; }
                }

                $part-colors: (
                    "brown": #795944,
                    "yellow": #CFCD9A,
                    "army-green": #767B5D,
                    "beige": #9D8D7E,
                    "grey": #79747A,
                    "pink": #BAB0B8,
                    "purple": #6A5B78,
                    "blue": #607E89,
                    "red": #933157,
                    "green": #729A75
                );

                @each $name, $color in $part-colors {
                    &.#{$name} {
                        border-bottom: 4px $color solid;
                        background: lighten($color, 20);
                    }
                }
            }
        }
    }
}
</style>
