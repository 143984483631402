<template>
    <div id="survey-landing" :key="loadedLanguage">
        <survey-loader v-if="loading"> </survey-loader>
        <template v-else>
            <survey-privacy
                v-if="!privacyAccepted"
                :name="knowledgeModelName"
                @accepted="privacyAccepted = true"
                :welcome-text="welcomeText"
                :total-survey-duration="totalSurveyDuration"
            />
            <div v-else>
                <bound-context
                    v-if="needsBoundContext"
                    :name="knowledgeModelName"
                    :survey="activeSurvey"
                    @startContext="onStartRoute"
                ></bound-context>
                <template v-else>
                    <survey-list-comp v-if="!activeSurvey" :list="surveyList" :loading="loading" @start="onStart" />
                    <survey-questions
                        v-else-if="activeSurvey"
                        :questions="activeSurvey.questions"
                        :survey="activeSurvey"
                        :surveys="surveyList.surveys"
                        :disable-previous="disableBackNavigation"
                        :grouped="activeSurvey.groupQuestionsBySection"
                        @finish="onFinish"
                    />
                </template>

                <survey-alert v-if="error" class="mt-3">{{ error }}</survey-alert>
                <survey-loader v-if="loading" cover />
            </div>
        </template>
    </div>
</template>
<script src="./SurveyView.ts"></script>
