import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';
import { defineComponent } from 'vue';

export default defineComponent({
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    setup(props: { question: any }, context) {
        const optionSelected = (option) => {
            const answer = {
                selectedAnswers: props.question.answerOptions
                    .filter(function(value) {
                        return value.checked;
                    })
                    .map(function(value) {
                        return value.id;
                    }),
            };

            context.emit('selected', answer);
        };

        return {
            optionSelected,
        };
    },
});
