export default class Language {
    public languageCode: string = '';
    public knowledgeModelAlias: string = '';
    public knowledgeModelName: string = '';
    public instrumentAlias?: string = '';
    public section?: string;
    public reportAlias?: string;

    constructor(obj?: Partial<Language>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    public get key() {
        return `${this.languageCode}_${this.knowledgeModelAlias}_${this.instrumentAlias}_${this.section ? this.section : 'empty-section'}_${
            this.reportAlias ? this.reportAlias : 'empty_report_alias'
        }`;
    }
}
