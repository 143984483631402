import EnvService from '@/generic/services/EnvService';
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import AppHelper from '@/generic/helpers/AppHelper';
import ContentWrapperVue from '@/generic/components/ContentWrapper.vue';
import { useStore } from 'vuex';
import Language from '@/store/models/Language';
import router from '@/router';

export default defineComponent({
    name: 'finish',
    components: {
        ContentWrapper: ContentWrapperVue,
    },
    setup() {
        const { t } = useI18n();
        const link = ref('');
        const hi5 = ref('');
        const env = EnvService.getEnvironment();
        const application = AppHelper.applicationFromUrl(env.theme);
        const store = useStore();
        const loading = ref(true);
        const thankYouMessages = ref([]);

        const thankYouMessage = computed(() => {
            const language = new Language(store.getters.language);
            const text = thankYouMessages.value.find((x) => x.languageCode === language.languageCode);

            return text ? text.text : null;
        });

        onMounted(async () => {
            link.value = t('generic.RESULT_FOOTER_LINK_URL');
            hi5.value = `/images/design/${application}/hi5.svg`;

            const language = new Language(store.getters.language);
            if (!language && !language.languageCode) {
                router.push({ name: 'not-found' });
            }
            await store.dispatch('setLanguage', language);

            thankYouMessages.value = store.getters.thankYouMessages;

            loading.value = false;
        });

        return {
            hi5,
            link,
            loading,
            thankYouMessage,
        };
    },
});
