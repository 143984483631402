import Answer from '@/survey/models/Answer';
import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';
import { computed, ComputedRef, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    setup(props, context) {
        const sliderValue = ref(0);
        const answerGiven = ref(false);
        const { t } = useI18n();
        const sorted: ComputedRef<Answer[]> = computed(() => props.question.answerOptions);
        const labels = computed(() => {
            if (sorted.value.length < 2) {
                return { left: '', right: '' };
            }

            const [left, right] = sorted.value;
            return { left: left.alias, right: right.alias };
        });

        const onChange = (e) => {
            const value = +e.target.value;
            const right = value;
            sliderValue.value = right;
            saveValue(right);
        };

        const saveValue = (right: number) => {
            if (props.question.answerOptions.length < 2) {
                return;
            }

            const left = 100 - right;
            const answers = [];
            const [first, second] = props.question.answerOptions as Answer[];
            first.optionValue = left;
            second.optionValue = right;
            answers.push({ shareId: first.id, sharePercentage: left });
            answers.push({ shareId: second.id, sharePercentage: right });
            context.emit('selected', { percentages: answers });
            answerGiven.value = true;
        };

        onMounted(() => {
            sliderValue.value = props.question.answerOptions.length > 1 ? +(props.question.answerOptions[1] as Answer).optionValue : 0;
            if (props.question.answerOptions.length > 1) {
                const [first, second] = props.question.answerOptions as Answer[];
                if (first.optionValue === 0 && second.optionValue === 0) {
                    sliderValue.value = 50;
                    saveValue(50);
                }
            }

            answerGiven.value = !!sliderValue.value || !!props.question.answerOptions.filter(x => !!x.optionValue).length;
        });

        return { onChange, sliderValue, labels, t, sorted, answerGiven };
    },
});
