export default class Environment {
    public name: string = '';
    public application: string = '';
    public apiEndpoint: string = '';
    public reportApiEndpoint: string = '';
    public appInsightsConnectionString: string = '';
    public enableDebug: boolean = true;
    public theme?: string;

    constructor(object: Partial<Environment>) {
        Object.assign(this, object);
    }
}
