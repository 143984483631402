import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import AuthHelper, { TokenType } from './AuthHelper';

export class AxiosHelper {
    public axiosResponseInterceptor: number;
    private authAxiosInstance: AxiosInstance = {} as AxiosInstance;
    private publicAxiosInstance: AxiosInstance = {} as AxiosInstance;

    constructor(authConfig?: AxiosRequestConfig, publicConfig?: AxiosRequestConfig) {
        this.authAxiosInstance = axios.create(authConfig);
        this.publicAxiosInstance = axios.create(publicConfig);
        this.authAxiosInstance.interceptors.request.use((config) => {
            const jwt = AuthHelper.token(TokenType.SurveyToken);

            if (!config.headers.Authorization) {
                config.headers.Authorization = `BrightInstruments ${jwt}`;
            }
            return config;
        });
    }

    public getAuthAxiosInstance(): AxiosInstance {
        return this.authAxiosInstance;
    }

    public getPublicAxiosInstance(): AxiosInstance {
        return this.publicAxiosInstance;
    }
}
