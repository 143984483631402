import ContentWrapperVue from '@/generic/components/ContentWrapper.vue';
import { defineComponent } from '@vue/runtime-core';
import BarChartVue from '@/report/components/charts/BarChart.vue';
import BarChartWrapperVue from '@/report/components/charts/BarChartWrapper.vue';
import scoreTableVue from './ScoreTable.vue';
import { PropType, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import BarChartScore from '@/report/models/Charts/BarChartScore';

export default defineComponent({
    components: {
        ContentWrapper: ContentWrapperVue,
        BarChartWrapper: BarChartWrapperVue,
        BarChart: BarChartVue,
        ScoreTable: scoreTableVue,
    },
    props: {
        categoryKey: { type: String },
        context: { type: String },
        primaryScores: { type: Object },
        secondaryScores: { type: Object },
        exposed: { type: Boolean },
        type: { type: String },
        hideTitle: { type: Boolean },
        hideIntro: { type: Boolean },
        hideExplanation: { type: Boolean },
        showQuartiles: { type: Boolean },
        hideIterationPicker: { type: Boolean },
        quartileValues: { type: Array as PropType<number[]>, default: () => [] },
        benchmarkScores: { type: Object as PropType<BarChartScore> },
    },
    setup(props) {
        const { t } = useI18n();

        const teamQuartileTranslation = computed(() => {
            const quartile = props.primaryScores.teamQuartile ? props.primaryScores.teamQuartile.toUpperCase() : null;
            const key = `${props.type}.${props.categoryKey}_2_QUARTILE_${quartile}`;
            if (!quartile) {
                console.warn('Quartile is invalid: ' + key);
                return key;
            }

            return t(key, { context: props.context });
        });

        const teamDeviationTranslation = computed(() => {
            const dev = props.primaryScores.standardDeviation ? props.primaryScores.standardDeviation.toUpperCase() : null;
            const key = `${props.type}.${props.categoryKey}_4_${dev}`;
            if (!dev) {
                console.warn('Deviation is invalid: ' + key);
                return key;
            }

            return t(key, { context: props.context });
        });

        return { teamQuartileTranslation, teamDeviationTranslation };
    }
});
