import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import IterationPickerVue from '@/report/components/partials/IterationPicker.vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { PillarThresholdType } from '@/report/models/PillarThreshold';

export default defineComponent({
    components: {
        IterationPicker: IterationPickerVue,
    },
    props: {
        showQuartiles: { type: Boolean },
        hideIterationPicker: { type: Boolean },
        disableIterationPicker: { type: Boolean },
        removeCompletionDate: { type: Boolean },
        type: { type: String, default: 'reportsWeb' },
        quarters: { type: Array, default: () => [0, 25, 50, 75, 100] },
        categoryKey: { type: String },
        primary: { type: Boolean },
    },
    computed: {
        colorClass() {
            if (this.primary) {
                return 'primary';
            }

            if (this.color) {
                return this.color;
            }
            return 'grey';
        },
    },
    setup(props) {
        const { params } = useRoute();
        const store = useStore();
        const direction = ref('');
        const { t } = useI18n();
        const showEmojis = params.reportAlias == 'ievp_extended';
        const bodyDirection = document.body.getAttribute('direction');
        const completionDate = computed(() => store.getters['report/report'].closeDate);
        const tooltipPosBenchmark = computed(() => t(`generic.${props.categoryKey}_BENCHMARK_TOOLTIP_POS`));
        const tooltipNegBenchmark = computed(() => t(`generic.${props.categoryKey}_BENCHMARK_TOOLTIP_NEG`));
        const quartilesValues = computed(() => store.getters.pillarThreshold(PillarThresholdType.quartile, props.categoryKey));
        const benchmarkScores = computed(() => store.getters.pillarThreshold(PillarThresholdType.benchmark, props.categoryKey));
        const barChartRatio = computed(() => store.getters.pillarThreshold(PillarThresholdType.barChartRatio, props.categoryKey));

        const quartileTooltips = computed(() => [
            t(`generic.QUARTILE_ONE_TOOLTIP`),
            t(`generic.QUARTILE_TWO_TOOLTIP`),
            t(`generic.QUARTILE_THREE_TOOLTIP`),
            t(`generic.QUARTILE_FOUR_TOOLTIP`),
        ]);

        const quartiles = [1, 2, 3, 4];

        const benchmarkPositivePosition = computed(() => {
            const maxPoint = normalizeValue(benchmarkScores.value.teamMedian + benchmarkScores.value.positiveAverage);
            const median = normalizeValue(benchmarkScores.value.teamMedian);
            const width = maxPoint - median;
            return `width: ${width}%; ${direction.value === 'ltr' ? `left: ${median}%` : `right: ${median}%`}`;
        });
        const benchmarkNegativePosition = computed(() => {
            const minPoint = normalizeValue(benchmarkScores.value.teamMedian - benchmarkScores.value.negativeAverage);
            const median = normalizeValue(benchmarkScores.value.teamMedian);
            const width = median - minPoint;
            return `width: ${width}%; ${direction.value === 'ltr' ? `right: ${100 - median}%` : `left: ${100 - median}%`}`;
        });

        const normalizeValue = (value: number) => {
            const min = barChartRatio.value[0] ?? 0;
            const max = barChartRatio.value[1] ?? 100;
            const newMin = 0;
            const newMax = 100;
            return ((value - min) / (max - min)) * (newMax - newMin) + newMin;
        };

        const getQuartile = (value) => {
            if (value < 1 || value > quartilesValues.value.length) {
                return 'display: none;'; // Return an empty string for invalid values
            }
            const summedDistance = quartilesValues.value.slice(0, value - 1).reduce((sum, q) => sum + q, 0);
            const distance = barChartRatio.value.length > 0 ? normalizeValue(summedDistance) : summedDistance;
            const summedWidth = normalizeValue(quartilesValues.value.slice(0, value).reduce((sum, q) => sum + q, 0));

            // Calculates the width otherwise uses default quartileValues.
            // When barChartRatio is set the first and last quartile may be smaller than the actual value.
            // The other cases it just calculates the new width based on the value and ratio.
            const width =
                barChartRatio.value.length > 0
                    ? value == 1
                        ? normalizeValue(quartilesValues.value[value - 1])
                        : value == quartilesValues.value.length && summedWidth - distance > barChartRatio.value[1] - distance
                        ? barChartRatio.value[1] - distance
                        : summedWidth - distance
                    : quartilesValues.value[value - 1];
            if (bodyDirection == 'rtl') {
                return `width: ${width}%; right: ${distance}%;`;
            } else {
                const checkValue = barChartRatio.value[0] ?? 0;
                return `width: ${width}%; left: ${distance > checkValue ? distance : 0}%;`;
            }
        };

        const getQuarters = (quarter, quarters) => {
            return quarters > 1 ? `left: ${(quarter * 100) / (quarters - 1)}%;` : ``;
        };

        onMounted(() => {
            direction.value = document.querySelector('body').getAttribute('direction');
        });

        return {
            completionDate,
            getQuartile,
            getQuarters,
            quartiles,
            quartileTooltips,
            showEmojis,
            benchmarkPositivePosition,
            benchmarkNegativePosition,
            tooltipPosBenchmark,
            tooltipNegBenchmark,
            benchmarkScores,
        };
    },
});
