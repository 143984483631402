import i18n, { getPreferredLanguage } from '@/i18n';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import AppHelper from '../helpers/AppHelper';
import EnvService from '../services/EnvService';
import BaseLayout, { loadEnvironment } from './BaseLayout';
import LanguageSelectorVue from './LanguageSelector.vue';

export default defineComponent({
    extends: BaseLayout,
    components: {
        LanguageSelector: LanguageSelectorVue,
    },
    setup() {
        const logo = ref('');
        const env = EnvService.getEnvironment();
        const application = AppHelper.applicationFromUrl(env.theme);
        const styleLoaded = ref(false);
        logo.value = `/images/design/${application}/logo.svg`;
        i18n.global.locale = getPreferredLanguage(useRoute());

        onMounted(() => {
            loadEnvironment();
            styleLoaded.value = true;
        });

        return {
            logo,
            styleLoaded,
        };
    },
});
