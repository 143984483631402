import { defineComponent, ref } from "vue";

export default defineComponent({
    props: {
        modelValue: { type: Boolean },
        name: { type: String, required: true },
        required: { type: Boolean },
        disabled: { type: Boolean, default: false },
        small: { type: Boolean },
    },
    methods: {
        onInput(event) {
            this.$emit('update:modelValue', event.target.checked);
        },
    },
    setup() {
        const checked = ref(false);
        return { checked };
    }
});
