const givenIds = [];

export const uniqueId = (prefix?: string) => {
    const id = givenIds.length + 1;
    givenIds.push(id);

    if (prefix) {
        return `${prefix}_${id}`;
    }
    return id;
};
