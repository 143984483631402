import EnvService from '@/generic/services/EnvService';

export default class LogHelper {
    public static error(msg: string, args?: any) {
        const env = EnvService.getEnvironment();
        if (env.enableDebug) {
            console.error(msg, { args });
        }
    }

    public static info(msg, args?: any) {
        const env = EnvService.getEnvironment();
        if (env.enableDebug) {
            console.info(`%c${msg}`, 'color:cyan', { args });
        }
    }
}
