import { DistributionData, PercentageDistributionChartOptions } from "@/report/models/Charts/DistributionChartData";
import DemographicRepresentationChartVue from "./DemographicRepresentationChart.vue"
import { PropType, computed, defineComponent } from "vue";

export default defineComponent({
    components: {
        demographicRepresentationChart: DemographicRepresentationChartVue,
    },
    props: {
        chartData: { type: Object as PropType<DistributionData[]> },
        chartOptions: { type: Object as PropType<PercentageDistributionChartOptions> },
        popoverComponent: { type: String, default: 'demographicRepresentationChart' },
    },
    setup(props) {
        const largestValue = computed<number>(() => Math.max(...props.chartData.map((x) => x.value)));

        const getStyleForDistributionOption = (data: DistributionData): string => {
            const height = (data.value / largestValue.value) * 100;
            const width = props.chartOptions.barWidth / props.chartData.length;
            if (height === 0 || largestValue.value === 0) {
                return `height: 10%; width: ${width}%;`;
            }
            return `height: ${height}%; width: ${width}%; background-color: ${data.color ?? 'black'}`;
        };

        return {
            largestValue,
            getStyleForDistributionOption,
        };
    },
});
