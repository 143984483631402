import { ref } from 'vue';
import { useAppInsights } from 'vue3-application-insights';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export const appInsightsHub = ref({
    instance: null as ApplicationInsights,
    init() {
        this.instance = useAppInsights();
    },
    getOperationId() {
        return this.instance.context.telemetryTrace.traceID;
    },
});
