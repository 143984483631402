<template>
    <survey-loader v-if="loading" />
    <div v-else
         id="survey-finish">
        <survey-title v-t="'generic.CLOSED_TITLE'"
                      class="text-center" />

        <div class="text-center">
            <p class="text-width-70">
                <survey-icon class="mb-2"
                             icon="warning"
                             size="lg"
                             color="accent" />
                <br />
                <span v-t="{ path: 'generic.CLOSED_TEXT' }" />
            </p>
        </div>
    </div>
</template>
<script src="./SurveyClosed.ts"></script>
<style>
    .text-width-70 {
        width: 70%;
        margin: 1rem auto;
    }
</style>