<template>
    <div id="result-form" class="text-center">
        <survey-title>Page not found</survey-title>
        <div id="form-wrapper">
            <p class="p-5">We're sorry, the page you are looking for could not be found.</p>

            <h1>
                <survey-icon icon="search" size="lg" />
            </h1>
        </div>
    </div>
</template>
