import { defineComponent } from 'vue';
import Info from './IconInfo.vue';
import Warning from './IconWarning.vue';
import Error from './IconWarning.vue';
import ChevronLeft from './IconChevronLeft.vue';
import ChevronRight from './IconChevronRight.vue';
import ChevronUp from './IconChevronUp.vue';
import ChevronDown from './IconChevronDown.vue';
import Menu from './IconMenu.vue';
import Quote from './IconQuote.vue';
import CloudOff from './IconCloudOff.vue';
import Refresh from './IconRefresh.vue';
import Search from './IconSearch.vue';
import HighFive from './IconHighFive.vue';
import CheckMark from './IconCheckMark.vue';
import Clock from './IconClock.vue';

export default defineComponent({
    props: {
        icon: { type: String },
        size: { type: String, default: 'md' },
        color: { type: String },
    },
    components: {
        Warning,
        Error,
        Info,
        ChevronLeft,
        ChevronRight,
        ChevronUp,
        ChevronDown,
        Menu,
        Quote,
        CloudOff,
        Refresh,
        Search,
        HighFive,
        CheckMark,
        Clock,
    },
});
