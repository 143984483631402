<template>
    <report id="report-controller">
        <cover-page :name="context" :date="$filters.date(report.dateReport.date)" :participantsCompleted="participantsCompletedInvited" />

        <page>
            <content-wrapper>
                <survey-translate :base="type" name="INTRO_START_TEAM" :args="{ context }" raw />
                <survey-title v-t="`${type}.INTRO_TITLE`" />
                <survey-translate tag="p" :base="type" name="INTRO_TEASER" raw />
            </content-wrapper>
            <team-compare-question
                v-if="(!exposed || org) && questionOptions && questionOptions.length"
                :categoryKey="CategoryType.General"
                :context="context"
                :scores="scores(primary, CategoryType.General)"
                :secondary-scores="scores(secondary, CategoryType.General)"
                :type="type"
                :options="questionOptions"
                :compare-to="compareTo"
                :compare-scores="compareGeneralScores(CategoryType.General)"
                @compare="compareQuestions"
                :loading="comparing"
                :demographic-table="report.table"
                :show-quartiles="!org"
            />
            <team-category
                v-else
                :categoryKey="CategoryType.General"
                :context="context"
                :primary-scores="scores(primary, CategoryType.General)"
                :secondary-scores="scores(secondary, CategoryType.General)"
                :exposed="exposed"
                :type="type"
                hide-title
                hide-intro
                :show-quartiles="!org"
            />
        </page>

        <template v-for="key in categoryKeys" :key="`cat_${key}`">
            <page page-break>
                <team-compare-question
                    v-if="(!exposed || org) && questionOptions && questionOptions.length"
                    :categoryKey="CategoryType[key]"
                    :context="context"
                    :scores="scores(primary, CategoryType[key])"
                    :secondary-scores="scores(secondary, CategoryType[key])"
                    :type="type"
                    :options="questionOptions"
                    :compare-to="compareTo"
                    :compare-scores="compareGeneralScores(CategoryType[key])"
                    @compare="compareQuestions"
                    :title="{ path: `${type}.${CategoryType[key]}_ROLE_TITLE`, args: { context } }"
                    :content="{ path: `${type}.${CategoryType[key]}_INTRODUCTION`, args: { context } }"
                    :loading="comparing"
                    :demographic-table="report.table"
                    explain
                    :show-quartiles="!org"
                />
                <team-category
                    v-else
                    :categoryKey="CategoryType[key]"
                    :context="context"
                    :primary-scores="scores(primary, CategoryType[key])"
                    :secondary-scores="scores(secondary, CategoryType[key])"
                    :exposed="exposed"
                    :type="type"
                    :show-quartiles="!org"
                />
            </page>
        </template>

        <template v-if="org">
            <template v-if="report.questionScores && report.questionScores.data && report.questionScores.data.length > 0">
                <template v-for="result in report.questionScores.data" :key="`question_${result.groupName}`">
                    <team-compare-question
                        :categoryKey="result.groupName"
                        :context="context"
                        :scores="mapResult(result)"
                        :type="'surveyQuestions'"
                        :options="questionOptions"
                        :compare-to="compareTo"
                        :compare-scores="compareScores(result.groupName)"
                        @compare="compareQuestions"
                        :title="{ path: `surveyQuestions.${result.groupName}`, args: { context } }"
                        :content="{ path: `surveyQuestions.${result.groupName}_DESCRIPTION`, args: { context } }"
                        :loading="comparing"
                        :demographic-table="report.table"
                    />
                </template>
            </template>

            <template v-if="report.table && report.table.entries && report.table.entries.length > 0">
                <team-table :categoryKey="'METADATA'" :context="context" :scores="report.table.entries" :type="'reportsWeb'" />
            </template>

            <team-heatmap :categoryKey="'HEATMAP'" :context="context" :type="'reportsWeb'" :heatmap="report.heatmap" />

            <team-compare :context="context" :type="'reportsWeb'" :options="questionOptions" />
        </template>
    </report>
</template>
<script src="./PsiTeam.ts"></script>
