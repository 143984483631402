<template>
    <div class="print-toolbar-wrapper" :class="{ 'd-none': reportAlias === 'psi_organization' }">
        <survey-loader v-if="loading" full-size>
            <div class="message" v-t="'reportsWeb.GENERAL_REPORT_GENERATED'"></div>
        </survey-loader>
        <div class="print-toolbar">
            <div class="print-toolbar-container">
                <survey-button href="javascript:;" v-t="'generic.GENERAL_GENERATE_PDF'" @click="print"></survey-button>
            </div>
        </div>
    </div>
</template>
<script src="./PrintToolbar.ts"></script>
<style lang="scss">
@import '@/variables';
@import '@/scss/mixins';

.print-toolbar-wrapper {
    .overlay-wrapper {
        display: block;
        z-index: 10;
    }

    .print-toolbar {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        background: #fff;
        padding: 0.5rem;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

        .print-toolbar-container {
            max-width: $main-max-width;
            margin: 0 auto;
        }
    }
}

@include print {
    .print-toolbar-wrapper {
        display: none !important;
    }
}

.page-footer {
    border: 0;
    padding: 0 1.5rem;
    font-size: 12px;

    table {
        border: none;
        width: 100%;
    }

    .copyright {
        color: #888;
        font-style: italic;
    }

    .page-number-wrapper {
        color: #000000;
        padding: 0.25rem;
        text-align: center;
    }

    &[data-page='1'] {
        display: none !important;
    }

    .this-page {
        white-space: nowrap;
    }
}

.hidden {
    display: none !important;
}
</style>
