<template>
    <div class="survey-percentage-question">
        <div v-for="option in question.answerOptions" :key="option.id" class="survey-percentage">
            <div class="survey-percentage-input">
                <survey-input
                    type="number"
                    :id="`answer${option.id}`"
                    :name="`answer${option.id}`"
                    v-model="option.optionValue"
                    :min="0"
                    :max="100"
                    @change="answerChanged($event, option)"
                    required
                />
                <span>%</span>
            </div>
            <label class="percentage-label" :for="`answer${option.id}`">
                <span v-t="'surveyAnswers.' + option.alias"></span>
            </label>
        </div>

        <survey-alert v-if="total !== 100" variant="info" class="mt-3">
            Please make sure the total percentage is <b>100%</b>. Currently the total amount is: <b>{{ total }}%</b>
        </survey-alert>
    </div>
</template>
<script src="./percentage.ts"></script>
<style lang="scss">
@import '@/variables';

.survey-percentage {
    display: grid;
    grid-template-columns: 7rem auto;
    margin-bottom: 1rem;
    gap: 1rem;
    align-items: center;

    &-input {
        display: flex;
        border: 1px $accent-color solid;
        border-radius: $border-radius;

        input {
            border: 0;
        }

        span {
            display: flex;
            align-items: center;
            padding: 0 0.5rem;
            align-self: stretch;
            background: $light-color;
            border-left: 1px $accent-color solid;
            border-radius: 0 $border-radius $border-radius 0;
        }
    }

}
</style>
