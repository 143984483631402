export default class RadarChartScore {
    public label: string;
    public score: number = 0;

    constructor(obj?: Partial<RadarChartScore>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
