<template>
    <div class="survey-checkbox" :class="{ 'small': small }">
        <input
            type="checkbox"
            class="survey-checkbox-input"
            :id="name"
            :name="name"
            :required="required"
            :checked="modelValue"
            :disabled="disabled"
            @input="onInput"
        />
        <label class="survey-checkbox-label" :for="name">
            <div class="survey-checkbox-control" :class="{ 'checked': modelValue }"></div>
            <slot />
        </label>
    </div>
</template>
<script src="./Checkbox.ts"></script>
<style lang="scss">
@import '@/variables';

.survey-checkbox {
    position: relative;
    margin-bottom: 1rem;

    &.small {
        font-size: 0.875rem;

        .survey-checkbox-control {
            height: 1rem;
            width: 1rem;
            border-radius: $border-radius-sm;
            margin-right: 0.5rem;

            &:before {
                top: 0.125rem;
                left: 0.125rem;
                height: 0.75rem;
                width: 0.75rem;
            }
        }
    }

    &:not(.small) {
        .survey-checkbox-control {
            width: 1.5rem;
            height: 1.5rem;
            min-width: 1.5rem;
            min-height: 1.5rem;
            border-radius: $border-radius;
            margin-right: 1rem;

            &:before {
                top: 0.25rem;
                left: 0.25rem;
                width: 1rem;
                height: 1rem;
            }
        }
    }

    &-input {
        position: absolute;
        left: 0;
        opacity: 0;
    }

    &-label {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }

    &-control {
        position: relative;
        display: inline-block;
        border: 1px transparent solid;
        box-sizing: content-box;

        &::before {
            display: block;
            position: absolute;
            content: "";
            border-radius: $border-radius-sm;
            transition: background .15s ease-in-out;
        }
    }
}
</style>
