import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    props: {
        name: { type: String },
        args: { type: Object },
        base: { type: String },
        override: { type: String },
        tag: { type: String, default: 'div' },
        raw: { type: Boolean, default: false },
    },
    setup(props) {
        const { t, messages, locale } = useI18n();
        const tBase = computed(() => {
            return props.base;
        });
        const translated = computed(() => {
            if (props.override) {
                const langMessages = messages.value[locale.value];
                if (langMessages[props.override] && langMessages[props.override][props.name]) {
                    return t(`${props.override}.${props.name}`, props.args);
                }
            }

            return t(`${tBase.value}.${props.name}`, props.args);
        });

        return {
            translated,
        };
    },
});
