export enum IevpReportSectionCategory {
    Personal = 'PERSONAL',
    Organization = 'ORGANIZATION',
}

export enum IevpTeamReportSectionCategory {
    PurposeMeaning = 'PURPOSE_MEANING',
    GrowthDevelopment = 'GROWTH_DEVELOPMENT',
    ConnectionCommunity = 'CONNECTION_COMMUNITY',
    MaterialOfferings = 'MATERIAL_OFFERINGS',
}

export enum IevpDeviationSectionCategory {
    // WorkExperience = 'WORK_EXPERIENCE',
    Turnover = 'TURNOVER',
    Burnout = 'BURNOUT',
    OrganizationIdentification = 'ORGANIZATION_IDENTIFICATION',
    Preference = 'PREFERENCE',
    Engagement = 'ENGAGEMENT',
    Satisfaction = 'SATISFACTION',
    Psychological = 'PSYCHOLOGICAL',
}

export interface IevpReportDataSection {
    category: IevpReportSectionCategory;
    mean: number;
    standardDeviation: number;
    materialOfferings: number;
    growthDevelopment: number;
    connectionCommunication: number;
    meaningPurpose: number;
    standMaterialOfferings: number;
    standGrowthDevelopment: number;
    standConnectionCommunication: number;
    standMeaningPurpose: number;
}


export interface IevpReportData {
    sections: IevpReportDataSection[];
    surveyId: number;
    instrumentAlias: string;
    participantName: string;
    dateCompleted: string;
    context: string;
}

export default interface IevpReportDataResponse extends IevpReportData {
    reportData: IevpReportData;
}
export interface IevpTeamReportData {
    organizationSections: IevpTeamReportDataSection[];
    personalSections: IevpTeamReportDataSection[];
    surveyId: number;
    instrumentAlias: string;
    participantName: string;
    dateCompleted: string;
    context: string;
    projectName: string;
    table?: any;
    invitedParticipants: number;
    completedParticipants: number;
    primaryColor: string;
    secondaryColor: string;
    participants: IevpParticipant[];
    answers: IevpAnswer[];
    distributionSections: IevpDistributionDataSection[];
    deviationSections: IevpTeamReportDataSection[];
}

export interface IevpDistributionDataSection {
    categoryName: string;
    answerDistributions: IevpAnswerDistribution[];
}

export interface IevpAnswerDistribution {
    questionNumber: number;
    questionType: string;
    distributions: any;
}

export interface IevpParticipant {
    participantId: number;
    organizationSection: IevpReportDataSection;
    personalSection: IevpReportDataSection;
    demographicData: DemographicData[];
}

export interface IevpTeamReportDataSection {
    category: IevpTeamReportSectionCategory;
    average: number;
    median: number;
    negativeAverageDeviation: number;
    positiveAverageDeviation: number;
    standardDeviationAverage: number;
}

export interface IevpTeamReportDataResponse extends IevpTeamReportData {
    reportData: IevpTeamReportData;
}

export interface DemographicData {
    demographicValue: string;
    label: string;
    value: string;
}

export interface IevpAnswer {
    questionNumber: number;
    questionType: string;
    participantId: number;
    surveyId: number;
    optionValue: string;
}
