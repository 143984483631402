export enum Constructs {
    conflicthanteringControle = 'conflicthanteringControle',
    conflicthanteringControleColor = '#AD494A',
    conflicthanteringAssertiviteit = 'conflicthanteringAssertiviteit',
    conflicthanteringAssertiviteitColor = '#843C39',
    samenwerkingSfeer = 'samenwerkingSfeer',
    samenwerkingSfeerColor = '#E7CB94',
    samenwerkingOnderdeel = 'samenwerkingOnderdeel',
    samenwerkingOnderdeelColor = '#E7BA52',
    flexibiliteitVerandering = 'flexibiliteitVerandering',
    flexibiliteitVeranderingColor = '#BD9F39',
    flexibiliteitAfmaken = 'flexibiliteitAfmaken',
    flexibiliteitAfmakenColor = '#8C6D31',
    nieuwImpulsiviteit = 'nieuwImpulsiviteit',
    nieuwImpulsiviteitColor = '#CEDB9C',
    nieuwKwetsbaarheid = 'nieuwKwetsbaarheid',
    nieuwKwetsbaarheidColor = '#B4CF6B',
    prestatiegerichtheidHardwerken = 'prestatiegerichtheidHardwerken',
    prestatiegerichtheidHardwerkenColor = '#8CA252',
    prestatiegerichtheidStreven = 'prestatiegerichtheidStreven',
    prestatiegerichtheidStrevenColor = '#637939',
    zorgvuldigheidStructuur = 'zorgvuldigheidStructuur',
    zorgvuldigheidStructuurColor = '#9C9EDE',
    zorgvuldigheidDetails = 'zorgvuldigheidDetails',
    zorgvuldigheidDetailsColor = '#6B6ECF',
    leiderschapGeloof = 'leiderschapGeloof',
    leiderschapGeloofColor = '#5255A3',
    leiderschapVerlangen = 'leiderschapVerlangen',
    leiderschapVerlangenColor = '#393B79',

    conflicthanteringColor = '#843C39',
    flexibiliteitColor = '#8C6D31',
    samenwerkingColor = '#E7BA52',
    nieuwColor = '#B4CF6B',
    prestatiegerichtheidColor = '#637939',
    zorgvuldigheidColor = '#6B6ECF',
    leiderschapColor = '#393B79',
}

export enum ConstructTranslations {
    conflicthanteringControle = 'CONFLICTHANTERING_SUBCONSTRUCT_B',
    conflicthanteringAssertiviteit = 'CONFLICTHANTERING_SUBCONSTRUCT_A',
    samenwerkingSfeer = 'SAMENWERKING_SUBCONSTRUCT_B',
    samenwerkingOnderdeel = 'SAMENWERKING_SUBCONSTRUCT_A',
    flexibiliteitVerandering = 'FLEXIBILITEIT_SUBCONSTRUCT_A',
    flexibiliteitAfmaken = 'FLEXIBILITEIT_SUBCONSTRUCT_B',
    nieuwImpulsiviteit = 'NIEUW_SUBCONSTRUCT_B',
    nieuwKwetsbaarheid = 'NIEUW_SUBCONSTRUCT_A',
    prestatiegerichtheidHardwerken = 'PRESTATIEGERICHTHEID_SUBCONSTRUCT_B',
    prestatiegerichtheidStreven = 'PRESTATIEGERICHTHEID_SUBCONSTRUCT_A',
    zorgvuldigheidStructuur = 'ZORGVULDIGHEID_SUBCONSTRUCT_A',
    zorgvuldigheidDetails = 'ZORGVULDIGHEID_SUBCONSTRUCT_B',
    leiderschapGeloof = 'LEIDERSCHAP_SUBCONSTRUCT_B',
    leiderschapVerlangen = 'LEIDERSCHAP_SUBCONSTRUCT_A',
}