import LogHelper from '@/generic/helpers/LogHelper';
import Answer from '@/survey/models/Answer';
import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';
import { compile, computed, defineComponent } from 'vue';

export default defineComponent({
    name: 'percentage',
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    setup(props, context) {
        const answerChanged = (value, answer: Answer) => {
            const answers = [];
            (props.question.answerOptions as any[]).forEach((a: Answer) => {
                answers.push({
                    shareId: a.id,
                    sharePercentage: a.id === answer.id ? +value : +a.optionValue,
                });
            });
            LogHelper.info('Input value changed', { answer, answers });
            context.emit('selected', { percentages: answers });
        };

        const total = computed(() => {
            let total = 0;
            (props.question.answerOptions as any[]).forEach((a: Answer) => {
                if (typeof a.optionValue !== 'undefined') {
                    total += +a.optionValue;
                }
            });

            return isNaN(total) ? 0 : total;
        });

        return {
            answerChanged,
            total,
        };
    },
});
