import BaseReportComponent from '@/report/components/BaseReportComponent';
import ReportWrapperVue from '@/report/components/ReportWrapper.vue';
import { computed, ComputedRef, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import IevpPersonalScoreVue from '../components/IevpPersonalScore.vue';
import IevpScoreVue from '../components/IevpScore.vue';
import { IevpPillar } from '../models/IevpPillar';
import IevpPillarScore from '../models/IevpPillarScore';
import IevpReportDataResponse, { IevpReportSectionCategory } from '../models/IevpReportData';
import IevpScore from '../models/IevpScore';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        ReportWrapper: ReportWrapperVue,
        score: IevpScoreVue,
        PersonalScore: IevpPersonalScoreVue,
    },
    setup() {
        const loaded = ref(false);
        const store = useStore();
        const reportData: ComputedRef<IevpReportDataResponse> = computed(() => store.getters['report/report']);
        const chartScores = computed(() => new IevpScore(reportData.value.sections));
        const pillarScores = (pillar: IevpPillar) => {
            const categories = [IevpReportSectionCategory.Personal, IevpReportSectionCategory.Organization];
            const scores: IevpPillarScore[] = [];
            categories.forEach((category: IevpReportSectionCategory) => {
                scores.push({
                    category,
                    score: chartScores.value.pillarScores(category)[pillar],
                });
            });
            return scores;
        };

        const participantName = computed(() => reportData.value.participantName);

        const pillars = [
            {
                key: 'mp',
                translateKey: 'MP',
                scores: pillarScores(IevpPillar.MeaningPurpose),
            },
            {
                key: 'cc',
                translateKey: 'CC',
                scores: pillarScores(IevpPillar.ConnectionCommunity),
            },
            {
                key: 'gd',
                translateKey: 'GD',
                scores: pillarScores(IevpPillar.GrowthDevelopment),
            },
            {
                key: 'mo',
                translateKey: 'MO',
                scores: pillarScores(IevpPillar.MaterialOfferings),
            },
        ];

        onMounted(() => {
            loaded.value = true;
        });

        return { pillars, chartScores, participantName, loaded };
    },
});
