<template>
    <div class="survey-progress">
        <div
            class="survey-progress-bar"
            role="progressbar"
            :style="`width: ${progress}%`"
            :aria-valuenow="progress"
            aria-valuemin="0"
            aria-valuemax="100"
        />
    </div>
</template>
<script src="./Progress.ts"></script>
<style lang="scss">
@import '@/variables';

.survey-progress {
    position: relative;
    height: .5rem;
    border-radius: $border-radius;
    background: $gray;

    &-bar {
        position: absolute;
        left: 0;
        width: 0;
        height: .5rem;
        border-radius: $border-radius;
        transition: width .5s ease-in-out;
    }
}
</style>
