import { CategoryType } from '@/report/models/CategoryType';
import IDateReport from '@/report/models/IDateReport';
import ISection from '@/report/models/ISection';
import { computed, ComputedRef, defineComponent, onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import TeamCategory from '../partials/TeamCategory.vue';
import TeamCompareQuestion from '@/report/components/partials/TeamCompareQuestions.vue';
import TeamTable from '../partials/TeamTable.vue';
import TeamCompare from '../partials/TeamCompare.vue';
import QuestionOption from '@/report/models/QuestionOption';
import { ReportService } from '@/generic/services/reportService';
import QuestionAnswerScore from '@/report/models/Demographic/IQuestionAnswerScore';
import TeamHeatmap from '../partials/TeamHeatmap.vue';
import BaseReportComponent from '../../components/BaseReportComponent';
import CoverPageVue from '@/report/fearless/components/CoverPage.vue';
import ITeamReportResponse from '@/report/models/ITeamReportResponse';
import { useI18n } from 'vue-i18n';
import ProjectExportData from '@/report/models/IProjectExportData';
import BarChartScore from '@/report/models/Charts/BarChartScore';
import he from 'he';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        TeamCategory,
        TeamCompareQuestion,
        TeamCompare,
        TeamTable,
        TeamHeatmap,
        CoverPage: CoverPageVue,
    },
    props: {
        type: String,
        org: Boolean,
        exposed: Boolean,
    },
    setup(props) {
        const comparing = ref(false);
        const store = useStore();
        const context = computed(() => he.decode(store.getters['report/report'].context ?? ''));
        const primary: ComputedRef<IDateReport> = computed(() => store.getters['report/primary']);
        const report: ComputedRef<ITeamReportResponse> = computed(() => store.getters['report/report']);
        const secondary: ComputedRef<IDateReport> = computed(() => store.getters['report/secondary']);
        const { params, query } = useRoute();
        const projectId = +params.projectId;
        const token = query.token.toString();
        const { t } = useI18n();
        const general: ComputedRef<ISection> = computed(() => {
            if (primary.value && primary.value.sections) {
                return primary.value.sections.find((sec: ISection) => {
                    return sec.category === CategoryType.General;
                });
            }
            return {} as ISection;
        });
        const categoryKeys = Object.keys(CategoryType).filter((key) => {
            return CategoryType[key] !== CategoryType.General;
        });
        const compareTo = ref('');
        const compareScoresData: Ref<QuestionAnswerScore> = ref({ question: '', scores: [] });
        const projectData: Ref<ProjectExportData> = ref();
        const participantsCompletedInvited = computed(() =>
            t(`${props.type}.PARTICIPANTS_COMPLETED_VS_INVITED`, {
                invitedParticipants: report.value.invitedParticipants,
                completedParticipants: report.value.completedParticipants,
            }),
        );
        const scores = (data: IDateReport, category: string) => {
            if (data && data.sections) {
                const section = data.sections.find((sec: ISection) => sec.category == category);
                return new BarChartScore({
                    teamMedian: section.teamMedian,
                    positiveAverage: section.positiveAverage,
                    negativeAverage: section.negativeAverage,
                    tooltipScore: section.teamMedian,
                    participants: section.participants,
                    teamQuartile: section.teamQuartile,
                    standardDeviation: section.standardDeviation,
                });
            }
            return {};
        };

        const compareQuestions = async (question: string) => {
            comparing.value = true;
            if (!question) {
                compareTo.value = '';
            } else {
                const service = new ReportService();
                const response = await service.getQuestionAnswerScores(projectId, question, token);
                compareScoresData.value = response;
                compareTo.value = question;
            }
            comparing.value = false;
        };

        const compareGeneralScores = (category: string) => {
            const scores = [];
            report.value.table.entries.forEach((entry) => {
                const splittedLabel = entry.title.split(':');
                if (splittedLabel.length && splittedLabel[0] === compareTo.value && entry.sections) {
                    entry.sections.forEach((section) => {
                        let label = '';
                        if (entry.title) {
                            label = splittedLabel.length > 1 ? splittedLabel[1] : 'None';
                        }
                        if (section.category == category) {
                            scores.push({
                                category: section.category,
                                pillar: section.category,
                                compareAnswer: label,
                                positiveAverage: section.positiveAverage,
                                negativeAverage: section.negativeAverage,
                                teamMedian: section.median,
                                groupSize: entry.groupSize,
                            });
                        }
                    });
                }
            });

            return scores;
        };

        const compareScores = (question: string) => {
            const answer = compareScoresData.value.scores.find((compare) => {
                return compare.answer === question;
            });
            return answer ? answer.sections : [];
        };

        const questionOptions: ComputedRef<QuestionOption[]> = computed(() => {
            if (!report.value.table || !report.value.table.entries) {
                return [];
            }

            const allEntries = report.value.table.entries.map((entry: any) => {
                return {
                    label: entry.title.split(':')[0],
                    question: entry.title.split(':')[0],
                };
            });

            const uniqueEntries = [];
            allEntries.forEach((entry) => {
                if (!uniqueEntries.find((x) => x.label === entry.label)) {
                    uniqueEntries.push(entry);
                }
            });

            return uniqueEntries;
        });

        const getProjectData = async () => {
            const service = new ReportService();
            const response = await service.getProjectData(projectId, token);
            projectData.value = response;
        };

        const mapResult = (result) => {
            return {
                teamMedian: result.median,
                negativeAverage: result.negativeAverage,
                positiveAverage: result.positiveAverage,
            };
        };

        onMounted(async () => {
            await getProjectData();
        });

        return {
            report,
            context,
            primary,
            secondary,
            general,
            CategoryType,
            categoryKeys,
            questionOptions,
            comparing,
            compareTo,
            projectData,
            participantsCompletedInvited,
            compareGeneralScores,
            compareScores,
            compareQuestions,
            mapResult,
            scores,
        };
    },
});
