import BaseReportComponent from '@/report/components/BaseReportComponent';
import ReportWrapperVue from '@/report/components/ReportWrapper.vue';
import { computed, ComputedRef, defineComponent, onMounted, Ref, ref } from 'vue';
import { useStore } from 'vuex';
import IevpPersonalScoreVue from '../components/IevpPersonalScore.vue';
import { IevpTeamPillarScore } from '../models/IevpPillarScore';
import {
    IevpDeviationSectionCategory,
    IevpParticipant,
    IevpReportSectionCategory,
    IevpTeamReportDataResponse,
    IevpTeamReportSectionCategory,
} from '../models/IevpReportData';
import IevpTeamScore from '../models/IevpTeamScore';
import QuestionOption from '@/report/models/QuestionOption';
import TeamCompareQuestion from '@/report/components/partials/TeamCompareQuestions.vue';
import { useI18n } from 'vue-i18n';
import BarChartScore from '@/report/models/Charts/BarChartScore';
import RadarChartData from '@/report/models/Charts/RadarChartData';
import TeamRadarVue from '@/report/components/charts/TeamRadar.vue';
import RadarChartScore from '@/report/models/Charts/RadarChartScore';
import { useRoute } from 'vue-router';
import IevpExtendedChartsVue from '@/report/ievp/components/IevpExtendedCharts.vue';
import i18n, { loadTranslationsAsync } from '@/i18n';
import DistributionChartVue from '@/report/components/charts/DistributionChart.vue';
import DistributionChartData, { DistributionData, PercentageDistributionChartOptions } from '@/report/models/Charts/DistributionChartData';
import { QuestionType } from '@/survey/models/QuestionType';
import DemographicRepresentationChartData, { DemographicRepresentation } from '@/report/models/Charts/DemographicRepresentationChartData';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        ReportWrapper: ReportWrapperVue,
        PersonalScore: IevpPersonalScoreVue,
        TeamCompareQuestion,
        teamRadar: TeamRadarVue,
        IevpExtendedCharts: IevpExtendedChartsVue,
        DistributionChart: DistributionChartVue,
    },
    props: {
        type: String,
    },
    setup() {
        const store = useStore();
        const { t } = useI18n();
        const { params, query } = useRoute();
        const showAnswerDistribution = query.distribution;
        const showAlternativeQuestions = query.alternative;
        const reportAlias = params.reportAlias;
        const compareTo = ref('default');
        const comparing = ref(false);
        const radarChartKey = ref(0);
        const reportData: ComputedRef<IevpTeamReportDataResponse> = computed(() => store.getters['report/report']);
        const context = computed(() => store.getters['report/report'].context);
        const organizationChartScores = computed(
            () => new IevpTeamScore(reportData.value.organizationSections, IevpReportSectionCategory.Organization),
        );
        const quarters = [0, 1.25, 2.5, 3.75, 5];
        const percentageDistributionQuarters = [-5, '', '', '', '', 5];

        const projectName = reportData.value.projectName;
        const invitedParticipants = computed(() => reportData.value.invitedParticipants);
        const completedParticipants = computed(() => reportData.value.completedParticipants);
        const youLabel = computed(() => t(`reportsWeb.LABEL_YOUR_VALUE`));
        const organizationLabel = computed(() => t(`reportsWeb.LABEL_ORGANIZATION_VALUE`));
        const translatedLabels = computed(() => [
            t(`reportsWeb.LABEL_MATERIAL`),
            t(`reportsWeb.LABEL_GROWTH`),
            t(`reportsWeb.LABEL_CONNECTION`),
            t(`reportsWeb.LABEL_PURPOSE`),
        ]);
        const colors = ['#FFB967', '#0F84D3'];
        const loaded = ref(false);
        const state = ref<Record<string, boolean>>({});

        const barChartColors = [
            'light-green',
            'light-green',
            'green',
            'green',
            'blue',
            'blue',
            'dark-blue',
            'dark-blue',
            'light-blue',
            'light-blue',
            'green-blue',
            'green-blue',
            'orange',
            'orange',
        ];

        const distributionColors = ['red', 'orange', 'yellow', 'lime', 'green'];
        const reversedDistributionColors = ['green', 'lime', 'yellow', 'orange', 'red'];
        const chartOrder: string[] = [
            IevpTeamReportSectionCategory.MaterialOfferings,
            IevpTeamReportSectionCategory.GrowthDevelopment,
            IevpTeamReportSectionCategory.ConnectionCommunity,
            IevpTeamReportSectionCategory.PurposeMeaning,
        ];

        const deviationSections: string[] = Object.values(IevpDeviationSectionCategory);
        const reversedDistributionQuestions = [28, 29, 44, 53];
        const reversedDistributionColorings = [26, 27, 28];
        const chartLabelStyle = {
            usePointStyle: true,
        };

        const calculateDeviationScore = (category: string) => {
            const section = reportData.value.deviationSections.find((x) => x.category == category);
            return new BarChartScore({
                teamMedian: section.median,
                positiveAverage: section.positiveAverageDeviation,
                negativeAverage: section.negativeAverageDeviation,
                tooltipScore: normalizeBarChartScore(section.median),
            });
        };

        const translationScaleMapper = (value, isReversed: boolean) => {
            const map = { 1: '5', 2: '4', 3: '3', 4: '2', 5: '1' };
            return !isReversed ? map[+value] : value;
        };

        const getDistributionData = (category: string) => {
            const section = reportData.value.distributionSections.find((x) => x.categoryName == category);
            return section.answerDistributions.map((x) => {
                return new DistributionChartData({
                    questionNumber: x.questionNumber,
                    description: t(`surveyQuestions.QUESTION_${x.questionNumber}`),
                    distributions: Object.entries(x.distributions).map((y: [string, number]) => {
                        const translateValue = translationScaleMapper(y[0], reversedDistributionQuestions.includes(x.questionNumber));
                        let answerLabel = '';
                        switch (x.questionType) {
                            case QuestionType.Score:
                                answerLabel = t(`surveyAnswers.ANSWER_${translateValue}`);
                                break;
                            case QuestionType.Choice:
                                answerLabel = t(`surveyAnswers.ANSWER_QUESTION_${x.questionNumber}_OPTION_${translateValue}`);
                                break;
                        }
                        if (answerLabel.includes('Picture')) {
                            answerLabel = `<img src="/images/survey/me_org_${translateValue}.png" style="width:100%;height:70px;">`;
                        }

                        return new DistributionData({
                            label: answerLabel,
                            value: y[1],
                            color: reversedDistributionColorings.includes(x.questionNumber)
                                ? reversedDistributionColors.at(+y[0] - 1)
                                : distributionColors.at(+y[0] - 1),
                        });
                    }),
                });
            });
        };

        const normalizeBarChartScore = (value: number) => {
            const min = 0;
            const max = 100;
            const newMin = 1;
            const newMax = 5;
            return ((value - min) / (max - min)) * (newMax - newMin) + newMin;
        };

        const personalChartScores = computed(() => new IevpTeamScore(reportData.value.personalSections, IevpReportSectionCategory.Personal));
        const pillarScores = (pillar: IevpTeamReportSectionCategory) => {
            const categories = [IevpReportSectionCategory.Personal, IevpReportSectionCategory.Organization];
            const scores: IevpTeamPillarScore[] = [];
            categories.forEach((category: IevpReportSectionCategory) => {
                switch (category) {
                    case IevpReportSectionCategory.Personal:
                        scores.push({ category, scores: personalChartScores.value.pillarScores(pillar) });
                        break;
                    case IevpReportSectionCategory.Organization:
                        scores.push({ category, scores: organizationChartScores.value.pillarScores(pillar) });
                        break;
                }
            });

            return scores.map((pillar: IevpTeamPillarScore) => {
                const personal = pillar.category === IevpReportSectionCategory.Personal;
                const label = personal ? youLabel : organizationLabel;
                const score = pillar.scores;
                return {
                    personal,
                    label,
                    labelTooltip: `${label}: ${score.average}`,
                    color: personal ? 'orange' : 'blue',
                    scores: new BarChartScore({
                        teamMedian: (score.median / quarters.length) * 100,
                        positiveAverage: (score.positiveAverageDeviation / quarters.length) * 100,
                        negativeAverage: (score.negativeAverageDeviation / quarters.length) * 100,
                    }),
                };
            });
        };

        const chartScores = computed(() => {
            const chartData: RadarChartData[] = [];
            const personalScores: RadarChartScore[] = [];
            if (showAlternativeQuestions) {
                for (let questionNumber = 30; questionNumber <= 33; questionNumber++) {
                    const answers = reportData.value.answers.filter((x) => x.questionNumber == questionNumber);
                    const score = answers.reduce((total, answer) => (total += +answer.optionValue), 0) / answers.length;
                    switch (questionNumber) {
                        case 30:
                            personalScores.push(new RadarChartScore({ label: IevpTeamReportSectionCategory.MaterialOfferings, score: score }));
                            break;
                        case 31:
                            personalScores.push(new RadarChartScore({ label: IevpTeamReportSectionCategory.GrowthDevelopment, score: score }));
                            break;
                        case 32:
                            personalScores.push(new RadarChartScore({ label: IevpTeamReportSectionCategory.ConnectionCommunity, score: score }));
                            break;
                        case 33:
                            personalScores.push(new RadarChartScore({ label: IevpTeamReportSectionCategory.PurposeMeaning, score: score }));
                            break;
                        default:
                            break;
                    }
                }
            } else {
                personalScores.push(
                    ...personalChartScores.value.sections
                        .map((section) => {
                            return new RadarChartScore({ label: section.category, score: section.median });
                        })
                        .sort((a, b) => (chartOrder.indexOf(a.label) > chartOrder.indexOf(b.label) ? 1 : -1)),
                );
            }

            const organizationScores: RadarChartScore[] = organizationChartScores.value.sections
                .map((section) => {
                    return new RadarChartScore({ label: section.category, score: section.median });
                })
                .sort((a, b) => (chartOrder.indexOf(a.label) > chartOrder.indexOf(b.label) ? 1 : -1));
            chartData.push(new RadarChartData({ label: youLabel.value, scores: personalScores, color: '#FFB967' }));
            chartData.push(new RadarChartData({ label: organizationLabel.value, scores: organizationScores, color: '#0F84D3' }));
            return chartData;
        });

        const compareGeneralScores = (category: string) => {
            const scores = [];

            reportData.value.table.entries.forEach((entry) => {
                const splittedLabel = entry.title.split(':');
                if (splittedLabel.length && splittedLabel[0] === compareTo.value && entry.sections) {
                    entry.sections.forEach((section) => {
                        if (section.sections) {
                            const data = section.sections.find((pillar) => pillar.category == category);
                            let label = '';
                            if (entry.title) {
                                label = splittedLabel.length > 1 ? splittedLabel[1] : 'None';
                            }
                            if (data) {
                                scores.push({
                                    category: data.category,
                                    pillar: data.category,
                                    compareAnswer: label,
                                    positiveAverage: data.positiveAverage,
                                    negativeAverage: data.negativeAverage,
                                    teamMedian: data.median,
                                    groupSize: entry.groupSize,
                                });
                            }
                        }
                    });
                }
            });
            return scores;
        };

        const compareScoresRadarChart = () => {
            const radarChartScores: RadarChartData[] = [];
            let colorIndex = 0;
            reportData.value.table.entries.forEach((entry) => {
                const splittedLabel = entry.title.split(':');
                if (splittedLabel.length && splittedLabel[0] === compareTo.value && entry.sections) {
                    entry.sections.forEach((section) => {
                        if (section.category === IevpReportSectionCategory.Personal || section.category === IevpReportSectionCategory.Organization) {
                            const scores: RadarChartScore[] = section.sections
                                .map((section) => {
                                    return new RadarChartScore({ label: section.category, score: section.median });
                                })
                                .sort((a, b) => (chartOrder.indexOf(a.label) > chartOrder.indexOf(b.label) ? 1 : -1));
                            if (section.category === IevpReportSectionCategory.Personal) {
                                radarChartScores.push(
                                    new RadarChartData({
                                        label: youLabel.value,
                                        scores: scores,
                                        color: radarChartColor(colorIndex),
                                        entry: entry.title,
                                        title: splittedLabel[1],
                                    }),
                                );
                            } else {
                                radarChartScores.push(
                                    new RadarChartData({
                                        label: organizationLabel.value,
                                        scores: scores,
                                        color: radarChartColor(colorIndex),
                                        entry: entry.title,
                                        title: splittedLabel[1],
                                    }),
                                );
                            }
                            colorIndex++;
                        }
                    });
                }
            });
            return radarChartScores;
        };

        const radarChartColor = (index: number) => {
            return colors[index % colors.length];
        };

        const compareQuestions = async (question: string) => {
            comparing.value = true;
            if (!question) {
                compareTo.value = 'default';
            } else {
                compareTo.value = question;
            }
            comparing.value = false;
            radarChartKey.value += 1;
        };

        const questionOptions: ComputedRef<QuestionOption[]> = computed(() => {
            if (!reportData.value.table || !reportData.value.table.entries) {
                return [];
            }

            const allEntries = reportData.value.table.entries.map((entry: any) => {
                return {
                    label: entry.title.split(':')[0],
                    question: entry.title.split(':')[0],
                };
            });

            const uniqueEntries = [];
            allEntries.forEach((entry) => {
                if (!uniqueEntries.find((x) => x.label === entry.label)) {
                    uniqueEntries.push(entry);
                }
            });
            return uniqueEntries;
        });

        const pillars = [
            {
                key: 'mp',
                translateKey: 'MP',
                category: 'PURPOSE_MEANING',
                scores: pillarScores(IevpTeamReportSectionCategory.PurposeMeaning),
            },
            {
                key: 'cc',
                translateKey: 'CC',
                category: 'CONNECTION_COMMUNITY',
                scores: pillarScores(IevpTeamReportSectionCategory.ConnectionCommunity),
            },
            {
                key: 'gd',
                translateKey: 'GD',
                category: 'GROWTH_DEVELOPMENT',
                scores: pillarScores(IevpTeamReportSectionCategory.GrowthDevelopment),
            },
            {
                key: 'mo',
                translateKey: 'MO',
                category: 'MATERIAL_OFFERINGS',
                scores: pillarScores(IevpTeamReportSectionCategory.MaterialOfferings),
            },
        ];

        const normalizeValue = (value: number): number => {
            const min = -1.5;
            const max = 1.5;
            const newMin = 0;
            const newMax = 5;
            return ((value - min) / (max - min)) * (newMax - newMin) + newMin;
        };

        const updateDistribution = (distribution: number[], score: number, participantDistribution: any, participant: IevpParticipant) => {
            if (score <= -3) {
                distribution[0]++;
                participantDistribution[0].push(participant);
            } else if (score > -3 && score <= -1) {
                distribution[1]++;
                participantDistribution[1].push(participant);
            } else if (score > -1 && score <= 1) {
                distribution[2]++;
                participantDistribution[2].push(participant);
            } else if (score > 1 && score <= 3) {
                distribution[3]++;
                participantDistribution[3].push(participant);
            } else {
                distribution[4]++;
                participantDistribution[4].push(participant);
            }
            return [distribution, participantDistribution];
        };

        const calculateParticipantDistribution = (
            category: string,
            participants: IevpParticipant[] = reportData.value.participants,
        ): { distribution: number[]; participantDistribution: any } => {
            let distribution = [0, 0, 0, 0, 0];
            let participantDistribution = { 0: [], 1: [], 2: [], 3: [], 4: [] };
            let score = 0;
            participants.forEach((participant) => {
                switch (category) {
                    case IevpTeamReportSectionCategory.MaterialOfferings:
                        score =
                            normalizeValue(participant.organizationSection.standMaterialOfferings) -
                            normalizeValue(participant.personalSection.standMaterialOfferings);
                        [distribution, participantDistribution] = updateDistribution(distribution, score, participantDistribution, participant);
                        break;
                    case IevpTeamReportSectionCategory.GrowthDevelopment:
                        score =
                            normalizeValue(participant.organizationSection.standGrowthDevelopment) -
                            normalizeValue(participant.personalSection.standGrowthDevelopment);
                        [distribution, participantDistribution] = updateDistribution(distribution, score, participantDistribution, participant);
                        break;
                    case IevpTeamReportSectionCategory.ConnectionCommunity:
                        score =
                            normalizeValue(participant.organizationSection.standConnectionCommunication) -
                            normalizeValue(participant.personalSection.standConnectionCommunication);
                        [distribution, participantDistribution] = updateDistribution(distribution, score, participantDistribution, participant);
                        break;
                    case IevpTeamReportSectionCategory.PurposeMeaning:
                        score =
                            normalizeValue(participant.organizationSection.standMeaningPurpose) -
                            normalizeValue(participant.personalSection.standMeaningPurpose);
                        [distribution, participantDistribution] = updateDistribution(distribution, score, participantDistribution, participant);
                        break;
                }
            });
            return { distribution, participantDistribution };
        };

        const getParticipantsDistribution = (
            category: string,
            participants: IevpParticipant[] = reportData.value.participants,
        ): DistributionData[] => {
            const { distribution, participantDistribution } = calculateParticipantDistribution(category, participants);
            const totalParticipants = participants.length;
            const percentageDistribution = [
                new DistributionData(),
                new DistributionData(),
                new DistributionData(),
                new DistributionData(),
                new DistributionData(),
            ];
            distribution.forEach((item, index) => {
                percentageDistribution[index].key = category;
                const amount = parseFloat(((item / totalParticipants) * 100).toFixed(2));
                if (compareTo.value != 'default') {
                    if (item >= 4) {
                        percentageDistribution[index].value = item;
                        percentageDistribution[index].label = amount + '%';
                        percentageDistribution[index].color = '#8EEF9F';
                        percentageDistribution[index].demographicData = convertToDemographicRepresentation(
                            participantDistribution[index],
                            totalParticipants,
                            amount,
                        );
                    } else {
                        percentageDistribution[index].value = 0;
                        percentageDistribution[index].label = '0%';
                    }
                } else {
                    percentageDistribution[index].value = item;
                    percentageDistribution[index].label = amount + '%';
                    percentageDistribution[index].color = '#8EEF9F';
                }
            });
            return percentageDistribution;
        };

        const percentageDistributionChartOptions = new PercentageDistributionChartOptions({ barWidth: 70 });

        const convertToDemographicRepresentation = (
            participants: IevpParticipant[],
            totalParticipants: number,
            distributionPercentage: number,
        ): DemographicRepresentationChartData => {
            const participantsWithDemographicData = participants.filter((x) => x.demographicData && x.demographicData.length);
            const sortOrder = reportData.value.table.entries
                .map((x) => {
                    const xTitle = x.title.split(':');
                    if (xTitle.length > 1) {
                        return xTitle[0] === compareTo.value ? xTitle[1] : undefined;
                    }
                })
                .filter((value) => value !== undefined);
            const demographicOptions = [
                ...new Set(
                    participantsWithDemographicData.map((x) => {
                        const demographic = x.demographicData.find((x) => x.label == compareTo.value);
                        if (typeof demographic === 'object') {
                            return demographic.value;
                        }
                    }),
                ),
            ]
                .filter((value) => value !== undefined)
                .sort((a, b) => (sortOrder.indexOf(a) + 1 || Number.MAX_VALUE) - (sortOrder.indexOf(b) + 1 || Number.MAX_VALUE));
            const participantsInDemographic = reportData.value.participants.filter(
                (x) => x.demographicData && x.demographicData.length && x.demographicData.find((y) => y.label == compareTo.value),
            );

            const representation = demographicOptions.map((x) => {
                const nValue = participantsInDemographic.filter((p) => p.demographicData.find((y) => y.value == x)).length;
                const baselineValue = Math.floor(nValue * (distributionPercentage / 100));
                const value = participantsWithDemographicData.filter((participant) => participant.demographicData.find((y) => y.value == x)).length;

                if (value >= 4) {
                    return new DemographicRepresentation({ demographicValue: x, baseline: baselineValue, value: value, nValue: nValue });
                } else {
                    return new DemographicRepresentation({ demographicValue: x, baseline: 0, value: value, nValue: nValue });
                }
            });
            return new DemographicRepresentationChartData({
                demographic: compareTo.value,
                totalParticipants: totalParticipants,
                representation: representation,
            });
        };

        const getSectionQuarters = (section: string) => {
            return [t(`reportsWeb.${section}_DEVIATION_MIN`), t(`reportsWeb.${section}_DEVIATION_MAX`)];
        };

        const answerOptionTranslations = computed<string[]>(() => {
            if (i18n.global.messages['en'] && i18n.global.messages['en']['surveyAnswers']) {
                const surveyAnswers = i18n.global.messages['en']['surveyAnswers'];
                const optionKeys = Object.keys(surveyAnswers).filter((key) => key.includes('ANSWER_QUESTION_29_OPTION_'));
                const otherKeys = Object.keys(surveyAnswers)
                    .filter(
                        (key) => !key.includes('IEVP_SLIDER') && !key.includes('IEVP_ARRANGE_ORDER') && !key.includes('ANSWER_QUESTION_29_OPTION_'),
                    )
                    .reverse();
                return optionKeys.concat(otherKeys);
            }
            return [];
        });

        onMounted(async () => {
            const lang = store.getters.language;
            lang.section = 'survey';
            lang.reportAlias = null;
            await loadTranslationsAsync(lang);

            loaded.value = true;
        });

        return {
            pillars,
            chartScores,
            personalChartScores,
            organizationChartScores,
            questionOptions,
            compareTo,
            context,
            comparing,
            projectName,
            quarters,
            translatedLabels,
            radarChartKey,
            barChartColors,
            invitedParticipants,
            completedParticipants,
            reportData,
            showAnswerDistribution,
            reportAlias,
            deviationSections,
            reversedDistributionQuestions,
            reversedDistributionColorings,
            answerOptionTranslations,
            loaded,
            calculateDeviationScore,
            compareQuestions,
            compareGeneralScores,
            compareScoresRadarChart,
            getParticipantsDistribution,
            getSectionQuarters,
            state,
            getDistributionData,
            percentageDistributionChartOptions,
            percentageDistributionQuarters,
            chartLabelStyle,
        };
    },
});
