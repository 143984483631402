import { defineComponent } from 'vue'
import spinnerVue from './Spinner.vue';

export default defineComponent({
    components: {
        Spinner: spinnerVue,
    },
    props: {
        fullSize: { type: Boolean },
        center: { type: Boolean },
        cover: { type: Boolean },
        spinnerSize: { type: String, default: 'md' },
    },
});
