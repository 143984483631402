import { defineComponent, mergeProps } from 'vue';
import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';


export default defineComponent({
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    setup(_, { emit }) {
        const onSelect = (optionId: string) => {
            emit('selected', { id: +optionId });
        };

        return { onSelect };
    },
});
