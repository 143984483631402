<template>
    <survey-loader v-if="!loaded" cover spinner-size="lg" />
    <survey-alert v-else-if="error" variant="error">Oh no! Something went wrong when trying to load your report.</survey-alert>
    <template v-else>
        <slot />

        <print-toolbar />
    </template>
</template>
<script src="./ReportWrapper.ts"></script>
