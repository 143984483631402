<template>
    <survey-alert variant="info">
        <span v-t="'generic.ARRANGE_ORDER_PRESSHOLD'"></span>
    </survey-alert>

    <ol ref="listRef"
        class="arrange-order-list">
        <transition-group name="list">
            <li v-for="(option, index) in orderedOptions"
                :key="'option_' + option.id"
                class="arrange-order-list-item"
                :id="'option_' + option.id">
                <div class="arrange-order-list-item-handle">
                    <survey-icon icon="menu" />
                </div>
                <div class="arrange-order-list-item-content">
                    <span class="mr-2">{{ index + 1 }}.</span> <span v-t="'surveyAnswers.' + option.alias"></span>
                </div>
                <div class="arrange-order-list-item-btns">
                    <div class="arrow arrow-up"
                         @click="move(option, -1)">
                        <survey-icon icon="chevron-up" />
                    </div>
                    <div class="arrow arrow-down"
                         @click="move(option, 1)">
                        <survey-icon icon="chevron-down" />
                    </div>
                </div>
            </li>
        </transition-group>
    </ol>
</template>
<script src="./arrangeorder.ts"></script>
<style lang="scss">
    @import '@/variables';

    .arrange-order-list {
        position: relative;
        list-style: none;
        padding-left: 0;

        &-item {
            display: grid;
            grid-template-columns: min-content auto min-content min-content;
            margin-bottom: 0.25rem;
            border: 1px $border-color solid;
            padding: 0.5rem 0;
            background: $white;
            align-items: center;
            user-select: none;

            &-handle {
                display: flex;
                align-items: center;
                padding: 0 0.5rem;
                color: $darker-gray;
                height: 100%;
                cursor: grab;

                &:active {
                    cursor: grabbing;
                }
            }

            &-content {
                padding: 0 0.5rem;
            }

            &-btns {
                display: flex;
                flex-direction: column;
                align-self: center;
                gap: 0.25rem;
                padding: 0 0.5rem;

                .arrow {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2rem;
                    height: 2rem;
                    border: 1px $border-color solid;
                    border-radius: 50%;
                    cursor: pointer;
                    background: transition 0.2s ease;

                    &:hover {
                        background: $light-color;
                    }
                }
            }

            &.placeholder {
                opacity: 0.4;
            }

            &.list-move,
            &.list-enter-active,
            &.list-leave-active {
                transition: all .3s cubic-bezier(0.55, 0, 0.1, 1);
            }
        }

        @media screen and (min-width: 768px) {
            .arrange-order-list-item {
                &-btns {
                    flex-direction: row;
                }
            }
        }
    }
</style>