import ContentWrapperVue from '@/generic/components/ContentWrapper.vue';
import ICategory from '@/report/models/ICategory';
import IReportData from '@/report/models/IReportData';
import { computed, ComputedRef, defineComponent } from 'vue';
import BarChartWrapperVue from '@/report/components/charts/BarChartWrapper.vue';

export default defineComponent({
    components: {
        BarChartWrapper: BarChartWrapperVue,
        ContentWrapper: ContentWrapperVue,
    },
    props: {
        context: { type: String },
        name: { type: String },
        primary: { type: Object },
        secondary: { type: Object },
        report: { type: String },
        hideIterationPicker: { type: Boolean },
        singleDescription: { type: Boolean },
    },
    setup(props) {
        const category: ComputedRef<ICategory> = computed(() => {
            const data = props.primary as IReportData;
            return data.categories.find((cat: ICategory) => {
                return cat.category === props.name;
            });
        });

        const categoryArgs = computed(() => {
            return {
                personalScore: category.value.personalScore,
                teamMedian: category.value.teamMedian,
                context: props.context,
            };
        });

        return {
            category,
            categoryArgs,
        };
    },
});
