<template>
    <div v-if="show" class="survey-bar-chart" :class="[colorClass, { stacked: stacked }]">
        <div class="median median-then" :style="`${medianPosition};`" v-tooltip:grey="toolTipGroupMedianTranslation + ' ' + tooltipMedianValue" />
        <div
            class="positive-score score"
            :style="`${positivePosition};`"
            :class="{ 'orange-tooltip': primary, 'grey-tooltip': !primary }"
            v-tooltip:[colorClass]="toolTipPositiveDeviationTranslation"
        />
        <div
            class="negative-score score"
            :style="`${negativePosition};`"
            v-tooltip:[colorClass]="toolTipNegativeDeviationTranslation"
        ></div>
        <div v-if="label"
            class="label"
            :style="`${labelPosition}%; width: ${scores.negativeAverage + scores.positiveAverage - 4}%`"
            v-tooltip:[colorClass]="label"
        >
            {{ decodedLabel }}
        </div>
        <div
            v-if="scores.personalScore != null"
            class="psi-wrapper"
            :style="`${personalPosition}`"
            v-tooltip:[colorClass]="tooltipYourScore"
        >
            <div class="psi" :class="{ 'primary': primary }"></div>
        </div>

        <template v-if="combinedScores">
            <template v-for="score in Object.keys(combinedScores)" :key="`ps_${score}`">
                <div class="team-member-score" :style="`${teamPosition}: ${score}%`" v-tooltip:[colorClass]="scoreToolTip(score)" />
            </template>
        </template>
    </div>
</template>
<script src="./BarChart.ts"></script>
<style lang="scss">
.survey-bar-chart {
    &.stacked {
        height: 2rem;
        font-size: 1rem;
        position: relative;
        margin-bottom: 0.25rem;
        line-height: 1;

        .positive-score,
        .negative-score {
            &.score {
                height: 100%;
                top: 0;
                z-index: 0;
            }
        }
    }

    &:not(.stacked) {
        &.secondary {
            .score {
                top: calc(30% + 0.125rem);
                height: 1.75rem;
            }

            .team-member-score {
                top: 2.5rem;
            }
        }
    }

    .label {
        display: inline-block;
        align-items: center;
        position: absolute;
        height: 100%;
        padding: 0 0.5rem;
        margin: 0 2%;
        white-space: nowrap;
        line-height: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: help;
    }

    .score {
        position: absolute;
        top: 30%;
        height: 2rem;
    }

    .team-member-score {
        position: absolute;
        top: 50%;
        margin: -0.45rem;
        height: 0.9rem;
        width: 0.9rem;
        border: 1px solid #fff;
    }

    .median {
        border-left: 2px dashed transparent;
        height: 75%;
        position: absolute;
        top: 12%;
        z-index: 1;

        .median-now {
            border: 1px solid transparent;
        }

        .median-then {
            border: 1px solid transparent;
            position: absolute;
        }
    }

    .psi-wrapper {
        top: 50%;
        position: absolute;
        z-index: 1;

        .psi {
            width: 1rem;
            height: 1rem;
            transform: rotate(315deg);
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -7.5px;
            margin-top: -7.5px;
        }
    }
}

</style>
