<template>
    <div class="survey-dropdown">
        <survey-select @select="onSelect">
            <option :value="null" disabled selected>-- {{ questionText }} --</option>
            <option v-for="option in dropdownOptions" :key="option.alias" :value="option.alias" :selected="option.alias === question.answer.value ? 'selected' : null">
                <span>{{ option.alias }}</span>
            </option>
        </survey-select>
    </div>
</template>
<script src="./DemographicDropdown.ts"></script>
<style lang="scss">
.survey-dropdown {
    select {
        white-space: nowrap;
        width: auto;
        max-width: max-content;
        margin-bottom: 1rem;
    }
}
</style>
