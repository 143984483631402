<template>
    <table class="survey-table striped">
        <thead>
            <tr>
                <th>{{ $t(`generic.GENERAL_FIRSTNAME`) }}</th>
                <th>{{ $t(`generic.GENERAL_LASTNAME`) }}</th>
                <th>{{ $t(`generic.GENERAL_SCORE`) }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(p, index) in scores.participants"
                :key="`p_score_${index}`">
                <td>{{ p.firstName }}</td>
                <td>{{ p.lastName }}</td>
                <td>{{ p.score }}</td>
            </tr>
        </tbody>
    </table>
</template>
<script src="./ScoreTable.ts"></script>
