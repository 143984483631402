<template>
    <div id="survey-view" :class="{ 'survey-view-grouped': grouped }">
        <div v-if="!grouped" id="survey-view-header">
            <h2 v-t="{ path: 'generic.QUESTION_PROGRESS', args: { from: questionIndex + 1, to: questions.length } }"></h2>
            <survey-progress :progress="progress" />
        </div>
        <context ref="contextComponent" :context="survey.context" :option="survey.contextOption" :location="survey.contextLocation" />

        <form v-if="!grouped" style="position: relative">
            <survey-question
                v-if="currentQuestion"
                :key="currentQuestion.id"
                :question="currentQuestion"
                :context="survey.context"
                :first-name="survey.participantFirstName"
                :instrument="survey.instrumentAlias"
            />

            <div v-if="showValidationError" class="alert alert-danger w-75">
                <span v-if="answerComponent === 'radio'" v-t="'generic.SELECTION_ERROR'" />
                <span v-if="answerComponent === 'percentage'" v-t="'generic.PERCENTAGE_ERROR'" />
                <span v-if="answerComponent === 'topfive'" v-t="'generic.PERCENTAGE_ERROR'" />
            </div>

            <div class="survey-view-footer">
                <survey-button
                    v-if="!isFirstQuestion && !disablePrevious"
                    v-t="'generic.QUESTION_PREVIOUS'"
                    @click="previous"
                    variant="transparent"
                />
                <template v-if="questionAnswered">
                    <survey-button v-if="!isLastQuestion" v-t="'generic.QUESTION_NEXT'" @click="next" />
                    <survey-button class="finish" v-else :disabled="pendingAnswers > 0" @click="finish">
                        <span v-t="'generic.QUESTION_LAST'" class="mr-2"></span>
                        <spinner v-if="pendingAnswers > 0" />
                    </survey-button>
                </template>
            </div>
        </form>
        <form v-else>
            <content-wrapper :key="currentSectionId">
                <survey-title v-t="currentSurvey.sectionTitleKey.replace('{sectionId}', currentSectionId)" />
                <survey-subtitle v-t="currentSurvey.sectionSubTitleKey.replace('{sectionId}', currentSectionId)" />
            </content-wrapper>

            <survey-question
                v-for="item in sectionQuestions"
                :key="`question_${item.question.id}`"
                :question="item.question"
                :context="survey.context"
                :first-name="survey.participantFirstName"
                :instrument="survey.instrumentAlias"
                :next-button-hovered="nextButtonHovered"
                @answered="nextButtonHovered = false"
            />

            <div class="survey-view-footer">
                <survey-button v-if="!firstSection" variant="transparent" @click="previousSection">Previous</survey-button>

                <span v-if="!lastSection" @mouseover="onNextButtonHover">
                    <survey-button @click="nextSection" :class="{ disabled: !canGoNextSection }">
                        <survey-translate tag="span" base="generic" name="QUESTION_NEXT" />
                    </survey-button>

                    <template v-if="!canGoNextSection && nextButtonHovered">
                        <span style="display: inline-block; margin-left:1rem;">
                            <survey-icon icon="warning" color="warning" />
                            <survey-translate tag="span" base="generic" name="SECTION_VALIDATION_MESSAGE" />
                        </span>
                    </template>
                </span>
                <span v-else>
                    <survey-button @mouseover="onNextButtonHover" @click="finish" class="finish" :class="{ disabled: !canComplete }">
                        <survey-translate tag="span" base="generic" name="QUESTION_LAST" />
                    </survey-button>

                    <template v-if="lastSection && !canComplete && nextButtonHovered">
                        <span style="display: inline-block; margin-left:1rem;">
                            <survey-icon icon="warning" color="warning" />
                            <survey-translate tag="span" base="generic" name="SECTION_VALIDATION_MESSAGE" />
                        </span>
                    </template>
                </span>
            </div>
        </form>

        <div v-if="offline" class="survey-offline-overlay">
            <survey-icon icon="cloud-off" size="lg" class="mb-3" />
            <survey-title>No internet</survey-title>
            <div>Oh no, your browser seems to be offline.</div>
            <div class="text-muted">Make sure you have a stable network connection.</div>
        </div>

        <div v-if="error" class="survey-offline-overlay">
            <survey-icon icon="error" size="lg" class="mb-3" color="error" />
            <survey-title color="error">Sorry, something went wrong</survey-title>
            <div v-if="!reloaded">Reload the survey or try again later.</div>
            <div v-else>Check your network, reload the survey or try again later.</div>

            <survey-button class="mt-3 mb-3" @click="reloadSurvey"
                ><survey-icon class="mt-1" icon="refresh"></survey-icon> Reload survey</survey-button
            >

            <div class="mt-1 text-center" v-if="error && error.operationId && reloaded">
                <p class="p-2">
                    If this problem persists, try for instance to take this survey on a mobile phone or a private computer. If you want to file a
                    support request about this issue to the survey administrator, please provide the following Operation Id:
                </p>
                <div class="mt-2">
                    Operation Id: <code>{{ error.operationId }}</code>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="./SurveyQuestions.ts"></script>
<style lang="scss">
@import '@/variables';

#survey-view {
    &-header {
        display: grid;
        grid-template-columns: min-content auto;
        align-items: center;
        margin-bottom: 2rem;
        gap: 1rem;

        h2 {
            color: darken($dark-color, 10);
            font-size: $font-size-lg;
            line-height: 1.5;
            font-weight: 700;
            white-space: nowrap;
            margin: 0;
        }
    }

    .survey-view-footer {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
    }

    .survey-view-grouped {
        form {
            .survey-subtitle {
                font-weight: 400;
            }

            .survey-question {
                &.first {
                    margin-top: 3rem;
                }
            }
        }
    }

    .survey-offline-overlay {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.9);
        z-index: 3; // Make sure to be on top of context overlay

        .survey-icon.icon-lg {
            font-size: 2rem;
        }
    }

    @media screen and (min-width: 560px) {
        .survey-view-footer {
            flex-direction: row;
        }
    }
}
</style>
