import { createI18n, VueI18nOptions } from 'vue-i18n';
import { TranslationService } from '@/generic/services/translationService';
import { RouteLocationNormalized, Router } from 'vue-router';
import to from 'await-to-js';
import Language from './store/models/Language';
import he from 'he';

const loadingLanguages = [];
const fallbackLocale = 'en';
const loadedLanguages = ['keys'];

export const i18n = createI18n({
    locale: localStorage.getItem('language'),
    fallbackLocale,
    allowComposition: true,
    globalInjection: true,
    messages: { keys: {} },
    warnHtmlInMessage: 'off',
} as VueI18nOptions);

const setI18nLanguage = (lang: string) => {
    i18n.global.locale = lang;
    localStorage.setItem('language', lang);
};

export const setLanguageDirection = (locale: string): void => {
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    const rtlLanguages = ['ar'];
    html.setAttribute('lang', locale);
    if (rtlLanguages.includes(locale)) {
        body.setAttribute('direction', 'rtl');
    } else {
        body.setAttribute('direction', 'ltr');
    }
};

export const getPreferredLanguage = (route: RouteLocationNormalized) => {
    const fallback = i18n.global.locale ? i18n.global.locale : fallbackLocale;
    if (!route) {
        return fallback;
    }

    const { params, query } = route;
    const lang = [query.prefLang, query.language, params.language].find((value) => !!value);
    return lang ? lang.toString().toLowerCase() : fallback;
};

export const getPreferredLanguageAndClear = (router: Router, route: RouteLocationNormalized): string => {
    const preferred = getPreferredLanguage(route);
    const keys = ['prefLang', 'language'];
    const params = { ...route.params };
    const query = { ...route.query };

    keys.forEach((key: string) => {
        if (params[key]) {
            delete params[key];
        }
        if (query[key]) {
            delete query[key];
        }
    });

    router.replace({ params, query });
    return preferred;
};

export const loadTranslationsAsync = async (language: Language): Promise<void> => {
    if (i18n.global.locale === 'keys') {
        return;
    }

    const languageKey = language.key;
    // If the language was already loaded
    if (loadedLanguages.includes(languageKey) || language.languageCode == 'keys') {
        setI18nLanguage(language.languageCode);
        return;
    }

    // When loading the language, wait for the first call to handle it
    if (loadingLanguages.includes(languageKey)) {
        return;
    }

    // If the language hasn't been loaded yet
    loadingLanguages.push(languageKey);
    const translationService = new TranslationService();
    const [err, translations] = await to(translationService.getTranslations(language));
    if (err) {
        loadingLanguages.splice(loadingLanguages.indexOf(languageKey), 1);
        return;
    }

    Object.keys(translations).forEach((k) => {
        const name = k;
        translations[k].forEach((j) => {
            if (!i18n.global.messages[language.languageCode]) {
                i18n.global.messages[language.languageCode] = {};
            }

            if (!i18n.global.messages[language.languageCode][name]) {
                i18n.global.messages[language.languageCode][name] = {};
            }

            const value = j.value.replaceAll('{{', '{').replaceAll('}}', '}');

            i18n.global.messages[language.languageCode][name][j.key.toUpperCase()] = he.decode(value); //j.value.replaceAll('{{', '{').replaceAll('}}', '}');
        });
    });

    loadedLanguages.push(languageKey);
    loadingLanguages.splice(loadingLanguages.indexOf(languageKey), 1);
    setI18nLanguage(language.languageCode);
};

export default i18n;
