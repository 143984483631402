<template>
    <table class="table-three-column" :class="[align]">
        <tr>
            <td v-for="(column, c) in columns" :key="`column_${c}`">
                <img :src="$t(`reportsWeb.${column.image}`)" :alt="column.imageAlt" />
                <survey-translate tag="p" base="reportsWeb" :name="column.name" raw />
            </td>
        </tr>
    </table>
</template>
<script src="./ThreeColumns.ts"></script>
<style lang="scss">
.table-three-column {
    width: 100%;

    &.center {
        tr {
            td {
                text-align: center;
            }
        }
    }

    &.left {
        tr {
            td {
                text-align: left;
            }
        }
    }

    tr {
        td {
            width: 33%;
            vertical-align: top;
            padding: 0.75rem;

            img {
                height: 175px;
                margin-bottom: 1rem;
            }
        }
    }
}
</style>
