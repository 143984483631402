import Answer from './Answer';
import { QuestionType } from './QuestionType';

export default class Question {
    public answerOptions: Answer[] = [];
    public answer: any = null;
    public id: number = 0;
    public question: number | string = 0;
    public questionNumber: number = 0;
    public questionType: QuestionType;
    public isDemographic: boolean;
    public section: number = 0;
    public type: number = 0;
    public view?: string;
    public priority?: number;
    public surveyId?: number;
    public label?: string;
    public order?: number;
    public value?: string;
    public reversed?: boolean;
    public horizontal?: boolean;
    public first?: boolean;

    constructor(obj?: Partial<Question>) {
        if (obj) {
            Object.assign(this, obj);
        }
        if (
            (!this.answerOptions.find((option) => option.checked) && (this.questionType === 'choice' || this.questionType === 'score')) ||
            (!this.answerOptions.find((option) => option.optionValue) && this.questionType === 'arrangeOrder')
        ) {
            this.answerOptions.sort((a: Answer, b: Answer) => a.order - b.order);
        }
    }

    public get surveySection(): string {
        return [this.priority ?? -1, this.section].join('_');
    }
}
