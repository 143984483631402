import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import AppHelper from '../helpers/AppHelper';
import EnvService from '../services/EnvService';
import BaseLayout, { loadEnvironment } from './BaseLayout';
import LanguageSelectorVue from './LanguageSelector.vue';

export default defineComponent({
    extends: BaseLayout,
    components: {
        LanguageSelector: LanguageSelectorVue,
    },
    setup() {
        const logo = ref('');
        const env = EnvService.getEnvironment();
        const application = AppHelper.applicationFromUrl(env.theme);
        const styleLoaded = ref(false);
        const store = useStore();
        const loadedLanguage = computed(() => store.getters.loadedLanguage.key);
        const loadingLanguage = computed(() => store.getters.loadingLanguage);

        if (application === 'pit') {
            logo.value = `/images/design/${application}/logo.png`;
        } else {
            logo.value = `/images/design/${application}/logo.svg`;
        }

        onMounted(() => {
            loadEnvironment();
            styleLoaded.value = true;
        });

        return {
            logo,
            styleLoaded,
            loadedLanguage,
            loadingLanguage,
        };
    },
});
