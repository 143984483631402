<template>
    <div class="baseline-graphics">
        <table>
            <tr>
                <td>
                    <img src="/images/design/kantor-baseline/move_follow_oppose_bystand.png" />
                </td>
                <td>
                    <img src="/images/design/kantor-baseline/closed_open_random.png" />
                </td>
                <td>
                    <img src="/images/design/kantor-baseline/affect_meaning_power.png" />
                </td>
            </tr>
        </table>
    </div>
</template>
<style lang="scss">
.baseline-graphics {
    padding: 0 1rem;
    margin-bottom: 1rem;

    table {
        width: 100%;
        tr {
            td {
                width: 33%;
                text-align: center;

                img {
                    height: 6rem;
                    border: none;
                }
            }
        }
    }
}
</style>
