import { BaseService } from './baseService';
import EnvService from './EnvService';
import LogHelper from '@/generic/helpers/LogHelper';
import Language from '@/store/models/Language';

export class TranslationService extends BaseService {
    private endpoint = `${EnvService.getEnvironment().apiEndpoint}/languages`;

    constructor() {
        super(false);
    }

    public async getTranslations(language: Language): Promise<Translations> {
        try {
            const response = await this.get(
                this.endpoint +
                    `/${language.languageCode}/translations/${language.knowledgeModelAlias}${
                        language.instrumentAlias ? '/' + language.instrumentAlias : ''
                    }${language.section ? '?section=' + language.section : ''}${language.reportAlias ? '&report_alias=' + language.reportAlias : ''}`,
            );

            return response.data as Translations;
        } catch (e) {
            LogHelper.error('Failed to fetch translations.', e);
            return null;
        }
    }
}

export class Translations {}
