import Answer from '@/survey/models/Answer';
import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'open-question',
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    setup(props, context) {
        const { t } = useI18n();
        const answer = ref('');
        const placeholder = ref('');
        const updateTimeoutTime = 500;
        let updateTimeout = null;

        const answerChanged = () => {
            context.emit('selected', { answerText: answer.value });
        };

        const onInput = () => {
            (props.question.answerOptions[0] as Answer).answerText = answer.value;

            clearTimeout(updateTimeout);
            updateTimeout = setTimeout(answerChanged, updateTimeoutTime);
        };

        onMounted(async () => {
            placeholder.value = t(`surveyQuestions.QUESTION_${(props.question.answerOptions[0] as Answer).optionValue}`);
            answer.value = (props.question.answerOptions[0] as any).answerText;
        });

        return {
            placeholder,
            answer,
            answerChanged,
            onInput,
        };
    },
});
