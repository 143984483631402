import LogHelper from '@/generic/helpers/LogHelper';
import QuestionHelper from '@/generic/helpers/QuestionHelper';
import { computed, ComputedRef, ref } from 'vue';
import { useStore } from 'vuex';
import Question from '../models/Question';

export const setupSurvey = (questions: Question[]) => {
    const store = useStore();
    const questionIndex = ref(-1);
    const showValidationError = ref(false);
    const contextComponent = ref(null);

    // Computed
    const currentQuestion: ComputedRef<Question> = computed(() => questions[questionIndex.value]);
    const answerComponent = computed(() => QuestionHelper.component(currentQuestion.value.questionType, null, currentQuestion.value.isDemographic));
    const progress = computed(() => ((questionIndex.value + 1) / questions.length) * 100);
    const isLastQuestion = computed(() => questionIndex.value + 1 === questions.length);
    const isFirstQuestion = computed(() => questionIndex.value === 0);
    const questionAnswered = computed(() => {
        if (currentQuestion.value) {
            return QuestionHelper.valid(currentQuestion.value);
        }
        return false;
    });
    const pendingAnswers: ComputedRef<number> = computed(() => store.getters.pendingAnswers);

    // Methods
    const showContextOverlay = () => contextComponent.value.show(2000);
    const findNextQuestionIndex = () => {
        questionIndex.value = QuestionHelper.findNextQuestionIndex(questions);
    };

    const next = () => {
        const question: Question = currentQuestion.value;
        const valid = QuestionHelper.valid(question);
        showValidationError.value = !valid;
        if (valid && questionIndex.value < questions.length - 1) {
            questionIndex.value++;
            showContextOverlay();
            window.scrollTo(0, 0);
        } else {
            LogHelper.info('Question is not valid, so we don"t move to the next...', question);
        }
    };

    const previous = () => {
        showValidationError.value = false;
        if (questionIndex.value > 0) {
            questionIndex.value--;
            showContextOverlay();
            window.scrollTo(0, 0);
        }
    };

    return {
        questionIndex,
        showValidationError,
        contextComponent,
        currentQuestion,
        answerComponent,
        progress,
        isLastQuestion,
        isFirstQuestion,
        questionAnswered,
        pendingAnswers,
        showContextOverlay,
        findNextQuestionIndex,
        next,
        previous,
    };
};

export const setupGroupedSurvey = (questions: Question[]) => {
    const currentSectionIndex = ref(0);
    const nextButtonHovered = ref(false);

    // Computed
    const sections: ComputedRef<string[]> = computed(() => {
        const numbers = [];
        questions.forEach((question: Question) => {
            const unique = question.surveySection;
            if (numbers.indexOf(unique) < 0) {
                numbers.push(unique);
            }
        });
        numbers.sort();
        return numbers;
    });
    const currentSection: ComputedRef<string> = computed(() => sections.value[currentSectionIndex.value]);
    const currentSectionId: ComputedRef<number> = computed(() => {
        const [_, sectionId] = currentSection.value.split('_');
        return +sectionId;
    });
    const sectionQuestions: ComputedRef<Array<{ question: Question }>> = computed(() => {
        const items = [];
        questions.forEach((question: Question, index: number) => {
            const prev = index > 0 ? questions[index - 1] : null;
            const next = index + 1 < questions.length ? questions[index + 1] : null;
            question.first = index === 0 || (prev && question.section > prev.section) || (prev && !QuestionHelper.sameQuestionType(question, prev));
            question.horizontal =
                (QuestionHelper.sameQuestionType(question, prev) || QuestionHelper.sameQuestionType(question, next)) && !question.isDemographic;
            if (question.surveySection === currentSection.value) {
                items.push({
                    question,
                });
            }
        });
        return items;
    });
    const firstSection = computed(() => currentSectionIndex.value === 0);
    const lastSection = computed(() => currentSectionIndex.value === sections.value.length - 1);
    const canComplete = computed(() => {
        let valid = true;
        questions.forEach((question: Question) => {
            if (!QuestionHelper.valid(question)) {
                valid = false;
            }
        });
        return valid;
    });

    const canGoNextSection = computed(() => {
        let valid = true;
        sectionQuestions.value.forEach((sectionQuestion) => {
            if (!QuestionHelper.valid(sectionQuestion.question)) {
                valid = false;
            }
        });
        return valid;
    });

    // Methods
    const nextSection = () => {
        if (!canGoNextSection.value) {
            return;
        }

        if (currentSectionIndex.value < sections.value.length) {
            currentSectionIndex.value++;
            nextButtonHovered.value = false;
            window.scrollTo(0, 0);
        }
    };

    const previousSection = () => {
        if (!firstSection.value) {
            currentSectionIndex.value--;
            window.scrollTo(0, 0);
        }
    };

    const resumeSurvey = () => {
        const invalid = questions.find((q: Question) => !QuestionHelper.valid(q));
        if (invalid) {
            const sectioIndex = sections.value.indexOf(invalid.surveySection);
            currentSectionIndex.value = sectioIndex;
        }

        if (typeof invalid === 'undefined') {
            currentSectionIndex.value = sections.value.length - 1;
        }
    };

    const onNextButtonHover = () => {
        nextButtonHovered.value = true;
    };

    return {
        currentSectionIndex,
        currentSection,
        currentSectionId,
        sections,
        sectionQuestions,
        firstSection,
        lastSection,
        canComplete,
        canGoNextSection,
        nextSection,
        previousSection,
        resumeSurvey,
        nextButtonHovered,
        onNextButtonHover,
    };
};
