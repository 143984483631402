import to from 'await-to-js';
import { AxiosError } from 'axios';
import LogHelper from './LogHelper';
import { appInsightsHub } from '@/store/appInsights';

export default class QueueHandler<model> {
    private queue: model[] = [];
    private callback: any;
    private retry: model[] = [];
    private error: { operationId: string; responseCode: number; message: string };

    constructor(items: model[], callback: (data: model) => Promise<void>) {
        this.queue = items;
        this.callback = callback;
    }

    public getError(): { operationId: string; responseCode: number; message: string } {
        return this.error;
    }

    public async process(): Promise<model[]> {
        if (this.queue.length === 0) {
            return [];
        }

        this.retry = [];
        let offline = false;

        while (!offline && this.queue.length > 0) {
            const data = this.queue.shift();
            const [err] = await to(this.callback(data));
            const axiosError = err as AxiosError;

            LogHelper.info('Processing queue ', data);
            if (err) {
                const reloadResponseCodes = [400, 401, 403, 429, 500, 502];
                const waitResponseCodes = [503];

                offline = err.message === 'Network Error';
                if (axiosError.response && waitResponseCodes.indexOf(axiosError.response.status) > -1) {
                    this.retry.push(data);
                } else if (axiosError.response && reloadResponseCodes.indexOf(axiosError.response.status) > -1) {
                    this.error = {
                        operationId: appInsightsHub.value.getOperationId(),
                        responseCode: axiosError.response ? axiosError.response.status : 0,
                        message: axiosError.message,
                    };
                }

                appInsightsHub.value.instance?.trackException({ exception: err });
                appInsightsHub.value.instance?.trackEvent({ name: 'queue_error' }, { error: err, data });
            }
        }

        return [...this.retry, ...this.queue];
    }
}
