<template>
    <survey-title v-t="`reportsWeb.RJC_TITLE`" />
    <survey-translate tag="div" base="reportsWeb" name="RJC_SUBTITLE" :args="subTitleArgs" />
    <page class="intro-gradient">
        <div>
            <survey-translate tag="h2" base="reportsWeb" name="INTRO_TITLE" />
            <survey-translate class="section-visible-text" tag="div" base="reportsWeb" name="INTRO_DESC_VISIBLE" />
            <b-collapse id="collapse-intro">
                <template #footer="{ visible, toggle, id }">
                    <div class="collapse-container">
                        <survey-translate
                            :class="`collapse-text ${visible ? 'visible' : ''}`"
                            tag="div"
                            base="reportsWeb"
                            name="INTRO_DESC_READ_MORE"
                        />
                    </div>
                    <survey-button id="read-more" :aria-expanded="visible" :aria-controls="id" @click="toggle">
                        <span
                            >Read {{ visible ? 'less' : 'more' }} <survey-icon v-if="!visible" icon="chevron-down"/><survey-icon
                                v-if="visible"
                                icon="chevron-up"
                        /></span>
                    </survey-button>
                </template>
            </b-collapse>
        </div>

        <img src="../../../../public/images/design/rjc/intro.png" />
    </page>
    <page>
        <survey-translate tag="h2" base="reportsWeb" name="SECTION_1_TITLE" />
        <survey-translate tag="div" class="section-visible-text" base="reportsWeb" name="SECTION_1_VISIBLE" />
        <b-collapse id="collapse-1">
            <template #footer="{ visible, toggle, id }">
                <div class="collapse-container">
                    <survey-translate tag="div" :class="`collapse-text ${visible ? 'visible' : ''}`" base="reportsWeb" name="SECTION_1_READ_MORE" />
                </div>
                <survey-button id="read-more" :aria-expanded="visible" :aria-controls="id" @click="toggle">
                    <span
                        >Read {{ visible ? 'less' : 'more' }} <survey-icon v-if="!visible" icon="chevron-down"/><survey-icon
                            v-if="visible"
                            icon="chevron-up"
                    /></span>
                </survey-button>
            </template>
        </b-collapse>
    </page>
    <page>
        <survey-translate tag="h2" base="reportsWeb" name="SECTION_2_TITLE" />
        <survey-translate tag="div" class="section-visible-text" base="reportsWeb" name="SECTION_2_VISIBLE" />
        <b-collapse id="collapse-2">
            <template #footer="{ visible, toggle, id }">
                <div class="collapse-container">
                    <survey-translate tag="div" :class="`collapse-text ${visible ? 'visible' : ''}`" base="reportsWeb" name="SECTION_2_READ_MORE" />
                </div>
                <survey-button id="read-more" :aria-expanded="visible" :aria-controls="id" @click="toggle">
                    <span
                        >Read {{ visible ? 'less' : 'more' }} <survey-icon v-if="!visible" icon="chevron-down"/><survey-icon
                            v-if="visible"
                            icon="chevron-up"
                    /></span>
                </survey-button>
            </template>
        </b-collapse>
    </page>
    <page>
        <survey-translate tag="h2" base="reportsWeb" name="TOTAL_TITLE" />
        <survey-translate
            tag="div"
            base="reportsWeb"
            name="TOTAL_DESC"
            :args="{ teamMedian: totalChartData.tooltipScore.toFixed(2), benchmarkMedian: rjcBenchmarkData('TOTAL').teamMedian }"
        />
        <team-compare-question
            :categoryKey="'TOTAL'"
            :context="reportData.context"
            :scores="totalChartData"
            :type="type"
            :options="questionOptions"
            :option-default="'All participants'"
            :compare-to="compareTo"
            :quarters="dimensionDeviationLabels['total']"
            :compare-scores="compareScores"
            @compare="compareQuestions"
            :loading="comparing"
            :demographic-table="reportData.table"
            show-deviation
            show-deviation-explainer
            class="rjc-deviation-graph"
        />
    </page>
    <page class="colored-section">
        <survey-translate tag="h2" base="reportsWeb" name="EMOTION_SECTION_TITLE" />
        <survey-translate tag="div" base="reportsWeb" name="EMOTION_SECTION_DESC" />
        <div class="sunburst">
            <team-sunburst-chart
                :reportData="emotionOneData"
                :chart-title="$t('reportsWeb.EMOTION_CHART_TITLE_1')"
                :max-depth="maxDepth"
                :step-size="stepSize"
            />
            <div class="img-wrapper">
                <img src="../../../../public/images/design/rjc/time.svg" />
                <div class="img-filler"></div>
            </div>
            <team-sunburst-chart
                :chartNumber="2"
                :reportData="emotionTwoData"
                :chart-title="$t('reportsWeb.EMOTION_CHART_TITLE_2')"
                :max-depth="maxDepth"
                :step-size="stepSize"
            />
        </div>
    </page>
    <page class="colored-section">
        <div class="limited-border"></div>
        <div class="dimensions-section">
            <table>
                <tr>
                    <td>
                        <survey-translate tag="h2" base="reportsWeb" name="DIMENSIONS_TITLE" />
                        <survey-translate tag="div" base="reportsWeb" name="DIMENSIONS_DESC" />
                        <survey-translate tag="h3" style="margin-bottom: 0;" base="reportsWeb" name="DIMENSIONS_SCORE_TITLE" />
                        <survey-translate tag="h5" style="margin: 1em 0;" base="reportsWeb" name="DIMENSIONS_SCORE_SUB_TITLE" />
                        <survey-translate tag="div" base="reportsWeb" name="DIMENSIONS_SCORE_DESC" :args="dimensionsScores" raw />
                    </td>
                    <td>
                        <team-radar
                            :scores="radarChartScores"
                            :options="questionOptions"
                            :chart-label-style="chartLabelStyle"
                            :compare-to="compareTo"
                            :compare-scores="compareScoresRadarChart"
                            @compare="compareQuestions"
                            :labels="translatedLabels"
                            :key="radarChartKey"
                            :min-value="0"
                            :max-value="100"
                            :max-ticks-limit="20"
                            show-ticks
                            :option-default="'All participants'"
                        />
                    </td>
                </tr>
            </table>
        </div>
    </page>
    <page v-for="dimension in chartOrder.slice(0, 2)" :key="dimension" class="dimensions-section">
        <survey-translate tag="h2" base="reportsWeb" :name="dimension.toUpperCase() + '_SECTION_TITLE'" />
        <table>
            <tr>
                <td>
                    <survey-translate tag="div" base="reportsWeb" :name="dimension.toUpperCase() + '_SECTION_DESC'" raw />
                </td>
                <td>
                    <div class="radar-overlay">
                        <team-radar
                            :scores="radarChartScores"
                            :options="questionOptions"
                            :chart-label-style="chartLabelStyle"
                            :compare-to="compareTo"
                            :compare-scores="compareScoresRadarChart"
                            @compare="compareQuestions"
                            :labels="translatedLabels"
                            :name="dimension"
                            :min-value="0"
                            :max-value="100"
                            :max-ticks-limit="20"
                            show-ticks
                            :option-default="'All participants'"
                        />
                    </div>
                </td>
            </tr>
        </table>

        <survey-translate tag="div" base="reportsWeb" :name="dimension.toUpperCase() + '_SECTION_EXPLAINER'" raw />
        <team-compare-question
            :categoryKey="dimension.toUpperCase()"
            :context="reportData.context"
            :scores="getDimensionChartData(dimension)"
            :type="type"
            :options="questionOptions"
            :option-default="'All participants'"
            :compare-to="compareTo"
            :quarters="dimensionDeviationLabels[dimension.toLowerCase()]"
            :compare-scores="compareScores"
            @compare="compareQuestions"
            :loading="comparing"
            :demographic-table="reportData.table"
            show-deviation
            show-deviation-explainer
            class="rjc-deviation-graph"
        />
        <survey-translate tag="h3" base="reportsWeb" :name="dimension.toUpperCase() + '_ESTABLISHED_TITLE'" />
        <survey-translate tag="div" base="reportsWeb" :name="dimension.toUpperCase() + '_ESTABLISHED_DESC'" raw />
    </page>
    <page v-for="n in 4" :key="n" class="dimensions-section">
        <survey-translate tag="h2" base="reportsWeb" :name="`SECTION_${n + 2}_TITLE`" />
        <survey-translate tag="div" class="section-visible-text" base="reportsWeb" :name="`SECTION_${n + 2}_VISIBLE`" />
        <b-collapse :id="`collapse-${n + 2}`">
            <template #footer="{ visible, toggle, id }">
                <div class="collapse-container">
                    <survey-translate
                        tag="div"
                        :class="`collapse-text ${visible ? 'visible' : ''}`"
                        base="reportsWeb"
                        :name="`SECTION_${n + 2}_READ_MORE`"
                    />
                </div>
                <survey-button id="read-more" :aria-expanded="visible" :aria-controls="id" @click="toggle">
                    <span
                        >Read {{ visible ? 'less' : 'more' }} <survey-icon v-if="!visible" icon="chevron-down"/><survey-icon
                            v-if="visible"
                            icon="chevron-up"
                    /></span>
                </survey-button>
            </template>
        </b-collapse>
        <div class="colored-section">
            <survey-translate style="margin: 0 0 1rem 0;" tag="h3" base="reportsWeb" :name="`SECTION_${n + 2}_ESTABLISHED_TITLE`" />
            <survey-translate tag="div" base="reportsWeb" :name="`SECTION_${n + 2}_ESTABLISHED_DESC`" raw />
        </div>
    </page>
    <page v-for="dimension in chartOrder.slice(-2)" :key="dimension" class="dimensions-section">
        <survey-translate tag="h2" base="reportsWeb" :name="dimension.toUpperCase() + '_SECTION_TITLE'" />
        <table>
            <tr>
                <td>
                    <survey-translate tag="div" base="reportsWeb" :name="dimension.toUpperCase() + '_SECTION_DESC'" raw />
                </td>
                <td>
                    <div class="radar-overlay">
                        <team-radar
                            :scores="radarChartScores"
                            :options="questionOptions"
                            :chart-label-style="chartLabelStyle"
                            :compare-to="compareTo"
                            :compare-scores="compareScoresRadarChart"
                            @compare="compareQuestions"
                            :labels="translatedLabels"
                            :name="dimension"
                            :min-value="0"
                            :max-value="100"
                            :max-ticks-limit="20"
                            show-ticks
                            :option-default="'All participants'"
                        />
                    </div>
                </td>
            </tr>
        </table>

        <survey-translate tag="div" base="reportsWeb" :name="dimension.toUpperCase() + '_SECTION_EXPLAINER'" raw />
        <team-sunburst-chart
            v-if="dimension === 'context'"
            :chartNumber="3"
            :reportData="emotionThreeData"
            :chart-title="$t('reportsWeb.EMOTION_CHART_TITLE_3')"
            :max-depth="maxDepth"
            :step-size="stepSize"
        />
        <team-compare-question
            :categoryKey="dimension.toUpperCase()"
            :context="reportData.context"
            :scores="getDimensionChartData(dimension)"
            :type="type"
            :options="questionOptions"
            :option-default="'All participants'"
            :compare-to="compareTo"
            :quarters="dimensionDeviationLabels[dimension.toLowerCase()]"
            :compare-scores="compareScores"
            @compare="compareQuestions"
            :loading="comparing"
            :demographic-table="reportData.table"
            show-deviation
            show-deviation-explainer
            class="rjc-deviation-graph"
        />
        <div class="colored-section">
            <survey-translate tag="h3" base="reportsWeb" :name="dimension.toUpperCase() + '_ESTABLISHED_TITLE'" />
            <survey-translate tag="div" base="reportsWeb" :name="dimension.toUpperCase() + '_ESTABLISHED_DESC'" raw />
        </div>
    </page>
    <page class="dimensions-section">
        <survey-translate tag="h2" base="reportsWeb" name="CONCLUSION_TITLE" />
        <survey-translate tag="div" base="reportsWeb" name="CONCLUSION_DESC" raw />
        <survey-translate tag="h2" base="reportsWeb" name="NEXT_STEPS_TITLE" />
        <survey-translate tag="div" base="reportsWeb" name="NEXT_STEPS_DESC" raw />
    </page>
</template>
<script src="./RjcTeam.ts"></script>
<style lang="scss">
@import '@/variables';

.deviation-wrapper {
    justify-content: center;

    .quarters span {
        overflow-wrap: break-word;
        width: 10rem;
        margin-left: -5rem;
    }

    .quarters span::before {
        left: 5rem;
    }
}

.sunburst {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1em;

    .img-filler {
        height: 380px;
    }

    img {
        height: 100px;
        width: 100px;
    }

    .chart-wrapper {
        max-width: 45%;
    }
}

.radar-overlay {
    border: #1a897e 1px solid;
    border-radius: 15px;
    position: relative;
    min-height: 10rem;
    overflow: hidden;
    z-index: 0;
    margin: 0 8rem;

    > div.chart-container {
        position: relative;
        height: 100%;
        min-width: 440px;

        &#process {
            top: -8rem;
            left: -8rem;
        }

        &#outcomes {
            top: -12rem;
            left: -14.5rem;
        }

        &#lenience {
            top: -17rem;
            left: -8rem;
        }

        &#context {
            top: -12rem;
            left: -1.5rem;
        }

        canvas {
            display: block;
            position: absolute;
            box-sizing: border-box;
            height: 400px !important;
            width: 400px !important;
        }
    }
}

.intro-gradient {
    display: flex;
    padding-top: 1rem !important;

    .page {
        display: block;
        position: relative;
    }

    img {
        padding: 2em;
        width: 480px;
        height: 480px;
    }

    > div {
        width: 100%;
    }
}

.section-visible-text {
    margin-bottom: 0;
}

.dimensions-section {
    + .dimensions-section {
        border-top: #1a897e 1px solid;
        padding-top: 0;
        margin-top: 2rem;
    }

    table {
        width: 100%;
        table-layout: fixed;
        font-size: 16px;

        tr {
            td {
                word-wrap: break-word;
                display: table-cell;
                width: 50%;
                vertical-align: top;
                padding: 0;
            }
        }
    }
}

#read-more {
    padding: 0;
    line-height: 27px;
    color: #1a897e;
    font-weight: 600;
    background-color: inherit;

    .survey-icon .rotated {
        transform: rotate(180deg);
        transition: all 300ms ease-in-out;
    }

    .survey-icon {
        transition: all 300ms ease-in-out;
    }
}

.colored-section {
    background-color: rgba(26, 137, 126, 0.1);
    padding-top: 1rem !important;
    padding: 1rem;
    margin-top: 2rem;

    + .colored-section {
        padding-top: 0 !important;
        margin-top: 0;
    }

    .limited-border {
        width: 100%;
        position: relative;
        border-top: #1a897e 1px solid;
    }
}

.collapse-container {
    overflow: hidden;
}

.collapse-text {
    margin-top: -300%;
    transition: all 600ms ease-in-out;
}

.collapse-text.visible {
    transition-timing-function: ease-out;
    transition: 300ms;
    margin-top: 0;
}

.rjc-deviation-graph {
    border: #1a897e 1px solid;
    border-radius: 1em;
    padding: 1rem;
    margin-top: 1rem;

    .survey-content-wrapper {
        margin: 0;
        border-radius: 1em;
    }

    .deviation-wrapper {
        border-bottom: #1a897e 1px solid;
    }

    .accordion-list {
        .accordion-item {
            border: none !important;
            background-color: white !important;

            > .accordion-item__summary {
                justify-content: flex-start;
            }
        }

        .accordion-item--open {
            border: none !important;
            background-color: white !important;

            > .accordion-item__summary {
                justify-content: flex-start;
                border: none !important;
                background-color: white !important;
            }
        }
    }
}

body.rendering #main-layout-content:has(> div.intro-gradient) {
    background-image: linear-gradient(rgba(26, 137, 126, 0.2), rgba(26, 137, 126, 0)) !important;
    background-position: top 100px right 0 !important;
    background-size: 600px 600px !important;
    background-repeat: repeat-x !important;

    @media screen and (max-width: 768px) {
        background-position: top 150px right 0 !important;
    }

    h2,
    h3,
    h4,
    h5 {
        color: #1a897e;
    }
}

#main-layout-content:has(> div.intro-gradient) {
    background-image: linear-gradient(rgba(26, 137, 126, 0.2), rgba(26, 137, 126, 0)) !important;
    background-position: top 130px right 0 !important;
    background-size: 600px 600px !important;
    background-repeat: repeat-x !important;

    @media screen and (max-width: 768px) {
        background-position: top 150px right 0 !important;
    }

    h2,
    h3,
    h4,
    h5 {
        color: #1a897e;
    }
}


</style>
