import ContentWrapperVue from '@/generic/components/ContentWrapper.vue';
import { defineComponent } from '@vue/runtime-core';
import BarChartVue from '@/report/components/charts/BarChart.vue';
import BarChartWrapperVue from '@/report/components/charts/BarChartWrapper.vue';
import PercentageDistributionChartVue from '../charts/PercentageDistributionChart.vue';
import { ComputedRef, PropType, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import DemographicDataTable from '@/report/models/IDemographicDataTable';
import BarChartScore from '@/report/models/Charts/BarChartScore';

export default defineComponent({
    components: {
        ContentWrapper: ContentWrapperVue,
        BarChartWrapper: BarChartWrapperVue,
        BarChart: BarChartVue,
        PercentageDistributionChart: PercentageDistributionChartVue,
    },
    props: {
        title: { type: Object },
        content: { type: Object },
        categoryKey: { type: String },
        context: { type: String },
        scores: { type: Object, default: () => new Object() },
        secondaryScores: { type: Object, default: () => new Object() },
        type: { type: String },
        options: { type: Array },
        optionDefault: { type: String, default: 'Select an option' },
        compareTo: { type: String },
        compareScores: { type: Object },
        demographicTable: { type: Object as PropType<DemographicDataTable> },
        loading: { type: Boolean },
        explain: { type: Boolean },
        showQuartiles: { type: Boolean },
        verticalDistribution: { type: Boolean },
        showDeviation: { type: Boolean },
        showDeviationExplainer: { type: Boolean, default: () => false },
        distributions: { type: Object },
        quarters: { type: Array, default: () => [0, 25, 50, 75, 100] },
        quarterMiddle: { type: String },
        colors: { type: Array, default: () => ['light-green', 'green', 'blue', 'dark-blue', 'light-blue', 'green-blue', 'orange'] },
        distributionChartOptions: { type: Object },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const state = ref<Record<string, boolean>>({});
        const sortOrder: ComputedRef<string[]> = computed(() => {
            return props.demographicTable.entries
                .map((x) => {
                    const xTitle = x.title.split(':');
                    if (xTitle.length > 1) {
                        return xTitle[0] === props.compareTo ? xTitle[1] : undefined;
                    }
                })
                .filter((value) => value !== undefined);
        });

        const onChange = (event) => {
            const value = event.target.value;
            emit('compare', value);
        };

        const barColor = (index: number) => {
            return props.colors[index % props.colors.length];
        };

        const getQuarters = (quarter, quarters) => {
            if (quarter === 0 || quarter === quarters - 1) {
                return quarters > 1 ? `left: ${(quarter * 100) / (quarters - 1)}%;` : ``;
            }
            return quarters > 1 ? `left: ${(quarter * 100) / (quarters - 1)}%;` : ``;
        };

        const getQuarterPosition = (quarter, quarters) => {
            if (quarter === 0) {
                return 'first';
            } else if (quarter === quarters - 1) {
                return 'last';
            }
            return '';
        };

        const teamQuartileTranslation = computed(() => {
            const quartile = props.scores.teamQuartile ? props.scores.teamQuartile.toUpperCase() : null;
            const key = `${props.type}.${props.categoryKey}_2_QUARTILE_${quartile}`;
            if (!quartile) {
                console.warn('Quartile is invalid: ' + key);
                return key;
            }

            return t(key, { context: props.context });
        });

        const teamDeviationTranslation = computed(() => {
            const dev = props.scores.standardDeviation ? props.scores.standardDeviation.toUpperCase() : null;
            const key = `${props.type}.${props.categoryKey}_4_${dev}`;
            if (!dev) {
                console.warn('Deviation is invalid: ' + key);
                return key;
            }

            return t(key, { context: props.context });
        });

        const sortedCompareScores = computed(() => {
            const scores = props.compareScores;
            return scores.sort(
                (a, b) =>
                    (sortOrder.value.indexOf(a.compareAnswer) + 1 || Number.MAX_VALUE) -
                    (sortOrder.value.indexOf(b.compareAnswer) + 1 || Number.MAX_VALUE),
            );
        });

        return {
            onChange,
            barColor,
            getQuarters,
            getQuarterPosition,
            state,
            teamQuartileTranslation,
            teamDeviationTranslation,
            sortedCompareScores,
        };
    },
});
