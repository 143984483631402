<template>
    <survey-loader v-if="!reportLoaded" />
    <report v-else id="novo-report" :key="loadedLanguage">
        <page>
            <content-wrapper>
                <survey-translate :base="type" name="INTRO_START_TEAM" :args="{ context }" raw />
                <survey-translate tag="small" base="reportsWeb" name="PARTICIPANTS_COMPLETED_VS_INVITED" :args="{ invitedParticipants, completedParticipants }" raw />
                <survey-title v-t="`${type}.INTRO_TITLE`" />
                <survey-translate tag="p" :base="type" name="INTRO_TEASER" />
            </content-wrapper>
            <bar-chart-wrapper class="fearless-graph" :type="type" :show-quartiles="false">
                <bar-chart stacked :scores="scores(report.dateReport, NovoCategoryType.General)" color="primary" class="mb-4" />
            </bar-chart-wrapper>
        </page>

        <template v-for="key in categories" :key="`cat_${key}`">
            <page page-break>
                <survey-subtitle v-t="{ path: `${type}.${key}_ROLE_TITLE`, args: { context } }" />
                <bar-chart-wrapper class="fearless-graph" :type="type" :show-quartiles="false">
                    <bar-chart stacked :scores="scores(report.dateReport, key)" label="Average" color="primary" class="mb-4" />

                    <template v-for="(questionScore, index) in questionScores(key)" :key="`question_score_${key}_${index}`">
                        <bar-chart stacked :scores="questionScore" :label="questionScore.question" :color="barColor(index)" />
                    </template>
                </bar-chart-wrapper>
            </page>
        </template>
    </report>
</template>
<script src="./NovoTeamReport.ts"></script>
