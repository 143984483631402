<template>
    <div ref="containerRef" class="ievp-chart-container"></div>
</template>
<script src="./IevpPersonalScore.ts"></script>
<style lang="scss">
@import '@/variables';

.ievp-chart-container {
    padding: 2rem 0.5rem;
    margin: 0 -2rem 2rem -2rem;

    @media screen and (min-width: 768px) {
        padding: 2rem 1rem 0;
        margin: 0 0 1rem 0;
        border: none;
    }
}
</style>
