<template>
    <div id="bound-context">
        <survey-title v-t="{ path: 'generic.TITLE', args: { name } }" />
        <survey-lead v-t="'generic.CUSTOM_CONTEXT_DESC'" />
        <survey-lead v-t="`generic.CUSTOM_CONTEXT_LABEL`" />
        <input class="survey-input" name="context" type="text" :placeholder="inputPlaceholder" v-model="surveyContext" @change="onChange" />
        <survey-button :disabled="!surveyContext" @click="startSurvey" v-t="'generic.TEST_START'" />
    </div>
</template>
<script src="./BoundContext.ts"></script>
<style lang="scss">
#bound-context {
    position: relative;
    .survey-input {
        margin-bottom: 1em;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.5rem;
        color: #1a1d20;
    }
}
</style>
