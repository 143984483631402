<template>
    <div class="survey-textarea">
        <textarea
            v-model="answer"
            @change="answerChanged"
            @input="onInput"
            :placeholder="placeholder"
            rows="3"
            max-rows="6"
        />
    </div>
</template>
<script src="./demographic-open-question.ts"></script>
<style lang="scss">
@import '@/variables';

.survey-textarea {
    textarea {
        display: block;
        width: 100%;
        border: 1px $accent-color solid;
        border-radius: $border-radius;
        padding: 1rem;
        font-size: $font-size-text;
    }
}
</style>
