import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
    name: 'open-question',
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    setup(props, context) {
        const answer = ref('');
        const placeholder = ref('');
        const updateTimeoutTime = 500;
        let updateTimeout = null;

        const answerChanged = () => {
            context.emit('selected', { value: answer.value, label: props.question.label });
        };

        const onInput = () => {
            const answer = props.question.answer;
            answer.value = answer.value;

            clearTimeout(updateTimeout);
            updateTimeout = setTimeout(answerChanged, updateTimeoutTime);
        };

        onMounted(async () => {
            answer.value = props.question.answer.value;
        });

        return {
            placeholder,
            answer,
            answerChanged,
            onInput,
        };
    },
});
