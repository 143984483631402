import { PropType, defineComponent, onMounted, ref, computed, ComputedRef } from 'vue';
import BaseReportComponent from '@/report/components/BaseReportComponent';
import { PitPersonalReportData } from '../models/PitReportData';
import { ConstructTranslations, Constructs } from '../models/Constructs';
import { useI18n } from 'vue-i18n';
import PitGridItemVue from './PitGridItem.vue';

export default defineComponent({
    extends: BaseReportComponent,
    props: {
        participants: { type: Array as PropType<PitPersonalReportData[]> },
        visibleConstructs: { type: Array as PropType<string[]>, default: () => [] },
        disableComparer: { type: Boolean, default: false },
    },
    components: {
        PitGridItem: PitGridItemVue,
    },
    setup(props) {
        const { t } = useI18n();
        const gridSize = 8;
        const colHeaders = [1, 2, 3, 4, 5, 6, 7];
        const rowHeaders = [8, 16, 24, 32, 40, 48, 56];
        const options: ComputedRef<{ option: string; label: string }[]> = computed(() => {
            const opts = [];
            if (props.participants.length >= 1) {
                opts.push(
                    ...Object.entries(props.participants[0])
                        .filter((entry) => Object.keys(Constructs).includes(entry[0]))
                        .filter((construct) => (props.visibleConstructs.length > 0 ? props.visibleConstructs.includes(construct[0]) : true))
                        .map((construct) => {
                            return { option: construct[0], label: `reportsWeb.${ConstructTranslations[construct.at(0)]}` };
                        }),
                );
            }

            return opts;
        });

        const gridItems = computed(() => makeRows(gridSize, gridSize));
        const xAxisSelection = ref(options.value[0]?.option);
        const yAxisSelection = ref(options.value[1]?.option ?? options.value[0]?.option);
        const xAxisLabel = computed(() =>
            props.disableComparer
                ? t(`reportsWeb.${ConstructTranslations[props.visibleConstructs.at(0)]}`)
                : t(options.value.find((x) => x.option == xAxisSelection.value).label),
        );
        const yAxisLabel = computed(() =>
            props.disableComparer
                ? t(`reportsWeb.${ConstructTranslations[props.visibleConstructs.at(1)]}`)
                : t(options.value.find((x) => x.option == yAxisSelection.value).label),
        );

        //returns list of objects with index in grid and value to print
        const gridValues = computed(() => {
            return props.participants.map((participant: PitPersonalReportData) => {
                const col = participant[xAxisSelection.value];
                const row = participant[yAxisSelection.value];
                let location = -1;
                if (col < colHeaders.length && row < rowHeaders.length) {
                    location = colHeaders[col] + rowHeaders[row];
                }
                return {
                    name: participant.participantName.split(' ').reduce((res, word) => (res += word.slice(0, 1)), ''),
                    location,
                    fullName: participant.participantName,
                };
            });
        });

        const makeRows = (rows, cols) => {
            const participants = gridValues.value;
            const grid = [];
            for (let c = 0; c < rows * cols; c++) {
                if (colHeaders.includes(c)) {
                    grid.push({ text: `${colHeaders.indexOf(c)}` });
                } else if (rowHeaders.includes(c)) {
                    grid.push({ text: `${rowHeaders.indexOf(c)}` });
                } else if (participants.find((x) => x.location === c)) {
                    const toPrint = participants.filter((x) => x.location === c);
                    if (toPrint.length > 3) {
                        grid.push({ text: `${toPrint.length}`, participants: toPrint.map((x) => x.fullName).join(', ') });
                    } else {
                        grid.push({ text: toPrint.map((x) => x.name).join(', '), participants: toPrint.map((x) => x.fullName).join(', ') });
                    }
                } else {
                    grid.push({});
                }
            }
            return grid;
        };

        const onChangeX = (e) => {
            xAxisSelection.value = e.target.value;
        };

        const onChangeY = (e) => {
            yAxisSelection.value = e.target.value;
        };

        onMounted(async () => {
            const container = document.getElementById('grid-container');
            container.style.setProperty('--grid-rows', gridSize.toString());
            container.style.setProperty('--grid-cols', gridSize.toString());
        });

        return {
            options,
            xAxisSelection,
            yAxisSelection,
            xAxisLabel,
            yAxisLabel,
            gridItems,
            onChangeX,
            onChangeY,
        };
    },
});
