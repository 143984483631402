<template>
    <survey-translate v-if="showEmojis" base="reportsWeb" tag="div" class="barchart-emoji" :name="`${categoryKey}_EMOJI_MIN`" />
    <div class="bar-wrapper survey-bar-wrapper">
        <div class="iteration-picker-wrapper">
            <slot name="iteration-pickers" v-if="!hideIterationPicker">
                <iteration-picker :type="type" :disabled="disableIterationPicker" class="float-left" primary />
                <iteration-picker :type="type" :disabled="disableIterationPicker" class="float-left" />
                <div class="clear"></div>
            </slot>
            <div v-if="removeCompletionDate">
                <div class="clear"></div>
            </div>
            <div v-else>
                <survey-translate base="generic" name="YOUR_COMPLETION_DATE" />
                {{ $filters.date(completionDate) }}
            </div>
        </div>

        <div class="canvas">
            <div>
                <slot />
            </div>
            <div v-if="benchmarkScores">
                <div
                    class="benchmark-positive-score benchmark-score"
                    :style="`${benchmarkPositivePosition};`"
                    v-tooltip:[colorClass]="tooltipPosBenchmark"
                ></div>
                <div
                    class="benchmark-negative-score benchmark-score"
                    :style="`${benchmarkNegativePosition};`"
                    v-tooltip:[colorClass]="tooltipNegBenchmark"
                ></div>
            </div>
            <div v-if="showQuartiles">
                <div class="quartiles" v-for="quartile in quartiles" v-bind:key="quartile">
                    <span
                        :class="`quartile quartile-${quartile}`"
                        :style="`${getQuartile(quartile)}`"
                        v-tooltip:[colorClass]="quartileTooltips[quartile - 1]"
                    ></span>
                </div>
            </div>
        </div>
        <div class="quarters">
            <span v-for="(quarter, index) in quarters" :style="`${getQuarters(index, quarters.length)}`" :key="quarter">{{ quarter }}</span>
        </div>
    </div>
    <survey-translate v-if="showEmojis" base="reportsWeb" tag="div" class="barchart-emoji" :name="`${categoryKey}_EMOJI_MAX`" />
</template>
<script src="./BarChartWrapper.ts"></script>
<style lang="scss">
.bar-wrapper {
    page-break-inside: avoid;
    position: relative;
    margin: 0 1rem;
    margin-bottom: 5rem;

    .canvas {
        position: relative;
        min-height: 75px;
        padding: 1rem 0;
    }

    .quartiles {
        height: 5px;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;

        .quartile {
            height: 1rem;
            position: absolute;
            top: 0;
            border-left: 2px transparent solid;
        }
    }

    .quarters {
        position: absolute;
        left: 0;
        width: 100%;
        height: 2rem;
        border-top: 1px transparent solid;
        z-index: 0;

        span {
            display: inline-block;
            position: absolute;
            top: 0;
            width: 3rem;
            text-align: center;
            padding-top: 1rem;
            margin-left: -1.5rem;

            &::before {
                content: '';
                position: absolute;
                border-left: 2px transparent solid;
                height: 0.75rem;
                top: 0;
                left: 1.5rem;
            }
        }

        span:first-child::before,
        span:last-child::before {
            top: -0.75rem;
            height: 1.5rem;
        }
    }

    .benchmark-score {
        background-color: grey;
        position: absolute;
        top: 75%;
        height: 0.25rem;
    }

    .benchmark-positive-score {
        border-radius: 0 0.5rem 0.5rem 0;
    }

    .benchmark-negative-score {
        border-radius: 0.5rem 0 0 0.5rem;
    }
}

body[direction='rtl'] {
    .bar-wrapper {
        .quartiles {
            .quartile-1 {
                border-right: 0;
            }

            .quartile-4 {
                border-right: 2px transparent solid;
            }
        }

        .quarters {
            span {
                margin-left: 0;
                margin-right: -1.5rem;
                direction: rtl;
            }

            @for $i from 0 through 4 {
                span:nth-child(#{$i + 1}) {
                    right: #{calc($i * 100 / 4) + '%'};
                }
            }
        }
    }
}
</style>
