import { defineComponent, onMounted, onUnmounted, PropType, Ref, ref } from 'vue';
import Chart from 'chart.js';
import IevpScore from '../models/IevpScore';
import { IevpReportSectionCategory } from '../models/IevpReportData';
import { useI18n } from 'vue-i18n';
import IevpTeamScore from '../models/IevpTeamScore';

export default defineComponent({
    props: {
        isTeam: { type: Boolean, default: false },
        scores: { type: Object as PropType<IevpScore> },
        personalTeamScores: { type: Object as PropType<IevpTeamScore> },
        organizationTeamScores: { type: Object as PropType<IevpTeamScore> },
    },
    setup(props) {
        const { t } = useI18n();
        const containerRef: Ref<HTMLElement> = ref();
        const normalizeScores = (scores: number[]) => {
            const minimum = -1.5;
            return scores.map((score: number) => Math.round((score + Math.abs(minimum)) * 1000) / 1000);
        }

        const drawChart = () => {
            const canvas = document.createElement('canvas');
            canvas.setAttribute('width', '150');
            canvas.setAttribute('height', '150');
            containerRef.value.innerHTML = '';
            containerRef.value.appendChild(canvas);
            const ctx = canvas.getContext('2d');
            const translatedLabels = [
                t(`reportsWeb.LABEL_MATERIAL`),
                t(`reportsWeb.LABEL_GROWTH`),
                t(`reportsWeb.LABEL_CONNECTION`),
                t(`reportsWeb.LABEL_PURPOSE`),
            ];

            const labels = translatedLabels.map((t) => t.split('&').join('&\n').split('\n'));
            const maxValue = 3;
            const minValue = 0;
            const maxTicksLimit = 4;

            let personalScores: number[] = [];
            let organizationScores: number[] = [];

            if (props.isTeam) {
                personalScores = normalizeScores(props.personalTeamScores.categoryScores());
                organizationScores = normalizeScores(props.organizationTeamScores.categoryScores());
            } else {
                personalScores = normalizeScores(props.scores.categoryScores(IevpReportSectionCategory.Personal));
                organizationScores = normalizeScores(props.scores.categoryScores(IevpReportSectionCategory.Organization));
            }

            const youColor = '#FFB967';
            const organizationColor = '#0F84D3';

            new Chart(ctx, {
                type: 'radar',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: t(`reportsWeb.LABEL_YOUR_VALUE`),
                            data: personalScores,
                            fill: true,
                            backgroundColor: `${youColor}80`,
                            borderColor: `${youColor}`,
                            borderWidth: 1,
                        },
                        {
                            label: t(`reportsWeb.LABEL_ORGANIZATION_VALUE`),
                            data: organizationScores,
                            backgroundColor: `${organizationColor}80`,
                            borderColor: `${organizationColor}`,
                            fill: true,
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    events: [], // disable hover
                    legend: {
                        labels: {
                            usePointStyle: true,
                        },
                    },
                    scale: {
                        display: true,
                        ticks: {
                            display: false, // show label or not
                            max: maxValue,
                            min: minValue,
                            maxTicksLimit,
                            lineWidth: 1,
                        },
                        angleLines: {
                            lineWidth: 0,
                        },
                        gridLines: {
                            color: '#dee2e6',
                            lineWidth: 0.5,
                        },
                        pointLabels: {
                            fontSize: 16,
                        },
                    },
                    plugins: {
                        datalabels: false,
                    }
                },
            });
        };

        onMounted(() => {
            drawChart();
            window.addEventListener('resize', drawChart);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', drawChart);
        });

        return { containerRef };
    }
});
