import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["skeleton-wrapper", { 'inline': _ctx.inline }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.amount, (i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "skeleton",
        style: _normalizeStyle(`width: ${_ctx.width()}`)
      }, null, 4))
    }), 128))
  ], 2))
}