<template>
    <div class="pit-explainer-wrapper">
        <p class="pit-header"
           :style="{ 'background-color': color }"
           v-t="'reportsWeb.' + translateKey + '_TITLE'"></p>
        <p class="pit-scores"><span v-t="'reportsWeb.' + translateKey + '_SUBCONSTRUCT_A'"></span>: {{ scoreA }}</p>
        <p class="pit-scores"><span v-t="'reportsWeb.' + translateKey + '_SUBCONSTRUCT_B'"></span>: {{ scoreB }}</p>
        <survey-translate tag="p" class="pit-explainer" base="reportsWeb" :name="translateKey + '_' + explainer" :args="{ participantName }" raw />
    </div>
</template>
<script src="./PitExplainer.ts"></script>
<style lang="scss">
.pit-header {
    width: 100%;
    font-weight: bold;
    font-size: medium;
    padding: 5px;
    color: white;
    margin-bottom: 10px;
}

.pit-scores {
    font-style: italic;
    font-size: medium;
    font-weight: 500;
    margin: 0;
}

.pit-explainer {
    margin-top: 10px;
    width: 100%;
}

.pit-explainer-wrapper {
    margin: 1rem 0;

    > p {
        margin: 0;

        &.pit-explainer,
        &.pit-header {
            margin: 10px 0;
        }
    }
}
</style>
