import AuthHelper, { TokenType } from '@/generic/helpers/AuthHelper';
import { ReportService } from '@/generic/services/reportService';
import { computed } from '@vue/reactivity';
import to from 'await-to-js';
import { defineComponent, onMounted, ref, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ReportListItem from '../models/ReportList/ReportListItem';
import ReportListResponse from '../models/ReportList/ReportListResponse';
import store from '@/store';
import { getPreferredLanguageAndClear } from '@/i18n';
import Language from '@/store/models/Language';

export default defineComponent({
    setup() {
        const route = useRoute();
        const { query } = route;
        const router = useRouter();
        const token = query.token.toString();
        const reportsResponse: Ref<ReportListResponse> = ref(new ReportListResponse());
        const error = ref(false);
        const loading = ref(false);
        const reports = computed(() => reportsResponse.value.reports.filter((item: ReportListItem) => !!item.surveyId));

        const loadReports = async () => {
            loading.value = true;
            const prefLanguageCode = getPreferredLanguageAndClear(router, route);
            const [err, list] = await to(new ReportService().getReports(prefLanguageCode));
            error.value = !!err;

            if (!err) {
                reportsResponse.value = list;

                store.commit('SET_PILLAR_THRESHOLDS', list.pillarThresholds);
                store.commit('SET_LANGUAGES', list.languages);
                await store.dispatch(
                    'setLanguage',
                    new Language({
                        languageCode: list.languageCode,
                        knowledgeModelAlias: list.knowledgeModelAlias,
                        knowledgeModelName: list.knowledgeModelName,
                        section: 'reports',
                    }),
                );
            }
            loading.value = false;
        };

        const openReport = (item: ReportListItem) => {
            router.push({
                name: 'personal-report-view',
                params: {
                    surveyId: item.surveyId.toString(),
                    reportAlias: item.alias.toString(),
                },
            });
        };

        onMounted(async () => {
            AuthHelper.setToken(TokenType.SurveyToken, token);
            await loadReports();

            if (reportsResponse.value.reports.length === 1) {
                openReport(reportsResponse.value.reports[0]);
            }
        });

        return { error, loading, reportsResponse, reports, openReport };
    },
});
