<template>
    <survey-loader v-if="!loaded" />
    <report v-else id="baseline-report">
        <!-- Cover -->
        <page>
            <div id="baseline-report-cover">
                <img id="baseline-report-cover-img" :src="$t('reportsWeb.GENERAL_IMAGE')" />

                <survey-title v-t="'reportsWeb.FIRST_PAGE_TITLE'" />
                <survey-translate tag="p" base="reportsWeb" name="FIRST_PAGE_NAME" :args="{ firstName: user, context }" raw />
                <survey-translate
                    tag="p"
                    base="reportsWeb"
                    name="FIRST_PAGE_DATE"
                    :args="{ dateTime: $filters.date(reportData.dateCompleted) }"
                    raw
                />
            </div>
        </page>

        <page>
            <survey-title v-t="{ path: 'reportsWeb.INTRO_TITLE' }" />
            <content-wrapper full>
                <survey-translate tag="p" base="reportsWeb" name="INTRO_INTRO" />
            </content-wrapper>

            <table id="general-table">
                <tr>
                    <td>
                        <survey-translate tag="h3" base="reportsWeb" name="GENERAL_ACTION_MODE" />
                        <survey-translate tag="p" base="reportsWeb" name="GENERAL_ACTION_MODE_DESCRIPTION" class="text-muted" />
                    </td>
                    <td><img :src="$t('reportsWeb.GENERAL_ACTION_MODE_M_IMAGE')" alt="Move icon" /></td>
                    <td><img :src="$t('reportsWeb.GENERAL_ACTION_MODE_F_IMAGE')" alt="Follow icon" /></td>
                    <td><img :src="$t('reportsWeb.GENERAL_ACTION_MODE_O_IMAGE')" alt="Oppose icon" /></td>
                    <td><img :src="$t('reportsWeb.GENERAL_ACTION_MODE_B_IMAGE')" alt="Bystand icon" /></td>
                </tr>
                <tr>
                    <td>
                        <survey-translate tag="h3" base="reportsWeb" name="GENERAL_OPERATING_SYSTEM" />
                        <survey-translate tag="p" base="reportsWeb" name="GENERAL_OPERATING_SYSTEM_DESCRIPTION" class="text-muted" />
                    </td>
                    <td><img :src="$t('reportsWeb.GENERAL_OPERATING_SYSTEM_C_IMAGE')" alt="Closed icon" /></td>
                    <td><img :src="$t('reportsWeb.GENERAL_OPERATING_SYSTEM_O_IMAGE')" alt="Open icon" /></td>
                    <td><img :src="$t('reportsWeb.GENERAL_OPERATING_SYSTEM_R_IMAGE')" alt="Random icon" /></td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <survey-translate tag="h3" base="reportsWeb" name="GENERAL_COM_DOMAIN" />
                        <survey-translate tag="p" base="reportsWeb" name="GENERAL_COM_DOMAIN_DESCRIPTION" class="text-muted" />
                    </td>
                    <td><img :src="$t('reportsWeb.GENERAL_COM_DOMAIN_A_IMAGE')" alt="Affect icon" /></td>
                    <td><img :src="$t('reportsWeb.GENERAL_COM_DOMAIN_M_IMAGE')" alt="Meaning icon" /></td>
                    <td><img :src="$t('reportsWeb.GENERAL_COM_DOMAIN_P_IMAGE')" alt="Power icon" /></td>
                    <td></td>
                </tr>
            </table>

            <content-wrapper full>
                <survey-subtitle tag="h3" v-t="{ path: 'reportsWeb.INTRO_WHY_PROPENSITIES_MATTER' }" />
                <survey-translate tag="p" base="reportsWeb" name="INTRO_WHY_PROPENSITIES_MATTER_DESCRIPTION" raw />
            </content-wrapper>
        </page>

        <page>
            <content-wrapper full>
                <survey-subtitle>Action propensities</survey-subtitle>
                <survey-subtitle
                    tag="h3"
                    v-t="{ path: 'reportsWeb.ACTION_PROPENSITY_TITLE', args: { propensity: reportData.highestActionPropensity } }"
                />
                <survey-translate tag="p" base="reportsWeb" name="ACTION_PROPENSITY_INTRO" raw />
            </content-wrapper>

            <table id="action-table">
                <tr>
                    <td>
                        <img :src="$t('reportsWeb.GENERAL_ACTION_MODE_M_IMAGE')" alt="Move icon" />
                        <survey-translate tag="p" base="reportsWeb" name="GENERAL_ACTION_MODE_M_GUIDANCE" raw />
                    </td>
                    <td>
                        <img :src="$t('reportsWeb.GENERAL_ACTION_MODE_F_IMAGE')" alt="Move icon" />
                        <survey-translate tag="p" base="reportsWeb" name="GENERAL_ACTION_MODE_F_GUIDANCE" raw />
                    </td>
                </tr>
                <tr>
                    <td>
                        <img :src="$t('reportsWeb.GENERAL_ACTION_MODE_O_IMAGE')" alt="Move icon" />
                        <survey-translate tag="p" base="reportsWeb" name="GENERAL_ACTION_MODE_O_GUIDANCE" raw />
                    </td>
                    <td>
                        <img :src="$t('reportsWeb.GENERAL_ACTION_MODE_B_IMAGE')" alt="Move icon" />
                        <survey-translate tag="p" base="reportsWeb" name="GENERAL_ACTION_MODE_B_GUIDANCE" raw />
                    </td>
                </tr>
            </table>
        </page>

        <page>
            <content-wrapper full>
                <survey-subtitle>Action propensities</survey-subtitle>
                <survey-subtitle tag="h3" v-t="{ path: 'reportsWeb.GENERAL_CENTER_OF_GRAVITY' }" />
                <survey-translate tag="p" base="reportsWeb" name="ACTION_CENTER_OF_GRAVITY" raw />
            </content-wrapper>

            <baseline-bar
                :calculated="reportData.calculatedActionDistribution"
                :reported="reportData.selfReportedActionDistribution"
                :colors="actionColors"
            />

            <consider name="ACTION_TO_CONSIDER" />
        </page>

        <page>
            <content-wrapper full>
                <survey-subtitle>Operating propensities</survey-subtitle>
                <survey-subtitle
                    tag="h3"
                    v-t="{ path: 'reportsWeb.OPERATING_SYSTEM_PROPENSITY_TITLE', args: { propensity: reportData.highestOperatingSystemPropensity } }"
                />
                <survey-translate tag="p" base="reportsWeb" name="OPERATING_SYSTEM_PROPENSITY_INTRO" raw />
            </content-wrapper>

            <three-columns
                :columns="[
                    { image: 'GENERAL_OPERATING_SYSTEM_C_IMAGE', alt: 'Open icon', name: 'GENERAL_OPERATING_SYSTEM_C_GUIDANCE' },
                    { image: 'GENERAL_OPERATING_SYSTEM_O_IMAGE', alt: 'Random icon', name: 'GENERAL_OPERATING_SYSTEM_O_GUIDANCE' },
                    { image: 'GENERAL_OPERATING_SYSTEM_R_IMAGE', alt: 'Closed icon', name: 'GENERAL_OPERATING_SYSTEM_R_GUIDANCE' },
                ]"
            />
        </page>

        <page>
            <content-wrapper full>
                <survey-subtitle>Operating propensities</survey-subtitle>
                <survey-subtitle tag="h3" v-t="{ path: 'reportsWeb.GENERAL_CENTER_OF_GRAVITY' }" />
                <survey-translate tag="p" base="reportsWeb" name="OPERATING_SYSTEM_CENTER_OF_GRAVITY" raw />
            </content-wrapper>

            <baseline-bar
                :calculated="reportData.calculatedOperatingSystemDistribution"
                :reported="reportData.selfReportedOperatingSystemDistribution"
                :colors="osColours"
            />

            <consider name="OPERATING_SYSTEM_TO_CONSIDER" />
        </page>

        <page>
            <content-wrapper full>
                <survey-subtitle>Communication propensities</survey-subtitle>
                <survey-subtitle
                    tag="h3"
                    v-t="{ path: 'reportsWeb.COM_PROPENSITY_TITLE', args: { propensity: reportData.highestCommunicationPropensity } }"
                />
                <survey-translate tag="p" base="reportsWeb" name="COM_PROPENSITY_INTRO" raw />
            </content-wrapper>

            <three-columns
                :columns="[
                    { image: 'GENERAL_COM_DOMAIN_A_IMAGE', alt: 'Open icon', name: 'GENERAL_COM_DOMAIN_A_GUIDANCE' },
                    { image: 'GENERAL_COM_DOMAIN_M_IMAGE', alt: 'Random icon', name: 'GENERAL_COM_DOMAIN_M_GUIDANCE' },
                    { image: 'GENERAL_COM_DOMAIN_P_IMAGE', alt: 'Closed icon', name: 'GENERAL_COM_DOMAIN_P_GUIDANCE' },
                ]"
            />
        </page>

        <page>
            <content-wrapper full>
                <survey-subtitle>Communication propensities</survey-subtitle>
                <survey-subtitle tag="h3" v-t="{ path: 'reportsWeb.GENERAL_CENTER_OF_GRAVITY' }" />
                <survey-translate tag="p" base="reportsWeb" name="COM_CENTER_OF_GRAVITY" raw />
            </content-wrapper>

            <baseline-bar
                :calculated="reportData.calculatedCommunicationDistribution"
                :reported="reportData.selfReportedCommunicationDistribution"
                :colors="communicationColours"
            />

            <consider name="COM_TO_CONSIDER" />
        </page>

        <page>
            <survey-subtitle>Behavioral propensities - <i>baseline</i></survey-subtitle>

            <div id="baseline-profile">
                <survey-translate tag="span" base="reportsWeb" name="GENERAL_YOUR_PROFILE_IS" />
                <survey-translate
                    tag="h2"
                    base="reportsWeb"
                    name="GENERAL_PROFILE"
                    :args="{
                        action: reportData.highestActionPropensity,
                        operatingSystem: reportData.highestOperatingSystemPropensity,
                        communication: reportData.highestCommunicationPropensity,
                    }"
                    raw
                />
            </div>

            <content-wrapper full>
                <survey-subtitle tag="h3" v-t="{ path: 'reportsWeb.BEHAVIORAL_PROFILE' }" />
                <survey-translate tag="p" base="reportsWeb" name="BEHAVIORAL_PROFILE_INTRO" raw />
            </content-wrapper>

            <table id="behavioral-profile">
                <tr>
                    <td>
                        <h4 v-sanitize-html="$t('reportsWeb.TALENTS')"></h4>
                        <ul
                            v-sanitize-html="
                                $filters.decorateList(
                                    $t(
                                        'reportsWeb.' +
                                            reportData.highestActionPropensity.toUpperCase() +
                                            '_' +
                                            reportData.highestOperatingSystemPropensity.toUpperCase() +
                                            '_' +
                                            reportData.highestCommunicationPropensity.toUpperCase() +
                                            '_TALENTS',
                                    ),
                                )
                            "
                        ></ul>
                    </td>
                    <td>
                        <h4 v-sanitize-html="$t('reportsWeb.TRAPS')"></h4>
                        <ul
                            v-sanitize-html="
                                $filters.decorateList(
                                    $t(
                                        'reportsWeb.' +
                                            reportData.highestActionPropensity.toUpperCase() +
                                            '_' +
                                            reportData.highestOperatingSystemPropensity.toUpperCase() +
                                            '_' +
                                            reportData.highestCommunicationPropensity.toUpperCase() +
                                            '_TRAPS',
                                    ),
                                )
                            "
                        ></ul>
                    </td>
                    <td>
                        <h4 v-sanitize-html="$t('reportsWeb.TIPS')"></h4>
                        <ul
                            v-sanitize-html="
                                $filters.decorateList(
                                    $t(
                                        'reportsWeb.' +
                                            reportData.highestActionPropensity.toUpperCase() +
                                            '_' +
                                            reportData.highestOperatingSystemPropensity.toUpperCase() +
                                            '_' +
                                            reportData.highestCommunicationPropensity.toUpperCase() +
                                            '_TIPS',
                                    ),
                                )
                            "
                        ></ul>
                    </td>
                </tr>
            </table>
        </page>

        <page>
            <content-wrapper full>
                <survey-subtitle tag="h3" v-t="{ path: 'reportsWeb.GENERAL_GETTING_UNSTUCK' }" />
                <survey-translate tag="p" base="reportsWeb" name="GENERAL_GETTING_UNSTUCK_INTRO" raw />
            </content-wrapper>

            <div class="graph-wrapper">
                <div class="graph-wrapper-item">
                    <div class="legend text-center" v-t="'reportsWeb.GENERAL_ACTION_MODE'"></div>
                    <div class="graph vertical">
                        <div class="zones low" style="position: absolute; top: 0; left: 0; width: 90%; height: 100%; z-index: 0">
                            <div class="stuck-zone"><span v-t="'reportsWeb.STUCK_ZONE'"></span></div>
                            <div class="over-use-zone"><span v-t="'reportsWeb.OVER_USER_ZONE'"></span></div>
                            <div class="strong-zone"><span v-t="'reportsWeb.STRONG_ZONE'"></span></div>
                            <div class="weak-zone"><span v-t="'reportsWeb.WEAK_ZONE'"></span></div>

                            <div
                                v-for="(report, name, index) in reportData.calculatedActionDistribution"
                                :key="'action_stuck_' + index"
                                class="zone-item action"
                                :data-item-key="name"
                                :data-item-value="report"
                                :style="'height: ' + (report / 70) * 100 + '%'"
                            >
                                <label> {{ name }}:{{ report }} </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="graph-wrapper-item">
                    <div class="legend text-center" v-t="'reportsWeb.GENERAL_OPERATING_SYSTEM'"></div>
                    <div class="graph vertical">
                        <div class="zones high" style="position: absolute; top: 0; left: 0; width: 90%; height: 100%; z-index: 0">
                            <div class="stuck-zone"><span v-t="'reportsWeb.STUCK_ZONE'"></span></div>
                            <div class="over-use-zone"><span v-t="'reportsWeb.OVER_USER_ZONE'"></span></div>
                            <div class="strong-zone"><span v-t="'reportsWeb.STRONG_ZONE'"></span></div>
                            <div class="weak-zone"><span v-t="'reportsWeb.WEAK_ZONE'"></span></div>

                            <div
                                v-for="(report, name, index) in reportData.calculatedOperatingSystemDistribution"
                                :key="'os_stuck_' + index"
                                class="zone-item"
                                :data-item-key="name"
                                :data-item-value="report"
                                :style="'height: ' + (report / 70) * 100 + '%'"
                            >
                                <label> {{ name }}:{{ report }} </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="graph-wrapper-item">
                    <div class="legend text-center" v-t="'reportsWeb.GENERAL_COM_DOMAIN'"></div>
                    <div class="graph vertical">
                        <div class="zones high" style="position: absolute; top: 0; left: 0; width: 90%; height: 100%; z-index: 0">
                            <div class="stuck-zone"><span v-t="'reportsWeb.STUCK_ZONE'"></span></div>
                            <div class="over-use-zone"><span v-t="'reportsWeb.OVER_USER_ZONE'"></span></div>
                            <div class="strong-zone"><span v-t="'reportsWeb.STRONG_ZONE'"></span></div>
                            <div class="weak-zone"><span v-t="'reportsWeb.WEAK_ZONE'"></span></div>

                            <div
                                v-for="(report, name, index) in reportData.calculatedCommunicationDistribution"
                                :key="'com_stuck_' + index"
                                class="zone-item"
                                :data-item-key="name"
                                :data-item-value="report"
                                :style="'height: ' + (report / 70) * 100 + '%'"
                            >
                                <label> {{ name }}:{{ report }} </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <baseline-graphics />

            <content-wrapper full>
                <survey-translate tag="p" base="reportsWeb" name="GENERAL_GETTING_UNSTUCK_OUTRO" />
            </content-wrapper>
        </page>

        <page>
            <content-wrapper full>
                <survey-subtitle tag="h3" v-t="{ path: 'reportsWeb.GENERAL_PUBLICPRIVATE' }" />
                <survey-translate tag="p" base="reportsWeb" name="GENERAL_PUBLICPRIVATE_INTRO" raw />
            </content-wrapper>

            <div class="graph-wrapper">
                <div class="graph-wrapper-item">
                    <div class="legend text-center" v-t="'reportsWeb.GENERAL_ACTION_MODE'"></div>
                    <div class="graph vertical">
                        <canvas class="zones-canvas" style="height: 100%; width: 90%"></canvas>

                        <div class="zones" style="position: absolute; top: 0; left: 0; width: 90%; height: 100%; z-index: 0">
                            <div
                                v-for="(report, name, index) in reportData.actionPublicPrivateDistribution"
                                :key="'action_pub_' + index"
                                class="zone-item action public"
                                :style="'height: ' + (report.publicDistribution - 5 * (report.publicDistribution / 100)) + '%'"
                            >
                                <label> {{ name }}:{{ report.publicDistribution }} </label>
                            </div>

                            <div
                                v-for="(report, name, index) in reportData.actionPublicPrivateDistribution"
                                :key="'action_pub_' + index"
                                class="zone-item action private"
                                :style="'height: ' + (report.privateDistribution - 5 * (report.privateDistribution / 100)) + '%'"
                            >
                                <label> {{ name }}:{{ report.privateDistribution }} </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="graph-wrapper-item">
                    <div class="legend text-center" v-t="'reportsWeb.GENERAL_OPERATING_SYSTEM'"></div>
                    <div class="graph vertical">
                        <canvas class="zones-canvas" style="height: 100%; width: 90%"></canvas>
                        <div class="zones" style="position: absolute; top: 0; left: 0; width: 90%; height: 100%; z-index: 0">
                            <div
                                v-for="(report, name, index) in reportData.operatingSystemPublicPrivateDistribution"
                                :key="'action_pub_' + index"
                                class="zone-item public"
                                :style="'height: ' + (report.publicDistribution - 5 * (report.publicDistribution / 100)) + '%'"
                            >
                                <label> {{ name }}:{{ report.publicDistribution }} </label>
                            </div>

                            <div
                                v-for="(report, name, index) in reportData.operatingSystemPublicPrivateDistribution"
                                :key="'action_pub_' + index"
                                class="zone-item private"
                                :style="'height: ' + (report.privateDistribution - 5 * (report.privateDistribution / 100)) + '%'"
                            >
                                <label> {{ name }}:{{ report.privateDistribution }} </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="graph-wrapper-item">
                    <div class="legend text-center" v-t="'reportsWeb.GENERAL_COM_DOMAIN'"></div>

                    <div class="graph vertical">
                        <canvas class="zones-canvas" style="height: 100%; width: 90%"></canvas>
                        <div class="zones" style="position: absolute; top: 0; left: 0; width: 90%; height: 100%; z-index: 0">
                            <div
                                v-for="(report, name, index) in reportData.communicationPublicPrivateDistribution"
                                :key="'action_pub_' + index"
                                class="zone-item public"
                                :style="'height: ' + (report.publicDistribution - 5 * (report.publicDistribution / 100)) + '%'"
                            >
                                <label> {{ name }}:{{ report.publicDistribution }} </label>
                            </div>

                            <div
                                v-for="(report, name, index) in reportData.communicationPublicPrivateDistribution"
                                :key="'action_pub_' + index"
                                class="zone-item private"
                                :style="'height: ' + (report.privateDistribution - 5 * (report.privateDistribution / 100)) + '%'"
                            >
                                <label> {{ name }}:{{ report.privateDistribution }} </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <baseline-graphics />
        </page>

        <page>
            <consider name="PUBLICPRIVATE_TO_CONSIDER" />
        </page>

        <page>
            <content-wrapper full>
                <survey-subtitle tag="h3" v-t="{ path: 'reportsWeb.GENERAL_KANTOR_BEHAVIORAL_PROFILES' }" />
                <survey-translate tag="p" base="reportsWeb" name="GENERAL_KANTOR_BEHAVIORAL_PROFILES_TAGLINE" raw />
                <survey-translate tag="p" base="reportsWeb" name="GENERAL_KANTOR_BEHAVIORAL_PROFILES_INTRO" raw />
            </content-wrapper>
        </page>
    </report>
</template>
<script src="./Baseline.ts"></script>
<style lang="scss">
@import '@/variables';

$base-line-cell-padding: 0.75rem;

#baseline-report {
    &-cover {
        text-align: center;
        margin-bottom: 3rem;

        &-img {
            margin: 50px auto 160px;
        }

        .survey-title {
            margin: 0;
        }

        p {
            margin: 0 0 0.25rem 0;
        }
    }

    .graph.vertical {
        position: relative;
    }

    #general-table {
        border-collapse: collapse;
        margin-bottom: 1rem;

        tr {
            border-top: 1px $border-color solid;

            td {
                padding: $base-line-cell-padding;

                &:first-child {
                    text-align: right;
                    vertical-align: top;
                    width: 50%;

                    h3,
                    p {
                        margin: 0;
                    }
                }
            }

            img {
                margin: 0 auto;
                max-width: 100%;
            }
        }
    }

    #action-table {
        width: 100%;

        tr {
            td {
                width: 50%;
                text-align: center;
                padding: $base-line-cell-padding;

                img {
                    max-height: 15rem;
                }
            }
        }
    }

    #baseline-profile {
        text-align: center;
        padding: 2rem;

        span {
            font-size: 1rem;
            font-style: italic;
        }

        h2 {
            margin: 0;
            text-transform: uppercase;
            font-size: 1.75rem;
        }
    }

    #behavioral-profile {
        width: 100%;

        tr {
            td {
                width: 33%;
                text-align: left;
                vertical-align: top;
                padding-right: 1rem;

                h4 {
                    color: $dark-gray;
                    font-style: italic;

                    b {
                        color: $font-color;
                        text-transform: uppercase;
                    }
                }

                ul {
                    list-style: none;
                    padding-left: 0;

                    li.item {
                        line-height: 1.2;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }

    .graph-wrapper {
        .graph-wrapper-item {
            display: inline-block;
            width: 33%;
            position: relative;
        }
    }

    .graph {
        padding: 0;

        .graph-bar {
            background-color: #fa4605;
        }

        &.vertical {
            height: 500px;
            margin: 0 0 10px;
            position: relative;

            .bar-wrapper {
                position: absolute;
                bottom: 0;
                padding: 0 10px;
                left: 5%;
                width: 65%;
                height: 100%;
                display: table;

                .bar {
                    width: 20px;
                    position: relative;
                }

                .the-bar {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    background: #555;
                    padding-top: 10px;

                    span {
                        transform: rotate(90deg);
                        color: #fff;
                        font-size: 0.825rem;
                        display: block;
                    }
                }

                .double-bar {
                    width: 40px;
                    position: relative;
                    display: table-cell;
                    border-left: 3px solid #fff;
                    border-right: 3px solid #fff;

                    .the-bar {
                        width: 50%;
                    }

                    .the-bar + .the-bar {
                        left: 50%;
                        background-color: #888;
                    }
                }

                &.public-private {
                    width: 90%;
                }
            }

            .zone-item {
                bottom: 0;
                display: inline-block;
                background-color: rgba(97, 97, 97, 0.7);
                margin: 0 0.6rem;
                padding: 0 0.6rem;
                width: calc(33% - 1.2rem);
                align-self: flex-end !important;
                -webkit-box-align: end !important;

                &.public,
                &.private {
                    margin: 0 0.2rem;
                    width: calc(15% - 0.8rem);

                    > label {
                        text-align: right;
                        width: 80px;
                        position: absolute;
                        bottom: -20px;
                        transform: rotate(-55deg);
                        transform-origin: right;
                        font-size: 12px;
                        margin-left: -75px;
                    }
                }

                &.public {
                    background-color: rgba(97, 97, 97, 1);

                    &:nth-child(1) {
                        margin-left: 1rem;
                    }

                    &:nth-child(3) {
                        margin-right: 1rem;
                    }
                }

                &.action {
                    &.public,
                    &.private {
                        margin: 0 0.2rem;
                        width: calc(11% - 0.8rem);
                    }

                    &:nth-child(1) {
                        margin-left: 1rem;
                    }

                    &:nth-child(4) {
                        margin-right: 1rem;
                    }
                }

                > label {
                    position: absolute;
                    bottom: -40px;
                    transform: rotate(-55deg);
                    font-size: 12px;
                    margin-left: -20px;
                }
            }

            .zones {
                display: flex;
                position: relative;
                height: 425px !important;
                margin-left: 30px;

                $percentages: 0 10 20 30 40 50 60 70;
                $maxPercentage: 70;

                @each $percentage in $percentages {
                    .z#{$percentage} {
                        position: absolute;
                        bottom: calc(100% / #{$maxPercentage} * #{$percentage});
                        transform: translateY(50%) translateX(-35px);
                        font-size: 0.825rem;
                    }
                }

                .weak-zone,
                .strong-zone,
                .over-use-zone,
                .stuck-zone {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    border-right: 3px #aaa solid;

                    span {
                        position: absolute;
                        top: 3px;
                        right: 6px;
                        color: #555;
                        font-size: 0.825rem;
                        font-weight: bold;
                    }
                }

                .weak-zone {
                    background-color: rgba(157, 141, 126, 0.4);
                    border-color: rgba(157, 141, 126, 0.6);
                }

                .strong-zone {
                    background-color: rgba(118, 123, 93, 0.4);
                    border-color: rgba(118, 123, 93, 0.6);
                }

                .over-use-zone {
                    background-color: rgba(207, 205, 154, 0.4);
                    border-color: rgba(207, 205, 154, 0.6);
                }

                .stuck-zone {
                    background-color: rgba(121, 89, 68, 0.4);
                    border-color: rgba(121, 89, 68, 0.6);
                }

                &.high {
                    .weak-zone,
                    .strong-zone {
                        height: calc(100% / #{$maxPercentage} * 20);
                    }

                    .strong-zone {
                        bottom: calc(100% / #{$maxPercentage} * 20);
                    }

                    .over-use-zone {
                        height: calc(100% / #{$maxPercentage} * 10);
                        bottom: calc(100% / #{$maxPercentage} * 40);
                    }

                    .stuck-zone {
                        height: calc(100% / #{$maxPercentage} * 20);
                        bottom: calc(100% / #{$maxPercentage} * 50);
                    }
                }

                &.low {
                    .weak-zone,
                    .strong-zone {
                        height: calc(100% / #{$maxPercentage} * 15);
                    }

                    .strong-zone {
                        bottom: calc(100% / #{$maxPercentage} * 15);
                    }

                    .over-use-zone {
                        height: calc(100% / #{$maxPercentage} * 10);
                        bottom: calc(100% / #{$maxPercentage} * 30);
                    }

                    .stuck-zone {
                        height: calc(100% / #{$maxPercentage} * 30);
                        bottom: calc(100% / #{$maxPercentage} * 40);
                    }
                }
            }
        }
    }

    .graphics {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;

        img {
            height: 6rem;
        }
    }
}
</style>
