import i18n from '@/i18n';
import { defineComponent } from 'vue';
import AppHelper from '@/generic/helpers/AppHelper';
import EnvService from '@/generic/services/EnvService';

const createFavIcons = (application: string) => {
    const themeIco = document.querySelector('#themeIcon');
    if (themeIco) {
        themeIco.setAttribute('href', `/images/design/${application}/favicon.ico`);
    }

    const favicons = [
        { rel: 'shortcut icon', href: 'favicon.ico' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: 'favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: 'favicon-16x16.png' },
        { rel: 'apple-touch-icon', sizes: '180x180', href: 'apple-touch-icon.png' },
        { rel: 'apple-touch-icon', sizes: '180x180', href: 'apple-touch-icon.png' },
    ];

    const head = document.querySelector('head');
    document.querySelectorAll('.link-icon').forEach((link) => {
        head.removeChild(link);
    });

    favicons.forEach((icon) => {
        const link = document.createElement('link');
        link.setAttribute('rel', icon.rel);
        link.setAttribute('href', `/images/design/${application}/${icon.href}`);
        link.setAttribute('class', 'link-icon');
        if (icon.sizes) {
            link.setAttribute('sizes', icon.sizes);
        }

        if (icon.type) {
            link.setAttribute('type', icon.type);
        }
        head.appendChild(link);
    });
};

const createMeta = (application: string) => {
    const head = document.querySelector('head');
    const colors = {
        fearless: '#ec671a',
        kantor: '#4067AF',
        tem: '#00356B',
        default: '#228be6',
    };
    const themeColor = colors[application] ?? colors.default;
    const meta = [{ name: 'theme-color', content: themeColor }];

    meta.forEach((tag) => {
        const exists = document.querySelector(`meta[name="${tag.name}"]`);
        if (exists) {
            exists.remove();
        }

        const meta = document.createElement('meta');
        meta.setAttribute('name', tag.name);
        meta.setAttribute('content', tag.content);
        meta.setAttribute('class', 'custom-meta');
        head.appendChild(meta);
    });
};

const createThemeCssLink = (application: string) => {
    const head = document.querySelector('head');
    const themeCss = document.createElement('link');
    themeCss.setAttribute('rel', 'stylesheet');
    themeCss.setAttribute('href', `/themes/${application}.css`);
    head.appendChild(themeCss);
};

export const loadEnvironment = () => {
    const env = EnvService.getEnvironment();
    const application = AppHelper.applicationFromUrl(env.theme);

    createFavIcons(application);
    createThemeCssLink(application);
    createMeta(application);
};

export default defineComponent({
    computed: {
        lang() {
            return i18n.global.locale;
        },
    },
});
