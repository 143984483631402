import DistributionChartData, { DistributionChartOptions, DistributionData } from "@/report/models/Charts/DistributionChartData";
import { PropType, computed, defineComponent } from "vue";

export default defineComponent({
    props: {
        chartData: { type: Object as PropType<DistributionChartData[]> },
        chartOptions: { type: Object as PropType<DistributionChartOptions> },
    },
    setup(props) {
        const largestDistribution = computed(() => Math.max(...props.chartData.map((x) => x.getDistributionSum())));

        const getStyleForDistributionOption = (data: DistributionData) => {
            const width = (data.value / largestDistribution.value) * 100;
            data.label = data.label.includes('%)') ? data.label : `${data.label} (${width.toFixed(2)}%)`;
            return `width: ${width}%; background-color: ${data.color ?? 'black'}`;
        };

        return {
            largestDistribution,
            getStyleForDistributionOption,
        };
    },
});
