import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        variant: { type: String, default: 'error' },
    },
    computed: {
        icon() {
            const icons = ['warning', 'error', 'info'];
            return icons.includes(this.variant) ? this.variant : null;
        },
    },
});
