<template>
    <div id="survey-privacy">
        <survey-skeleton v-if="loading" :amount="2" :min-width="85" class="mb-3" />
        <template v-else>
            <survey-title v-t="{ path: 'generic.TITLE', args: { name } }" />
            <survey-lead v-t="'generic.INTRO'" />
        </template>

        <survey-skeleton v-if="loading" :amount="4" :max-width="40" class="mb-3" />
        <div class="survey-note" v-if="welcomeText">
            <div class="survey-note-wrapper">
                <div class="survey-note-content" v-sanitize-html="welcomeText"></div>
            </div>
        </div>

        <survey-skeleton v-if="loading" :amount="2" :max-width="50" class="mb-3" />
        <div v-else>
            <survey-checkbox name="agree1" required v-model="agree1">
                <span v-t="'generic.AGREE1'" class="mr-1"></span>
                <a target="_blank" :href="translatedUrl" v-t="'generic.PRIVACYPOLICY'"></a>
            </survey-checkbox>
        </div>

        <survey-skeleton v-if="loading" :max-width="50" class="mb-3" />
        <p v-else>
            <a target="_blank" :href="translatedUrl" v-t="'generic.PRIVACYPOLICY_EMPHASIZE'"></a>
        </p>

        <survey-alert v-if="!!error" variant="info" class="mb-5">{{ error }}</survey-alert>

        <div id="survey-privacy-footer" class="mt-2">
            <survey-skeleton v-if="loading" :min-width="100" inline style="width: 3rem" />
            <template v-else>
                <survey-button type="submit" @click="accept" v-t="'generic.TEST_START'"></survey-button>
                <strong class="text-accent" v-if="totalSurveyDuration">
                    <survey-icon icon="clock" class="mr-2"></survey-icon>
                    <span class="text-accent" v-t="{ path: 'generic.TIME', args: { time: totalSurveyDuration } }"></span>
                </strong>
            </template>
        </div>
    </div>
</template>
<script src="./SurveyPrivacy.ts"></script>
<style lang="scss">
#survey-privacy {
    &-footer {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}

.survey-note {
    margin: 5rem 0;
    background: transparent !important;

    &-wrapper {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        position: relative;
        width: 70%;
        margin: 3rem auto;

        >.survey-note-content {
            min-height: 200px;
            background: #fff !important;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            padding: 2rem;
            position: relative;
            z-index: 2;

            p {
                margin: 0;
            }
        }

        &:before {
            content: '';
            height: 98%;
            position: absolute;
            width: 100%;
            background: #fafafa;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
            left: -5px;
            top: 4px;
            transform: rotate(-2.5deg);
            z-index: 1;
        }

        &:after {
            content: '';
            height: 98%;
            position: absolute;
            width: 100%;
            background: #f6f6f6;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
            right: -3px;
            top: 1px;
            transform: rotate(1.4deg);
            z-index: 1;
        }
    }
}
</style>
