<template>
    <div id="dashboard-layout">
        <div id="dashboard-layout-header">
            <a href="#" title="Home" id="brand-logo" target="_self">
                <img :src="logo" alt="Logo" />
            </a>
        </div>

        <div id="dashboard-layout-content">
            <div id="main-language-selector">
                <language-selector  />
            </div>
            <router-view :key="lang" />
        </div>

        <survey-loader v-if="!styleLoaded" full-size />
    </div>
</template>
<script src="./DashboardLayout.ts"></script>
<style lang="scss">
@import '@/variables';

#dashboard-layout {
    display: grid;
    position: relative;
    grid-template-rows: min-content auto;
    justify-items: center;
    align-items: flex-start;
    position: relative;
    height: 100%;
    padding: 0 1rem 1rem 1rem;

    &-header {
        position: relative;
        padding: 2.25rem 0 2rem 0;
        max-width: $main-max-width;
        width: 100%;

        #brand-logo {
            display: flex;
            height: 2.5rem;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
    }

    &-content {
        display: flex;
        position: relative;
        height: min-content;

        #main-language-selector {
            position: absolute;
            top: -1rem;
            right: 1rem;
        }
    }
}
</style>
