import { computed, ComputedRef, defineComponent, PropType } from 'vue';
import SurveyListItem from '../models/SurveyListItem';
import SurveyListResponse, { SurveyListSurvey } from '../models/SurveyListResponse';

export default defineComponent({
    props: {
        loading: { type: Boolean },
        list: { type: Object as PropType<SurveyListResponse> },
    },
    setup(props, { emit }) {
        const startSurvey = (item: SurveyListItem) => {
            emit('start', item);
        };

        const listItems: ComputedRef<SurveyListItem[]> = computed(() => {
            const items = [];
            props.list.surveys.forEach((s: SurveyListSurvey) => {
                items.push({
                    id: s.surveyId,
                    name: s.instrumentName,
                    completed: s.surveyCompleted,
                    isDemographic: false,
                    contextOption: s.contextOption,
                    context: s.context,
                    instrumentAlias: s.instrumentAlias,
                    firstOpenDate: s.firstOpenDate,
                });
            });

            if (props.list.demographicSurvey) {
                items.push({
                    id: 99999999999999,
                    name: 'Demographic data',
                    completed: props.list.demographicSurvey.surveyCompleted,
                    isDemographic: true,
                });
            }

            if (items.filter((x) => !x.completed).length === 1) {
                const unfinishedItem: SurveyListItem = items.find((x) => !x.completed);
                startSurvey(unfinishedItem);
            }

            return items;
        });

        return { startSurvey, listItems };
    },
});
