import { computed, ComputedRef, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PersonalChartVue from '@/report/fearless/charts/PersonalChart.vue';
import { CategoryType } from '@/report/models/CategoryType';
import IReportData from '@/report/models/IReportData';
import ICategory from '@/report/models/ICategory';
import BaseReportComponent from '../../components/BaseReportComponent';
import CoverPageVue from '@/report/fearless/components/CoverPage.vue';
import { PillarThresholdType } from '@/report/models/PillarThreshold';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        PersonalChart: PersonalChartVue,
        CoverPage: CoverPageVue,
    },
    setup() {
        const store = useStore();
        const { t } = useI18n();
        const user = computed(() => store.getters['report/user']);
        const context = computed(() => store.getters['report/context']);
        const loaded = computed(() => store.getters['report/loaded']);
        const primary: ComputedRef<IReportData> = computed(() => store.getters['report/primary']);
        const secondary: ComputedRef<IReportData> = computed(() => store.getters['report/secondary']);
        const reportData = computed(() => store.getters['report/report']);
        const categoryKeys = Object.keys(CategoryType).filter((key: string) => key !== 'General');
        const trialPrimary: ComputedRef<IReportData> = computed(() => {
            if (primary.value && primary.value.categories) {
                primary.value.categories.forEach((cat: ICategory) => {
                    switch (cat.category) {
                        case CategoryType.General:
                            cat.teamMedian = +t('reportsWeb.GENERAL_MEDIAN_SCORE');
                            cat.positiveAverage = +t('reportsWeb.GENERAL_POSITIVE_AVERAGE');
                            cat.negativeAverage = +t('reportsWeb.GENERAL_NEGATIVE_AVERAGE');
                            break;
                        case CategoryType.Help:
                            cat.teamMedian = +t('reportsWeb.HELP_MEDIAN_SCORE');
                            cat.positiveAverage = +t('reportsWeb.HELP_POSITIVE_AVERAGE');
                            cat.negativeAverage = +t('reportsWeb.HELP_NEGATIVE_AVERAGE');
                            break;
                        case CategoryType.Diversity:
                            cat.teamMedian = +t('reportsWeb.DIVERSITY_MEDIAN_SCORE');
                            cat.positiveAverage = +t('reportsWeb.DIVERSITY_POSITIVE_AVERAGE');
                            cat.negativeAverage = +t('reportsWeb.DIVERSITY_NEGATIVE_AVERAGE');
                            break;
                        case CategoryType.Attitude:
                            cat.teamMedian = +t('reportsWeb.ATTITUDE_MEDIAN_SCORE');
                            cat.positiveAverage = +t('reportsWeb.ATTITUDE_POSITIVE_AVERAGE');
                            cat.negativeAverage = +t('reportsWeb.ATTITUDE_NEGATIVE_AVERAGE');
                            break;
                        case CategoryType.Conversation:
                            cat.teamMedian = +t('reportsWeb.CONVERSATION_MEDIAN_SCORE');
                            cat.positiveAverage = +t('reportsWeb.CONVERSATION_POSITIVE_AVERAGE');
                            cat.negativeAverage = +t('reportsWeb.CONVERSATION_NEGATIVE_AVERAGE');
                            break;
                        default:
                            return {};
                    }
                });
            }
            return primary.value;
        });

        const general: ComputedRef<ICategory> = computed(() => {
            if (trialPrimary.value && trialPrimary.value.categories) {
                return trialPrimary.value.categories.find((cat: ICategory) => {
                    return cat.category === CategoryType.General;
                });
            }
            return {} as ICategory;
        });

        const conclusion = computed(() => (general.value.negativeMedianDistributionRange ? 'NEGATIVE' : 'POSITIVE'));
        const scores = computed(() => {
            return (data: IReportData, category: string) => {
                if (data && data.categories) {
                    return data.categories.find((cat: ICategory) => {
                        return cat.category === category;
                    });
                }
                return {};
            };
        });

        return {
            loaded,
            user,
            t,
            context,
            categoryKeys,
            CategoryType,
            primary,
            secondary,
            store,
            reportData,
            general,
            conclusion,
            scores,
            trialPrimary,
        };
    },
});
