import { defineComponent, onMounted, ref } from 'vue';
import ITableSection from '@/report/models/ITableSection';
import IHeatmapScore from '@/report/models/IHeatmapScore';
import PageVue from '@/report/components/Page.vue';
import ContentWrapperVue from '@/generic/components/ContentWrapper.vue';
import ScoreTooltip from '@/report/fearless/partials/ScoreTooltip.vue';

export default defineComponent({
    components: {
        ScoreTooltip,
        Page: PageVue,
        ContentWrapper: ContentWrapperVue,
    },
    props: {
        categoryKey: { type: String },
        context: { type: String },
        type: { type: String },
        options: { type: Array },
        heatmap: { type: Object },
    },
    setup(props) {
        const scores = ref([] as IHeatmapScore[]);
        const rows = ref([] as IHeatmapScore[]);
        const titles = ref([] as string[]);
        const loading = ref(false);

        onMounted(async () => {
            loading.value = true;

            if (props.heatmap) {
                scores.value = props.heatmap.entries;
                rows.value = props.heatmap.entries.slice(0, scores.value.length - 1);
                const titlesArray = [...props.heatmap.titles];
                titles.value = titlesArray.splice(1).reverse();
            }

            loading.value = false;
        });

        const getScoreOfCategory = (categories: ITableSection[], category: string) => {
            return categories != null ? categories.find((x) => x.category == category).median : null;
        };

        const heat = (data: number) => {
            let color = '';
            const colors = {
                0: 'red',
                61: 'orange',
                76: 'light-green',
                86: 'green',
            };
            if (!data) {
                return 'secondary';
            }

            Object.keys(colors).forEach((key: string) => {
                if (data >= +key) {
                    color = colors[key];
                }
            });
            return color;
        };

        return { scores, rows, titles, getScoreOfCategory, heat, loading };
    },
});
