import { PropType, defineComponent } from "vue";
import { PitPersonalReportData } from "../models/PitPersonalReportData";

export default defineComponent({
    props: {
        reportData: { type: Object as PropType<PitPersonalReportData> },
        translateKey: String,
        scoreA: Number,
        scoreB: Number,
        explainer: String,
        color: String,
        participantName: String,
    },
});
