<template>
    <div class="ievp-extended-chart-wrapper">
        <survey-translate base="reportsWeb" tag="h2" class="distribution-title" :name="`${categoryKey}_TITLE`" raw />
        <survey-translate base="reportsWeb" tag="div" class="distribution-description" :name="`${categoryKey}_DESC`" raw />
        <team-compare-question
            :categoryKey="categoryKey"
            :context="context"
            :scores="scores"
            :type="type"
            :options="options"
            :option-default="optionDefault"
            :compare-to="compareTo"
            :quarters="quarters"
            :compare-scores="compareScores"
            @compare="compare"
            :loading="loading"
            :demographic-table="report.table"
            show-deviation
        />

        <AccordionList v-model:state="state">
            <AccordionItem id="accordion-item-extended-1">
                <template #summary>
                    <survey-translate base="reportsWeb" :name="`${categoryKey}_EXPLAINER_TITLE`" raw />
                </template>
                <survey-translate base="reportsWeb" :name="`${categoryKey}_EXPLAINER_DESC`" raw />
                <distribution-chart v-if="state['accordion-item-extended-1']" :chartData="distributionData" />
            </AccordionItem>
        </AccordionList>
    </div>
</template>
<script src="./IevpExtendedCharts.ts"></script>
<style lang="scss">
.distribution-title {
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0 0 0 0;
    padding: 0 0 1rem 0;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.distribution-description {
    line-height: 1.5;
    font-size: 18px;
    text-align: left;
    margin: 0.25rem;
}

.page > .ievp-extended-chart-wrapper:first-child {
    margin: 0;
}

.ievp-extended-chart-wrapper {
    margin-top: 1em;
    padding: 1em;
    border: 0.1em solid grey;
    border-radius: 1em;

    .survey-content-wrapper.inverse {
        margin-left: 0;
        margin-right: 0;
    }

    .deviation-wrapper .quarters {
        span {
            overflow-wrap: break-word;
            width: 10rem;
            margin-left: -5rem;
        }

        span::before {
            left: 5rem;
        }
    }
}
</style>
