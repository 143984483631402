<template>
    <div class="survey-dropdown">
        <survey-select @select="onSelect">
            <option :value="null" disabled selected>-- {{ questionText }} --</option>
            <option v-for="option in question.answerOptions" :key="option.id" :value="option.id" :selected="option.checked ? 'selected' : null">
                <span v-t="'surveyAnswers.' + option.alias"></span>
            </option>
        </survey-select>
    </div>
</template>
<script src="./Dropdown.ts"></script>
<style lang="scss">
.survey-dropdown {
    select {
        white-space: nowrap;
        width: auto;
        max-width: max-content;
    }
}
</style>
