<template>
    <div v-for="item in chartData" :key="item.description" class="distribution-chart-wrapper">
        <div class="distribution-chart-label">
            <span class="grey-tooltip" v-tooltip:grey="item.description">{{ item.description }}</span>
        </div>
        <div class="distributions">
            <div
                v-for="data in item.distributions"
                :key="data.label"
                :style="getStyleForDistributionOption(data)"
                class="distribution-item grey-tooltip"
                v-tooltip:grey="data.label"
            ></div>
        </div>
    </div>
</template>
<script src="./DistributionChart.ts"></script>
<style lang="scss">
@import '@/variables';

.distribution-chart-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    height: 4rem;

    .distribution-chart-label {
        display: flex;
        align-items: center;
        width: 20%;
        height: 100%;
        justify-content: center;
        padding: 5px 3px;

        span {
            height: 100%;
            font-size: 12px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    .distributions {
        --w: 1px; /* thickness of the line */
        --n: 9; /* number of lines */
        background: linear-gradient(90deg, #0000 calc(100% - var(--w)), rgb(197, 197, 197) 0);
        background-size: calc((100% - var(--n) * var(--w)) / (var(--n) + 1) + var(--w)) 100%;
        border-left: 1px solid black;
        border-right: 1px solid #cecece;
        display: flex;
        width: 80%;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        .distribution-item {
            word-wrap: break-word;
            height: 60%;
        }

        .distribution-item:hover {
            box-shadow: 0.25px 0.25px 1px 1px grey;
        }
    }
}
</style>
