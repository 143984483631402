import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import radio from './question-types/QuestionRadio.vue';
import arrangeOrder from './question-types/arrangeorder.vue';
import percentage from './question-types/percentage.vue';
import Answer from '@/survey/models/Answer';
import { useStore } from 'vuex';
import QuestionHelper from '@/generic/helpers/QuestionHelper';
import multipleChoice from './question-types/multiplechoice.vue';
import demographicRadio from './question-types/DemographicRadio.vue';
import openQuestion from './question-types/open-question.vue';
import demographicOpenQuestion from './question-types/demographic-open-question.vue';
import demographicRadioOtherSuffix from './question-types/demographic-radio-other-suffix.vue';
import Question from '@/survey/models/Question';
import PercentageSliderVue from './question-types/PercentageSlider.vue';
import MeOrgVue from './question-types/MeOrg.vue';
import DropdownVue from './question-types/Dropdown.vue';
import { QuestionType } from '../models/QuestionType';
import demographicDropdownVue from './question-types/DemographicDropdown.vue';

export default defineComponent({
    name: 'question',
    props: {
        question: { type: Object as PropType<Question> },
        context: { type: String },
        firstName: { type: String },
        nextButtonHovered: { type: Boolean },
    },
    components: {
        radio,
        multipleChoice,
        arrangeOrder,
        percentage,
        demographicRadio,
        openQuestion,
        PercentageSlider: PercentageSliderVue,
        RadioImageCheckbox: MeOrgVue,
        RadioDropdown: DropdownVue,
        demographicRadioDropdown: demographicDropdownVue,
        demographicOpenQuestion,
        demographicRadioOtherSuffix: demographicRadioOtherSuffix,
    },
    setup(props: { question: Question; context: string; firstName: string }) {
        const { t } = useI18n();
        const store = useStore();

        const questionText = computed(() => {
            if (props.question.isDemographic) {
                return props.question.question;
            }
            return t(`surveyQuestions.QUESTION_${props.question.questionNumber}`, { context: props.context, firstName: props.firstName });
        });

        const answerOptions = computed(() => props.question.answerOptions);
        return { answerOptions, store, questionText };
    },
    computed: {
        isDemographic() {
            return this.question.isDemographic;
        },
        answerComponent() {
            return QuestionHelper.component(this.question.questionType, this.question.view, this.question.isDemographic);
        },
        orientation() {
            if (this.question.horizontal && [QuestionType.Score, QuestionType.Choice].includes(this.question.questionType)) {
                return 'horizontal';
            }
            return '';
        },
        isAnswered() {
            return QuestionHelper.valid(this.question);
        },
    },
    methods: {
        async answerSelected(answer: Answer) {
            const question = this.question;

            // TODO bring this to specific question types ts
            if (this.question.isDemographic) {
                question.answer = answer;
                question.answer.label = question.label;
            }

            if (
                this.question.questionType === QuestionType.ArrangeOrder ||
                this.question.questionType === QuestionType.Choice ||
                this.question.questionType === QuestionType.Score
            ) {
                this.question.answerOptions.forEach((option) => {
                    option.optionValue = option.id;
                });
            }

            this.store.dispatch('postAnswer', { question, answer });
            this.$emit('answered');
        },
    },
});
