import IReportResponse from '@/report/models/IReportResponse';
import Axios from 'axios';
import { computed, ComputedRef, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import LogHelper from '@/generic/helpers/LogHelper';
import EnvService from '@/generic/services/EnvService';
import fileDownload from 'js-file-download';
import $ from 'jquery';
import { useI18n } from 'vue-i18n';
import AuthHelper, { TokenType } from '@/generic/helpers/AuthHelper';
import Language from '@/store/models/Language';
import { domToImage } from 'modern-screenshot';

export default defineComponent({
    setup() {
        const loading = ref(false);
        const store = useStore();
        const report: ComputedRef<IReportResponse> = computed(() => store.getters['report/report']);
        const isTeam: ComputedRef<boolean> = computed(() => store.getters['report/isTeam']);
        //TODO: report alias is now used to hide print bar, make this a setting in the future
        const reportAlias: ComputedRef<string> = computed(() => store.getters['report/alias']);
        const participantName: ComputedRef<string> = computed(() => {
            return report.value.participantName ? report.value.participantName.replace(' ', '_') : 'N/A';
        });
        const filename: ComputedRef<string> = computed(() => {
            const parts = [participantName.value, report.value.instrumentAlias];
            const name = parts.filter((part) => part !== '' && part != undefined && part != 'N/A').join('_');
            if (isTeam.value) {
                return `Team_${name ? name + '_' : ''}Report.pdf`;
            }
            return `${name}_Report.pdf`;
        });
        const { t } = useI18n();
        const pitViewSelector = computed(() => (reportAlias.value === 'pit' ? document.getElementById('view-selector') : null));

        const canvasToImg = () => {
            document.querySelectorAll('canvas').forEach((c) => {
                const imgSrc = c.toDataURL('image/png');
                const img = document.createElement('img');
                img.style.width = c.clientWidth + 'px';
                img.style.height = c.clientHeight + 'px';
                img.src = imgSrc;
                c.parentNode.insertBefore(img, c);
                c.parentNode.removeChild(c);
            });
        };

        /**
         * Append selected attribute, to make sure
         * the selected option is visible in the pdf
         */
        const appendSelectedAttribute = () => {
            document.querySelectorAll('option').forEach((option) => {
                if (option.selected) {
                    option.setAttribute('selected', 'selected');
                }
            });
        };

        const removeSelectedAttribute = () => {
            document.querySelectorAll('option').forEach((option) => option.removeAttribute('selected'));
        };

        const preRender = () => {
            canvasToImg();
            document.querySelector('body').setAttribute('class', 'rendering');
            appendSelectedAttribute();
        };

        const afterRender = () => {
            document.querySelector('body').removeAttribute('class');
            removeSelectedAttribute();
        };

        const doCustomBaselineHtmlFixes = () => {
            $('.page:not(:last)').attr('style', 'page-break-after: always;');

            $('.row')
                .removeClass('row')
                .addClass('best-row');
            $("[class^='col']").each(function(index, element) {
                const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                    letters = ['xs', 'sm', 'md', 'lg', 'xl'];
                const $element = $(element);

                for (let i = 0; i < numbers.length; i++) {
                    for (let j = 0; j < letters.length; j++) {
                        if ($element.hasClass('col-' + numbers[i])) {
                            $element.removeClass('col-' + numbers[i]).addClass('best-col-' + numbers[i]);
                        } else if ($element.hasClass('col-' + letters[j] + '-' + numbers[i])) {
                            $element.removeClass('col-' + letters[j] + '-' + numbers[i]).addClass('best-col-' + letters[j] + '-' + numbers[i]);
                        }
                    }
                }
            });

            // $('canvas').each(function(index, element) {
            //     const $this = $(element),
            //         $img = $('<img>');

            //     $this
            //         .show()
            //         .parents('.graph')
            //         .find('img')
            //         .remove();

            //     $img.attr('src', element.toDataURL()).css({ height: '100%', 'z-index': '1', position: 'relative' });
            //     $this
            //         .hide()
            //         .parents('.graph')
            //         .append($img);
            // });

            // $('.zones').each(function() {
            //     const $el = $(this);
            //     $el.width($el.width());
            // });
            $('.stuck-zone, .over-use-zone, .strong-zone, .weak-zone').each(function() {
                const $el = $(this);
                $el.height($el.height()).css('bottom', $el.css('bottom'));
            });
        };

        const doCustomPitHtmlFixes = async () => {
            const node = document.getElementById('chart-wrapper');
            if (reportAlias.value === 'pit') {
                document.getElementById('main-layout-content').removeChild(pitViewSelector.value);
            }

            await domToImage(node)
                .then((img) => {
                    img.setAttribute('id', 'wheel-export-image');
                    node.appendChild(img);
                })
                .catch((error) => {
                    console.error('oops, something went wrong!', error);
                });
        };

        const getPrintHtml = async () => {
            preRender();
            if (report.value.instrumentAlias === 'baseline') {
                doCustomBaselineHtmlFixes();
            }

            if (
                report.value.knowledgeModelId === 12 ||
                report.value.instrumentAlias === 'pit-test' ||
                report.value.knowledgeModelId === 9 ||
                report.value.instrumentAlias === 'pit-test-correct' ||
                (isTeam.value && reportAlias.value === 'pit')
            ) {
                await doCustomPitHtmlFixes();
            }

            const html = document.querySelector('html');
            html.querySelectorAll('noscript').forEach((noscript) => noscript.remove());
            html.querySelectorAll('.iteration-picker-wrapper').forEach((noscript) => (noscript.className += ' hidden'));
            html.querySelectorAll('#demographic-selector').forEach((noscript) => (noscript.className += ' hidden'));
            const htmlString = html.innerHTML;
            afterRender();
            return htmlString;
        };

        const print = async (e: MouseEvent) => {
            e.preventDefault();
            const data = new URLSearchParams();
            const htmlData = await getPrintHtml();
            data.append('html', htmlData);
            data.append('ParticipantName', participantName.value);
            const language: Language = store.getters.language;
            data.append('CopyrightName', `(C) ${language.knowledgeModelName}`);
            loading.value = true;

            try {
                const env = EnvService.getEnvironment();
                const token = isTeam.value ? TokenType.ReportTeamToken : TokenType.SurveyToken;
                const jwt = AuthHelper.token(token);
                const response = await Axios({
                    url: `${env.reportApiEndpoint}/reports/pdf`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        Authorization: isTeam.value ? `TeamToken ${jwt}` : `BrightInstruments ${jwt}`,
                    },
                    responseType: 'blob',
                    data: data,
                });
                // TODO: Add expose content-disposition header in web.config
                const disposition = response.headers['content-disposition'];
                let dispositionFilename: string;
                LogHelper.info('Print disposition header', { disposition, headers: response.headers });
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        dispositionFilename = matches[1].replace(/['"]/g, '');
                    }
                }
                fileDownload(response.data, dispositionFilename === '' ? dispositionFilename : filename.value, 'application/pdf;charset=utf-8');
            } catch (e) {
                LogHelper.error('Could not download file');
            }

            if (
                report.value.knowledgeModelId === 12 ||
                report.value.instrumentAlias === 'pit-test' ||
                report.value.knowledgeModelId === 9 ||
                report.value.instrumentAlias === 'pit-test-correct'
            ) {
                document.getElementById('wheel-export-image').remove();
                if (reportAlias.value === 'pit') {
                    document.getElementById('main-layout-content').prepend(pitViewSelector.value);
                }
            }

            const html = document.querySelector('html');
            html.querySelectorAll('.iteration-picker-wrapper').forEach((noscript) => (noscript.className = 'iteration-picker-wrapper'));
            html.querySelectorAll('#demographic-selector').forEach((noscript) => (noscript.className = 'survey-select'));

            loading.value = false;
        };

        return {
            print,
            loading,
            reportAlias,
        };
    },
});
