import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_survey_loader = _resolveComponent("survey-loader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_survey_loader, {
        key: 0,
        cover: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode("Downloading the latest version...")
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}