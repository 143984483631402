import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    props: {
        loading: { type: Boolean },
        name: { type: String, required: true },
        totalSurveyDuration: { type: Number, required: true },
        welcomeText: { type: String },
    },
    setup(_, { emit }) {
        const agree1 = ref(false);
        const error = ref('');
        const translatedUrl = computed(() => t('generic.PRIVACYPOLICY_LINK'));
        const { t } = useI18n();

        const accept = async () => {
            if (!agree1.value) {
                return (error.value = t('generic.AGREE1_ERROR'));
            }

            error.value = '';
            emit('accepted');
        };

        return {
            error,
            accept,
            agree1,
            translatedUrl,
        };
    },
});
