<template>
    <div id="report-list" :key="loadedLanguage">
        <survey-alert v-if="error">Failed to load survey list.</survey-alert>

        <survey-skeleton v-if="loading" class="mb-3" />
        <survey-title v-else>{{ reportsResponse.name }}</survey-title>

        <div class="report-list">
            <survey-skeleton v-if="loading" :amount="3" />
            <template v-else>
                <survey-alert v-if="reports.length == 0" variant="info">No reports found yet, please try again later.</survey-alert>
                <div class="report-item" v-for="report in reports" :key="report.surveyId">
                    <span class="lead">{{ report.name }}</span>
                    <survey-button @click="openReport(report)">Open</survey-button>
                </div>
            </template>
        </div>
    </div>
</template>
<script src="./ReportList.ts"></script>
<style lang="scss">
.report-list {
    .report-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }
}
</style>
