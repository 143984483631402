<template>
    <div class="survey-loader" :class="{ 'full-size': fullSize, 'center': center, 'cover': cover }">
        <div class="loader">
            <spinner :size="spinnerSize" />
            <slot />
        </div>
    </div>
</template>
<script src="./LoaderContainer.ts"></script>
<style lang="scss">
.survey-loader {
    display: flex;
    z-index: 99;
    align-items: center;
    justify-content: center;

    &.full-size {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(5px);
    }

    &.cover {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(5px);
    }

    .loader {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
    }
}
</style>
