<template>
    <svg
        viewBox="0 0 388 463"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="icon-high-five"
    >
        <path
            d="M261.828 411.626C249.354 413.705 241.07 412.835 228.943 409.174C216.973 405.562 202.82 394.084 202.82 394.084C202.82 394.084 155.038 329.703 124.945 282.721C103.216 248.796 64.7246 191.898 64.7246 191.898C64.7246 191.898 65.9438 181.443 69.7376 176.383C74.2244 170.399 86.1415 166.852 86.1415 166.852L105.582 175.79C105.582 175.79 95.8445 162.597 93.4633 154.386C91.8716 148.898 92.1722 145.351 93.2208 139.73C94.1124 134.95 94.7074 131.985 97.5448 128.084C101.25 122.991 104.928 120.881 110.951 119.375C118.51 117.485 130.582 122.908 130.582 122.908L122.035 106.846C122.035 106.846 119.965 96.6968 121.767 90.6472C123.434 85.0538 125.223 81.6003 129.842 78.1682C129.842 78.1682 136.455 74.7129 141.067 74.1245C147.932 73.2486 152.316 74.3901 158.44 77.695C166.77 82.1904 173.811 96.7305 173.811 96.7305C173.811 96.7305 174.683 87.7044 177.344 82.7834C179.663 78.4954 181.115 75.42 185.47 73.3899C189.688 71.4232 192.826 72.1322 197.314 73.1463L197.511 73.1907C203.963 74.6476 211.963 82.2107 211.963 82.2107L255.954 155.515L305.017 241.311C305.017 241.311 300.817 220.165 300.553 204.199C300.348 191.848 297.867 184.723 300.029 172.573C301.247 165.731 300.804 160.965 305.017 155.515C309.023 150.333 312.818 147.927 319.176 146.794C325.708 145.629 330.203 146.721 335.796 150.377C342.262 154.602 346.613 167.173 346.613 167.173L346.665 279.052C346.665 279.052 347.511 290.187 348.77 297.536C351.008 310.594 352.26 320.678 349.39 335.016C347.196 345.979 345.676 351.107 339.293 361.734C332.648 372.798 316.979 386.348 316.979 386.348C316.979 386.348 300.489 398.128 288.794 403.464C278.763 408.041 272.667 409.82 261.828 411.626Z"
            fill="#fff"
        />
        <path
            d="M173.811 96.7305C173.811 96.7305 174.683 87.7044 177.344 82.7834C179.663 78.4954 181.115 75.42 185.47 73.3899C189.749 71.3946 192.917 72.1534 197.511 73.1907C203.963 74.6476 211.963 82.2107 211.963 82.2107L255.954 155.515L305.017 241.311C305.017 241.311 300.817 220.165 300.553 204.199C300.348 191.848 297.867 184.723 300.029 172.573C301.247 165.731 300.804 160.965 305.017 155.515C309.023 150.333 312.818 147.927 319.176 146.794C325.708 145.629 330.203 146.721 335.796 150.377C342.262 154.602 346.613 167.173 346.613 167.173L346.665 279.052C346.665 279.052 347.511 290.187 348.77 297.536C351.008 310.594 352.26 320.678 349.39 335.016C347.196 345.979 345.676 351.107 339.293 361.734C332.648 372.798 316.979 386.348 316.979 386.348C316.979 386.348 300.489 398.128 288.794 403.464C278.763 408.041 272.667 409.82 261.828 411.626C249.354 413.705 241.07 412.835 228.943 409.174C216.973 405.562 202.82 394.084 202.82 394.084C202.82 394.084 155.038 329.703 124.945 282.721C103.216 248.796 64.7246 191.898 64.7246 191.898C64.7246 191.898 65.9438 181.443 69.7376 176.383C74.2244 170.399 86.1415 166.852 86.1415 166.852L105.582 175.79M173.811 96.7305C173.811 96.7305 166.77 82.1904 158.44 77.695C152.316 74.3901 147.932 73.2486 141.067 74.1245C136.455 74.7129 129.842 78.1682 129.842 78.1682C125.223 81.6003 123.434 85.0538 121.767 90.6472C119.965 96.6968 122.035 106.846 122.035 106.846L130.582 122.908M173.811 96.7305L198.762 148.786M130.582 122.908C130.582 122.908 118.51 117.485 110.951 119.375C104.928 120.881 101.25 122.991 97.5448 128.084C94.7074 131.985 94.1124 134.95 93.2208 139.73C92.1722 145.351 91.8716 148.898 93.4633 154.386C95.8445 162.597 105.582 175.79 105.582 175.79M130.582 122.908L156.132 165.694M105.582 175.79L133.607 217.803"
            stroke="#CBCBCB"
            stroke-width="15"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M22.4452 182.027C22.4452 182.027 19.866 261.3 11.5609 311.297C8.88572 327.402 8.61368 346.301 8.27878 350.279C7.94388 354.257 12.6328 379.819 17.3376 391.064C22.0424 402.309 36.8891 417.989 55.2367 428.471C69.104 436.393 78.4561 438.48 94.2732 441.772C109.896 445.023 119.187 446.876 135.033 445.204C151.996 443.414 163.917 440.186 178.9 432.642C193.758 425.161 210.503 407.328 210.503 407.328L333.257 242.413C333.257 242.413 334.258 228.888 329.615 222.078C325.238 215.657 320.749 211.772 312.731 210.642C305.77 209.66 295.407 215.025 295.407 215.025C295.407 215.025 305.647 206.166 308.391 198.593C310.613 192.46 311.283 188.381 309.787 182.02C308.125 174.958 305.622 170.61 299.523 166.134C295.181 162.947 292.034 161.515 286.581 160.872C280.47 160.151 271.169 163.747 271.169 163.747C271.169 163.747 279.244 154.869 281.425 147.92C283.181 142.326 283.768 138.774 282.681 133.004C281.49 126.681 280.067 122.539 275.006 118.171C269.217 113.174 263.692 112.462 255.861 112.386C250.043 112.33 246.571 113.014 241.335 115.336C232.652 119.186 224.737 132.298 224.737 132.298C224.737 132.298 223.489 122.548 219.72 117.688C215.319 112.013 210.858 109.571 203.582 107.984C195.937 106.317 183.74 110.486 183.74 110.486L69.1909 262.74L75.6099 186.504C75.6099 186.504 73.5187 176.236 68.8212 171.745C63.2524 166.422 57.6563 164.951 49.7461 165.132C44.1419 165.26 40.9213 166.459 36.0362 168.985C29.3771 172.428 22.4452 182.027 22.4452 182.027Z"
            fill="#EC671A"
            class="icon-high-five-outline"
        />
        <path
            d="M224.737 132.298C224.737 132.298 223.489 122.548 219.72 117.688C215.319 112.013 210.858 109.571 203.582 107.984C195.937 106.317 183.74 110.486 183.74 110.486L69.1909 262.74L75.6099 186.504C75.6099 186.504 73.5187 176.236 68.8212 171.745C63.2524 166.422 57.6563 164.951 49.7461 165.132C44.1419 165.26 40.9213 166.459 36.0362 168.985C29.3771 172.428 22.4452 182.027 22.4452 182.027C22.4452 182.027 19.866 261.3 11.5609 311.297C8.88572 327.402 8.61368 346.301 8.27878 350.279C7.94387 354.257 12.6328 379.819 17.3376 391.064C22.0424 402.309 36.8891 417.989 55.2367 428.471C69.104 436.393 78.4561 438.48 94.2732 441.772C109.896 445.023 119.187 446.876 135.033 445.204C151.996 443.414 163.917 440.186 178.9 432.642C193.758 425.161 210.503 407.328 210.503 407.328L333.257 242.413C333.257 242.413 334.258 228.888 329.615 222.078C325.238 215.657 320.749 211.772 312.731 210.642C305.77 209.66 295.407 215.025 295.407 215.025M224.737 132.298C224.737 132.298 232.652 119.186 241.335 115.336C246.571 113.014 250.043 112.33 255.861 112.386C263.692 112.462 269.217 113.174 275.006 118.171C280.067 122.539 281.49 126.681 282.681 133.004C283.768 138.774 283.181 142.326 281.425 147.92C279.244 154.869 271.169 163.747 271.169 163.747M224.737 132.298L145.657 236.395M271.169 163.747C271.169 163.747 280.47 160.151 286.581 160.872C292.034 161.515 295.181 162.947 299.523 166.134C305.622 170.61 308.125 174.958 309.787 182.02C311.283 188.381 310.613 192.46 308.391 198.593C305.647 206.166 295.407 215.025 295.407 215.025M271.169 163.747L190.054 268.665M295.407 215.025L233.008 296.058"
            stroke="#C14F0C"
            stroke-width="15"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon-high-five-outline"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M210.008 4.29811C213.998 3.21441 218.135 5.48386 219.248 9.36707L229.041 43.5215C230.155 47.4047 227.823 51.4312 223.833 52.5149C219.844 53.5986 215.707 51.3292 214.593 47.446L204.8 13.2915C203.687 9.40829 206.019 5.38182 210.008 4.29811Z"
            fill="#EC671A"
            stroke="#C14F0C"
            stroke-width="8"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon-high-five-fill"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M316.191 7.01053C319.993 8.60881 321.745 12.9049 320.103 16.6062L304.597 51.5548C302.955 55.256 298.541 56.9608 294.739 55.3625C290.936 53.7642 289.184 49.4681 290.826 45.7669L306.332 10.8183C307.974 7.11704 312.388 5.41225 316.191 7.01053Z"
            fill="#EC671A"
            stroke="#C14F0C"
            stroke-width="8"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon-high-five-fill"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M383.253 84.7361C384.417 88.6053 382.137 92.66 378.162 93.7925L344.703 103.324C340.728 104.457 336.562 102.238 335.399 98.3686C334.235 94.4994 336.515 90.4447 340.49 89.3122L373.948 79.7807C377.924 78.6482 382.09 80.8668 383.253 84.7361Z"
            fill="#EC671A"
            stroke="#C14F0C"
            stroke-width="8"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon-high-five-fill"
        />
    </svg>
</template>
<style lang="scss">
.icon-high-five {
    font-size: 4rem;

    &-outline {
        fill: white;
        stroke: var(--color);
    }

    &-fill {
        fill: var(--color);
        stroke: var(--color);
    }
}
</style>

