<template>
    <div class="demographic-representation-grid">
        <div class="total-header">
            <span>total={{ chartData.totalParticipants }}</span>
        </div>
        <div class="rep-header">
            <span>Under represented</span>
            <span>Expected in dataset</span>
            <span>Over represented</span>
        </div>
        <div v-for="(data, index) in chartData.representation" :key="index" class="representation-item" :style="'grid-row: ' + index + 2 + ';'">
            <div>
                <div>{{ data.demographicValue }} (total={{ data.nValue }}, n={{ data.value }})</div>
            </div>
            <div v-if="data.baseline == 0" class="chart-area">
                <div>Not enough data</div>
            </div>
            <div v-else class="chart-area" :style="getGridTemplateColumns(data)">
                <div class="white"></div>
                <div class="representation-bar" :class="{ under: data.value < data.baseline }">
                    {{ showValue(data.value, data.baseline, false) }}
                </div>
                <div class="baseline"></div>
                <div class="representation-bar" :class="{ over: data.value > data.baseline }">
                    {{ showValue(data.value, data.baseline, true) }}
                </div>
                <div class="white"></div>
            </div>
        </div>
    </div>
</template>

<script src="./DemographicRepresentationChart.ts"></script>

<style lang="scss">
.demographic-representation-grid {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;

    div.total-header {
        grid-column: 1;
        grid-row: 1;
        border-right: 1px solid rgba(#cecece, 0.8);
        width: 200px;
        padding: 5px;
    }

    div.rep-header {
        grid-column: 2;
        grid-row: 1;

        display: flex;
        width: 425px;
        max-height: 35px;
        padding: 5px;
        justify-content: space-between;
        font-weight: bold;
    }

    div.representation-item {
        border-top: 1px solid rgba(206, 206, 206, 0.8);
        grid-column: 1 / div 2;
        display: flex;

        >div:nth-child(1) {
            width: 200px;
            border-right: 1px solid rgba(206, 206, 206, 0.8);
        }

        .chart-area {
            display: grid;
            grid-template-columns: 100%;
            width: 425px;
            text-align: center;
            // overflow: hidden;

            >div {
                line-height: 36px;
                height: 36px;
            }

            .baseline {
                background-color: rgba(206, 206, 206, 0.8);
                border-right: 0px;
                padding: 0;
                height: 100%;
            }

            .representation-bar {
                padding: 0;
                height: 100%;
                line-height: normal;

                &.over {
                    background-color: rgb(142, 239, 159);
                    border-right: 1px solid green;
                    border-left: 0px;
                    border-top: 1px solid green;
                    border-bottom: 1px solid green;
                    text-align: right;
                    padding-right: 2px;
                    height: 26px;
                    margin-top: 5px;
                }

                &.under {
                    background-color: rgb(142, 239, 159);
                    border-right: 0px;
                    border-left: 1px solid green;
                    border-top: 1px solid green;
                    border-bottom: 1px solid green;
                    text-align: left;
                    padding-left: 2px;
                    height: 26px;
                    margin-top: 5px;
                }
            }
        }
    }
}

.demographic-representation-header {
    border-bottom: 1px solid rgba($color: #cecece, $alpha: 0.8);
    font-weight: bold;
    font-size: 12px;

    // .first-column {
    //     width: 150px;
    //     border-right: 1px solid rgba($color: #cecece, $alpha: 0.8);
    //     padding-left: 15px;
    // }

    // :not(.first-column) {
    //     padding: 0 15px;
    // }

    .chart-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

.demographic-representation-rows {
    display: flex;
    flex-direction: row;
    height: 50px;
    border-bottom: 1px solid rgba($color: #cecece, $alpha: 0.8);

    .header {
        width: 150px;
        border-right: 1px solid rgba($color: #cecece, $alpha: 0.8);
        line-height: 50px;
        padding-left: 15px;
    }
}
</style>
