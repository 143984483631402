import { PropType, defineComponent, onMounted } from 'vue';
import BaseReportComponent from '@/report/components/BaseReportComponent';
import Sunburst from 'sunburst-chart';
import SunburstChartData from '../../models/Charts/SunburstChartData';
import { Constructs } from '../models/RjcChartConstructs';

export default defineComponent({
    extends: BaseReportComponent,
    props: {
        reportData: { type: Object as PropType<Record<number, number>> },
        chartNumber: { type: Number, default: 1 },
        chartTitle: { type: String, default: '' },
        stepSize: { type: Number, default: 2 },
        maxDepth: { type: Number, default: 20 },
        showStrokes: { type: Boolean, default: false },
    },
    setup(props) {
        const childData = (depth: number, stepSize: number, maxDepth: number, data?: SunburstChartData): SunburstChartData => {
            if (depth <= 0) {
                return {};
            }
            return {
                name: isWithinThreshold(depth, stepSize) ? data?.name : '',
                color: data?.color
                    ? isWithinThreshold(depth, stepSize)
                        ? 'rgba(0,0,0,0.0)'
                        : data?.color
                    : depth - stepSize < 0
                    ? 'rgba(0,0,0,0.0)'
                    : data?.color,
                label: data?.label,
                strokeColor: props.showStrokes ? 'white' : 'rgba(0,0,0,0.0)',
                size: isWithinThreshold(maxDepth, stepSize) ? 1 : 0,
                children: isWithinThreshold(maxDepth, stepSize)
                    ? []
                    : isWithinThreshold(depth, stepSize)
                    ? [fillChartData(maxDepth - stepSize, stepSize)]
                    : [childData(depth - stepSize, stepSize, maxDepth - stepSize, data)],
            };
        };

        const isWithinThreshold = (value: number, threshold: number): boolean => value - threshold < threshold;
        const fillChartData = (maxDepth: number, stepSize: number): SunburstChartData => {
            if (maxDepth <= 0) {
                return {};
            }
            return {
                name: '',
                color: 'rgba(0,0,0,0.0)',
                strokeColor: props.showStrokes ? 'white' : 'rgba(0,0,0,0.0)',
                size: isWithinThreshold(maxDepth, stepSize) ? 1 : 0,
                children: isWithinThreshold(maxDepth, stepSize) ? [] : [fillChartData(maxDepth - stepSize, stepSize)],
            };
        };

        const constructChartData = (construct: Constructs, entry: [string, number], stepSize: number): SunburstChartData => {
            const constructName = construct.toString();
            const constructColorEnum = Object.entries(Constructs).find((x) => x[0] == `${constructName}Color`);
            const constructColor = constructColorEnum && constructColorEnum.length == 2 ? constructColorEnum[1] : 'black';
            const entryValue = entry[1];
            const entryScore = entry[1];

            if (entryValue <= 0) {
                return {
                    name: constructName,
                    color: 'white',
                    size: 1,
                };
            }
            return {
                name: '',
                color: constructColor,
                strokeColor: props.showStrokes ? 'white' : constructColor,
                label: constructName,
                size: 0,
                children: [
                    childData(entryValue, stepSize, props.maxDepth, {
                        name: constructName,
                        score: entryScore,
                        label: constructName,
                        color: constructColor,
                        strokeColor: props.showStrokes ? 'white' : constructColor,
                    }),
                ],
            };
        };

        const getChartData = (): SunburstChartData[] => {
            const relevantConstructs = Object.entries(props.reportData).filter((entry) => Object.keys(Constructs).includes(`Construct_${entry[0]}`));
            return constructData(relevantConstructs, props.stepSize);
        };

        const constructData = (relevantConstructs: [string, any][], stepSize: number): SunburstChartData[] => {
            const data: SunburstChartData[] = [];

            relevantConstructs.forEach((entry) => {
                const [constructKey] = entry;
                // keyof takes an object type and returns a type that accepts any of the object's keys. Typeof makes sure to return the object type of Constructs.
                const construct = Constructs[`Construct_${constructKey}` as keyof typeof Constructs];
                data.push(constructChartData(construct, entry, stepSize));
            });
            return data;
        };

        const reloadChartData = () => {
            const htmlElement = document.getElementById(`rjc-chart-${props.chartNumber}`);
            htmlElement.innerHTML = '';
            const rootData = {
                name: 'root',
                size: 0,
                children: getChartData(),
            };
            const myChart = Sunburst();
            myChart
                .data(rootData)
                .size('size')
                .color('color')
                .strokeColor('strokeColor')
                .excludeRoot(true)
                .labelOrientation('angular')
                .showTooltip((node) => node.label ?? false)
                .tooltipTitle((node) => node.label)
                .onClick(() => null)
                .transitionDuration(0)
                .handleNonFittingLabel((label, availablePx) => {
                    const numFitChars = Math.round(availablePx / 7); // ~7px per char
                    return numFitChars < 5 ? null : `${label.slice(0, Math.round(numFitChars) - 3)}...`;
                })
                .width(400)
                .height(400)
                .radiusScaleExponent(0.5)(htmlElement);
        };

        onMounted(() => {
            reloadChartData();
        });
    },
});
