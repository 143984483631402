<template>
    <div class="pit-cross">
        <div class="table-wrapper">
            <div class="y-label-wrapper">
                <div class="y-label">{{ yAxisLabel }}</div>
            </div>
            <div class="cross-wrapper">
                <div class="x-label">{{ xAxisLabel }}</div>
                <div id="grid-container">
                    <pit-grid-item v-for="(item, index) in gridItems" :key="index" :participants="item.participants" :text="item.text" />
                </div>
            </div>
        </div>
    </div>
    <div v-if="options.length && !disableComparer" style="display: flex; justify-content: center; align-items: center;">
        <div class="pit-select">
            <span v-t="'reportsWeb.X_AXIS_LABEL'"></span>
            <survey-select :value="xAxisSelection" @change="onChangeX($event)">
                <option v-for="option in options" :key="option.option" :value="option.option">{{ $t(option.label) }}</option>
            </survey-select>
        </div>
        <div class="pit-select">
            <span v-t="'reportsWeb.Y_AXIS_LABEL'"></span>
            <survey-select :value="yAxisSelection" @change="onChangeY($event)">
                <option v-for="option in options" :key="option.option" :value="option.option">{{ $t(option.label) }}</option>
            </survey-select>
        </div>
    </div>
</template>
<script src="./PitCross.ts"></script>
<style lang="scss">
:root {
    --grid-cols: 1;
    --grid-rows: 1;
}

.pit-cross {
    margin: 3em 0;
    font-size: medium;
}

#pit-report .page + .page {
    padding-top: 0;
}

.x-label {
    position: relative;
    text-align: center;
    font-weight: bold;
}

.y-label-wrapper {
    display: table-cell;
    vertical-align: middle;
    padding-top: 20px;
}

.y-label {
    display: flex;
    justify-content: center;
    position: relative;
    font-weight: bold;
    white-space: nowrap;
    max-width: 2em;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform-origin: center center;
    -webkit-transform-origin: center center;
    text-align: center;
}

.table-wrapper {
    display: table;
    width: 100%;
    vertical-align: middle;
}

.cross-wrapper {
    width: 97%;
    display: table-cell;
}

.pit-select {
    margin: 0.5rem;

    select {
        max-width: 290px;
    }
}

#grid-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    position: relative;
}

.grid-item {
    padding: 1em;
    border: 1px solid #ddd;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.pointer {
    cursor: pointer;
}

#grid-container .border-override {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 2px solid #fff;
}

.grid-item:nth-child(10),
.grid-item:nth-child(11),
.grid-item:nth-child(12),
.grid-item:nth-child(18),
.grid-item:nth-child(19),
.grid-item:nth-child(20),
.grid-item:nth-child(26),
.grid-item:nth-child(27),
.grid-item:nth-child(28) {
    background-color: rgb(69, 162, 81, 0.1);
}

.grid-item:nth-child(12),
.grid-item:nth-child(20),
.grid-item:nth-child(28) {
    border-right: 2px solid rgb(69, 162, 81);
}

.grid-item:nth-child(26),
.grid-item:nth-child(27),
.grid-item:nth-child(28) {
    border-bottom: 2px solid rgb(69, 162, 81);
}

.grid-item:nth-child(14),
.grid-item:nth-child(15),
.grid-item:nth-child(16),
.grid-item:nth-child(22),
.grid-item:nth-child(23),
.grid-item:nth-child(24),
.grid-item:nth-child(30),
.grid-item:nth-child(31),
.grid-item:nth-child(32) {
    background-color: rgb(169, 32, 24, 0.1);
}

.grid-item:nth-child(14),
.grid-item:nth-child(22),
.grid-item:nth-child(30) {
    border-left: 2px solid rgb(169, 32, 24);
}

.grid-item:nth-child(30),
.grid-item:nth-child(31),
.grid-item:nth-child(32) {
    border-bottom: 2px solid rgb(169, 32, 24);
}

.grid-item:nth-child(42),
.grid-item:nth-child(43),
.grid-item:nth-child(44),
.grid-item:nth-child(50),
.grid-item:nth-child(51),
.grid-item:nth-child(52),
.grid-item:nth-child(58),
.grid-item:nth-child(59),
.grid-item:nth-child(60) {
    background-color: rgb(71, 107, 183, 0.1);
}

.grid-item:nth-child(44),
.grid-item:nth-child(52),
.grid-item:nth-child(60) {
    border-right: 2px solid rgb(71, 107, 183);
}

.grid-item:nth-child(42),
.grid-item:nth-child(43),
.grid-item:nth-child(44) {
    border-top: 2px solid rgb(71, 107, 183);
}

.grid-item:nth-child(46),
.grid-item:nth-child(47),
.grid-item:nth-child(48),
.grid-item:nth-child(54),
.grid-item:nth-child(55),
.grid-item:nth-child(56),
.grid-item:nth-child(62),
.grid-item:nth-child(63),
.grid-item:nth-child(64) {
    background-color: rgb(245, 184, 59, 0.1);
}

.grid-item:nth-child(46),
.grid-item:nth-child(54),
.grid-item:nth-child(62) {
    border-left: 2px solid rgb(245, 184, 59);
}

.grid-item:nth-child(46),
.grid-item:nth-child(47),
.grid-item:nth-child(48) {
    border-top: 2px solid rgb(245, 184, 59);
}

.grid-item:nth-child(13),
.grid-item:nth-child(21),
.grid-item:nth-child(29),
.grid-item:nth-child(34),
.grid-item:nth-child(35),
.grid-item:nth-child(36),
.grid-item:nth-child(37),
.grid-item:nth-child(38),
.grid-item:nth-child(39),
.grid-item:nth-child(40),
.grid-item:nth-child(45),
.grid-item:nth-child(53),
.grid-item:nth-child(61) {
    background-color: rgb(220, 232, 246);
}

.grid-item:nth-child(1),
.grid-item:nth-child(2),
.grid-item:nth-child(3),
.grid-item:nth-child(4),
.grid-item:nth-child(5),
.grid-item:nth-child(6),
.grid-item:nth-child(7),
.grid-item:nth-child(8) {
    border-top: 2px solid #fff;
}

.grid-item:nth-child(1),
.grid-item:nth-child(9),
.grid-item:nth-child(17),
.grid-item:nth-child(25),
.grid-item:nth-child(33),
.grid-item:nth-child(41),
.grid-item:nth-child(49),
.grid-item:nth-child(57) {
    border-left: 2px solid #fff;
}

.grid-item:nth-child(8),
.grid-item:nth-child(16),
.grid-item:nth-child(24),
.grid-item:nth-child(32),
.grid-item:nth-child(40),
.grid-item:nth-child(48),
.grid-item:nth-child(56),
.grid-item:nth-child(64) {
    border-right: 2px solid #fff;
}

.grid-item:nth-child(57),
.grid-item:nth-child(58),
.grid-item:nth-child(59),
.grid-item:nth-child(60),
.grid-item:nth-child(61),
.grid-item:nth-child(62),
.grid-item:nth-child(63),
.grid-item:nth-child(64) {
    border-bottom: 2px solid #fff;
}
</style>
