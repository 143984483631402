<template>
    <input
        :value="modelValue"
        :type="type"
        class="survey-input"
        @change="onChange"
        :required="required"
        :name="name"
        :id="id"
        :min="min"
        :max="max"
    />
</template>
<script src="./Input.ts"></script>
<style lang="scss">
.survey-input {
    border: 1px transparent solid;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    outline: none;
}
</style>
