import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        required: { type: Boolean },
    },
    methods: {
        onInput(event: any) {
            this.$emit('select', event.target.value);
        },
    },
});
