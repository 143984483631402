export default class AppHelper {
    public static applicationFromUrl(fallback: string = 'default'): string {
        const url = window.location.hostname + window.location.search;
        const names = ['kantor', 'semco', 'tem', 'courius', 'fearless', 'ievp', 'pit', 'rjc'];

        for (const i in names) {
            if (url.indexOf(names[i]) > -1) {
                return names[i];
            }
        }

        return fallback;
    }
}
