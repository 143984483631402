import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import to from 'await-to-js';
import { SurveyService } from '../surveyService';
import SurveyListResponse from '../models/SurveyListResponse';
import { useStore } from 'vuex';
import SurveyPrivacyVue from '../components/SurveyPrivacy.vue';
import SurveyListVue from '../components/SurveyList.vue';
import SurveyListItem from '../models/SurveyListItem';
import Survey from '../models/Survey';
import SurveyQuestionsVue from '../components/SurveyQuestions.vue';
import MainLayoutView from '@/generic/views/MainLayoutView';
import { setup, loadDemographicSurvey } from './BaseSurvey';
import Language from '@/store/models/Language';
import Answer from '../models/Answer';
import { appInsightsHub } from '@/store/appInsights';
import { ContextOption } from '../models/Context';
import BoundContextVue from '../components/BoundContext.vue';

export default defineComponent({
    extends: MainLayoutView,
    components: {
        SurveyPrivacy: SurveyPrivacyVue,
        SurveyListComp: SurveyListVue,
        SurveyQuestions: SurveyQuestionsVue,
        BoundContext: BoundContextVue,
    },
    props: {
        surveyList: { type: SurveyListResponse },
    },
    setup(props) {
        const { finishSurvey, redirectToFinishPage } = setup();
        const route = useRoute();
        const router = useRouter();
        const loading = ref(true);
        const error = ref(null);
        const store = useStore();
        const privacyAccepted = ref(false);
        const activeSurvey = ref<Survey>(new Survey());
        const needsBoundContext = computed<boolean>(() => {
            return (
                activeSurvey.value &&
                activeSurvey.value.surveyId &&
                activeSurvey.value.contextOption === ContextOption.SurveyBound &&
                !activeSurvey.value.context
            );
        });
        const disableBackNavigation = ref(false);
        const knowledgeModelName = computed(() => {
            return props.surveyList.knowledgeModelName;
        });

        const totalSurveyDuration = computed(() => {
            const durations = props.surveyList.surveys.filter((x) => !x.surveyCompleted).map((x) => x.surveyDuration);

            let total = 0;
            if (durations.length > 0) {
                total = durations.reduce((a, b) => a + b);
            }

            return total + (props.surveyList && props.surveyList.demographicSurvey ? props.surveyList.demographicSurvey.surveyDuration : 0);
        });

        const welcomeText = computed(() => {
            const language = new Language(store.getters.language);
            const text = props.surveyList && props.surveyList.welcomeTexts.find((x) => x.languageCode === language.languageCode);

            return text ? text.text : null;
        });

        // Methods
        const init = async (arg?: string | string[]) => {
            const argValue = arg ? arg.toString() : null;
            if (!argValue) {
                activeSurvey.value = null;
                return;
            }

            if (!privacyAccepted.value) {
                const query = route.query;
                router.push({ params: { arg: '' }, query });
                return;
            }

            loading.value = true;
            activeSurvey.value =
                arg === 'demographic'
                    ? await loadDemographicSurvey(props.surveyList)
                    : await loadSurvey(+arg, activeSurvey.value.surveyId > 0 ? activeSurvey.value.context : null, activeSurvey.value.firstOpenDate);
            privacyAccepted.value = !!arg;
            loading.value = false;

            if (activeSurvey.value.surveyCompleted) {
                redirectToFinishPage();
            }

            if (arg === 'demographic') {
                const demographicSurvey = activeSurvey.value;
                const countryQuestion = demographicSurvey.questions.find((x) => x.label.toLowerCase() === 'default_country');
                if (countryQuestion) {
                    countryQuestion.answerOptions = await loadCountryOptions(countryQuestion.value);
                }
            }
        };

        const loadCountryOptions = async (givenAnswerValue?: string) => {
            const [err, list] = await to(new SurveyService().getCountries());
            return list.map((x, i) => new Answer({ ...x, id: i + 1, alias: x.name, checked: givenAnswerValue === x.name }));
        };

        const loadSurvey = async (surveyId: number, context?: string, firstOpenDate?: string) => {
            const [surveyErr, surveyResponse] = firstOpenDate ? await to(new SurveyService().getSurvey(surveyId)) : await to(new SurveyService().startSurvey(surveyId, context));
            error.value = surveyErr ? 'Survey could not be loaded' : null;
            const language = new Language(store.getters.language);
            language.instrumentAlias = surveyResponse.instrumentAlias;
            language.section = 'survey';

            await store.dispatch('setLanguage', language);
            appInsightsHub.value.instance?.trackEvent({ name: 'load_survey' }, { surveyId });
            return surveyResponse;
        };

        const onStart = async (item: SurveyListItem) => {
            activeSurvey.value = new Survey({
                surveyId: item.id,
                context: item.context,
                contextOption: item.contextOption,
                instrumentAlias: item.instrumentAlias,
                firstOpenDate: item.firstOpenDate,
                demographicsEnabled: item.isDemographic,
            });
            if (activeSurvey.value.contextOption !== ContextOption.SurveyBound || !needsBoundContext.value) {
                onStartRoute(activeSurvey.value);
            }

            const language = new Language(store.getters.language);
            language.instrumentAlias = activeSurvey.value.instrumentAlias;
            language.section = 'survey';

            await store.dispatch('setLanguage', language);

        };

        const onStartRoute = (item: Survey) => {
            activeSurvey.value.context = item.context;
            activeSurvey.value.firstOpenDate = item.firstOpenDate;
            const arg = item.demographicsEnabled ? 'demographic' : item.surveyId;
            const query = route.query;
            router.push({ params: { arg }, query });
        };

        const onFinish = async () => {
            loading.value = true;
            if (!activeSurvey.value.demographicsEnabled) {
                const surveyId = activeSurvey.value.surveyId;
                error.value = await finishSurvey(surveyId);
            }

            loading.value = false;
            if (!error.value) {
                redirectToFinishPage();
            }
        };
        // Watch
        watch(() => route.params.arg, init);

        onMounted(async () => {
            loading.value = false;
            await init(route.params.arg);
        });

        return {
            privacyAccepted,
            loading,
            error,
            activeSurvey,
            onStart,
            onStartRoute,
            onFinish,
            knowledgeModelName,
            disableBackNavigation,
            totalSurveyDuration,
            welcomeText,
            needsBoundContext,
        };
    },
});
