import VuexPersist from 'vuex-persist';
import { SurveyRedirectLink, SurveyService } from '@/survey/surveyService';
import { ActionContext, createStore } from 'vuex';
import Question from '@/survey/models/Question';
import LogHelper from '@/generic/helpers/LogHelper';
import Answer from '@/survey/models/Answer';
import IOrderedAnswer from '@/survey/models/IOrderedAnswer';
import IPostAnswer from '@/survey/models/IPostAnswer';
import ReportModule from './report';
import QuestionHelper from '@/generic/helpers/QuestionHelper';
import { State as ReportState } from './report';
import { FinishSurveyStatus } from '@/survey/models/FinishSurveyStatus';
import { loadTranslationsAsync, setLanguageDirection } from '@/i18n';
import Language from './models/Language';
import QueueHandler from '@/generic/helpers/QueueHandler';
import { SurveyListLanguage, SurveyText } from '@/survey/models/SurveyListResponse';
import PillarThreshold, { PillarThresholdType } from '@/report/models/PillarThreshold';
import BarChartScore from '@/report/models/Charts/BarChartScore';
import { ReportService } from '@/generic/services/reportService';

export type State = {
    _processingQueue: boolean;
    _answerQueue: IPostAnswer[];
    _languages: SurveyListLanguage[];
    _language: Language;
    _loadedLanguage: Language;
    _loadingLanguage: boolean;
    _thankYouMessages: SurveyText[];
    report: ReportState;
    _anonymousSurvey: SurveyRedirectLink;
    _queueError: { operationId: string; responseCode: number; message: string };
    _pillarThresholds: PillarThreshold[];
};

const baseState: State = {
    _processingQueue: false,
    _answerQueue: [],
    _languages: [],
    _language: new Language(),
    _loadedLanguage: new Language(),
    _loadingLanguage: true,
    _thankYouMessages: [],
    report: null,
    _anonymousSurvey: null,
    _queueError: null,
    _pillarThresholds: [],
};

const persist = new VuexPersist({
    key: 'survey',
    storage: window.localStorage,
    reducer(state: State) {
        return {
            _answerQueue: state._answerQueue,
            _languages: state._languages,
            _language: state._language,
            _pillarThresholds: state._pillarThresholds,
        };
    },
});

export default createStore({
    state() {
        return baseState;
    },
    mutations: {
        SET_LANGUAGES(state: State, payload: SurveyListLanguage[]) {
            state._languages = payload;
        },
        SET_LANGUAGE(state: State, payload: Language) {
            state._language = payload;
        },
        SET_LOADING_LANGUAGE(state: State, payload: boolean) {
            state._loadingLanguage = payload;
        },
        SET_LOADED_LANGUAGE(state: State, language: Language) {
            state._loadedLanguage = language;
        },
        SET_THANK_YOU_MESSAGES(state: State, payload: SurveyText[]) {
            state._thankYouMessages = payload;
        },
        SET_QUEUE_PROCESSING(state: State, payload: boolean) {
            state._processingQueue = payload;
        },
        SET_QUEUE_ERROR(state: State, payload: { operationId: string; responseCode: number; message: string }) {
            state._queueError = payload;
        },
        ADD_ANSWER_TO_QUEUE(state: State, payload: IPostAnswer) {
            state._answerQueue.push(payload);
        },
        UPDATE_ANSWER_QUEUE(state: State, payload: IPostAnswer[]) {
            LogHelper.info('Set new queue', payload);
            state._answerQueue = payload;
        },
        CLEAR_ANSWER_QUEUE(state: State) {
            state._answerQueue = [];
        },
        SET_ANONYMOUS_SURVEY(state: State, payload: SurveyRedirectLink) {
            state._anonymousSurvey = payload;
            localStorage.setItem(`anonymousSurvey:${payload.projectId}`, JSON.stringify(payload));
        },
        CLEAR(state: State) {
            Object.assign(state, baseState);
        },
        SET_PILLAR_THRESHOLDS(state: State, payload: PillarThreshold[]) {
            state._pillarThresholds = payload;
        },
    },
    getters: {
        pendingAnswers(state: State) {
            return state._answerQueue.length;
        },
        languages(state: State) {
            return state._languages;
        },
        loadingLanguage(state: State) {
            return state._loadingLanguage;
        },
        language(state: State): Language {
            return new Language(state._language);
        },
        loadedLanguage(state: State): Language {
            return new Language(state._loadedLanguage);
        },
        thankYouMessages(state: State) {
            return state._thankYouMessages;
        },
        anonymousSurvey(state: State) {
            return state._anonymousSurvey;
        },
        queueError(state: State) {
            return state._queueError;
        },
        pillarThreshold: (state: State) => (thresholdType: string, alias: string): number[] | BarChartScore => {
            switch (thresholdType) {
                case PillarThresholdType.quartile:
                    const quartiles = state._pillarThresholds.filter(
                        (x) => x.type.toString() == PillarThresholdType.quartile && x.pillarAlias == alias,
                    );
                    return quartiles.sort((x, y) => x.order - y.order).map((x) => x.value);
                case PillarThresholdType.benchmark:
                    const benchmarks = state._pillarThresholds.filter(
                        (x) => x.type.toString() == PillarThresholdType.benchmark && x.pillarAlias == alias,
                    );
                    return new BarChartScore({
                        teamMedian: benchmarks.find((x) => x.order == 1)?.value ?? 0,
                        negativeAverage: benchmarks.find((x) => x.order == 0)?.value ?? 0,
                        positiveAverage: benchmarks.find((x) => x.order == 2)?.value ?? 0,
                    });
                case PillarThresholdType.barChartRatio:
                    const ratios = state._pillarThresholds.filter(
                        (x) => x.type.toString() == PillarThresholdType.barChartRatio && x.pillarAlias == alias,
                    );
                    return ratios.sort((x, y) => x.order - y.order).map((x) => x.value);
                default:
                    return null;
            }
        },
    },
    actions: {
        async postAnswer(context, payload: { question: Question; answer: Answer | IOrderedAnswer }) {
            const postAnswer = QuestionHelper.handleQuestionAnswer(payload.question, payload.answer, payload.question.surveyId);
            if (postAnswer && (postAnswer.answer || postAnswer.answerId > 0)) {
                context.commit('ADD_ANSWER_TO_QUEUE', postAnswer);
                context.dispatch('processQueue');
            }
        },
        async processQueue(context: ActionContext<State, State>) {
            if (context.state._processingQueue) {
                return;
            }

            context.commit('SET_QUEUE_PROCESSING', true);
            const queue = [...context.state._answerQueue];
            const queueHandler = new QueueHandler(queue, async (answer) => new SurveyService().postAnswer(answer));

            context.commit('UPDATE_ANSWER_QUEUE', []);
            const newQueue = await queueHandler.process();

            if (newQueue.length > 0 || queueHandler.getError()) {
                const error = queueHandler.getError();
                context.commit('SET_QUEUE_ERROR', error);
            }

            context.commit('UPDATE_ANSWER_QUEUE', [...newQueue, ...context.state._answerQueue]);
            context.commit('SET_QUEUE_PROCESSING', false);
        },
        async finishSurvey(context, payload: { surveyId: number }) {
            await context.dispatch('processQueue');

            const surveyId = payload.surveyId;
            const surveyService = new SurveyService();
            const language = context.getters.language;
            const completedStatus = await surveyService.complete(surveyId, language.languageCode);
            LogHelper.info('complete status', { completedStatus });

            if (completedStatus === FinishSurveyStatus.Completed) {
                context.commit('CLEAR_ANSWER_QUEUE');
            }
            return completedStatus;
        },
        async setLanguage({ commit }, payload: Language) {
            setLanguageDirection(payload.languageCode);
            commit('SET_LANGUAGE', payload);
            commit('SET_LOADING_LANGUAGE', true);

            await loadTranslationsAsync(payload);

            commit('SET_LOADED_LANGUAGE', payload);
            commit('SET_LOADING_LANGUAGE', false);
        },
        async getPillarThresholds(context: ActionContext<State, State>, payload: { instrumentAlias: string }) {
            const reportService = new ReportService();
            const language: Language = context.getters.language;
            if (payload.instrumentAlias != language.instrumentAlias) {
                const list = await reportService.getReports(language.languageCode);
                context.commit('SET_PILLAR_THRESHOLDS', list.pillarThresholds);
            }
        },
    },
    modules: {
        report: ReportModule,
    },
    plugins: [persist.plugin],
});
