export default class ProjectInOrganizationScore {
    public negativeAverage: number = 0;
    public positiveAverage: number = 0;
    public teamMedian: number = 0;
    public projectName: string = '';
    public projectId: number = 0;
    public context: string = '';
    public groupSize: number;

    constructor(obj?: Partial<ProjectInOrganizationScore>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
