import { computed, ComputedRef, defineComponent } from 'vue';
import { useStore } from 'vuex';
import category from '../partials/LosCategory.vue';
import score from '../partials/LosScore.vue';
import { LosCategoryType } from '@/report/models/LosCategoryType';
import IReportResponse from '@/report/models/IReportResponse';
import ICategory from '@/report/models/ICategory';
import BaseReportComponent from '../../components/BaseReportComponent';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        category,
        score,
    },
    props: {
        type: { type: String, default: 'reportsWeb' },
    },
    setup() {
        const store = useStore();
        const report: ComputedRef<IReportResponse> = computed(() => store.getters['report/report']);
        const scores = computed(() => {
            return (category): ICategory => {
                if (report.value && report.value.categories) {
                    return report.value.categories.find((cat: ICategory) => {
                        return cat.category === category;
                    });
                }

                return {} as ICategory;
            };
        });
        const participant = computed(() => report.value.participantName);
        const score = computed(() => {
            return (category) => {
                const s = scores.value(category);
                return participant.value === 'Team' ? s.teamMedian : s.personalScore;
            };
        });
        const categoryKeys = Object.keys(LosCategoryType).filter((key) => {
            return LosCategoryType[key] !== LosCategoryType.General;
        });
        const envCategories = [LosCategoryType.Safety, LosCategoryType.Appreciation, LosCategoryType.Openness, LosCategoryType.Reflection];
        const processCategories = [
            LosCategoryType.Experimentation,
            LosCategoryType.Infocollection,
            LosCategoryType.Analysis,
            LosCategoryType.Education,
            LosCategoryType.Infotransfer,
        ];

        const average = computed(() => {
            return (categories) => {
                let count = 0;
                let teamScore = 0;
                let personalScore = 0;

                categories.forEach((cat) => {
                    teamScore += scores.value(cat).teamMedian;
                    personalScore += scores.value(cat).personalScore;
                    count++;
                });

                if (report.value.participantName == 'Team') {
                    return teamScore / count;
                } else {
                    return personalScore / count;
                }
            };
        });

        return {
            report,
            envCategories,
            processCategories,
            categoryKeys,
            LosCategoryType,
            scores,
            score,
            participant,
            average,
        };
    },
});
