<template>
    <team-report-wrapper :loaded="loaded" :error="false">
        <report id="ievp-personal">
            <page break-after>
                <content-wrapper center>
                    <survey-translate tag="div" base="reportsWeb" name="REPORT_INTRO_TITLE" :args="{ projectName }" raw />
                    <survey-translate
                        tag="small"
                        base="reportsWeb"
                        name="PARTICIPANTS_COMPLETED_VS_INVITED"
                        :args="{ invitedParticipants, completedParticipants }"
                        raw
                    />
                    <survey-title size="xl" v-t="'reportsWeb.REPORT_TITLE'" />
                </content-wrapper>

                <content-wrapper justify-center>
                    <div class="ievp-personal-note">
                        <div class="ievp-personal-note-background" />

                        <div class="ievp-personal-note-content">
                            <survey-subtitle v-t="'reportsWeb.INTRO_TITLE'" />
                            <survey-translate tag="p" base="reportsWeb" name="INTRO_TITLE_DESCRIPTION" raw />
                        </div>
                    </div>
                    <survey-subtitle v-t="'reportsWeb.ABOUT_TITLE'" />
                </content-wrapper>

                <div class="text-with-image">
                    <table>
                        <tr>
                            <td style="padding: 3rem;">
                                <img class="text-with-image-img" src="/images/design/ievp/puzzle.png" />
                            </td>
                            <td>
                                <survey-translate tag="p" base="reportsWeb" name="ABOUT_TITLE_DESCRIPTION" raw />
                            </td>
                        </tr>
                    </table>
                </div>
            </page>

            <page>
                <content-wrapper justify-center>
                    <survey-subtitle v-t="'reportsWeb.UNDERSTANDING_TITLE'" />
                    <survey-title v-t="'reportsWeb.STEP_1_TITLE'" />
                </content-wrapper>
                <div class="text-with-image">
                    <table>
                        <tr>
                            <td>
                                <survey-translate tag="p" base="reportsWeb" name="STEP_1_DESCRIPTION_IMAGE" raw />
                                <survey-translate base="reportsWeb" name="STEP_1_DESCRIPTION" raw />
                            </td>
                            <td>
                                <team-radar
                                    :scores="chartScores"
                                    :options="questionOptions"
                                    :chart-label-style="chartLabelStyle"
                                    :compare-to="compareTo"
                                    :compare-scores="compareScoresRadarChart()"
                                    @compare="compareQuestions"
                                    :labels="translatedLabels"
                                    :key="radarChartKey"
                                    :option-default="'All participants'"
                                />
                            </td>
                        </tr>
                    </table>
                </div>
            </page>

            <page>
                <content-wrapper justify-center>
                    <survey-title v-t="'reportsWeb.STEP_2_TITLE'" />
                    <survey-translate base="reportsWeb" name="STEP_2_DESCRIPTION" raw />
                </content-wrapper>
            </page>

            <page class="ievp-personal-pillar" v-for="pillar in pillars" :key="pillar.key" :class="'pillar-' + pillar.key">
                <content-wrapper justify-center>
                    <div class="ievp-personal-pillar-title">
                        <img :src="`/images/design/ievp/puzzle_${pillar.key}.png`" />
                        <survey-title v-t="`reportsWeb.${pillar.translateKey}_TITLE`" />
                    </div>
                    <survey-translate base="reportsWeb" :name="`${pillar.translateKey}_INTRO`" raw />

                    <team-compare-question
                        :categoryKey="pillar.translateKey"
                        :context="context"
                        :distributions="getParticipantsDistribution(pillar.category)"
                        :distribution-chart-options="percentageDistributionChartOptions"
                        :type="type"
                        :options="questionOptions"
                        :option-default="'All participants'"
                        :compare-to="compareTo"
                        @compare="compareQuestions"
                        :loading="comparing"
                        :quarters="percentageDistributionQuarters"
                        :quarter-middle="'0'"
                        :colors="barChartColors"
                        vertical-distribution
                    />
                    <AccordionList v-if="projectData && reportAlias === 'ievp_extended'" v-model:state="state">
                        <AccordionItem :id="`accordion-item-${pillar.key}`">
                            <template #summary><survey-translate base="reportsWeb" :name="`${pillar.translateKey}_EXPLAIN_TITLE`" raw/></template>
                            <survey-translate base="reportsWeb" :name="`${pillar.translateKey}_EXPLAIN_DESC`" raw />
                            <distribution-chart v-if="state[`accordion-item-${pillar.key}`]" :chartData="getDistributionData(pillar.category)" />
                        </AccordionItem>
                    </AccordionList>
                    <survey-translate base="reportsWeb" :name="`${pillar.translateKey}_TODO_1`" raw />
                </content-wrapper>
            </page>
            <page break-before>
                <content-wrapper justify-center>
                    <survey-title v-t="'reportsWeb.CONCLUSION_TITLE'" />
                    <survey-translate base="reportsWeb" name="CONCLUSION_DESCRIPTION" raw />

                    <survey-subtitle v-t="'reportsWeb.CONCLUSION_FINAL_TITLE'" />
                    <survey-translate base="reportsWeb" name="CONCLUSION_FINAL_DESCRIPTION" raw />
                </content-wrapper>
            </page>

            <page v-if="reportData && reportAlias === 'ievp_extended'">
                <Ievp-extended-charts
                    v-for="section in deviationSections"
                    :key="section"
                    :categoryKey="section"
                    :context="context"
                    :scores="calculateDeviationScore(section)"
                    :type="type"
                    :options="questionOptions"
                    :option-default="'All participants'"
                    :compare-to="compareTo"
                    :compare-scores="compareGeneralScores(section)"
                    @compare="compareQuestions"
                    :loading="comparing"
                    :report="reportData"
                    :distribution-data="getDistributionData(section)"
                    :quarters="getSectionQuarters(section)"
                />
            </page>
            <page v-else-if="reportAlias === 'ievp_extended'">
                <survey-loader spinner-size="lg" />
            </page>
        </report>
    </team-report-wrapper>
</template>
<script src="./IevpTeam.ts"></script>
<style lang="scss">
@import '@/variables';

$pillars: (
    'mp': '#516FB5',
    'cc': '#2C7669',
    'gd': '#0F84D3',
    'mo': '#EF6A6D',
);

summary::-webkit-details-marker {
    display: none;
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
}

#ievp-personal {
    .ievp-personal-note {
        position: relative;
        text-align: center;
        margin: 3rem auto;
        padding: 3rem 2rem;
        overflow: hidden;
        border-radius: 1rem;

        &-background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            background-color: #e7f2fb;
        }

        &-content {
            position: relative;
            z-index: 1;
        }

        .quote-before {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 48px;
        }

        .quote-after {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 48px;
            transform: rotate(180deg);
        }
    }

    .text-with-image {
        table {
            width: 100%;
            tr {
                td {
                    display: block;
                }
            }
        }

        &-img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .ievp-personal-pillar {
        &-title {
            margin: 0 0 1rem 0;

            img {
                height: 2.5rem;
                vertical-align: bottom;
                margin-right: 1rem;
            }

            h1 {
                display: inline-block;
                position: relative;
                font-weight: 400;
                font-size: 1.5rem;
                margin: 0;
                width: calc(100% - 4rem);
                line-height: 1.1;
                min-height: 2.5rem;
                padding-bottom: 0.75rem;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 2.5rem;
                    border-bottom: 6px $border-color solid;
                }
            }
        }

        @each $key, $color in $pillars {
            &.pillar-#{$key} {
                h1 {
                    color: $font-color;
                    &::after {
                        border-color: #{$color};
                    }
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        .text-with-image {
            table {
                width: 100%;
                tr {
                    td {
                        display: table-cell;
                        width: 50%;
                        vertical-align: top;
                        padding: 0;

                        &:first-child {
                            padding-right: 2rem;
                        }

                        p {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>
