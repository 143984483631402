<template>
    <div class="page" :class="{ 'break-before': breakBefore, 'break-after': breakAfter }">
        <slot />
    </div>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
    props: {
        breakBefore: { type: Boolean },
        breakAfter: { type: Boolean },
    },
});
</script>
<style lang="scss">
@import '@/scss/mixins';

.page {
    page-break-inside: avoid;

    &.break-before {
        page-break-before: always;
    }

    &.break-after {
        page-break-after: always;
    }

    +.page {
        padding-top: 3rem;
    }
}

@include print {
    .page + .page {
        padding-top: 0rem;
    }
}
</style>
