<template>
    <p class="survey-lead">
        <slot />
    </p>
</template>
<style lang="scss">
@import '@/variables';

.survey-lead {
    font-size: $font-size-lg;
    font-weight: 500;
    line-height: 1.5rem;
    color: darken($dark-color, 20);
}
</style>
