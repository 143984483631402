import { IevpReportSectionCategory, IevpTeamReportDataSection, IevpTeamReportSectionCategory } from './IevpReportData';

export default class IevpTeamScore {
    public category: IevpReportSectionCategory;
    public sections: IevpTeamReportDataSection[];

    constructor(sections: IevpTeamReportDataSection[], category: IevpReportSectionCategory) {
        this.sections = sections;
        this.category = category;
    }

    public pillarScores(pillar: IevpTeamReportSectionCategory) {
        return this.sectionScores(pillar);
    }

    public categoryScores(): number[] {
        return [
            this.sectionScores(IevpTeamReportSectionCategory.MaterialOfferings).standardDeviationAverage,
            this.sectionScores(IevpTeamReportSectionCategory.GrowthDevelopment).standardDeviationAverage,
            this.sectionScores(IevpTeamReportSectionCategory.ConnectionCommunity).standardDeviationAverage,
            this.sectionScores(IevpTeamReportSectionCategory.PurposeMeaning).standardDeviationAverage,
        ];
    }

    private sectionScores(category: IevpTeamReportSectionCategory): IevpTeamReportDataSection {
        return this.sections.find((s) => s.category === category);
    };
}
