import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        participant: String,
        scores: Object,
        category: String,
        type: String,
    },
});
