<template>
    <survey-checkbox
        v-for="(option, index) in question.answerOptions"
        :key="'option_' + index"
        v-model="option.checked"
        :name="`answer_${index}`"
        @input="optionSelected(option)"
    >
        <span v-t="'surveyAnswers.' + option.alias"></span>

    </survey-checkbox>
</template>
<script src="./multiplechoice.ts"></script>
