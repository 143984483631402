<template>
    <div id="main-layout">
        <div id="main-layout-header">
            <div id="brand-logo">
                <img :src="logo" alt="Logo" />
            </div>
        </div>

        <div id="main-layout-content">
            <router-view v-bind="{ loadedLanguage }" />
            <div id="main-language-selector">
                <language-selector />
            </div>
            <div id="main-layout-content-clearfix"></div>
        </div>

        <survey-loader v-if="!styleLoaded && !loadingLanguage" full-size />
    </div>
</template>
<script src="./MainLayout.ts"></script>
<style lang="scss">
@import '@/variables';
@import '@/scss/mixins';

#main-layout {
    display: grid;
    position: relative;
    grid-template-rows: min-content auto;
    justify-items: center;
    align-items: center;
    position: relative;
    padding: 0 0 8rem 0;

    &-header,
    &-content {
        max-width: $main-max-width;
        width: 100%;
    }

    &-header {
        position: relative;
        padding: 2rem 1rem 3rem 2rem;

        #brand-logo {
            display: flex;
            height: 3rem;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
    }

    &-content {
        position: relative;
        padding: $main-content-padding;
        height: min-content;

        #main-language-selector {
            position: absolute;
            top: -1rem;
            right: 1rem;
            z-index: 999;
        }

        &-clearfix {
            display: flex;
            justify-content: flex-end;
            margin-top: 1rem;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 0 1rem 8rem 1rem;

        #main-layout-header {
            padding: 2rem 0 4rem 0;
        }
    }
}

@include print {
    #main-layout {
        border-top: none !important;

        &::before,
        &-header,
        #main-language-selector {
            display: none !important;
        }

        &-content {
            box-shadow: none !important;
            padding: $main-content-padding-print !important;
        }
    }
}
</style>
