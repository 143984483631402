import Home from './views/home.vue';
import NotFound from './views/notfound.vue';
import MainLayoutVue from './layouts/MainLayout.vue';

export default [
    {
        path: '/',
        component: MainLayoutVue,
        children: [
            {
                path: '',
                name: 'home',
                component: Home,
            },
            {
                path: '/:pathMatch(.*)*',
                name: 'not-found',
                component: NotFound,
            },
        ],
    },
];
