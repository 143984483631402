<template>
    <div>
        <div v-if="!loading">
            <survey-view v-if="!isCombinedSurvey" :survey-list="surveyList"></survey-view>
            <survey-view-combined v-if="isCombinedSurvey" :survey-list="surveyList"></survey-view-combined>
        </div>
    </div>
</template>
<script src="./SurveyViewWrapper.ts"></script>
<style lang="scss">
</style>
