import { PropType, computed, defineComponent, ref } from 'vue';
import Survey from '../models/Survey';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    props: {
        survey: { type: Object as PropType<Survey>, required: true },
        name: { type: String, required: true },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const inputPlaceholder = computed(() => t('generic.CUSTOM_CONTEXT_PLACEHOLDER'));
        const surveyContext = ref<string>();
        const startSurvey = () => {
            const survey = new Survey(props.survey);
            survey.context = surveyContext.value;

            emit('startContext', survey);
        };

        const onChange = (event: Event) => {
            surveyContext.value = (event.target as HTMLInputElement).value;
        };

        return {
            onChange,
            startSurvey,
            surveyContext,
            inputPlaceholder,
        };
    },
});
