export enum LosCategoryType {
    General = 'GENERAL',
    Safety = 'SAFETY',
    Appreciation = 'APPRECIATION',
    Openness = 'OPENNESS',
    Reflection = 'REFLECTION',
    Experimentation = 'EXPERIMENTATION',
    Infocollection = 'INFOCOLLECTION',
    Analysis = 'ANALYSIS',
    Education = 'EDUCATION',
    Infotransfer = 'INFOTRANSFER',
    Leadership = 'LEADERSHIP',
}
