import Answer from '@/survey/models/Answer';
import Question from '@/survey/models/Question';
import { QuestionType } from '@/survey/models/QuestionType';
import IPostAnswer from '@/survey/models/IPostAnswer';
import IOrderedAnswer from '@/survey/models/IOrderedAnswer';

export default class QuestionHelper {
    public static component(questionType: QuestionType, view?: string, isDemographic?: boolean): string {
        const names = {
            [QuestionType.AllThatApply]: 'multipleChoice',
            [QuestionType.ArrangeOrder]: 'arrangeOrder',
            [QuestionType.Percentage]: 'percentage',
            [QuestionType.OpenQuestion]: 'open-question',
        };
        const demoViews = {
            ['default_country']: 'dropdown',
            ['otherOptionWithOpenText']: 'other-suffix',
            ['dropdown']: 'dropdown',
        };

        const componentName = names[questionType] ? names[questionType] : 'radio';
        if (isDemographic && view) {
            const demoView = demoViews[view] ? demoViews[view] : undefined;
            return [isDemographic ? 'demographic' : '', componentName, demoView].filter((a) => !!a).join('-');
        }
        return [isDemographic ? 'demographic' : '', componentName, view].filter((a) => !!a).join('-');
    }

    public static valid(question: Question): boolean {
        switch (question.questionType) {
            case QuestionType.Percentage:
                let total = 0;
                question.answerOptions.forEach((option: Answer) => {
                    total += 1 * Math.abs(option.optionValue);
                });
                return total === 100;
            case QuestionType.ArrangeOrder:
                return question.answerOptions.every((option: Answer) => !!option.optionValue);
            case QuestionType.AllThatApply:
                return (
                    (question.answerOptions && question.answerOptions.filter((x) => x.checked).length) ||
                    (question.answer && question.answer.selectedAnswers && question.answer.selectedAnswers.length)
                );
            case QuestionType.OpenQuestion:
                return (
                    (question.answer && question.answer.value && question.answer.value.length > 0) ||
                    (question.answer && question.answer.answerText && question.answer.answerText.length > 0) ||
                    (question.answerOptions.length > 0 && question.answerOptions[0].answerText && question.answerOptions[0].answerText.length > 0)
                );
            case QuestionType.Score:
                if (question.isDemographic) {
                    return !!question.value || (question.answerOptions && !!question.answerOptions.find((x) => x.checked));
                }
                return question.answerOptions && !!question.answerOptions.find((x) => x.checked);
            case QuestionType.Choice:
                if (question.isDemographic) {
                    return question.answer && question.answer.value;
                }
                return question.answerOptions && !!question.answerOptions.find((x) => x.checked);
            default:
                throw Error('Question type not implemented');
        }
    }

    public static sameQuestionType(a?: Question, b?: Question): boolean {
        if (!a || !b || a.questionType !== b.questionType) {
            return false;
        }

        if (a.answerOptions.length === 0 || a.answerOptions.length !== b.answerOptions.length) {
            return false;
        }

        const firstOptionA = a.answerOptions[0];
        const firstOptionB = b.answerOptions[0];

        return firstOptionA.alias === firstOptionB.alias;
    }

    public static handleQuestionAnswer(question: Question, answer: Answer | IOrderedAnswer, surveyId: number): IPostAnswer {
        const postAnswer = {
            questionId: question.id,
            questionType: question.questionType,
            surveyId: surveyId,
            isDemographic: question.isDemographic,
        } as IPostAnswer;

        switch (question.questionType) {
            case QuestionType.Score:
            case QuestionType.Choice:
                if (question.isDemographic) {
                    postAnswer.answer = answer;
                    break;
                }
                postAnswer.answerId = answer.optionValue;
                break;
            case QuestionType.ArrangeOrder:
                question.answer = answer;
                postAnswer.answer = answer;
                break;
            case QuestionType.Percentage:
                if (!QuestionHelper.valid(question)) {
                    return;
                }
                question.answer = answer;
                postAnswer.answer = answer;
                break;
            case QuestionType.AllThatApply:
                postAnswer.answer = {
                    selectedAnswers: question.answerOptions.filter((x) => x.checked).map((x) => x.id),
                };
                break;
            case QuestionType.OpenQuestion:
                if (question.isDemographic) {
                    postAnswer.answer = answer;
                } else {
                    postAnswer.answer = {
                        answerText: (answer as Answer).answerText,
                    };
                }
                question.answer = answer;
                break;
            default:
                question.answer = answer;
                postAnswer.answer = answer;
                break;
        }

        return postAnswer;
    }

    public static findNextQuestionIndex(questions: Question[]): number {
        let i = 0;
        for (; i < questions.length; i++) {
            if (!QuestionHelper.valid(questions[i])) {
                return i;
            }
        }
        return i - 1;
    }
}
