
import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        amount: { type: Number, default: 1 },
        minWidth: { type: Number, default: 15 },
        maxWidth: { type: Number, default: 100 },
        inline: { type: Boolean },
    },
    methods: {
        width() {
            const width = this.minWidth + Math.ceil(Math.random() * (this.maxWidth - this.minWidth));
            return `${width}%`;
        },
    },
});
