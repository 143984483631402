import CategoryBasic from '@/report/models/ICategoryBasic';
import ScorePerCategory from '@/report/models/ScorePerCategory';
import { ReportService } from '@/generic/services/reportService';
import { ref, defineComponent, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { CategoryType } from '@/report/models/CategoryType';
import PageVue from '@/report/components/Page.vue';
import QuestionCompareScore from '@/report/models/Demographic/IQuestionCompareScore';
import QuestionCompareSection from '@/report/models/Demographic/IQuestionCompareSection';
import ScoreTooltip from '@/report/fearless/partials/ScoreTooltip.vue';
import ScoreBadge from '@/report/fearless/partials/ScoreBadge.vue';

export default defineComponent({
    components: {
        ScoreTooltip,
        ScoreBadge,
        Page: PageVue,
    },
    props: {
        context: { type: String },
        type: { type: String },
        showNames: { type: Boolean },
        options: { type: Array },
    },
    setup() {
        const { params, query } = useRoute();
        const token = query.token.toString();
        const projectId = +params.projectId;
        const scores: Ref<QuestionCompareScore[]> = ref([]);
        const titles: Ref<string[]> = ref([]);
        const loading = ref(false);
        const compare = ref({
            a: '',
            b: '',
        });
        const scoresPerCategory = ref({} as { [key: string]: ScorePerCategory[] });
        const categoryScores = (category: string) => {
            if (!scoresPerCategory.value[category]) {
                return [];
            }

            const scores = {};
            const titles = [];
            scoresPerCategory.value[category].forEach((score: ScorePerCategory) => {
                const mainTitle = score.main.indexOf(':') > -1 ? score.main.split(':')[1] : score.main;

                if (!scores[mainTitle]) {
                    scores[mainTitle] = [];
                }

                scores[mainTitle].push(score);
                const title = score.compare.indexOf(':') > -1 ? score.compare.split(':')[1] : score.compare;
                if (!titles.includes(title)) {
                    titles.push(title);
                }
            });
            return { scores, titles };
        };

        const compareQuestions = async () => {
            if (!compare.value.a || !compare.value.b) {
                return;
            }

            loading.value = true;
            const service = new ReportService();
            const data = await service.getQuestionCompareScores(projectId, compare.value.a, compare.value.b, token);

            scores.value = data.scores;
            titles.value = [];
            if (scores.value.length) {
                scores.value[0].sections.forEach((section: QuestionCompareSection) => {
                    titles.value.push(section.compareAnswer);
                });

                scoresPerCategory.value = {};
                Object.keys(CategoryType).forEach((key: string) => {
                    scoresPerCategory.value[CategoryType[key]] = [];
                });
                scores.value.forEach((score: QuestionCompareScore) => {
                    score.sections.forEach((section: QuestionCompareSection) => {
                        const hasScore = {};
                        section.categories.forEach((cat: CategoryBasic) => {
                            scoresPerCategory.value[cat.category].push({
                                main: score.mainAnswer,
                                compare: section.compareAnswer,
                                median: cat.median,
                            } as ScorePerCategory);
                            hasScore[cat.category] = true;
                        });

                        Object.keys(CategoryType).forEach((key: string) => {
                            if (!hasScore[CategoryType[key]]) {
                                scoresPerCategory.value[CategoryType[key]].push({
                                    main: score.mainAnswer,
                                    compare: section.compareAnswer,
                                    median: null,
                                } as ScorePerCategory);
                            }
                        });
                    });
                });
            }
            loading.value = false;
        };

        const updateQuestion = async (prop, value: string) => {
            compare.value[prop] = value;
            await compareQuestions();
        };

        return {
            compare,
            updateQuestion,
            scores,
            titles,
            loading,
            scoresPerCategory,
            categoryScores,
        };
    },
});
