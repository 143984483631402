import { createRouter, createWebHistory } from 'vue-router';
import genericRoutes from '@/generic/routes';
import surveyRoutes from '@/survey/routes';
import reportRoutes from '@/report/routes';
import dashboardRoutes from '@/dashboard/routes';

const routes: Array<any> = [...genericRoutes, ...surveyRoutes, ...reportRoutes, ...dashboardRoutes];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
