export const enum TokenType {
    SurveyToken = 'survey-token',
    ReportTeamToken = 'report-team-token',
}

export default class AuthHelper {
    public static setToken(key: TokenType, token: string) {
        localStorage.setItem(key, token);
    }

    public static token(key: TokenType): string {
        return localStorage.getItem(key);
    }
}
