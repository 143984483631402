<template>
    <div class="los-category">
        {{ $t(`${type}.${category}_TITLE`, { personalScore: scores.personalScore, teamMedian: scores.teamMedian }) }}
        <span v-if="participant == 'Team'">{{ scores.teamMedian }}</span>
        <span v-else>{{ scores.personalScore }}</span>
    </div>
</template>
<script src="./LosCategory.ts"></script>
<style lang="scss">
.los-category {
    display: flex;
    justify-content: space-between;
    line-height: 1.4;
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
}
</style>
