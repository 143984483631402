export default class DistributionChartData {
    public questionNumber: number;
    public distributions: DistributionData[];
    public description: string; // label shown by the chart

    constructor(obj?: Partial<DistributionChartData>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    public getDistributionSum(): number {
        return this.distributions.reduce((sum, curr) => (sum += curr.value), 0);
    }
}

export class DistributionData {
    public label: string; //what the VALUE represents, and initial tooltip text
    public value: number; //value
    public color?: string; //color of the bar
    public order?: number; //needed? left to right
    public key?: string; //key value to differentiate categories
    public demographicData?: any; //

    constructor(obj?: Partial<DistributionData>) {
        if (obj) {
            Object.assign(this, obj);
        } else {
            this.label = '0%';
            this.value = 0;
        }
    }
}

export class DistributionChartOptions {
    width: string;
    height: string;

    constructor(obj?: Partial<DistributionChartOptions>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class PercentageDistributionChartOptions {
    barWidth: number;

    constructor(obj?: Partial<PercentageDistributionChartOptions>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
