<template>
    <div class="category">
        <survey-subtitle v-t="`${report}.${name}_ROLE_TITLE`" />
        <content-wrapper>
            <survey-translate
                tag="p"
                :base="report"
                :name="`${name}_INTRO`"
                :args="{ context }"
                raw
            />
        </content-wrapper>
        <content-wrapper inverse>
            <survey-translate
                :base="report"
                :name="`${name}_SCORE_DESC`"
                :args="categoryArgs"
            />
        </content-wrapper>

        <bar-chart-wrapper :hide-iteration-picker="hideIterationPicker" :category-key="name" show-quartiles>
            <slot name="charts" />
        </bar-chart-wrapper>

        <content-wrapper v-if="!singleDescription">
            <survey-translate tag="p" :base="report" :name="`${name}_1_QUARTILE_${category.psiQuartile.toUpperCase()}`" :args="categoryArgs" />
            <survey-translate tag="p" :base="report" :name="`${name}_2_QUARTILE_${category.teamQuartile.toUpperCase()}`" :args="categoryArgs" />
            <survey-translate tag="p" :base="report" :name="`${name}_3_${category.standardDeviation.toUpperCase()}`" :args="categoryArgs" />
        </content-wrapper>
        <content-wrapper v-else>
            <survey-translate tag="p" :base="report" :name="`${name}_1_QUARTILE_${category.psiQuartile.toUpperCase()}`" :args="categoryArgs" />
            <survey-translate tag="p" :base="report" :name="`${name}_EXPLANATION`" :args="categoryArgs" raw />
        </content-wrapper>
    </div>
</template>
<script src="./category.ts"></script>