<template>
    <survey-loader v-if="!loaded" />
    <report v-else id="report-controller" class="psi">
        <cover-page :name="user" :date="$filters.date(reportData.dateCompleted)" />

        <page>
            <content-wrapper>
                <survey-translate base="reportsWeb" name="INTRO_START" :args="{ participantName: user, context }" raw />
                <survey-title v-t="'reportsWeb.INTRO_TITLE'" />
                <survey-translate tag="p" base="reportsWeb" name="INTRO_TEASER" raw />
            </content-wrapper>

            <content-wrapper inverse>
                <personal-chart :categories="primary.categories" />
            </content-wrapper>
        </page>

        <page>
            <survey-subtitle v-t="{ path: 'reportsWeb.GENERAL_TITLE', args: { context } }" />
            <content-wrapper inverse>
                <survey-translate
                    base="reportsWeb"
                    name="GENERAL_SCORE_DESC"
                    :args="{
                        context,
                        personalScore: general.personalScore,
                        teamMedian: general.teamMedian,
                        positiveAvg: general.positiveAvg,
                        negativeAvg: general.negativeAvg,
                    }"
                    raw
                />
            </content-wrapper>
            <bar-chart-wrapper show-quartiles :category-key="CategoryType.General">
                <bar-chart :scores="scores(primary, CategoryType.General)" primary />
                <bar-chart :scores="scores(secondary, CategoryType.General)" />
            </bar-chart-wrapper>

            <content-wrapper>
                <survey-translate
                    tag="p"
                    base="reportsWeb"
                    :name="`GENERAL_1_QUARTILE_${general.psiQuartile.toUpperCase()}`"
                    :args="{ context }"
                    raw
                />
                <survey-translate
                    tag="p"
                    base="reportsWeb"
                    :name="`GENERAL_2_QUARTILE_${general.teamQuartile.toUpperCase()}`"
                    :args="{ context }"
                    raw
                />
                <survey-translate tag="p" base="reportsWeb" name="GENERAL_INTRO" />
            </content-wrapper>
        </page>

        <template v-for="key in categoryKeys" :key="`cat_${key}`">
            <page break-before>
                <category :name="CategoryType[key]"
                          report="reportsWeb"
                          :primary="primary"
                          :secondary="secondary"
                          :context="context">
                    <template v-slot:charts>
                        <bar-chart :scores="scores(primary, CategoryType[key])"
                                   primary />
                        <bar-chart :scores="scores(secondary, CategoryType[key])" />
                    </template>
                </category>
            </page>
        </template>

        <page break-before>
            <survey-title v-t="{ path: 'reportsWeb.GENERAL_CONCLUSION', args: { context } }" />
            <content-wrapper>
                <survey-translate
                    tag="p"
                    base="reportsWeb"
                    :name="`GENERAL_3_${general.medianDistribution.toUpperCase()}_${conclusion}`"
                    :args="{ context }"
                    raw
                />

                <survey-translate
                    tag="p"
                    base="reportsWeb"
                    :name="`GENERAL_4_${general.standardDeviation.toUpperCase()}`"
                    :args="{ context }"
                    raw
                />
            </content-wrapper>
        </page>
    </report>
</template>
<script src="./PsiPersonal.ts"></script>