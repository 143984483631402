<template>
    <div class="survey-icon" :class="[`icon-${size}`, color]">
        <component :is="icon" />
    </div>
</template>
<script src="./Icon.ts"></script>
<style lang="scss">
.survey-icon {
    display: inline-block;
    vertical-align: middle;
    line-height: inherit;

    &.icon-sm {
        svg {
            height: 0.75em;
            width: 0.75em;
        }
    }

    &.icon-md {
        svg {
            height: 1em;
            width: 1em;
        }
    }

    &.icon-lg {
        svg {
            height: 1.25em;
            width: 1.25em;
        }
    }

    &.icon-full {
        width: 100%;
        height: 100%;
    }

    svg {
        vertical-align: baseline;
    }
}
</style>