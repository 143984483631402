import BaseReportComponent from '@/report/components/BaseReportComponent';
import ReportWrapperVue from '@/report/components/ReportWrapper.vue';
import { ComputedRef, computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { PitTeamReportData } from '../models/PitReportData';
import { Constructs } from '../models/Constructs';
import PitWheelWrapperVue from '../components/PitWheelWrapper.vue';
import PitExplainerVue from '../components/PitExplainer.vue';
import { PitPersonalReportData } from '../models/PitReportData';
import PitCrossVue from '../components/PitCross.vue';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        ReportWrapper: ReportWrapperVue,
        PitWheelWrapper: PitWheelWrapperVue,
        PitExplainer: PitExplainerVue,
        PitCross: PitCrossVue,
    },
    setup() {
        const store = useStore();
        const reportData: ComputedRef<PitTeamReportData> = computed(() => store.getters['report/report']);
        const participants: ComputedRef<PitPersonalReportData[]> = computed(() => reportData.value.participants);
        const colors = Constructs;
        const filteredParticipants = ref([]);
        const crossParticipants = ref([]);
        filteredParticipants.value = reportData.value.participants.map((participant) => participant.participantId);
        crossParticipants.value = participants.value.filter((participant) => filteredParticipants.value.find((x) => participant.participantId == x));

        const constructPairs = computed(() => {
            return Object.keys(Constructs)
                .filter((x) => !x.split(/(?=[A-Z])/).find((y) => y == 'Color'))
                .reduce((list, elem, index) => {
                    (index + 1) % 2 === 0 ? list[Math.floor(index / 2)].push(elem) : list.push([elem]);
                    return list;
                }, []);
        });

        const viewOptions = [
            { name: 'Team Report', key: 'default' },
            { name: 'Pit Cross Comparer', key: 'cross' },
        ];
        const view = ref(viewOptions[0].key);

        const participantSelected = (participantId: number) => {
            const participant = participants.value.find((p: PitPersonalReportData) => p.participantId === participantId);
            return participant && filteredParticipants.value.includes(participantId);
        };

        const toggleParticipant = (participantId: number) => {
            const i = filteredParticipants.value.indexOf(participantId);
            if (i < 0) {
                filteredParticipants.value.push(participantId);
            } else {
                filteredParticipants.value.splice(i, 1);
            }
            crossParticipants.value = participants.value.filter((participant) =>
                filteredParticipants.value.find((x) => participant.participantId == x),
            );
        };

        const setView = (newView: string) => {
            view.value = newView;
        };

        return {
            colors,
            reportData,
            participants,
            participantSelected,
            toggleParticipant,
            filteredParticipants,
            crossParticipants,
            constructPairs,
            viewOptions,
            setView,
            view,
        };
    },
});
