import Answer from '@/survey/models/Answer';
import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';
import { computed, defineComponent } from 'vue';

export default defineComponent({
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    setup(props, { emit }) {
        const options = [
            { url: '/images/survey/me_org_1.png', value: 1 },
            { url: '/images/survey/me_org_2.png', value: 2 },
            { url: '/images/survey/me_org_3.png', value: 3 },
            { url: '/images/survey/me_org_4.png', value: 4 },
            { url: '/images/survey/me_org_5.png', value: 5 },
        ];

        const selectedValue = computed(() => {
            const option = props.question.answerOptions.find((o: Answer) => o.checked);
            return option ? option.id : 0;
        });

        const onSelect = (value: number) => {
            const option = props.question.answerOptions.find((o: Answer) => o.id === value);
            props.question.answerOptions.forEach((element) => {
                element.checked = false;
            });

            option.checked = true;

            emit('selected', option);
        };

        return { options, onSelect, selectedValue };
    },
});
