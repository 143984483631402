<template>
    <div class="survey-content-wrapper" :class="{ 'inverse': inverse, 'full': full, 'center': center, 'justify-center': justifyCenter }">
        <slot />
    </div>
</template>
<script src="./ContentWrapper.ts"></script>
<style lang="scss">
@import '@/variables';
@import '@/scss/mixins';

.survey-content-wrapper {
    margin-bottom: 1.5rem;
    max-width: 40rem;
    padding: 0;

    &.full {
        max-width: 100%;
    }

    &.inverse {
        margin-left: -2rem;
        margin-right: -2rem;
        padding: 1rem 2rem;
        max-width: none;
    }

    &.center {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &.justify-center {
        margin-left: auto;
        margin-right: auto;
    }

    &, p {
        line-height: 1.5;
        margin-top: 0;
        font-size: $font-size-text;
    }
}

@include print {
    .survey-content-wrapper {
        &.inverse {
            margin-left: 0;
            margin-right: 0;
        }

        &, p {
            font-size: $font-size-text-print;
            line-height: 1.3;
        }
    }
}
</style>
 