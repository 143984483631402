<template>
    <div class="me-org-question">
        <div
            class="me-org-question-option"
            v-for="(option, i) in options"
            :key="`option_${i}`"
            @click="onSelect(option.value)"
            :class="{ active: selectedValue === option.value }"
        >
            <img :src="option.url" />
        </div>
    </div>
</template>
<script src="./MeOrg.ts"></script>
<style lang="scss">
@import '@/variables';

.me-org-question {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0;

    &-option {
        cursor: pointer;
        border: 4px transparent solid;
        transition: 0.3s ease;
        transition-property: box-shadow, transform;
        max-width: 175px;
        text-align: center;
        overflow: hidden;
        z-index: 0;
        background: $white;

        img {
            max-width: 100%;
            margin: 0 -1rem;
        }

        &:hover {
            box-shadow: $shadow;
            transform: scale(1.1);
            z-index: 1;
        }

        &.active {
            border: 4px $accent-color solid;
        }
    }
}
</style>
