import DemographicRepresentationChartData, { DemographicRepresentation } from '@/report/models/Charts/DemographicRepresentationChartData';
import { PropType, computed, defineComponent } from 'vue';

export default defineComponent({
    props: {
        chartData: { type: Object as PropType<DemographicRepresentationChartData> },
    },
    setup(props) {
        const getBarStyle = (value: number, baseline: number, isOverRepBar: boolean): string => {
            if (isOverRepBar) {
                const percentage = value > baseline ? 50 * ((value - baseline) / props.chartData.totalParticipants) : 0;
                return `width:${percentage}%`;
            }
            const percentage = value < baseline ? 50 * ((value - baseline) / props.chartData.totalParticipants) : 0;
            return `width:${percentage}%; left:${50 - percentage}%`;
        };

        const showValue = (value: number, baseline: number, isOverRepBar: boolean): number => {
            if (isOverRepBar) {
                return value > baseline ? value - baseline : null;
            }
            return value < baseline ? baseline - value : null;
        };

        const getGridTemplateColumns = (representation: DemographicRepresentation): string => {
            const underRep = representation.value < representation.baseline;
            const overRep = representation.value > representation.baseline;

            if (underRep) {
                const cijfer = representation.baseline - representation.value;

                return `grid-template-columns: ${50 - (cijfer / representation.baseline / 2) * 100}% ${(cijfer / representation.baseline / 2) *
                    100}% 2px 0% 50%`;
            }

            if (overRep) {
                const cijfer = representation.value - representation.baseline;

                return `grid-template-columns: 50% 0% 2px ${(cijfer / representation.baseline / 2) * 100}% ${50 -
                    (cijfer / representation.baseline / 2) * 100}%`;
            }

            return `grid-template-columns: 50% 0% 2px 0% 50%`;
        };

        return {
            getBarStyle,
            showValue,
            getGridTemplateColumns,
        };
    },
});
