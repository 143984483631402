import Answer from '@/survey/models/Answer';
import { QuestionTypeEmits, QuestionTypeProps } from '@/survey/models/QuestionType';
import { defineComponent, onMounted, ref } from 'vue';
import Sortable from 'sortablejs';
import { SortableEvent } from 'sortablejs';

export default defineComponent({
    emits: QuestionTypeEmits,
    props: QuestionTypeProps,
    setup(props: { question: any }, context) {
        const orderedAnswer = ref([]);
        const orderedOptions = ref([]);
        const listRef = ref();

        props.question.answerOptions.forEach((option: Answer) => {
            orderedAnswer.value.push(option.id);
        });

        const getOrderedOptions = () => {
            const options = props.question.answerOptions;

            orderedOptions.value = options.sort((a: Answer, b: Answer) => {
                const indexA = orderedAnswer.value.indexOf(a.id);
                const indexB = orderedAnswer.value.indexOf(b.id);

                return indexA - indexB;
            });
        };

        const move = (answer: Answer, move: number) => {
            const previous = [...orderedAnswer.value];
            const index = previous.indexOf(answer.id);
            const newIndex = index + move;

            if (newIndex >= 0 && newIndex < orderedAnswer.value.length) {
                orderedAnswer.value[index] = previous[newIndex];
                orderedAnswer.value[newIndex] = answer.id;
            }

            saveAnswer();
            getOrderedOptions();
        };

        const onListUpdate = (fromIndex: number, toIndex: number) => {
            const value = orderedAnswer.value[fromIndex];
            orderedAnswer.value.splice(fromIndex, 1);
            orderedAnswer.value.splice(toIndex, 0, value);
            saveAnswer();
            getOrderedOptions();
        };

        const saveAnswer = () => {
            context.emit('selected', { orderedAnswers: [...orderedAnswer.value] });
        };

        onMounted(() => {
            getOrderedOptions();
            saveAnswer();

            new Sortable(listRef.value, {
                animation: 200,
                ghostClass: 'placeholder',
                handle: '.arrange-order-list-item-handle',
                onUpdate(evt: SortableEvent) {
                    onListUpdate(evt.oldIndex, evt.newIndex);
                },
            });
        });

        return {
            orderedAnswer,
            orderedOptions,
            move,
            listRef,
        };
    },
});
