<template>
    <div v-if="primary || options.length" class="survey-iteration-picker" :class="{ 'primary': primary }">
        <template v-if="!hideLabel">
            <div class="iteration-legend">
                <span class="indicator"></span>

                <span v-if="primary">{{ $t(`generic.GENERAL_PERIOD_CURRENT`) }}</span>
                <span v-else>{{ $t(`generic.GENERAL_PERIOD_PREVIOUS`) }}</span>
            </div>
        </template>

        <survey-select v-if="primary" :disabled="disabled">
            <option selected>{{ $filters.date(primarySelected.dateCompleted || primarySelected.date) }}</option>
        </survey-select>
        <survey-select v-else @select="onSelect" :disabled="disabled">
            <option :value="null" v-t="`generic.GENERAL_PERIOD_COMPARE_DEFAULT`"></option>
            <option
                v-for="(option, index) in options"
                :value="option.name"
                :key="`iteration_${index}`"
                :selected="secondarySelected === option.value"
            >
                {{ $filters.date(option.name) }}
            </option>
        </survey-select>

        <survey-checkbox v-if="primary && options.length" :disabled="disabled" name="showPrimary" v-model="showPrimary" small>
            <span v-t="`generic.HIDE_PERIOD`"></span>
        </survey-checkbox>
    </div>
</template>
<script src="./IterationPicker.ts"></script>
<style lang="scss">
@import '@/variables';

.survey-iteration-picker {
    display: inline-block;
    vertical-align: top;
    margin-right: 1rem;

    select {
        font-size: 0.875rem;
        width: $iteration-picker-width;
        font-weight: 400;
        margin-bottom: 0.25rem;
    }

    .iteration-legend {
        margin: 0 0 0.25rem 0;
        font-size: 0.875rem;
        position: relative;
        padding-left: 15px;

        .indicator {
            position: absolute;
            margin: 0;
            left: 0;
            top: 3px;
            height: 10px;
            width: 10px;
        }
    }
}
</style>
