<template>
    <div id="chart-wrapper">
        <h2 class="rjc-chart-title">{{ chartTitle }}</h2>
        <div id="chart">
            <div :id="`rjc-chart-${chartNumber}`"></div>
        </div>
    </div>
</template>
<script src="./RjcSunburstChart.ts"></script>
<style lang="scss">
#chart {
    position: relative;
    max-height: 1000px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    textPath.text-contour {
        stroke-width: 2;
    }
}

.rjc-chart-title {
    max-height: 3rem;
    height: 3rem;
    text-align: center;
    padding: 0 3rem;
    word-break: break-word;
}
</style>
