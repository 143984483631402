import { defineComponent, onMounted, onUnmounted, PropType, Ref, ref } from 'vue';
import Chart from 'chart.js-latest/auto';
import RadarChartData from '@/report/models/Charts/RadarChartData';
import { computed } from '@vue/reactivity';
import he from 'he';

export default defineComponent({
    props: {
        scores: { type: Array as PropType<RadarChartData[]> },
        labels: { type: Array as PropType<string[]> },
        chartLabelStyle: { type: Object as PropType<{ boxWidth?: number; boxHeight?: number }> },
        width: { type: String, default: '150' },
        height: { type: String, default: '150' },
        minValue: { type: Number, default: 0 },
        maxValue: { type: Number, default: 5 },
        maxTicksLimit: { type: Number, default: 10 },
        showTicks: { type: Boolean, default: false },
        key: { type: String },
        name: { type: String },
        options: { type: Array },
        optionDefault: { type: String },
        compareTo: { type: String },
        compareScores: { type: Array as PropType<RadarChartData[]> },
    },
    setup(props, { emit }) {
        const containerRef: Ref<HTMLElement> = ref();
        const datasets = computed(() => {
            const sets = [];
            if (props.compareTo === 'default' && props.scores) {
                props.scores.forEach((score) => {
                    sets.push({
                        label: he.decode(score.label),
                        data: score.scores.map((x) => x.score),
                        fill: score.fill,
                        backgroundColor: `${score.color}80`,
                        borderColor: `${score.color}`,
                        borderWidth: score.borderWidth,
                        pointRadius: score.pointRadius,
                    });
                });
            } else if (props.compareScores) {
                props.compareScores.forEach((score) => {
                    sets.push({
                        label: he.decode(score.label + ' ' + score.title),
                        data: score.scores.map((x) => x.score),
                        fill: score.fill,
                        backgroundColor: `${score.color}80`,
                        borderColor: `${score.color}`,
                        borderWidth: score.borderWidth,
                        pointRadius: score.pointRadius,
                    });
                });
            }
            return sets;
        });
        const uniqueLabels = computed(() => {
            if (!props.scores) {
                return [];
            }

            if (!props.labels) {
                const uniqueEntries = [];
                props.scores.forEach((data) => {
                    data.scores.forEach((score) => {
                        if (!uniqueEntries.find((x) => x === score.label)) {
                            uniqueEntries.push(score.label);
                        }
                    });
                });
                return uniqueEntries;
            }
            return props.labels;
        });
        const chartAmounts = computed(() => {
            const counts = {};
            if (props.compareTo === 'default' && props.scores) {
                counts['default'] = props.scores.length;
            } else if (props.compareScores) {
                props.compareScores.forEach((score) => {
                    counts[score.entry] = counts[score.entry] ? (counts[score.entry] += 1) : 1;
                });
            }
            return counts;
        });

        const drawChart = () => {
            containerRef.value.innerHTML = '';
            const labels = uniqueLabels.value
                ? uniqueLabels.value.map((t) =>
                      t
                          .split('&')
                          .join('&\n')
                          .split('\n'),
                  )
                : uniqueLabels.value;
            let count = 0;
            const keys = Object.keys(chartAmounts.value);
            if (chartAmounts.value && keys.length > 0) {
                for (let i = 0; i < datasets.value.length; i += chartAmounts.value[keys[count]]) {
                    const canvas = document.createElement('canvas');
                    canvas.setAttribute('width', props.width);
                    canvas.setAttribute('height', props.height);
                    containerRef.value.appendChild(canvas);
                    const ctx = canvas;
                    const data = datasets.value.slice(i, i + chartAmounts.value[keys[count]]);
                    const splittedKeys = keys[count].split(':');
                    let title = 'All respondents';
                    if (splittedKeys.length > 1) {
                        title = splittedKeys[1];
                    }
                    const paddingTop = 40;

                    new Chart(ctx, {
                        type: 'radar',
                        data: {
                            labels: labels,
                            datasets: data,
                        },
                        options: {
                            elements: {
                                line: {
                                    borderWidth: 2,
                                },
                            },
                            events: [],
                            layout: {
                                padding: {
                                    top: paddingTop,
                                },
                            },
                            scales: {
                                r: {
                                    ticks: {
                                        display: props.showTicks,
                                        backdropColor: 'rgba(255, 255, 255, 0.0)',
                                        maxTicksLimit: props.maxTicksLimit,
                                    },
                                    max: props.maxValue,
                                    min: props.minValue,
                                    angleLines: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                legend: {
                                    labels: props.chartLabelStyle,
                                },
                                title: {
                                    display: true,
                                    text: he.decode(title),
                                    position: 'top',
                                    align: 'center',
                                    font: {
                                        size: 16,
                                    },
                                    padding: 0,
                                },
                            },
                        },
                    });
                    count++;
                }
            }
        };

        onMounted(() => {
            drawChart();
            window.addEventListener('resize', drawChart);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', drawChart);
        });

        const onChange = (event) => {
            const value = event.target.value;
            emit('compare', value);
        };

        return { containerRef, onChange };
    },
});
