import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "view-selector",
  id: "view-selector"
}
const _hoisted_2 = {
  key: 0,
  id: "pit-report"
}
const _hoisted_3 = { class: "participant-list-wrapper" }
const _hoisted_4 = { class: "project-checkbox-label" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_survey_button = _resolveComponent("survey-button")!
  const _component_survey_title = _resolveComponent("survey-title")!
  const _component_pit_wheel_wrapper = _resolveComponent("pit-wheel-wrapper")!
  const _component_survey_translate = _resolveComponent("survey-translate")!
  const _component_survey_checkbox = _resolveComponent("survey-checkbox")!
  const _component_page = _resolveComponent("page")!
  const _component_pit_cross = _resolveComponent("pit-cross")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewOptions, (viewOption) => {
        return (_openBlock(), _createBlock(_component_survey_button, {
          key: viewOption.key,
          class: "view-selector-btn",
          onClick: ($event: any) => (_ctx.setView(viewOption.key)),
          variant: _ctx.view === viewOption.key ? 'primary' : 'transparent'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(viewOption.name), 1)
          ]),
          _: 2
        }, 1032, ["onClick", "variant"]))
      }), 128))
    ]),
    (_ctx.view === 'default')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_page, null, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_survey_title, { style: {"text-align":"center"} }, null, 512), [
                [_directive_t, 'reportsWeb.TEAM_REPORT_TITLE']
              ]),
              _createVNode(_component_pit_wheel_wrapper, {
                ref: "chart",
                reportData: _ctx.reportData,
                filteredParticipants: _ctx.filteredParticipants
              }, null, 8, ["reportData", "filteredParticipants"]),
              _createElementVNode("div", null, [
                _createVNode(_component_survey_translate, {
                  class: "participant-title",
                  tag: "div",
                  base: "reportsWeb",
                  name: "PARTICIPANT_LIST_TITLE"
                }),
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.participants, (participant) => {
                    return (_openBlock(), _createBlock(_component_survey_checkbox, {
                      key: `filter_${participant.participantId}`,
                      id: "participant-list",
                      name: `filter_${participant.participantId}`,
                      modelValue: _ctx.participantSelected(participant.participantId),
                      onInput: ($event: any) => (_ctx.toggleParticipant(participant.participantId)),
                      class: "project-checkbox"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", null, _toDisplayString(participant.participantName), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["name", "modelValue", "onInput"]))
                  }), 128))
                ])
              ])
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.constructPairs, (constructs, index) => {
            return (_openBlock(), _createBlock(_component_page, { key: constructs }, {
              default: _withCtx(() => [
                (index === 0)
                  ? (_openBlock(), _createBlock(_component_survey_translate, {
                      key: 0,
                      tag: "h2",
                      base: "reportsWeb",
                      name: "CROSS_TITLE"
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_pit_cross, {
                  participants: _ctx.crossParticipants,
                  "visible-constructs": constructs,
                  "disable-comparer": ""
                }, null, 8, ["participants", "visible-constructs"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]))
      : (_ctx.view === 'cross')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_survey_translate, {
              tag: "h2",
              base: "reportsWeb",
              name: "CROSS_TITLE"
            }),
            _createVNode(_component_pit_cross, { participants: _ctx.crossParticipants }, null, 8, ["participants"])
          ]))
        : _createCommentVNode("", true)
  ], 64))
}