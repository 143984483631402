<template>
    <div class="survey-report" :class="{ 'los-report': los }">
        <slot />
    </div>
</template>
<script src="./Report.ts"></script>
<style lang="scss">
@import '@/scss/mixins';

.survey-report {
    position: relative;
    padding: 1rem 0;
}

@include print {
    .survey-report {
        padding: 0;
    }
}
</style>
