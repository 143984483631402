<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 110 110">
            <!-- outer circle -->
            <circle cx="55" cy="55" r="50" style="fill:none;stroke:white;stroke-width:5" />
            <circle cx="55" cy="55" r="50" id="section-1" />
            <circle cx="55" cy="55" r="50" id="section-2" />
            <circle cx="55" cy="55" r="50" id="section-2-1" />
            <circle cx="55" cy="55" r="50" id="section-3" />
            <circle cx="55" cy="55" r="50" id="section-4" />
            <circle cx="55" cy="55" r="50" id="section-5" />
            <circle cx="55" cy="55" r="50" id="section-6" />
            <circle cx="55" cy="55" r="50" id="section-7" />

            <!-- inner circle -->
            <circle cx="55" cy="55" r="44" style="fill:none;stroke:#E7BA52;stroke-width:5" />
            <circle cx="55" cy="55" r="44" id="section-1a" />
            <circle cx="55" cy="55" r="44" id="section-1b" />
            <circle cx="55" cy="55" r="44" id="section-2a" />
            <circle cx="55" cy="55" r="44" id="section-2b" />
            <circle cx="55" cy="55" r="44" id="section-2b2" />
            <circle cx="55" cy="55" r="44" id="section-3a" />
            <circle cx="55" cy="55" r="44" id="section-3b" />
            <circle cx="55" cy="55" r="44" id="section-4a" />
            <circle cx="55" cy="55" r="44" id="section-4b" />
            <circle cx="55" cy="55" r="44" id="section-5a" />
            <circle cx="55" cy="55" r="44" id="section-5b" />
            <circle cx="55" cy="55" r="44" id="section-6a" />
            <circle cx="55" cy="55" r="44" id="section-6b" />
            <circle cx="55" cy="55" r="44" id="section-7a" />
            <circle cx="55" cy="55" r="44" id="section-7b" />

            <!-- Path for text -->
            <path id="sector-1" style="fill:none;stroke:none;" d="M 5,55.5 A 50,50 0 0 1 105,55.5" />
            <text text-anchor="start">
                <textPath xlink:href="#sector-1" startOffset="51%" class="sunburst-text sunburst-header" v-t="'reportsWeb.CONFLICTHANTERING_TITLE'"></textPath>
            </text>

            <text text-anchor="start">
                <textPath xlink:href="#sector-1" startOffset="79%" class="sunburst-text sunburst-header" v-t="'reportsWeb.SAMENWERKING_TITLE'"></textPath>
            </text>

            <path id="sector-2" style="fill:none;stroke:none;" d="M 105,54.5 A 50,50 0 0 1 5,54.5" />
            <text text-anchor="start">
                <textPath xlink:href="#sector-2" startOffset="8.25%" class="sunburst-text sunburst-header" v-t="'reportsWeb.FLEXIBILITEIT_TITLE'"></textPath>
            </text>

            <text text-anchor="start">
                <textPath xlink:href="#sector-2" startOffset="36.5%" class="sunburst-text sunburst-header" v-t="'reportsWeb.NIEUW_TITLE'"></textPath>
            </text>

            <text text-anchor="start">
                <textPath xlink:href="#sector-2" startOffset="65%" class="sunburst-text sunburst-header" v-t="'reportsWeb.PRESTATIEGERICHTHEID_TITLE'"></textPath>
            </text>

            <path id="sector-3" style="fill:none;stroke:none;" d="M 55.5,105 A 50,50 0 0 1 55.5,5" />
            <text text-anchor="start">
                <textPath xlink:href="#sector-3" startOffset="44%" class="sunburst-text sunburst-header" v-t="'reportsWeb.ZORGVULDIGHEID_TITLE'"></textPath>
            </text>

            <text text-anchor="start">
                <textPath xlink:href="#sector-1" startOffset="22.5%" class="sunburst-text sunburst-header" v-t="'reportsWeb.LEIDERSCHAP_TITLE'"></textPath>
            </text>

            <path id="subsector-1" style="fill:none;stroke:none;" d="M 11,55.5 A 44,44 0 0 1 99,55.5" />
            <text text-anchor="start">
                <textPath xlink:href="#subsector-1" startOffset="51%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.CONFLICTHANTERING_SUBCONSTRUCT_B'">Emotiecontrole</textPath>
            </text>
            <text text-anchor="start">
                <textPath xlink:href="#subsector-1" startOffset="65%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.CONFLICTHANTERING_SUBCONSTRUCT_A'">Assertiviteit</textPath>
            </text>

            <path id="subsector-2" style="fill:none;stroke:none;" d="M 55,11 A 44,44 0 0 1 54,99" />
            <text text-anchor="start">
                <textPath xlink:href="#subsector-2" startOffset="29.5%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.SAMENWERKING_SUBCONSTRUCT_B'">Sfeer nastreven</textPath>
            </text>
            <text text-anchor="start">
                <textPath xlink:href="#subsector-2" startOffset="43%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.SAMENWERKING_SUBCONSTRUCT_A'">Bij groep horen</textPath>
            </text>

            <text text-anchor="start">
                <textPath xlink:href="#subsector-2" startOffset="57.5%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.FLEXIBILITEIT_SUBCONSTRUCT_A'">Verandering</textPath>
            </text>
            <text text-anchor="start">
                <textPath xlink:href="#subsector-2" startOffset="71.5%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.FLEXIBILITEIT_SUBCONSTRUCT_B'">Afmaken</textPath>
            </text>

            <path id="subsector-3" style="fill:none;stroke:none;" d="M 99,54.5 A 44,44 0 0 1 11,54.5" />
            <text text-anchor="start">
                <textPath xlink:href="#subsector-3" startOffset="36.5%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.NIEUW_SUBCONSTRUCT_B'">Impulsiviteit</textPath>
            </text>
            <text text-anchor="start">
                <textPath xlink:href="#subsector-3" startOffset="50.5%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.NIEUW_SUBCONSTRUCT_A'">Kwetsbaarheid</textPath>
            </text>

            <text text-anchor="start">
                <textPath xlink:href="#subsector-3" startOffset="65%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.PRESTATIEGERICHTHEID_SUBCONSTRUCT_B'">Hard werken</textPath>
            </text>
            <text text-anchor="start">
                <textPath xlink:href="#subsector-3" startOffset="79.5%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.PRESTATIEGERICHTHEID_SUBCONSTRUCT_A'">Presteren</textPath>
            </text>

            <path id="subsector-4" style="fill:none;stroke:none;" d="M 55.5,99 A 44,44 0 0 1 55,11" />
            <text text-anchor="start">
                <textPath xlink:href="#subsector-4" startOffset="44%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.ZORGVULDIGHEID_SUBCONSTRUCT_A'">Structuur</textPath>
            </text>
            <text text-anchor="start">
                <textPath xlink:href="#subsector-4" startOffset="58.25%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.ZORGVULDIGHEID_SUBCONSTRUCT_B'">Details</textPath>
            </text>

            <text text-anchor="start">
                <textPath xlink:href="#subsector-1" startOffset="22.5%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.LEIDERSCHAP_SUBCONSTRUCT_B'">Geloof</textPath>
            </text>
            <text text-anchor="start">
                <textPath xlink:href="#subsector-1" startOffset="36.5%" class="sunburst-text sunburst-subheader" v-t="'reportsWeb.LEIDERSCHAP_SUBCONSTRUCT_A'">Wil om te sturen</textPath>
            </text>
        </svg>
</template>
<style lang="scss">
#chart {
    div.sunburst-viz {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sunburst-text {
        font-weight: bold;
        fill: white;
    }

    .sunburst-header {
        font-size: 2px;
    }

    .sunburst-subheader {
        font-size: 1.5px;
    }

    circle {
        stroke-width: 10px;
        fill: transparent;
    }
    #gray {
        stroke: #aaa;
    }
    #section-6 {
        stroke: #6b6ecf;
        stroke-width: 5px;
        stroke-dasharray: 45 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -145.75; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-6a {
        stroke: #6b6ecf;
        stroke-width: 5px;
        stroke-dasharray: 20 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -147.75; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-6b {
        stroke: #9c9ede;
        stroke-width: 5px;
        stroke-dasharray: 20 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -128.25; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }

    #section-5 {
        stroke: #637939;
        stroke-width: 5px;
        stroke-dasharray: 45 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -101; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-5a {
        stroke: #637939;
        stroke-width: 5px;
        stroke-dasharray: 20.5 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -108; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-5b {
        stroke: #8ca252;
        stroke-width: 5px;
        stroke-dasharray: 19.5 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -89; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }

    #section-7 {
        stroke: #393b79;
        stroke-width: 5px;
        stroke-dasharray: 44.82 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -190.75; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-7a {
        stroke: #5255a3;
        stroke-width: 5px;
        stroke-dasharray: 20 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -167.75; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-7b {
        stroke: #393b79;
        stroke-width: 5px;
        stroke-dasharray: 19.70 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -187.62; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-4 {
        stroke: #b4cf6b;
        stroke-width: 5px;
        stroke-dasharray: 45 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -56; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-4a {
        stroke: #b4cf6b;
        stroke-width: 5px;
        stroke-dasharray: 21 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -68; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-4b {
        stroke: #cedb9c;
        stroke-width: 5px;
        stroke-dasharray: 19.75 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -49.25; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-3 {
        stroke: #8c6d31;
        stroke-width: 5px;
        stroke-dasharray: 45 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -11; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-3a {
        stroke: #8c6d31;
        stroke-width: 5px;
        stroke-dasharray: 20.25 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -29; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-3b {
        stroke: #bd9f39;
        stroke-width: 5px;
        stroke-dasharray: 19.75 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -9.75; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-2 {
        stroke: #e7ba52;
        stroke-width: 5px;
        stroke-dasharray: 45 270; /* length of arc, circumference of circle */
        stroke-dashoffset: -280.5; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-2-1 {
        stroke: #e7ba52;
        stroke-width: 5px;
        stroke-dasharray: 40 360; /* length of arc, circumference of circle */
        stroke-dashoffset: 29; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-2a {
        stroke: #e7cb94;
        stroke-width: 5px;
        stroke-dasharray: 20 270; /* length of arc, circumference of circle */
        stroke-dashoffset: -246.75; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-2b {
        stroke: #e7ba52;
        stroke-width: 5px;
        stroke-dasharray: 20 270; /* length of arc, circumference of circle */
        stroke-dashoffset: 23.5; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-2b2 {
        stroke: #e7ba52;
        stroke-width: 5px;
        stroke-dasharray: 20 270; /* length of arc, circumference of circle */
        stroke-dashoffset: 300.25; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-1 {
        stroke: #843c39;
        stroke-width: 5px;
        stroke-dasharray: 45 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -235.5; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-1a {
        stroke: #ad494a;
        stroke-width: 5px;
        stroke-dasharray: 20 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -207.25; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
    #section-1b {
        stroke: #843c39;
        stroke-width: 5px;
        stroke-dasharray: 20 360; /* length of arc, circumference of circle */
        stroke-dashoffset: -227; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    }
}
</style>