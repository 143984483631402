<template>
    <div id="survey-list">
        <survey-skeleton v-if="loading" />
        <survey-title>{{ list.name }}</survey-title>
        <div class="survey-list">
            <survey-skeleton v-if="loading"
                             :amount="3" />
            <template v-else>
                <survey-alert v-if="listItems.length == 0"
                              variant="info">No survey's found yet, please try again later.</survey-alert>
                <div class="survey-item"
                     v-for="item in listItems"
                     :key="item.id">
                    <span class="lead">{{ item.name }}</span>
                    <survey-button v-if="!item.completed"
                                   @click="startSurvey(item)"
                                   v-t="'generic.TEST_START'">
                    </survey-button>
                    <survey-button v-else
                                   disabled>Completed</survey-button>
                </div>
            </template>
        </div>
    </div>
</template>
<script src="./SurveyList.ts"></script>
<style lang="scss">
    .survey-list {
        .survey-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
        }
    }
</style>