import ProjectInOrganizationScore from './ProjectInOrganizationScore';

export default class OrganizationScore {
    public question: string = '';
    public groupName: string = '';
    public projects: ProjectInOrganizationScore[] = [];

    constructor(obj?: Partial<OrganizationScore>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
