import { ContextOption } from './Context';
import { QuestionType } from './QuestionType';

export class SurveyListSurvey {
    instrumentName: string;
    instrumentAlias: string;
    surveyCompleted: boolean;
    surveyId: number;
    surveyDuration: number;
    surveyPriority: number;
    contextOption: ContextOption;
    context?: string;
    firstOpenDate?: string;
}

export interface DemoGraphicSurveyQuestion {
    answerOptions: Array<{ id: number; label: string; value: string }>;
    id: number;
    question: string;
    section: number;
    label: string;
    value?: string;
    order: number;
    questionType: QuestionType;
    view?: string;
}

export interface DemoGraphicSurvey {
    surveyCompleted: boolean;
    questions: DemoGraphicSurveyQuestion[];
    surveyPriority: number;
    surveyDuration: number;
}

export default class SurveyListResponse {
    public name: string = '';
    public projectId: number = 0;
    public surveys: SurveyListSurvey[] = [];
    public demographicSurvey: DemoGraphicSurvey;
    public languages: SurveyListLanguage[];
    public knowledgeModelAlias: string;
    public languageCode: string;
    public knowledgeModelName: string;
    public surveyConfiguration?: SurveyConfiguration;
    public welcomeTexts?: SurveyText[];
    public thankYouTexts?: SurveyText[];
    public closed: boolean;
}

export class SurveyText {
    public text: string;
    public languageCode: string;
}

export class SurveyConfiguration {
    public disableBackNavigation: boolean;
    public isCombinedSurvey: boolean;
}

export class SurveyListLanguage {
    public name: string;
    public languageCode: string;

    constructor(object?: Partial<SurveyListLanguage>) {
        if (object) {
            Object.assign(this, object);
        }
    }
}
