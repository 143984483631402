import Environment from '@/generic/models/Environment';

export default class EnvService {
    public static getEnvironment(): Environment {
        const browserWindow: any = window || {};
        const env = browserWindow.__env || {};

        return new Environment(env);
    }
}
