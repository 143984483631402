export default class Answer {
    public alias: string = '';
    public label?: string;
    public value?: string;
    public id: number = 0;
    public optionValue: number = 0;
    public checked: boolean;
    public answerText?: string = '';
    public order: number = 0;

    public constructor(obj?: Partial<Answer>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
