<template>
    <div id="survey-landing" :key="loadedLanguage">
        <survey-loader v-if="loading" />
        <template v-else>
            <survey-privacy
                v-if="!privacyAccepted"
                :name="knowledgeModelName"
                @accepted="privacyAccepted = true"
                :total-survey-duration="totalSurveyDuration"
                :welcome-text="welcomeText"
            />
            <div v-else>
                <bound-context
                    v-if="needsBoundContext"
                    :name="knowledgeModelName"
                    :survey="activeSurvey"
                    @startContext="combineSurveyStart"
                ></bound-context>
                <survey-questions
                    v-else
                    :questions="questions"
                    :survey="activeSurvey"
                    :surveys="surveys"
                    :disable-previous="disableBackNavigation"
                    :grouped="activeSurvey.groupQuestionsBySection"
                    :has-demographic-section="hasDemographicSection"
                    @question-index-updated="onQuestionUpdate"
                    @finish="onFinish"
                />
                <survey-loader v-if="finishingSurvey" cover text="Finishing survey..." />
            </div>
        </template>
    </div>
</template>
<script src="./SurveyViewCombined.ts"></script>
