import IReportResponse from '@/report/models/IReportResponse';
import { computed, ComputedRef, defineComponent, onMounted, ref, resolveDynamicComponent } from 'vue';
import { useRoute } from 'vue-router';
import { Computed, useStore } from 'vuex';
import psi from '../fearless/reports/PsiPersonal.vue';
import psiTrial from '../fearless/reports/PsiTrial.vue';
import los from '../fearless/reports/LosPersonal.vue';
import baseline from '../kantor/views/Baseline.vue';
import { getPreferredLanguage } from '@/i18n';
import PrintToolbarVue from '../components/partials/PrintToolbar.vue';
import to from 'await-to-js';
import IevpPersonalVue from '../ievp/views/IevpPersonal.vue';
import MainLayoutView from '@/generic/views/MainLayoutView';
import Language from '@/store/models/Language';
import PitPersonalVue from '../pit/views/PitPersonal.vue';

export default defineComponent({
    extends: MainLayoutView,
    components: {
        psi,
        psi_personal: psi,
        los,
        los_personal: los,
        baseline,
        ievp: IevpPersonalVue,
        ievp_personal: IevpPersonalVue,
        psi_trial: psiTrial,
        PrintToolbar: PrintToolbarVue,
        pit_personal: PitPersonalVue,
    },
    setup() {
        const store = useStore();
        const { params } = useRoute();
        const route = useRoute();
        const loaded = ref(false);
        const hasError = ref(false);
        const report: ComputedRef<IReportResponse> = computed(() => store.getters['report/report']);
        const view = computed(() => params.reportAlias.toString());
        const component = resolveDynamicComponent(view.value);

        onMounted(async () => {
            const surveyId = params.surveyId;
            const reportAlias = params.reportAlias;
            const [err] = await to(store.dispatch('report/fetchReport', { surveyId, reportAlias }));
            if (err) {
                loaded.value = true;
                hasError.value = true;
                return;
            }

            if (typeof component === 'string') {
                loaded.value = true;
                hasError.value = true;
                throw new Error('View not supported');
            }

            await store.dispatch('getPillarThresholds', { instrumentAlias: report.value.instrumentAlias });
            const language: Language = store.getters.language;

            // knowledge model translations (if report reloaded, in the normal flow this is loaded on the report list view)
            store.dispatch(
                'setLanguage',
                new Language({
                    knowledgeModelAlias: language.knowledgeModelAlias,
                    knowledgeModelName: language.knowledgeModelName,
                    languageCode: getPreferredLanguage(route),
                    section: 'reports',
                }),
            );

            store.dispatch(
                'setLanguage',
                new Language({
                    knowledgeModelAlias: language.knowledgeModelAlias,
                    knowledgeModelName: language.knowledgeModelName,
                    instrumentAlias: report.value.instrumentAlias,
                    languageCode: getPreferredLanguage(route),
                    section: 'reports',
                    reportAlias: reportAlias.toString(),
                }),
            );

            loaded.value = true;
        });

        return {
            store,
            loaded,
            view,
            hasError,
        };
    },
});
