import ContentWrapperVue from '@/generic/components/ContentWrapper.vue';
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import IevpPillarScore from '../models/IevpPillarScore';
import { IevpReportSectionCategory } from '../models/IevpReportData';

export default defineComponent({
    components: {
        ContentWrapper: ContentWrapperVue,
    },
    props: {
        scores: { type: Array as PropType<IevpPillarScore[]> },
    },
    setup(props) {
        const { t } = useI18n();
        const mappedScores = computed(() => {
            const youLabel = t(`reportsWeb.LABEL_YOUR_VALUE`);
            const organizationLabel = t(`reportsWeb.LABEL_ORGANIZATION_VALUE`);
            const tooltipOrganization = `${t(`reportsWeb.TOOLTIP_YOUR_VALUE`)} <div class="text-muted">(self assessed)</div>`;
            const tooltipPersonal = `${t(`reportsWeb.TOOLTIP_ORGANIZATION_VALUE`)} <div class="text-muted">(self assessed)</div>`;

            return props.scores.map((score: IevpPillarScore) => {
                const personal = score.category === IevpReportSectionCategory.Personal;
                const label = personal ? youLabel : organizationLabel;
                return {
                    personal,
                    label,
                    labelTooltip: `${label}: ${score.score}`,
                    tooltip: personal ? tooltipPersonal : tooltipOrganization,
                    left: ((1.5 + score.score) / 3) * 100,
                    score: score.score,
                };
            });
        });

        return { mappedScores };
    },
});
