import BaseReportComponent from '@/report/components/BaseReportComponent';
import ReportWrapperVue from '@/report/components/ReportWrapper.vue';
import { ComputedRef, computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { PitPersonalReportData } from '../models/PitPersonalReportData';
import { Constructs } from '../models/Constructs';
import PitWheelWrapperVue from '../components/PitWheelWrapper.vue';
import PitExplainerVue from '../components/PitExplainer.vue';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        ReportWrapper: ReportWrapperVue,
        PitWheelWrapper: PitWheelWrapperVue,
        PitExplainer: PitExplainerVue,
    },
    setup() {
        const store = useStore();
        const reportData: ComputedRef<PitPersonalReportData> = computed(() => store.getters['report/report']);
        const colors = Constructs;
        const date = new Date(reportData.value.dateCompleted).toLocaleDateString();

        const translateValues: ComputedRef<PitPersonalReportData> = computed(() => {
            const values: PitPersonalReportData = { ...reportData.value };
            Object.keys(reportData.value).forEach((x) => {
                values[x] = reportData.value[x] == 0 ? 1 : reportData.value[x] == 6 ? 5 : reportData.value[x];
            });
            return values;
        });

        return {
            translateValues,
            colors,
            reportData,
            date,
        };
    },
});
