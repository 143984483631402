import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        score: Number,
        category: String,
        title: String,
        type: String,
    },
    setup(props) {
        const quartiles = ref([]);

        // Supportive Learning Environment
        const SAFETY = [
            { begin: 31, end: 66 },
            { begin: 67, end: 75 },
            { begin: 76, end: 76 },
            { begin: 77, end: 86 },
            { begin: 87, end: 100 },
        ];

        const APPRECIATION = [
            { begin: 14, end: 56 },
            { begin: 57, end: 63 },
            { begin: 64, end: 64 },
            { begin: 65, end: 79 },
            { begin: 80, end: 100 },
        ];

        const OPENNESS = [
            { begin: 38, end: 80 },
            { begin: 81, end: 89 },
            { begin: 90, end: 90 },
            { begin: 91, end: 95 },
            { begin: 96, end: 100 },
        ];

        const REFLECTION = [
            { begin: 14, end: 35 },
            { begin: 36, end: 49 },
            { begin: 50, end: 50 },
            { begin: 51, end: 64 },
            { begin: 65, end: 100 },
        ];

        const LEARNING_ENV = [
            { begin: 31, end: 61 },
            { begin: 62, end: 70 },
            { begin: 71, end: 71 },
            { begin: 72, end: 79 },
            { begin: 80, end: 90 },
        ];

        // Concrete Learning Processes and Practises
        const EXPERIMENTATION = [
            { begin: 18, end: 53 },
            { begin: 54, end: 70 },
            { begin: 71, end: 71 },
            { begin: 72, end: 82 },
            { begin: 83, end: 100 },
        ];

        const INFOCOLLECTION = [
            { begin: 23, end: 70 },
            { begin: 71, end: 79 },
            { begin: 80, end: 80 },
            { begin: 81, end: 89 },
            { begin: 90, end: 100 },
        ];

        const ANALYSIS = [
            { begin: 19, end: 56 },
            { begin: 57, end: 70 },
            { begin: 71, end: 71 },
            { begin: 72, end: 86 },
            { begin: 87, end: 100 },
        ];

        const EDUCATION = [
            { begin: 26, end: 68 },
            { begin: 69, end: 79 },
            { begin: 80, end: 80 },
            { begin: 81, end: 89 },
            { begin: 90, end: 100 },
        ];

        const INFOTRANSFER = [
            { begin: 34, end: 60 },
            { begin: 61, end: 70 },
            { begin: 71, end: 71 },
            { begin: 72, end: 84 },
            { begin: 85, end: 100 },
        ];

        const LEARNING_PROCESS = [
            { begin: 31, end: 62 },
            { begin: 63, end: 73 },
            { begin: 74, end: 74 },
            { begin: 75, end: 82 },
            { begin: 83, end: 97 },
        ];

        // Leadership that reinforces learning
        const LEADERSHIP = [
            { begin: 33, end: 66 },
            { begin: 67, end: 75 },
            { begin: 76, end: 76 },
            { begin: 77, end: 82 },
            { begin: 83, end: 100 },
        ];

        const quartilesPerCategory: Record<string, any[]> = {
            SAFETY,
            APPRECIATION,
            OPENNESS,
            REFLECTION,
            LEARNING_ENV,
            EXPERIMENTATION,
            INFOCOLLECTION,
            ANALYSIS,
            EDUCATION,
            INFOTRANSFER,
            LEARNING_PROCESS,
            LEADERSHIP,
        };

        if (props.category && quartilesPerCategory[props.category.toString()]) {
            quartiles.value = quartilesPerCategory[props.category.toString()];
        }

        return {
            quartiles,
        };
    },
});
