import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    props: {
        primary: { type: Boolean, default: false },
        hideLabel: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        type: { type: String },
    },
    setup(props) {
        const store = useStore();
        const showPrimary = computed({
            get: () => !store.getters['report/showPrimary'],
            set: (v) => {
                store.commit('report/SET_SHOW_PRIMARY', !v);
            },
        });
        const primarySelected = computed(() => {
            return store.getters['report/primary'];
        });
        const secondarySelected = computed(() => {
            return store.getters['report/secondary'];
        });
        const options = computed(() => {
            return store.getters['report/iterations'];
        });
        const onSelect = (optionName: string) => {
            const optionSelected = options.value.find((option: { name: string; value: any }) => {
                return option.name === optionName;
            });

            if (!props.primary) {
                store.commit('report/SET_SECONDARY', optionSelected ? optionSelected.value : null);
            }
        };

        return {
            options,
            primarySelected,
            secondarySelected,
            showPrimary,
            onSelect,
        };
    },
});
