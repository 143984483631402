<template>
    <report los>
        <page>
            <survey-title v-t="`${type}.INTRO_TITLE`" />
            <survey-translate tag="p" style="font-size: smaller;" base="reportsWeb" name="PARTICIPANTS_COMPLETED_VS_INVITED"
                :args="{ invitedParticipants, completedParticipants }" raw />
            <content-wrapper v-sanitize-html="$t(`${type}.INTRO_TEASER`)" />

            <content-wrapper>
                <template v-for="(cat, index) in envCategories" :key="`env_cat_${index}`">
                    <category :participant="participant" :category="cat" :scores="scores(cat)" :type="type" />
                </template>
                <div class="los-score-subheader my-2">
                    Learning Environment Composite <span>{{ average(envCategories) }}</span>
                </div>
                <template v-for="(cat, index) in processCategories" :key="`env_cat_${index}`">
                    <category :participant="participant" :category="cat" :scores="scores(cat)" :type="type" />
                </template>
                <div class="los-score-subheader my-2">
                    Learning Process Composite <span>{{ average(processCategories) }}</span>
                </div>
                <category class="los-last-category" :participant="participant" :category="LosCategoryType.Leadership"
                    :scores="scores(LosCategoryType.Leadership)" :type="type" />
            </content-wrapper>
        </page>

        <div class="los-table-wrapper">
            <table class="los-score-table">
                <tr>
                    <td></td>
                    <td colspan="5" class="los-score-table-header">
                        <h3 class="los-score-header first">Scaled Scores</h3>
                    </td>
                </tr>
                <tr>
                    <td>Building Blocks and Their Subcomponents</td>
                    <td class="los-score-quartile">Bottom quartile</td>
                    <td class="los-score-quartile">Second quartile</td>
                    <td class="los-score-quartile">Median</td>
                    <td class="los-score-quartile">Third quartile</td>
                    <td class="los-score-quartile">Top quartile</td>
                </tr>
                <tr>
                    <td colspan="6" class="los-score-table-header">
                        <h3 class="los-score-header">Supportive Learning Environment</h3>
                    </td>
                </tr>
                <template v-for="(cat, index) in envCategories" :key="`env_cat_${index}`">
                    <tr>
                        <td>
                            <span v-t="`${type}.${cat}_TITLE`"></span>
                        </td>
                        <score :category="cat" :score="score(cat)" :type="type" />
                    </tr>
                </template>
                <tr>
                    <td>Learning Environment Composite</td>
                    <score :score="average(envCategories)" category="LEARNING_ENV" />
                </tr>
                <tr>
                    <td colspan="6" class="los-score-table-header">
                        <h3 class="los-score-header">Concrete learning Processes and Practices</h3>
                    </td>
                </tr>
                <template v-for="(cat, index) in processCategories" :key="`env_cat_${index}`">
                    <tr>
                        <td>
                            <span v-t="`${type}.${cat}_TITLE`"></span>
                        </td>
                        <score :category="cat" :score="score(cat)" :type="type" />
                    </tr>
                </template>
                <tr>
                    <td>Learning Process Composite</td>
                    <score :score="average(processCategories)" category="LEARNING_PROCESS" />
                </tr>
                <tr>
                    <td colspan="6" class="los-score-table-header">
                        <h3 class="los-score-header">Leadership That Reinforces Learning</h3>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span v-t="`${type}.${LosCategoryType.Leadership}_TITLE`"></span>
                    </td>
                    <score :category="LosCategoryType.Leadership" :score="score(LosCategoryType.Leadership)" :type="type" />
                </tr>
            </table>
        </div>
    </report>
</template>
<script src="./LosTeam.ts"></script>
