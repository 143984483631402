import RadarChartScore from './RadarChartScore';

export default class RadarChartData {
    public label: string;
    public scores: RadarChartScore[];
    public color: string;
    public entry: string = '';
    public title: string = '';
    public fill: boolean = true;
    public borderWidth: number = 1;
    public pointRadius: number = 3;

    constructor(obj?: Partial<RadarChartData>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
