<template>
    <page break-before>
        <survey-subtitle v-t="{ path: `${type}.${categoryKey}`, args: { context } }" />
        <content-wrapper tag="p" v-t="{ path: `${type}.${categoryKey}_DESCRIPTION`, args: { context } }" />

        <div class="metadata-table-wrapper">
            <table :type="type">
                <thead>
                    <tr>
                        <th v-t="{ path: `${type}.${categoryKey}_NAME`, args: { context } }"></th>
                        <th
                            v-for="section in scores[0].sections"
                            :key="`table_${section.category}`"
                            v-t="{ path: `${type}.${categoryKey}_${section.category}_ABBREVIATION`, args: { context } }"
                            v-tooltip:grey="
                                $t(`${type}.${section.category}_ROLE_TITLE`, {
                                    context,
                                })
                            "
                        ></th>
                        <th v-t="{ path: `${type}.${categoryKey}_DISTRIBUTION`, args: { context } }"></th>
                    </tr>
                </thead>
                <tr v-for="score in scores" :key="`table_${score.title}`">
                    <td>{{ score.title }} ({{score.groupSize}})</td>
                    <td v-for="section in score.sections" :key="`table_${section.category}`">
                        <survey-badge uppercase :variant="color(section.median)">
                            {{ section.median }}
                        </survey-badge>
                    </td>
                    <td>
                        <survey-badge uppercase :variant="distribution(score.distribution)">
                            {{ score.distribution.toLowerCase() }}
                        </survey-badge>
                    </td>
                </tr>
            </table>
        </div>
    </page>
</template>
<script src="./TeamTable.ts"></script>
<style lang="scss">
@import '@/scss/components/table';

.metadata-table-wrapper {
    @extend .survey-table-shared;
    table {
        tr {
            th, td {
                text-align: left;
                min-width: 50px;
                white-space: nowrap;
            }

            th:nth-child(n + 2), td:nth-child(n + 2) {
                width: 0;
                white-space: nowrap;
                text-align: center;
                vertical-align: middle;
            }
        }
    }
}
</style>
