import DashboardLayoutVue from '@/generic/layouts/DashboardLayout.vue';
import OrganizationDashboardVue from './views/OrganizationDashboard.vue';

export default [
    {
        path: '/dashboard',
        component: DashboardLayoutVue,
        children: [
            {
                path: 'organization/:orgId',
                name: 'org-dashboard',
                component: OrganizationDashboardVue,
            },
        ],
    },
];
