import { appInsightsHub } from '@/store/appInsights';
import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
    setup() {
        const setVhProp = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        const isRegistered = ref(false);
        const isUpdating = ref(false);
        const loading = computed(() => isRegistered.value && isUpdating.value);

        onMounted(() => {
            setVhProp();
            window.addEventListener('resize', setVhProp);

            document.addEventListener('pwa:registered', () => {
                isRegistered.value = true;
            });
            document.addEventListener('pwa:updatefound', () => {
                console.log('Survey app: update found');
                isUpdating.value = false;
            });
            document.addEventListener('pwa:updated', () => {
                console.log('Survey app: updated');
                isUpdating.value = false;
            });

            appInsightsHub.value.init();
        });

        return { loading, isUpdating };
    },
});
